<template>
  <em class="fluid-xs leading-tightest not-italic my-3 inline-block font-medium">{{
    $t(isVideo ? 'watchTime' : 'readTime', { min: readTime })
  }}</em>
</template>

<script>
export default {
  props: {
    readTime: {
      type: Number,
      default: 1
    },
    isVideo: {
      type: Boolean,
      default: false
    }
  }
}
</script>
