<template>
  <svg viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
    <g fill="currentColor" fill-rule="evenodd">
      <path d="M3.6 0h1.8v9H3.6z" />
      <path d="M9 3.6v1.8H0V3.6z" />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PlusIcon'
}
</script>
