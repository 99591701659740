<template>
  <transition name="drawer" appear>
    <div v-if="isOpen" class="fixed inset-0 z-50">
      <div class="backdrop absolute inset-0" @click="close" />
      <nav class="mobile-drawer w-80 h-full overflow-y-auto bg-white flex flex-col">
        <div
          class="bg-putty fluid-xs leading-snug uppercase font-medium shrink-0 flex items-center justify-between h-12 pl-2 pr-3"
        >
          <div class="flex h-full">
            <button
              class="fluid-xs leading-snug uppercase font-medium flex items-center h-full px-2"
              @click="toggleTopSection('LOCALES')"
            >
              {{ currentLang }}
              <IconChevron
                class="ml-2 transition-all transform"
                :class="{ 'rotate-180': currentlyOpenSection === 'LOCALES' }"
              />
            </button>
          </div>
          <div class="flex h-full">
            <button class="flex items-center h-full px-2" @click="toggleTopSection('HELP')">
              <IconHelp :is-active="currentlyOpenSection === 'HELP'" />
            </button>
            <button v-if="accountEnabled" class="flex items-center h-full px-2" @click="toggleTopSection('ACCOUNT')">
              <IconAccount :is-active="currentlyOpenSection === 'ACCOUNT' || $auth.loggedIn" />
            </button>
            <button class="fluid-xs leading-snug uppercase font-medium flex items-center h-full px-2" @click="close">
              <CloseIcon class="w-4" />
            </button>
          </div>
        </div>
        <Expandable :open="isTopSectionOpen">
          <div>
            <MiniNav v-show="currentlyOpenSection === 'LOCALES'" :nav-type="'LOCALES'" @onClick="close" />
            <MiniNav v-show="currentlyOpenSection === 'HELP'" :nav-type="'HELP'" @onClick="close" />
            <MiniNav v-show="currentlyOpenSection === 'ACCOUNT'" :nav-type="'ACCOUNT'" @onClick="close" />
          </div>
        </Expandable>
        <TopLevelMobileNav ref="refTopLevelMobileNav" class="border-t" :nav-tops="navTops" @onClick="closeTopSection" />
        <MobileContactBubble />
      </nav>
    </div>
  </transition>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import MobileContactBubble from '@/components/layout/header/mobileDrawer/MobileContactBubble'
import TopLevelMobileNav from '@/components/layout/header/mobileDrawer/TopLevelMobileNav'
import IconAccount from '@/components/layout/header/IconAccount'
import IconChevron from '@/components/layout/header/IconChevron'
import Expandable from '~/components/common/Expandable'
import MiniNav from '~/components/layout/header/MiniNav'
import IconHelp from '~/components/layout/header/IconHelp'
import CloseIcon from '~/components/icons/CloseIcon'

export default {
  components: {
    IconHelp,
    MiniNav,
    Expandable,
    IconChevron,
    IconAccount,
    TopLevelMobileNav,
    MobileContactBubble,
    CloseIcon
  },
  data() {
    return {
      currentlyOpenSection: null, // "LOCALES", "HELP", "ACCOUNT",
      isTopSectionOpen: false
    }
  },
  computed: {
    ...mapState('mainNav', ['navTops', 'isOpen']),
    currentLang() {
      const { locale } = this.$i18n
      return locale
    },
    accountEnabled() {
      return this.$config.auth.enabled
    }
  },
  methods: {
    ...mapActions('mainNav', ['open', 'close']),
    toggleTopSection(sectionName) {
      if (this.currentlyOpenSection === sectionName) {
        this.closeTopSection()
      } else {
        this.currentlyOpenSection = sectionName
        this.$nextTick(() => (this.isTopSectionOpen = true))
        this.$refs.refTopLevelMobileNav.collapseOpenNav()
      }
    },
    closeTopSection() {
      this.currentlyOpenSection = null
      this.isTopSectionOpen = false
    }
  }
}
</script>

<style scoped>
.drawer-enter-active,
.drawer-leave-active {
  transition: all 300ms linear;
}

.mobile-drawer {
  transition: transform 300ms ease;
}

.drawer-enter .backdrop,
.drawer-leave-to .backdrop {
  @apply opacity-0;
}

.drawer-enter .mobile-drawer,
.drawer-leave-to .mobile-drawer {
  transform: translateX(-100%);
  @apply translate-x-[-100%];
}
.backdrop {
  transition: opacity 300ms ease;
  background-color: rgba(0, 0, 0, 0.75);
  @apply z-[-1];
}
</style>
