export default {
  props: {
    tsProduct: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    reviewsList() {
      return this.tsProduct?.reviews || [];
    },
    totalScore() {
      const scores =
        this.reviewsList?.length && this.reviewsList.map((element) => parseInt(element.mark)).reduce((a, b) => a + b);
      return scores ? Number(scores / this.totalCount) : 0;
    },
    totalCount() {
      return this.reviewsList?.length;
    },
    worstScore() {
      return this.scoreGroup.size && Array.from(this.scoreGroup)[this.scoreGroup.size - 1][0];
    },
    bestScore() {
      return this.scoreGroup.size && this.scoreGroup.keys().next().value;
    },
    recentTopReviews() {
      return (
        this.reviewsList.length &&
        this.reviewsList
          ?.filter((item) => !!item.comment && !!item.reviewer?.profile.firstname && item.mark >= 4)
          .slice(0, 5)
      );
    },
    reviewsCount() {
      return this.reviewsList?.filter((item) => !!item.comment).length;
    },
    scoreGroup() {
      const scores =
        this.reviewsList?.length &&
        this.reviewsList
          ?.map((element) => parseInt(element.mark))
          .reduce((acc, e) => acc.set(e, (acc.get(e) || 0) + 1), new Map());

      return scores ? new Map([...scores.entries()].sort().reverse()) : new Map();
    },
  },
};
