<template>
  <button
    :disabled="lineItems.length === 0"
    class="bg-br-green font-medium hover:text-white flex items-center justify-center w-full h-12 text-center text-white transition-all rounded-full shadow-lg"
    @click="handleCheckout"
  >
    {{ $t('cart.checkOut') }}
  </button>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('cart', ['lineItems']),
  },
  methods: {
    async handleCheckout() {
      const url = await this.$store.dispatch('cart/checkoutUrl');

      if (url) {
        window.open(url, '_self');
      }
    },
  },
};
</script>
