<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6">
    <g fill="currentColor" fill-rule="evenodd">
      <path
        d="M0 0H1V4H0z"
        transform="translate(-1281.000000, -30.000000) translate(1251.000000, 27.000000) translate(33.000000, 6.000000) rotate(-45.000000) translate(-33.000000, -6.000000) translate(31.000000, 4.000000)"
      />
      <path
        d="M1.5 1.5H2.5V5.5H1.5z"
        transform="translate(-1281.000000, -30.000000) translate(1251.000000, 27.000000) translate(33.000000, 6.000000) rotate(-45.000000) translate(-33.000000, -6.000000) translate(31.000000, 4.000000) translate(2.000000, 3.500000) rotate(90.000000) translate(-2.000000, -3.500000)"
      />
    </g>
  </svg>
</template>

<script>
export default {}
</script>
