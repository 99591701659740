<template>
  <nuxt-link
    :to="to"
    :class="{
      'btn-tag-fill': !secondary,
      'btn-tag-outline': secondary
    }"
    class="btn-tag"
  >
    <slot />
  </nuxt-link>
</template>

<script>
export default {
  props: {
    to: {
      type: [Object, String],
      required: true
    },
    secondary: {
      type: Boolean,
      default: false
    }
  }
}
</script>
