<template>
  <button
    class="button text-blackly hover:text-br-green focus:outline-none group flex flex-row items-center h-full w-auto justify-center min-w-[38px] min-h-[38px] hover:scale-125"
    @click="$emit('click')"
  >
    <div style="" class="svg-wrapper transition-all h-full w-[100px]">
      <PlayIcon class="h-10" />
    </div>
    <div v-if="$slots.default" class="pl-2 fluid-xs leading-tightest text-white"><slot /></div>
  </button>
</template>

<script>
import PlayIcon from '@/components/icons/PlayIcon'
export default {
  components: { PlayIcon },
  props: {}
}
</script>
