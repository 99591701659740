<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g v-if="!isActive" fill="none" fill-rule="evenodd">
      <path
        d="M13.78 13.4H10.221C9.584 13.4 4 13.484 4 16.4V20.6H20V16.4C20 13.484 14.416 13.4 13.779 13.4H13.78ZM18.738 19.4H5.264V16.4C5.264 15.043 8.604 14.602 10.222 14.6H13.781C15.398 14.6 18.739 15.04 18.739 16.4L18.738 19.4ZM11.601 11.8C14.031 11.8 16.001 9.83 16.001 7.4C16.001 4.97 14.031 3 11.601 3C9.171 3 7.201 4.97 7.201 7.4C7.201 9.83 9.171 11.8 11.601 11.8ZM11.601 4.228C13.351 4.228 14.773 5.651 14.773 7.4C14.773 9.15 13.35 10.572 11.601 10.572C9.851 10.572 8.429 9.149 8.429 7.4C8.429 5.65 9.852 4.228 11.601 4.228Z"
        fill="black"
      />
    </g>
    <g v-if="isActive" fill="none" fill-rule="evenodd">
      <path
        d="M13.78 13.4H10.221C9.584 13.4 4 13.484 4 16.4V20.6H20V16.4C20 13.484 14.416 13.4 13.779 13.4H13.78ZM11.601 11.8C14.031 11.8 16.001 9.83 16.001 7.4C16.001 4.97 14.031 3 11.601 3C9.171 3 7.201 4.97 7.201 7.4C7.201 9.83 9.171 11.8 11.601 11.8Z"
        fill="black"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>
