<template>
  <div v-if="deal && bundleAvailable" :class="{ 'my-12': !ctaBar }">
    <div v-if="ctaBar" :class="[ctaLightGreen ? 'bg-br-green-light' : 'bg-br-green']">
      <div class="container">
        <p
          class="ctaBar py-4 inline-block fluid-base leading-tight w-full text-center font-normal"
          :class="[ctaLightGreen ? 'light-theme text-black' : 'dark-theme text-white']"
        >
          <span
            class="inline-block md:border-r-2 px-4"
            :class="[ctaLightGreen ? 'border-black' : 'border-white']"
            v-html="ctaBarHeading"
          /><button
            class="expandButton inline-block underline underline-offset-8 px-4 font-normal transition-all"
            :class="[ctaLightGreen ? 'hover:text-br-green' : 'hover:text-black', open ? 'expanded' : '']"
            @click.prevent="open = !open"
          >
            {{ ctaBarAction }}
          </button>
        </p>
      </div>
    </div>
    <component
      :is="!ctaBar ? 'div' : 'Expandable'"
      v-track:view.item="gtmData()"
      :open="open || !ctaBar"
      :duration="300"
      scroll-into-view
    >
      <div class="container flex flex-row flex-wrap md:flex-no-wrap py-10" :class="{ 'md:shadow-xl pt-0': !ctaBar }">
        <div class="flex flex-col w-full md:w-3/5 lg:w-1/2 order-2 md:order-1 px-0 md:px-10">
          <h2 class="py-3 md:py-10 md:border-b border-gray-400 w-full hidden md:block">{{ heading }}</h2>
          <div class="checkmark-bullets checkmark-noborder w-full">
            <ul class="md:mt-8 flex flex-row flex-wrap fluid-sm">
              <li v-for="(row, index) in bulletpoints" :key="index" class="w-full !my-2">
                {{ row.point }}
              </li>
            </ul>
          </div>
          <div class="text-center md:text-left">
            <ProductPriceLine
              v-if="deal.bundlePrice"
              class="my-4"
              :price="deal.bundlePrice"
              :compare-price="deal.bundleCompareAtPrice"
            />
          </div>
          <div class="mt-4 text-center md:text-left md:whitespace-nowrap">
            <CtaButton
              class="mr-1.5"
              :disabled="!bundleAvailable"
              :loading="isSubmitting"
              @click.native="addDealToCart"
              >{{ $t('cart.addToCart') }}</CtaButton
            >
            <nuxt-link :to="$localeRootPath('/products/' + deal.handle)">
              <button
                v-track:click.item="gtmData()"
                class="btn-pill-white whitespace-nowrap mt-2"
                data-testid="btnViewProduct"
              >
                {{ $t('shop.viewProduct') }}
              </button>
            </nuxt-link>
          </div>
        </div>
        <div class="flex flex-col justify-center items-center w-full md:w-2/5 lg:w-1/2 order-1 md:order-2 md:py-12">
          <h2 class="w-full block md:hidden text-center">{{ heading }}</h2>
          <FixedAspect :ratio="2 / 3" class="w-full max-w-xs md:max-w-none">
            <CldnImg
              v-if="bundleImage"
              :src="bundleImage.src"
              :alt="bundleImage.alt"
              :ratio="2 / 3"
              crop="fit"
              loading="lazy"
              sizes="(min-width: 1440px) 648px, (min-width: 1024px) 50vw, 100vw"
            />
          </FixedAspect>
        </div>
      </div>
    </component>
  </div>
</template>

<script>
import FixedAspect from '@/components/common/FixedAspect';
import CldnImg from '@/components/CldnImg';
import Expandable from '@/components/common/Expandable';
import ProductPriceLine from '@/components/shop/products/ProductPriceLine';
import CtaButton from '@/components/common/CtaButton';
import { mapGetters } from 'vuex';
import { addVariantToCart } from '@/utils/product';

export default {
  name: 'Upsell',

  components: {
    FixedAspect,
    CldnImg,
    Expandable,
    ProductPriceLine,
    CtaButton,
  },

  props: {
    heading: {
      type: String,
      default: null,
    },
    bulletpoints: {
      type: Array,
      default: () => [],
    },
    ctaBar: {
      type: Boolean,
      default: true,
    },
    ctaLightGreen: {
      type: Boolean,
      default: false,
    },
    ctaBarHeading: {
      type: String,
      default: null,
    },
    ctaBarAction: {
      type: String,
      default: null,
    },

    upsellProduct: {
      type: [String],
      default: null,
    },

    discountCode: {
      type: String,
      default: null,
    },
    discountValue: {
      type: Number,
      default: null,
    },
    discountType: {
      type: String,
      default: null,
      validator: (value) => ['percentage', 'fixedAmount'].includes(value),
    },
  },

  data() {
    return {
      open: false,
      deal: null,
      isSubmitting: false,
    };
  },

  async fetch() {
    // Quit early if we don't want to show the upsell teasers
    if (this.$store.state.app.showUpsellTeasers === false) {
      console.warn('Upsell component loaded, but upsell teasers are disabled globally.');
      return;
    }

    if (!this.upsellProduct) {
      console.warn('Upsell component loaded, but no upsell product was provided.');
      return;
    }

    // find shopify product
    this.deal = await this.$shopifyProductByHandle(this.upsellProduct);

    if (!this.deal) {
      console.warn('no deal found for', this.upsellProduct);
      return;
    }

    if (this.discountCode && this.discountValue && this.discountType && this.firstVariant) {
      if (this.discountType === 'percentage') {
        this.deal.bundlePrice = {
          amount: this.firstVariant.price.amount * (1 - this.discountValue / 100),
          currencyCode: this.firstVariant.price.currencyCode,
        };
      } else {
        this.deal.bundlePrice = {
          amount: this.firstVariant.price.amount - this.discountValue,
          currencyCode: this.firstVariant.price.currencyCode,
        };
      }

      this.deal.bundleCompareAtPrice = this.firstVariant.price;
    } else {
      this.deal.bundlePrice = this.firstVariant.price;
      this.deal.bundleCompareAtPrice = this.firstVariant.compareAtPrice;
    }
  },

  computed: {
    ...mapGetters('cart', ['lineItems']),

    bundleImage() {
      try {
        return this.deal?.images.edges[0].node;
      } catch (e) {
        return false;
      }
    },

    firstVariant() {
      return this.deal?.variants.edges[0].node;
    },

    bundleAvailable() {
      return (this.deal && this.firstVariant.id && this.deal?.availableForSale) ?? false;
    },
  },

  methods: {
    async addDealToCart() {
      try {
        this.isSubmitting = true;

        if (this.discountCode) {
          await this.$store.dispatch('cart/applyDiscount', this.discountCode);
        }
        await addVariantToCart.call(this, this.deal, this.firstVariant, false);

        this.$store.dispatch('cart/open');
      } catch (e) {
        console.warn(e);
      } finally {
        this.isSubmitting = false;
      }
    },

    gtmData(payload) {
      try {
        const product = this.deal;
        const variant = product.variants?.edges[0].node;
        const itemSKU = variant.sku;

        return {
          item_list_id: [this.$route?.name, this.heading.toLowerCase().replace(/ /g, '-')].join(' / '),
          item_list_name: [this.$route?.name, this.heading].join(' / '),
          index: 0,
          item_id: itemSKU,
          price: variant.price.amount,
          currency: variant.price.currencyCode,
          item_category: product.productType,
          item_name: product.title,
        };
      } catch (e) {
        console.warn(e);
        return {};
      }
    },
  },
};
</script>

<style lang="postcss">
.expandButton:after {
  content: '\2193';
  font-family: 'system-ui';
  @apply inline-block underline underline-offset-8;
}
.expandButton.expanded:after {
  content: '\2191';
}
.ctaBar.light-theme .text__bg--highlight {
  @apply text-br-green font-normal;
}
.ctaBar.dark-theme .text__bg--highlight {
  @apply text-black font-normal;
}
</style>
