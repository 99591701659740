<template>
  <div v-if="!isExpired">
    {{ days }} days | {{ hours }} h | {{ minutes }} m | <span class="w-7 inline-block text-left">{{ seconds }} s</span>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    dateTime: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      now: new Date().getTime()
    }
  },
  computed: {
    countDownDate() {
      return new Date(this.dateTime).getTime()
    },
    distance() {
      return this.countDownDate - this.now
    },
    days() {
      return Math.floor(this.distance / (1000 * 60 * 60 * 24))
    },
    hours() {
      return Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    },
    minutes() {
      return Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60))
    },
    seconds() {
      return Math.floor((this.distance % (1000 * 60)) / 1000)
    },
    isExpired() {
      return this.distance < 0
    }
  },
  mounted() {
    if (this.isExpired) {
      this.close()
    }

    const _this = this
    this.interval = setInterval(function () {
      _this.count()
    }, 1000)
  },
  beforeUnmount() {
    clearInterval(this.interval)
  },
  methods: {
    ...mapActions('globalMarketingBar', ['close']),
    count() {
      this.now = new Date().getTime()
    }
  }
}
</script>
