<template>
  <div v-if="notificationActive" class="bg-br-green-light p-4" :style="style">
    <div class="fluid-xs leading-tightest font-medium">{{ notificationHeading }}</div>
    <div class="fluid-xs leading-tightest font-light mt-2" v-html="notificationBody"></div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { mapState } from 'vuex'

export const CartNotificationFragment = gql`
  fragment CartNotificationFragment on cartSettings_GlobalSet {
    notificationActive
    notificationFontColor
    notificationBackgroundColor
    notificationHeading
    notificationBody
  }
`
export default {
  computed: {
    ...mapState('cartSettings', [
      'notificationActive',
      'notificationFontColor',
      'notificationBackgroundColor',
      'notificationHeading',
      'notificationBody'
    ]),
    style() {
      return {
        color: this.notificationFontColor,
        backgroundColor: this.notificationBackgroundColor
      }
    }
  }
}
</script>
