<template>
  <div class="flex py-l h-screen">
    <div class="mx-auto text-center max-w-3xl">
      <h1 v-if="error.statusCode">{{ errorMessage }}</h1>
      <div class="flex justify-center gap-8 mt-8">
        <nuxt-link :to="$localeRootPath('/')" class="font-medium inline-flex items-center"
          >{{ $t('errorPages.goToHomepage') }}<InlineArrowIcon class="ml-2 h-2"
        /></nuxt-link>
        <nuxt-link :to="$localeRootPath('/shop')" class="font-medium inline-flex items-center"
          >{{ $t('errorPages.goToTheShop') }}<InlineArrowIcon class="ml-2 h-2"
        /></nuxt-link>
      </div>
    </div>
  </div>
</template>

<script>
import InlineArrowIcon from '@/components/icons/InlineArrowIcon'
import entryPage from '@/mixins/entryPage'
export default {
  components: { InlineArrowIcon },
  mixins: [entryPage],
  layout: 'default',
  scrollToTop: true,
  props: {
    error: {
      type: Object,
      default: () => {}
    }
  },
  head() {
    return {
      title: this.pageTitle
    }
  },
  computed: {
    errorMessage() {
      switch (this.error.statusCode) {
        case 500:
          return this.$t('errorPages.500')
        case 410:
          return this.$t('errorPages.410')
        default:
          return this.$t('errorPages.404')
      }
    },
    pageTitle() {
      switch (this.error.statusCode) {
        case 500:
          return '500-Error: Internal server error'
        case 410:
          return '410-Error: Gone forever'
        case 404:
          return '404-Error: Not found'
        default:
          return this.error.statusCode + '-Error'
      }
    }
  },
  mounted() {
    const eventData = {
      page_title: this.pageTitle,
      page_path:
        '/' +
        this.error.statusCode +
        '?page=' +
        document.location.pathname +
        document.location.search +
        '&from=' +
        document.referrer
    }
    this.gtmPushPageMeta({ ...eventData })
  }
}
</script>
