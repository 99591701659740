import { Bus } from '@/utils/bus';
import { gql } from 'graphql-tag';
import { productTransformer, discountCalculator, BND_TYPE, FBT_TYPE } from './helpers';

export default async ({ app, $craftRequest }, inject) => {
  const {
    store,
    $shopifyProductsRequest,
    i18n: { locale, locales },
  } = app;

  const query = gql`
    query ($site: String!) {
      pickystory: globalSet(site: [$site], handle: "pickystory") {
        ... on pickystory_GlobalSet {
          enableCartXSellTeasers
          enableUpsellTeasers
          enableXSellTeasers
        }
      }
    }
  `;

  let settings = {};

  try {
    settings = await $craftRequest(query, { site: locale });
  } catch (e) {
    console.error(e);
  }

  let pickyEnabled = true;

  if (!settings.pickystory.enableCartXSellTeasers && !settings.pickystory.enableXSellTeasers) {
    pickyEnabled = false;
  }

  const { shopifyApiAccessToken, shopifyName } = locales.find((l) => l.code === locale);

  const pickyScript = {
    hid: 'pickystory-widget',
    src: 'https://cdn.pickystory.com/widget/dist/latest/pickystory-widget.min.js',
    defer: true,
  };

  const debugToken = process.env.pickystoryDebugToken ?? '';

  const pickySettings = {
    hid: 'pickstory-settings',
    innerHTML: `
    window.Shopify = {
      shop: '${shopifyName}',
    }
    window.pickystory = {
      storefrontApiEnable: true,
      discountDebugToken: '${debugToken}',
    };
    `,
  };

  if (pickyEnabled) {
    app.head.script = [...app.head.script, pickySettings, pickyScript];
  }

  if (typeof window !== 'undefined') {
    window.addEventListener('pickystory', async (event) => {
      if (event?.detail?.name === 'API_READY') {
        Bus.$emit('pickystory-ready');
      }

      if (event?.detail?.name === 'DEAL_DISCOUNTS') {
        store.commit('cart/SET_ASYNC_SUBMITTING', true);
        try {
          if (event.detail.payload.discount && event.detail.payload.discount.code) {
            await store.dispatch('cart/refreshCheckout');
          } else {
            // we do not have any deal active so we need to clean the state of the line items
            await store.dispatch('cart/refreshCheckoutAndAttributes');
          }
        } catch (error) {
          console.log('Error refreshing checkout after Picky Event');
        } finally {
          store.commit('cart/SET_ASYNC_SUBMITTING', false);
        }
      }
    });
  }

  const initPickystoryCart = (cartId) => {
    if (!window) {
      // we cannot use window object on server side
      console.warn('Pickystory can not be initialized, window is not defined');
      return;
    }

    if (window.pickystory && window.pickystory.client) {
      window.pickystory.client.setStorefrontOptions({
        token: shopifyApiAccessToken,
        checkoutMode: false,
        cartId,
      });
    } else {
      // Pickystory might not be ready yet, so we can postpone the initialization
      // until the API_READY event is fired
      Bus.$on('pickystory-ready', () => {
        window.pickystory.client.setStorefrontOptions({
          token: shopifyApiAccessToken,
          checkoutMode: false,
          cartId,
        });
      });
    }
  };

  const findDeal = async (location = null, productHandle, type, maxProducts = false) => {
    const { client } = window.pickystory;

    const locations = client.getLocations(type);

    // if no deal group name is provided, use the first one
    const dealGroup = location ? locations.find((loc) => loc.name === location) : locations[0];

    if (!dealGroup) {
      return Promise.reject(new Error('Deal group not found'));
    }

    // find deals based on current product
    const deals = dealGroup.allDeals
      .filter((deal) => {
        return deal.products.some((product) => {
          return product.handle === productHandle;
        });
      })
      .filter((deal) => {
        if (!maxProducts) {
          return true;
        }

        return deal.products.length <= maxProducts;
      });

    if (deals.length > 0) {
      // for now get just the first deal
      // TODO: this here would be a good place to further specify which deal to use
      const deal = deals[0];

      // get shopify information for all products in the deal
      const shopifyProducts = await $shopifyProductsRequest(
        [
          ...deal.products.map((product) => product.handle),
          ...deal.addonProducts.map((product) => product.handle),
          deal.containerProduct ? deal.containerProduct.handle : null,
        ].filter((handle) => handle !== null)
      );

      return Promise.resolve(
        discountCalculator(
          {
            pickystory: deal, // keep this as reference to call deal functions

            // transform the other stuff we may need for rendering blackroll components into properties
            id: deal.id,
            category: deal.category,
            discountType: deal.discountType,
            discountValue: deal.discountValue,
            products: deal.products.map((p) => productTransformer(p, shopifyProducts)),
            discountProducts: deal.addonProducts.map((p) => productTransformer(p, shopifyProducts)),
            containerProduct: deal.containerProduct ? productTransformer(deal.containerProduct, shopifyProducts) : null,
            isBogo: deal.spec.discount?.target === 'addon',
          },
          type
        )
      );
    }

    return Promise.resolve(new Error(`No deal found for handle ${productHandle} in ${location}`));
  };

  inject('pickystory', {
    isPickyStoryReady: () => {
      return window.pickystory?.client && window.pickystory.storefrontApiEnable;
    },
    initPickystoryCart,
    findDeal,
    types: {
      BND_TYPE,
      FBT_TYPE,
    },
  });
};
