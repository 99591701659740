<template>
  <div class="space-x-4 h-5" :class="!leftArrowDisabled || !rightArrowDisabled ? 'inline-flex' : 'hidden'">
    <ArrowButton
      direction="left"
      :arrow-disabled="leftArrowDisabled"
      :inverted="inverted"
      @click="$emit('onLeftClick')"
    />
    <ArrowButton
      direction="right"
      :arrow-disabled="rightArrowDisabled"
      :inverted="inverted"
      @click="$emit('onRightClick')"
    />
  </div>
</template>

<script>
import ArrowButton from '@/components/common/ArrowButton'

export default {
  components: { ArrowButton },
  props: {
    leftArrowDisabled: {
      type: Boolean,
      default: false
    },
    rightArrowDisabled: {
      type: Boolean,
      default: false
    },
    inverted: {
      type: Boolean,
      default: false
    }
  }
}
</script>
