<template>
  <img
    :src="generatedSrc"
    :srcset="cldnSrcSet"
    :sizes="sizes"
    :loading="loading"
    :alt="alt === '' ? false : alt"
    :data-prop-issues="propIssues"
    :class="[hFull ? 'h-full' : '', wFull ? 'w-full' : '']"
  />
</template>

<script>
import { RESOLUTIONS } from '@/plugins/cloudinary.js';
export const heightOrDerived = (ratio, width, height = null) =>
  height || (ratio && width ? (parseFloat(width) * parseFloat(ratio)).toFixed() : null);

/**
 * renders img tag of passed bucket-image  passed via :src
 * renders img tag of passed bucket-video passed vis :src if :videoPreview=true
 */
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    transformation: {
      type: String,
      default: null,
    },
    width: {
      type: [Number, String],
      default: null,
    },
    height: {
      type: [Number, String],
      default: null,
    },
    ratio: {
      type: [Number, String], // possible options like: "1.0", "3/2". I think we should put a warn for developer when there is no ratio. If you don't set it then it remains the ratio from CMS.
      default: null,
    },
    quality: {
      type: [Number, String],
      default: 80,
    },
    crop: {
      type: String, // one of: "fit", "fill", "crop", "scale"
      default: 'auto',
    },
    srcSet: {
      type: Object,
      default: () => null,
    },
    sizes: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: '',
    },
    loading: {
      type: String,
      default: 'lazy',
    },
    format: {
      type: String,
      default: 'auto',
    },
    /**
     * if true src attribute is handled as video url, first frame extracted as image
     */
    videoPreview: {
      type: Boolean,
      default: false,
    },
    hFull: {
      type: Boolean,
      default: false,
    },
    wFull: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      generatedSrc: 'data:,', // valid empty data url to be passed as src
      propIssues: null,
    };
  },
  computed: {
    /**
     * set global page image quality query param _image_quality=<quality>
     */
    qualityResolved() {
      return parseInt(this.$route?.query?._image_quality) || this.quality;
    },
    heightOrDerived() {
      return heightOrDerived(this.ratio, this.width, this.height);
    },
    cldnSrcSet() {
      if (this.sizes) {
        return RESOLUTIONS.map((resolution) => {
          const src = this.srcUrl(this.src, {
            width: resolution,
            height: this.ratio ? (resolution * parseFloat(this.ratio)).toFixed() : null,
            quality: this.qualityResolved,
            crop: this.crop,
            format: this.format,
            transformation: this.transformation,
          });
          return `${src} ${resolution}w`;
        }).join(',');
      } else {
        const multipliers = this.width > 768 ? [1, 2] : [1, 2, 3];
        return multipliers
          .map((m) => {
            const src = this.srcUrl(this.src, {
              width: this.width * m,
              height: this.heightOrDerived * m,
              quality: this.qualityResolved,
              crop: this.crop,
              format: this.format,
              transformation: this.transformation,
            });
            return `${src} ${m}x`;
          })
          .join(',');
      }
    },
  },
  mounted() {
    this.propValidation();
    this.indicateIssue();
  },
  created() {
    // set src for older browsers
    if (this.sizes) {
      const width = 360;
      this.generatedSrc = this.srcUrl(this.src, {
        width,
        height: heightOrDerived(this.ratio, width),
        crop: this.crop,
        format: this.format,
        quality: this.qualityResolved,
        transformation: this.transformation,
      });
    } else {
      this.generatedSrc = this.srcUrl(this.src, {
        width: this.width,
        height: this.heightOrDerived,
        quality: this.qualityResolved,
        crop: this.crop,
        format: this.format,
        transformation: this.transformation,
      });
    }
  },
  methods: {
    srcUrl(...args) {
      return this.videoPreview ? this.$cldn.videoPreviewUrl(...args) : this.$cldn.imgUrl(...args);
    },
    propValidation() {
      if (!this.width && !this.sizes && !this.height) {
        const issue = 'either width or sizes have to be set';
        console.warn(issue, this.$el);
        this.propIssues = issue;
      }
    },
    indicateIssue() {
      if (this.$nuxt?.context.isDev && this.propIssues) this.$el.style.border = '2px solid red';
    },
  },
};
</script>
