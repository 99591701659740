<template>
  <div
    class="md:px-0 items-stretch relative flex matrix-block lg:flex-row flex-col lg:flex-nowrap"
    :class="{ container: !fullWidth }"
  >
    <FixedAspect v-if="image[0]" :ratio="0.5" class="h-full w-full" :class="[small ? 'block lg:hidden' : 'hidden']">
      <CldnImg :src="image[0].url" :alt="image[0].title" :ratio="0.5" sizes="100vw" crop="fill" />
    </FixedAspect>
    <FixedAspect
      :ratio="0.75"
      :h-full="true"
      class="relative"
      :class="[small ? 'w-full md:w-1/3 hidden lg:block' : 'lg:w-1/2 order-3 md:order-1']"
    >
      <CldnImg
        v-if="image[0]"
        :src="image[0].url"
        alt="image[0].title"
        :ratio="0.75"
        sizes="(min-width: 1440px) 432px, 50vw"
        crop="fill"
        w-full
      />
    </FixedAspect>
    <div
      class="flex flex-col justify-center w-full"
      :class="[
        small ? 'items-center md:items-baseline p-8 lg:w-3/4' : 'items-baseline lg:w-1/2 p-6 md:px-20 md:py-16 order-2',
        backgroundGray ? 'bg-putty' : 'bg-blackly text-white',
      ]"
    >
      <div
        class="block"
        :class="[small ? 'fluid-lg leading-none mb-4' : 'fluid-3xl leading-tightest mb-8 font-normal']"
      >
        {{ heading }}
      </div>
      <div class="fluid-lg font-light leading-normal w-100 lg:w-5/6 lg:pr-5" v-html="body"></div>
      <LinkField
        v-bind="cta"
        text="more info"
        class="inline-block mt-6"
        :tracking-name="heading"
        :class="[small ? 'mt-6' : 'mt-10', backgroundGray ? 'btn-pill-outline-black' : 'btn-pill-black']"
        >{{ cta.text || $t('product.moreInfo') }}
      </LinkField>
    </div>
  </div>
</template>

<script>
import LinkField from '~/components/common/LinkField';
import CldnImg from '~/components/CldnImg';
import FixedAspect from '~/components/common/FixedAspect';

export default {
  name: 'TeaserWithCta',
  components: {
    CldnImg,
    FixedAspect,
    LinkField,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    cta: {
      type: Object,
      default: () => {},
    },
    image: {
      type: Array,
      default: () => [],
    },
    small: {
      type: Boolean,
      default: false,
    },
    backgroundGray: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },
};
</script>
