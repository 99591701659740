<template>
  <div class="relative">
    <CardSlider
      ref="slider"
      :breakpoints="breakpoints"
      :show-arrows="false"
      :fullwidth-slides="true"
      :is-container="false"
      auto-height
      no-padding
      class="shadow-md overflow-x-hidden"
    >
      <div v-for="deal in deals" :key="'deal-' + deal.id">
        <CartDealBundle
          v-bind="{
            discountPerProduct: deal.discountPerProduct,
            price: deal.focusProduct.variants[0].price,
            image: deal.focusProduct.images[0].src,
            title: deal.focusProduct.title,
            description: deal.focusProduct.productCardUsp,
            deal,
          }"
        />
      </div>
    </CardSlider>

    <div v-if="deals.length >= 2" class="relative mt-3 flex justify-between items-center">
      <div class="w-8">
        <ArrowButton direction="left" :arrow-disabled="isFirst" @click="prevSlide" />
      </div>

      <div class="mx-auto w-full flex justify-center space-x-1">
        <button
          v-for="(deal, index) in deals"
          :key="deal.id"
          class="h-1 bg-gray-200 w-full max-w-[46px] focus:outline-none relative"
          data-test-id="slide-button"
          @click="changeSlide(index)"
        >
          <span
            v-if="index === current"
            class="h-full block bg-black"
            :class="{
              'w-full': index === current,
            }"
          ></span>
        </button>
      </div>

      <div class="w-8">
        <ArrowButton direction="right" :arrow-disabled="isLast" @click="nextSlide" />
      </div>
    </div>
  </div>
</template>

<script>
import ArrowButton from '@/components/common/ArrowButton';
import CardSlider from '@/components/common/CardSlider';
import CartDealBundle from '@/components/cart/CartDeals/CartDealBundle';

export default {
  components: {
    ArrowButton,
    CardSlider,
    CartDealBundle,
  },

  props: {
    lineItems: {
      type: Array,
      default: () => [],
    },
    deals: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      current: 0,
      breakpoints: [
        {
          minWindowWidth: 0,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      ],
    };
  },

  computed: {
    isFirst() {
      return this.current === 0;
    },
    isLast() {
      return this.current === this.deals.length - 1;
    },
  },

  methods: {
    prevSlide() {
      if (!this.isFirst) {
        this.current--;
        this.$refs.slider.$emit('prev');
      }
    },
    nextSlide() {
      if (!this.isLast) {
        this.current++;
        this.$refs.slider.$emit('next');
      }
    },
    changeSlide(index) {
      this.current = index;
      this.$refs.slider.$emit('scrollToSlide', index);
    },
  },
};
</script>
