<template>
  <div class="relative my-3">
    <div v-show="isRendered" class="absolute inset-0 flex items-center text-xs py-1 pointer-events-none">
      <PayPalAltIcon class="h-4 mr-2" />
      <span>{{ $t('paypal.payLaterTitle') }}</span>
      <span class="ml-2 underline text-gray-800">{{ $t('paypal.moreInfo') }}</span>
    </div>

    <div class="opacity-0">
      <div id="paypal-later-hint"></div>
    </div>
  </div>
</template>

<script>
import PayPalAltIcon from '@/components/icons/PayPalAltIcon.vue';

export default {
  components: {
    PayPalAltIcon,
  },

  props: {
    amount: {
      type: [Number, String],
      default: 1,
    },
  },

  data() {
    return {
      isRendered: false,
    };
  },

  mounted() {
    if (!document.querySelector('script[src*="paypal.com/sdk/js"]')) {
      let language = 'en_US';

      const langMapping = {
        de: 'de_DE',
        en: 'en_US',
        fr: 'fr_FR',
        nl: 'nl_NL',
      };

      if (langMapping[this.$i18n.locale]) {
        language = langMapping[this.$i18n.locale];
      }

      const clientId = 'AQMcCMPtGI5E_PtXfx-geaeFabK2E9Bd87VHtWjV4ryNgh8ZGvQ2B5_4DD0PZYsJAlQgrE-RBmE_tp2v';

      const paypalSdk = document.createElement('script');
      paypalSdk.setAttribute(
        'src',
        `https://www.paypal.com/sdk/js?client-id=${clientId}&locale=${language}&components=messages&enable-funding=paylater`
      );
      paypalSdk.setAttribute('data-namespace', 'paypal_sdk');
      paypalSdk.addEventListener('load', () => {
        this.render();
      });
      document.head.insertAdjacentElement('beforeend', paypalSdk);
    } else {
      this.render();
    }
  },

  methods: {
    render() {
      window.paypal_sdk
        .Messages({
          amount: typeof this.amount === 'string' ? Number(this.amount) : this.amount,
          placement: 'cart',
          style: {
            layout: 'text',
            logo: { type: 'alternative' },
            color: 'black',
          },
        })
        .render('#paypal-later-hint');

      this.isRendered = true;
    },
  },
};
</script>
