<template>
  <nav
    id="PageHeader"
    class="page-header h-header-bar-height-mobile lg:h-header-bar-height sticky top-0 z-40 grid w-full bg-white border-b"
  >
    <div
      class="gradient transition-opacity delay-500 absolute inset-0 opacity-0 pointer-events-none"
      :class="{ 'opacity-100': openNavTopIndex !== null }"
    />
    <button
      class="lg:hidden w-header-bar-height-mobile justify-self-start flex items-center justify-center h-full"
      @click="handleHamburgerClick()"
    >
      <IconBurger />
    </button>
    <div class="justify-self-center lg:justify-self-start">
      <nuxt-link
        :to="$localeRootPath('/')"
        class="hover:text-black hover:no-underline lg:pl-8 w-44 md:w-48 lg:w-60 flex items-center h-full px-4 text-black no-underline"
      >
        <Logo />
      </nuxt-link>
    </div>
    <MainNav />
    <div class="flex items-center justify-end">
      <SearchInput class="hidden sm:flex" />

      <!-- Show Search icon for mobile users if search has been closed -->
      <button
        v-if="isHidden"
        class="flex sm:hidden items-center justify-center w-8 h-8 ml-1 text-blackly animate-flash"
        @click="unhide"
      >
        <SearchIcon class="w-5 h-5" />
      </button>

      <RegionAndLanguageIndicator class="ml-1" />

      <HeaderPopover
        v-if="false && hasAlternates"
        :popover-type="'LOCALES'"
        :on-toggle="toggleCurrentlyOpenPopover"
        :on-close="() => closeAllPopovers()"
        class="lg:block hidden"
      >
        <span class="fluid-xs leading-snug uppercase font-medium relative flex items-center">
          {{ currentLocale }}
          <IconChevron class="ml-1" />
        </span>
      </HeaderPopover>

      <HeaderPopover
        :popover-type="'HELP'"
        :on-toggle="toggleCurrentlyOpenPopover"
        :on-close="() => closeAllPopovers()"
        class="lg:block hidden"
      >
        <template #default="{ isActive }"> <IconHelp :is-active="isActive" /> </template>
      </HeaderPopover>
      <client-only>
        <HeaderPopover
          v-if="accountEnabled"
          :popover-type="'ACCOUNT'"
          :on-toggle="toggleCurrentlyOpenPopover"
          :on-close="() => closeAllPopovers()"
          class="lg:block hidden"
        >
          <template #default="{ isActive }">
            <IconAccount :is-active="isActive || $auth.loggedIn" />
          </template>
        </HeaderPopover>
      </client-only>
      <CartButton />
    </div>
  </nav>
</template>

<script>
import CartButton from '@/components/CartButton';
import MainNav from '@/components/layout/header/MainNav';
import Logo from '@/components/layout/header/Logo';
import { mapActions, mapState, mapGetters } from 'vuex';
import IconBurger from '@/components/layout/header/IconBurger';
import { mixin as onClickOutside } from 'vue-on-click-outside';
import IconChevron from '@/components/layout/header/IconChevron';
import HeaderPopover from '@/components/layout/header/HeaderPopover';
import IconHelp from '@/components/layout/header/IconHelp';
import IconAccount from '@/components/layout/header/IconAccount';
import SearchInput from '@/components/layout/header/SearchInput';
import SearchIcon from '@/components/icons/SearchIcon';
import RegionAndLanguageIndicator from '@/components/layout/header/RegionAndLanguageIndicator';

export default {
  components: {
    SearchInput,
    SearchIcon,
    IconAccount,
    IconHelp,
    HeaderPopover,
    IconChevron,
    IconBurger,
    Logo,
    CartButton,
    MainNav,
    RegionAndLanguageIndicator,
  },
  mixins: [onClickOutside],
  data() {
    return {
      isAccountPopoverOpen: false,
      isHelpPopoverOpen: false,
      isLangPopoverOpen: false,
      currentlyOpenPopover: null, // "LOCALES", "HELP", "ACCOUNT",
    };
  },
  computed: {
    ...mapState('mainNav', ['openNavTopIndex']),
    ...mapGetters('mainNav', ['hasAlternates']),
    ...mapGetters('search', ['isHidden']),
    currentLocale() {
      const { locale } = this.$i18n;
      return locale;
    },
    accountEnabled() {
      return this.$config.auth.enabled;
    },
  },
  watch: {
    $route(to, from) {
      this.closeAllPopovers();
    },
    openNavTopIndex() {
      this.closeAllPopovers();
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, { passive: true });
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapActions('mainNav', ['close']),
    ...mapActions('search', ['unhide']),

    handleHamburgerClick() {
      this.$store.dispatch('mainNav/open');
    },
    toggleCurrentlyOpenPopover(popoverName) {
      if (this.currentlyOpenPopover === popoverName) {
        this.currentlyOpenPopover = null;
      } else {
        this.currentlyOpenPopover = popoverName;
      }
    },
    closeAllPopovers() {
      this.currentlyOpenPopover = null;
    },
    handleScroll() {
      this.closeAllPopovers();
    },

    openMobileSearch() {
      this.$store.dispatch('search/open');
    },
  },
};
</script>

<style scoped>
.gradient {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(255, 255, 255, 0) 50%);
}
.page-header {
  grid-template-columns: 1fr 1fr 1fr;
}
</style>
