<template>
  <div class="w-full flex flex-col">
    <div class="h-full w-full flex items-start">
      <div class="relative pr-6">
        <input
          :id="id"
          type="checkbox"
          v-bind="$attrs"
          class="absolute opacity-0 cursor-pointer bg-white w-3 h-3 border mt-1 z-20"
          @change="emitValue"
        />
        <span
          class="checkmark absolute top-1 left-0 h-3 w-3 bg-white border border-solid border-gray-divider-darker rounded-[1px]"
        ></span>
        <span
          v-if="required"
          class="absolute text-br-green z-10 top-0"
          style="right: 2px"
          :class="{ 'text-br-red': error }"
          >*</span
        >
      </div>
      <label
        v-if="label"
        class="w-full h-full font-light fluid-sm leading-none text-black mt-1"
        :for="id"
        :class="{ 'text-br-red': error }"
        v-html="label"
      ></label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormCheckboxInput',
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: {
    error: {
      type: [Boolean, String],
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: this._uid
    }
  },
  methods: {
    emitValue(e) {
      return this.$emit('change', e.target.checked)
    }
  }
}
</script>
<style scoped>
/* When the checkbox is checked, add a green background */
input:checked ~ .checkmark {
  @apply bg-br-green;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  @apply hidden;
}

/* Show the checkmark when checked */
input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkmark:after {
  left: 4px;
  top: 1px;
  height: 6px;
  width: 3px;
  @apply border border-white border-solid border-t-0 border-r border-b border-l-0 rotate-45;
}
</style>
