<template>
  <!-- <div class="hidden" /> -->
  <ArrowBtnNav
    :class="{ hidden: isFirst && isLast }"
    :left-arrow-disabled="isFirst"
    :right-arrow-disabled="isLast"
    :inverted="inverted"
    @onLeftClick="$emit('prev')"
    @onRightClick="$emit('next')"
  />
</template>

<script>
import ArrowBtnNav from '@/components/common/ArrowBtnNav'
/**
 * Remote-nav for CardSlider, handles payload of CardSlider change event
 * emits prev and next events
 */
export default {
  components: {
    ArrowBtnNav
  },
  props: {
    inverted: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isFirst: false,
      isLast: false
    }
  },
  mounted() {
    this.$on('sliderChange', this.handleSliderChange)
  },
  methods: {
    handleSliderChange(state) {
      if (typeof state !== 'object') return
      if ('isFirst' in state) {
        this.isFirst = state.isFirst
      }
      if ('isLast' in state) {
        this.isLast = state.isLast
      }
    }
  }
}
</script>
