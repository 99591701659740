<template>
  <div class="py-20 bg-putty overflow-hidden">
    <CardSlider :breakpoints="breakpoints" :heading="heading" :display-overflow="true">
      <div v-for="(slide, index) in featureCards" :key="index" class="focus:outline-none relative pt-4">
        <div
          class="fluid-lg leading-tight font-medium bg-blackly inline-flex items-center justify-center w-12 h-12 font-semibold text-white rounded-full"
        >
          {{ (index + 1) | indexPad }}
        </div>
        <h3 v-if="slide.heading" class="fluid-xl leading-tight font-medium my-4" v-html="slide.heading"></h3>
        <div v-if="slide.body" class="fluid-base leading-relaxed font-light" v-html="slide.body"></div>
      </div>
    </CardSlider>
  </div>
</template>

<script>
import { indexPad } from '@/utils/common'
import CardSlider from '@/components/common/CardSlider'
export default {
  components: { CardSlider },
  filters: { indexPad },
  props: {
    heading: {
      type: String,
      default: null
    },
    featureCards: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      breakpoints: [
        {
          minWindowWidth: 0,
          slidesToShow: 1,
          slidesToScroll: 1
        },
        {
          minWindowWidth: 640,
          slidesToShow: 2,
          slidesToScroll: 1
        },
        {
          minWindowWidth: 768,
          slidesToShow: 2.5,
          slidesToScroll: 1
        }
      ]
    }
  }
}
</script>
