import get from 'lodash/get';

/**
 * Routine/Workout base functionality
 * receives routine-data as entry and provides it as routineEntry
 * routineEntry can be overwritten by implementing Component to
 * change source of entry
 */
export default {
  props: {
    entry: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      exercises: [],
    };
  },
  computed: {
    /**
     * default binding of entry-prop to routineEntry can be overwritten in inheritting components
     * @returns Object - shape CraftRoutineEntry
     */
    routineEntry() {
      return this.entry;
    },
    routineDuration() {
      return `${this.routineEntry.duration} Min`;
    },
    routineExerciseCount() {
      return this.routineEntry?.exerciseCount;
    },
    routineDifficulty() {
      return get(this, 'routineEntry.difficulty[0].title', '');
    },
    routineVideo() {
      return get(this, 'routineEntry.video[0]');
    },
    routineImage() {
      return get(this, 'routineEntry.image[0]');
    },
  },
  methods: {
    parseExerciseProperties(properties) {
      if (!properties) return null;
      const values = properties.map((elem) => elem.title);
      return values.sort().join(', ');
    },
  },
};
