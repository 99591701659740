import { OrderModel } from '@/schemes/ordersModel';
import { RefreshScheme } from '~auth/runtime';
import { mapToBasicLocale } from '@/utils/i18n.js';
import {
  FEG_DATA_GQL,
  FEG_UPDATE_ADDRESSES,
  FEG_CUSTOMER_UPDATE,
  FEG_ADD_EXERCISE,
  FEG_DELETE_EXERCISE,
  FEG_ADD_WORKOUT,
  FEG_DELETE_WORKOUT,
  FEG_DEMAND_DATA_GQL,
  FEG_GET_MULTIPASS_GQL,
  FEG_ADD_ADDON,
  FEG_GET_LEARNWORLDS_SSO,
} from '@/schemes/queries.js';
import sortBy from 'lodash/sortBy';

export default class OwAuthScheme extends RefreshScheme {
  config = {
    verificationContinuePath: '/account',
    registerContinuePath: '/account',
  };

  async updateUser(customer) {
    try {
      const response = await this.$auth.requestWith(this.name, {
        ...this.options.endpoints.feg,
        headers: { 'content-type': 'application/json' },
        data: { query: FEG_CUSTOMER_UPDATE, variables: { customer } },
      });

      await this.$auth.fetchUser();

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getMultipass(language, returnUrl) {
    try {
      const response = await this.$auth.requestWith(this.name, {
        ...this.options.endpoints.feg,
        headers: { 'content-type': 'application/json' },
        data: { query: FEG_GET_MULTIPASS_GQL, variables: { language, returnUrl } },
      });

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getLearnworldsSSO(courseId = null, redirectUrl = null) {
    try {
      const response = await this.$auth.requestWith(this.name, {
        ...this.options.endpoints.feg,
        headers: { 'content-type': 'application/json' },
        data: { query: FEG_GET_LEARNWORLDS_SSO, variables: { courseId, redirectUrl } },
      });

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  getDefaultAddress() {
    if (!this.check().valid) {
      return Promise.resolve();
    }

    const customer = this.$auth.user.blackrollCustomer;

    if (!customer || !customer.addresses?.length > 0) {
      return false;
    }

    const defaultAddress = customer.addresses.find((address) => address.defaultAddress);

    return defaultAddress ?? customer.addresses[0];
  }

  async deleteAddress(addressIndex) {
    try {
      const addresses = Array.from(this.$auth.user.blackrollCustomer.addresses);

      // remove address at index here
      addresses.splice(addressIndex, 1);

      const response = await this.$auth.requestWith(this.name, {
        ...this.options.endpoints.feg,
        headers: { 'content-type': 'application/json' },
        data: { query: FEG_UPDATE_ADDRESSES, variables: { addresses } },
      });

      const userData = JSON.parse(JSON.stringify(this.$auth.user));
      userData.blackrollCustomer.addresses = this.sortAddresses(response.data.data.blackrollCustomerAddressUpdate);

      this.$auth.setUser(userData);

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async addAddress(newAddress) {
    try {
      const addresses = Array.from(this.$auth.user.blackrollCustomer.addresses).map((address, index) => {
        delete address.defaultAddress;
        return address;
      });

      addresses.push(newAddress);

      const response = await this.$auth.requestWith(this.name, {
        ...this.options.endpoints.feg,
        headers: { 'content-type': 'application/json' },
        data: { query: FEG_UPDATE_ADDRESSES, variables: { addresses } },
      });

      const userData = JSON.parse(JSON.stringify(this.$auth.user));
      userData.blackrollCustomer.addresses = this.sortAddresses(response.data.data.blackrollCustomerAddressUpdate);

      this.$auth.setUser(userData);

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateAddress(addressIndex, newData) {
    try {
      const addresses = Array.from(this.$auth.user.blackrollCustomer.addresses).map((address, index) => {
        if (index === addressIndex) {
          return newData;
        }

        delete address.defaultAddress;
        return address;
      });

      const response = await this.$auth.requestWith(this.name, {
        ...this.options.endpoints.feg,
        headers: { 'content-type': 'application/json' },
        data: { query: FEG_UPDATE_ADDRESSES, variables: { addresses } },
      });

      const userData = JSON.parse(JSON.stringify(this.$auth.user));
      userData.blackrollCustomer.addresses = this.sortAddresses(response.data.data.blackrollCustomerAddressUpdate);

      this.$auth.setUser(userData);

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async addExercise(exercise, pimId) {
    try {
      const response = await this.$auth.requestWith(this.name, {
        ...this.options.endpoints.feg,
        headers: { 'content-type': 'application/json' },
        data: { query: FEG_ADD_EXERCISE, variables: { exercise, pimId } },
      });

      await this.$auth.fetchUser();

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteExercise(exercise, pimId) {
    try {
      const response = await this.$auth.requestWith(this.name, {
        ...this.options.endpoints.feg,
        headers: { 'content-type': 'application/json' },
        data: { query: FEG_DELETE_EXERCISE, variables: { exercise, pimId } },
      });

      await this.$auth.fetchUser();

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async addWorkout(workout, pimId = null) {
    try {
      const response = await this.$auth.requestWith(this.name, {
        ...this.options.endpoints.feg,
        headers: { 'content-type': 'application/json' },
        data: { query: FEG_ADD_WORKOUT, variables: { workout, pimId } },
      });

      await this.$auth.fetchUser();

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async deleteWorkout(workout, pimId = null) {
    try {
      const response = await this.$auth.requestWith(this.name, {
        ...this.options.endpoints.feg,
        headers: { 'content-type': 'application/json' },
        data: { query: FEG_DELETE_WORKOUT, variables: { workout, pimId } },
      });

      await this.$auth.fetchUser();

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async addAddon(code) {
    try {
      const response = await this.$auth.requestWith(this.name, {
        ...this.options.endpoints.feg,
        method: 'post',
        headers: { 'content-type': 'application/json' },
        data: { query: FEG_ADD_ADDON, variables: { code } },
      });

      await this.$auth.fetchUser();

      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async registerUser(data) {
    // token should not be set
    if (this.check().valid) {
      return Promise.resolve();
    }

    try {
      const response = await this.$auth.request({
        method: 'post',
        url: process.env.authApiBase + '/users',
        data: { ...data, continueUrl: this.buildUrl(this.config.verificationContinuePath) },
      });

      // add a flag, that user is unverified at this point
      this.$auth.$storage.setLocalStorage('unverified', true);

      await this.$auth.setUserToken(response.data.data.idToken, response.data.data.refreshToken);

      return Promise.resolve(response.data.data);
    } catch (error) {
      return Promise.reject(error.response);
    }
  }

  async deleteUser() {
    if (!this.check().valid) {
      return Promise.resolve();
    }

    try {
      await this.$auth.requestWith(this.name, {
        method: 'delete',
        url: process.env.authApiBase + '/users',
      });

      this.$auth.logout();
    } catch (error) {
      return Promise.resolve(false);
    }
  }

  async resetPassword(payload) {
    try {
      await this.$auth.request({
        method: 'post',
        url: process.env.authApiBase + '/users/passwordReset',
        data: payload,
      });

      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async resendVerification() {
    if (!this.check().valid) {
      return Promise.resolve(false);
    }

    if (this.$auth.user.isVerified) {
      return Promise.resolve(false);
    }

    try {
      await this.$auth.requestWith(this.name, {
        method: 'post',
        url: process.env.authApiBase + '/users/resendVerificationEmailLink',
        data: {
          uid: this.$auth.user.uid,
          languageCode: mapToBasicLocale(this.$auth.ctx.i18n.locale),
          continueUrl: this.buildUrl(this.config.verificationContinuePath),
        },
      });

      return Promise.resolve(true);
    } catch (error) {
      return Promise.resolve(false);
    }
  }

  async confirmEmail(verificationCode) {
    try {
      const response = await this.$auth.request({
        method: 'post',
        url: process.env.authApiBase + '/graphql',
        data: {
          query: `mutation {
              confirmEmail(input: {oobCode: "${verificationCode}"}) {
                  email
                  emailVerified
              }
          }`,
        },
      });

      if (response.data?.errors?.length > 0) {
        return Promise.reject(response.data.errors[0]);
      }

      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async setPassword(apiKey, password) {
    try {
      const response = await this.$auth.request({
        method: 'post',
        url: process.env.authApiBase + '/graphql',
        data: {
          query: `mutation {
              setNewPasswordReset(input: {oobCode: "${apiKey}", password: "${password}"}) {
                  email
              }
          }`,
        },
      });

      if (response.data?.errors?.length > 0) {
        return Promise.reject(response.data.errors[0]);
      }

      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async fetchCountries() {
    try {
      const response = await this.$auth.request({
        method: 'get',
        url: process.env.authApiBase + '/countries',
      });

      return Promise.resolve(response.data.data);
    } catch (error) {
      return Promise.resolve([]);
    }
  }

  async fetchUser() {
    // Token is required but not available
    if (!this.check().valid) {
      return Promise.resolve();
    }

    // User endpoint is disabled
    if (!this.options.endpoints.user) {
      this.$auth.setUser({});
      return Promise.resolve();
    }

    // fetch firebase
    try {
      const firebase = await this.fetchFirebase();

      // if verified, fetch feg
      let feg = {};

      // check if we are verfified
      if (firebase.isVerified) {
        // if local has unverified flag, user has update status
        // we need to refresh our token
        if (this.$auth.$storage.getLocalStorage('unverified') === true) {
          await this.$auth.refreshTokens();
          this.$auth.$storage.removeLocalStorage('unverified');

          feg = await this.fetchFEG(true);
        } else {
          feg = await this.fetchFEG();
        }
      }

      const userData = {
        ...feg,
        ...firebase,
      };

      this.$auth.setUser(userData);

      return Promise.resolve(userData);
    } catch (error) {
      this.$auth.callOnError(error, { method: 'fetchUser' });
      this.$auth.logout();

      return Promise.reject(error);
    }
  }

  fetchFirebase() {
    return this.$auth.requestWith(this.name, null, this.options.endpoints.user).then((response) => {
      const userData = response.data.data.fb;

      if (!userData) {
        const error = new Error(`User Data response does not contain field ${this.options.user.property}`);
        return Promise.reject(error);
      }

      return userData;
    });
  }

  fetchFEG(ondemand = false) {
    return this.$auth
      .requestWith(this.name, null, {
        ...this.options.endpoints.feg,
        headers: { 'content-type': 'application/json' },
        data: { query: ondemand === true ? FEG_DEMAND_DATA_GQL : FEG_DATA_GQL },
      })
      .then((response) => {
        if (response.data.data) {
          const data = {
            ...response.data.data,
          };

          data.blackrollCustomer.addresses = this.sortAddresses(response.data.data.blackrollCustomer.addresses);

          data.blackrollCustomer.orders = sortBy(response.data.data.blackrollCustomer.orders, 'date')
            .reverse()
            .map((order) => {
              try {
                return new OrderModel(order);
              } catch (e) {
                window.$nuxt.$sentry.captureException(e);
                return order;
              }
            });

          return data;
        } else {
          return { feg: null };
        }
      })
      .catch((e) => {
        window.$nuxt.$sentry.captureException(e);
        return Promise.resolve({ feg: 'no data available' });
      });
  }

  sortAddresses(addresses) {
    return addresses.sort((a, b) => {
      if (a.defaultAddress) {
        return -1;
      }

      if (b.defaultAddress) {
        return 1;
      }

      const aName = a.company || a.firstName || a.lastName;
      const bName = b.company || b.firstName || b.lastName;

      return aName < bName ? -1 : 1;
    });
  }

  buildUrl(path) {
    if (typeof location === 'undefined') return null;

    return (
      location.protocol +
      '//' +
      location.hostname +
      (location.port ? ':' + location.port : '') +
      this.$auth.ctx.app.localePath(path)
    );
  }

  logout() {
    this.token.$storage?.ctx?.store?.dispatch('cart/resetCheckout');
    this.reset();
    return Promise.resolve();
  }
}
