<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
    <path
      d="M11.882 1.007a5.23 5.23 0 0 0-2.82 1.018l-.061.046-.06-.046A5.214 5.214 0 0 0 5.866 1C3.142 1 1 3.142 1 5.866c0 1.96 1.135 3.834 3.933 6.67l.482.482.317.31.671.645.355.334.747.695c.258.237.525.48.8.73.393.358.995.358 1.388 0l.954-.872.88-.82.545-.522.513-.5.482-.482c.155-.158.306-.313.451-.465l.42-.447c.204-.22.396-.433.577-.642l.347-.41C16.362 8.757 17 7.336 17 5.866 17 3.142 14.858 1 12.134 1l-.253.007h.001Z"
      fill="currentColor"
    />
    <path
      d="m11.867.507.004.306-.015-.305-.275.02a5.73 5.73 0 0 0-2.351.785L9 1.457l-.222-.14A5.713 5.713 0 0 0 5.869.5C2.866.5.5 2.866.5 5.866c0 2.105 1.166 4.07 4.077 7.021l.484.485.321.313.674.648.36.338.748.697.805.734a1.53 1.53 0 0 0 2.06 0l.955-.873.884-.823.55-.527.516-.503.487-.486.459-.474.422-.45c.217-.234.412-.45.591-.657l.35-.414C16.81 9.001 17.5 7.475 17.5 5.866c0-3-2.366-5.366-5.366-5.366l-.267.007Zm.04 1 .24-.007c2.435 0 4.353 1.918 4.353 4.366 0 1.343-.597 2.662-2.023 4.387l-.344.406-.174.198-.388.427-.62.655-.24.247-.48.478-.508.496-.542.519-.875.815-.95.869a.53.53 0 0 1-.714 0l-.799-.73-.744-.692-.353-.332-.667-.641-.314-.307-.478-.479-.526-.542a34.722 34.722 0 0 1-.247-.262l-.46-.505C2.226 8.816 1.5 7.346 1.5 5.866 1.5 3.418 3.418 1.5 5.866 1.5c.999.006 1.973.33 2.777.927l.355.272.365-.275a4.725 4.725 0 0 1 2.545-.918Z"
    />
  </svg>
</template>

<script>
export default {};
</script>
