import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6c67f7ac = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _5a5f2c49 = () => interopDefault(import('../pages/academy/index.vue' /* webpackChunkName: "pages/academy/index" */))
const _aa3855f2 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _6bef8b6c = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _75760464 = () => interopDefault(import('../pages/account/index/login.vue' /* webpackChunkName: "pages/account/index/login" */))
const _61cbec10 = () => interopDefault(import('../pages/account/index/register.vue' /* webpackChunkName: "pages/account/index/register" */))
const _5490cf4a = () => interopDefault(import('../pages/account/index/reset.vue' /* webpackChunkName: "pages/account/index/reset" */))
const _53dbda30 = () => interopDefault(import('../pages/account/courses.vue' /* webpackChunkName: "pages/account/courses" */))
const _5df0069a = () => interopDefault(import('../pages/account/courses/index.vue' /* webpackChunkName: "pages/account/courses/index" */))
const _5915ce93 = () => interopDefault(import('../pages/account/exercises.vue' /* webpackChunkName: "pages/account/exercises" */))
const _2d03dad6 = () => interopDefault(import('../pages/account/exercises/index.vue' /* webpackChunkName: "pages/account/exercises/index" */))
const _46ba811e = () => interopDefault(import('../pages/account/exercises/premium/index.vue' /* webpackChunkName: "pages/account/exercises/premium/index" */))
const _4b247922 = () => interopDefault(import('../pages/account/exercises/workouts.vue' /* webpackChunkName: "pages/account/exercises/workouts" */))
const _4503dfd6 = () => interopDefault(import('../pages/account/exercises/premium/_slug.vue' /* webpackChunkName: "pages/account/exercises/premium/_slug" */))
const _7b37ff1d = () => interopDefault(import('../pages/account/orders.vue' /* webpackChunkName: "pages/account/orders" */))
const _5cddfbe0 = () => interopDefault(import('../pages/account/orders/index.vue' /* webpackChunkName: "pages/account/orders/index" */))
const _b50d1370 = () => interopDefault(import('../pages/account/orders/_id.vue' /* webpackChunkName: "pages/account/orders/_id" */))
const _31542828 = () => interopDefault(import('../pages/account/passwordReset.vue' /* webpackChunkName: "pages/account/passwordReset" */))
const _625adfa1 = () => interopDefault(import('../pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _36dec734 = () => interopDefault(import('../pages/account/profile/addresses.vue' /* webpackChunkName: "pages/account/profile/addresses" */))
const _0c952454 = () => interopDefault(import('../pages/account/profile/details.vue' /* webpackChunkName: "pages/account/profile/details" */))
const _7f96e0ca = () => interopDefault(import('../pages/account/verifyEmail.vue' /* webpackChunkName: "pages/account/verifyEmail" */))
const _62856e36 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4f75b2a6 = () => interopDefault(import('../pages/debug.vue' /* webpackChunkName: "pages/debug" */))
const _76b7afb8 = () => interopDefault(import('../pages/exercises/index.vue' /* webpackChunkName: "pages/exercises/index" */))
const _6580e78a = () => interopDefault(import('../pages/exercises/index/index.vue' /* webpackChunkName: "pages/exercises/index/index" */))
const _b0493470 = () => interopDefault(import('../pages/exercises/index/_.vue' /* webpackChunkName: "pages/exercises/index/_" */))
const _18dd84a6 = () => interopDefault(import('../pages/experts/index.vue' /* webpackChunkName: "pages/experts/index" */))
const _ed4e6444 = () => interopDefault(import('../pages/fonts.vue' /* webpackChunkName: "pages/fonts" */))
const _881a424a = () => interopDefault(import('../pages/knowledge/index.vue' /* webpackChunkName: "pages/knowledge/index" */))
const _7a95951e = () => interopDefault(import('../pages/knowledge/index/index.vue' /* webpackChunkName: "pages/knowledge/index/index" */))
const _ba442e20 = () => interopDefault(import('../pages/knowledge/index/category/_category.vue' /* webpackChunkName: "pages/knowledge/index/category/_category" */))
const _e1af13d2 = () => interopDefault(import('../pages/knowledge/index/_topic/index.vue' /* webpackChunkName: "pages/knowledge/index/_topic/index" */))
const _4e488f88 = () => interopDefault(import('../pages/knowledge/index/_topic/articles.vue' /* webpackChunkName: "pages/knowledge/index/_topic/articles" */))
const _5e1440bc = () => interopDefault(import('../pages/knowledge/index/_topic/_category.vue' /* webpackChunkName: "pages/knowledge/index/_topic/_category" */))
const _cc75cbae = () => interopDefault(import('../pages/processing.vue' /* webpackChunkName: "pages/processing" */))
const _7656ad3e = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _0d62008f = () => interopDefault(import('../pages/shop/index.vue' /* webpackChunkName: "pages/shop/index" */))
const _444418f0 = () => interopDefault(import('../pages/stories/index.vue' /* webpackChunkName: "pages/stories/index" */))
const _2fb466ce = () => interopDefault(import('../pages/article/_slug/index.vue' /* webpackChunkName: "pages/article/_slug/index" */))
const _3517042c = () => interopDefault(import('../pages/collections/_handle/index.vue' /* webpackChunkName: "pages/collections/_handle/index" */))
const _4986eb61 = () => interopDefault(import('../pages/experts/_slug/index.vue' /* webpackChunkName: "pages/experts/_slug/index" */))
const _bae70010 = () => interopDefault(import('../pages/products/_slug/index.vue' /* webpackChunkName: "pages/products/_slug/index" */))
const _ecb1424e = () => interopDefault(import('../pages/routine/_slug.vue' /* webpackChunkName: "pages/routine/_slug" */))
const _e1e1d6ec = () => interopDefault(import('../pages/shop/_slug/index.vue' /* webpackChunkName: "pages/shop/_slug/index" */))
const _353ba02b = () => interopDefault(import('../pages/stories/_slug/index.vue' /* webpackChunkName: "pages/stories/_slug/index" */))
const _6704d36c = () => interopDefault(import('../pages/story/_slug.vue' /* webpackChunkName: "pages/story/_slug" */))
const _e3de9036 = () => interopDefault(import('../pages/about/_slug/index.vue' /* webpackChunkName: "pages/about/_slug/index" */))
const _34f93cd6 = () => interopDefault(import('../pages/academy/_.vue' /* webpackChunkName: "pages/academy/_" */))
const _3a3629df = () => interopDefault(import('../pages/service/_.vue' /* webpackChunkName: "pages/service/_" */))
const _5022c88e = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-blackroll",
    component: _6c67f7ac,
    name: "about___en"
  }, {
    path: "/academy",
    component: _5a5f2c49,
    name: "academy___en"
  }, {
    path: "/account",
    component: _aa3855f2,
    children: [{
      path: "",
      component: _6bef8b6c,
      name: "account___en",
      children: [{
        path: "login",
        component: _75760464,
        name: "account-index-login___en"
      }, {
        path: "register",
        component: _61cbec10,
        name: "account-index-register___en"
      }, {
        path: "reset",
        component: _5490cf4a,
        name: "account-index-reset___en"
      }]
    }, {
      path: "courses",
      component: _53dbda30,
      children: [{
        path: "",
        component: _5df0069a,
        name: "account-courses___en"
      }]
    }, {
      path: "exercises",
      component: _5915ce93,
      children: [{
        path: "",
        component: _2d03dad6,
        name: "account-exercises___en"
      }, {
        path: "premium",
        component: _46ba811e,
        name: "account-exercises-premium___en"
      }, {
        path: "workouts",
        component: _4b247922,
        name: "account-exercises-workouts___en"
      }, {
        path: "premium/:slug",
        component: _4503dfd6,
        name: "account-exercises-premium-slug___en"
      }]
    }, {
      path: "orders",
      component: _7b37ff1d,
      children: [{
        path: "",
        component: _5cddfbe0,
        name: "account-orders___en"
      }, {
        path: ":id",
        component: _b50d1370,
        name: "account-orders-id___en"
      }]
    }, {
      path: "passwordReset",
      component: _31542828,
      name: "account-passwordReset___en"
    }, {
      path: "profile",
      component: _625adfa1,
      name: "account-profile___en",
      children: [{
        path: "addresses",
        component: _36dec734,
        name: "account-profile-addresses___en"
      }, {
        path: "details",
        component: _0c952454,
        name: "account-profile-details___en"
      }]
    }, {
      path: "verifyEmail",
      component: _7f96e0ca,
      name: "account-verifyEmail___en"
    }]
  }, {
    path: "/cart",
    component: _62856e36,
    name: "cart___en"
  }, {
    path: "/ch-de",
    component: _98173ca8,
    name: "index___ch-de"
  }, {
    path: "/ch-fr",
    component: _98173ca8,
    name: "index___ch-fr"
  }, {
    path: "/de",
    component: _98173ca8,
    name: "index___de"
  }, {
    path: "/debug",
    component: _4f75b2a6,
    name: "debug___en"
  }, {
    path: "/exercises",
    component: _76b7afb8,
    children: [{
      path: "",
      component: _6580e78a,
      name: "exercises-index___en"
    }, {
      path: "*",
      component: _b0493470,
      name: "exercises-index-all___en"
    }]
  }, {
    path: "/experts",
    component: _18dd84a6,
    name: "experts___en"
  }, {
    path: "/fonts",
    component: _ed4e6444,
    name: "fonts___en"
  }, {
    path: "/fr",
    component: _98173ca8,
    name: "index___fr"
  }, {
    path: "/knowledge",
    component: _881a424a,
    children: [{
      path: "",
      component: _7a95951e,
      name: "knowledge-index___en"
    }, {
      path: "category/:category?",
      component: _ba442e20,
      name: "knowledge-index-category-category___en"
    }, {
      path: ":topic",
      component: _e1af13d2,
      name: "knowledge-index-topic___en"
    }, {
      path: ":topic/articles",
      component: _4e488f88,
      name: "knowledge-index-topic-articles___en"
    }, {
      path: ":topic/:category?",
      component: _5e1440bc,
      name: "knowledge-index-topic-category___en"
    }]
  }, {
    path: "/nl",
    component: _98173ca8,
    name: "index___nl"
  }, {
    path: "/processing",
    component: _cc75cbae,
    name: "processing___en"
  }, {
    path: "/search",
    component: _7656ad3e,
    name: "search___en"
  }, {
    path: "/shop",
    component: _0d62008f,
    name: "shop___en"
  }, {
    path: "/stories",
    component: _444418f0,
    name: "stories___en"
  }, {
    path: "/ch-de/academy",
    component: _5a5f2c49,
    name: "academy___ch-de"
  }, {
    path: "/ch-de/account",
    component: _aa3855f2,
    children: [{
      path: "",
      component: _6bef8b6c,
      name: "account___ch-de",
      children: [{
        path: "login",
        component: _75760464,
        name: "account-index-login___ch-de"
      }, {
        path: "register",
        component: _61cbec10,
        name: "account-index-register___ch-de"
      }, {
        path: "reset",
        component: _5490cf4a,
        name: "account-index-reset___ch-de"
      }]
    }, {
      path: "courses",
      component: _53dbda30,
      children: [{
        path: "",
        component: _5df0069a,
        name: "account-courses___ch-de"
      }]
    }, {
      path: "exercises",
      component: _5915ce93,
      children: [{
        path: "",
        component: _2d03dad6,
        name: "account-exercises___ch-de"
      }, {
        path: "premium",
        component: _46ba811e,
        name: "account-exercises-premium___ch-de"
      }, {
        path: "workouts",
        component: _4b247922,
        name: "account-exercises-workouts___ch-de"
      }, {
        path: "premium/:slug",
        component: _4503dfd6,
        name: "account-exercises-premium-slug___ch-de"
      }]
    }, {
      path: "orders",
      component: _7b37ff1d,
      children: [{
        path: "",
        component: _5cddfbe0,
        name: "account-orders___ch-de"
      }, {
        path: ":id",
        component: _b50d1370,
        name: "account-orders-id___ch-de"
      }]
    }, {
      path: "passwordReset",
      component: _31542828,
      name: "account-passwordReset___ch-de"
    }, {
      path: "profile",
      component: _625adfa1,
      name: "account-profile___ch-de",
      children: [{
        path: "addresses",
        component: _36dec734,
        name: "account-profile-addresses___ch-de"
      }, {
        path: "details",
        component: _0c952454,
        name: "account-profile-details___ch-de"
      }]
    }, {
      path: "verifyEmail",
      component: _7f96e0ca,
      name: "account-verifyEmail___ch-de"
    }]
  }, {
    path: "/ch-de/cart",
    component: _62856e36,
    name: "cart___ch-de"
  }, {
    path: "/ch-de/debug",
    component: _4f75b2a6,
    name: "debug___ch-de"
  }, {
    path: "/ch-de/experten",
    component: _18dd84a6,
    name: "experts___ch-de"
  }, {
    path: "/ch-de/fonts",
    component: _ed4e6444,
    name: "fonts___ch-de"
  }, {
    path: "/ch-de/processing",
    component: _cc75cbae,
    name: "processing___ch-de"
  }, {
    path: "/ch-de/search",
    component: _7656ad3e,
    name: "search___ch-de"
  }, {
    path: "/ch-de/shop",
    component: _0d62008f,
    name: "shop___ch-de"
  }, {
    path: "/ch-de/stories",
    component: _444418f0,
    name: "stories___ch-de"
  }, {
    path: "/ch-de/ueber-blackroll",
    component: _6c67f7ac,
    name: "about___ch-de"
  }, {
    path: "/ch-de/uebungen",
    component: _76b7afb8,
    children: [{
      path: "",
      component: _6580e78a,
      name: "exercises-index___ch-de"
    }, {
      path: "*",
      component: _b0493470,
      name: "exercises-index-all___ch-de"
    }]
  }, {
    path: "/ch-de/wissen",
    component: _881a424a,
    children: [{
      path: "",
      component: _7a95951e,
      name: "knowledge-index___ch-de"
    }, {
      path: "category/:category?",
      component: _ba442e20,
      name: "knowledge-index-category-category___ch-de"
    }, {
      path: ":topic",
      component: _e1af13d2,
      name: "knowledge-index-topic___ch-de"
    }, {
      path: ":topic/articles",
      component: _4e488f88,
      name: "knowledge-index-topic-articles___ch-de"
    }, {
      path: ":topic/:category?",
      component: _5e1440bc,
      name: "knowledge-index-topic-category___ch-de"
    }]
  }, {
    path: "/ch-fr/a-propos-de-blackroll",
    component: _6c67f7ac,
    name: "about___ch-fr"
  }, {
    path: "/ch-fr/academy",
    component: _5a5f2c49,
    name: "academy___ch-fr"
  }, {
    path: "/ch-fr/account",
    component: _aa3855f2,
    children: [{
      path: "",
      component: _6bef8b6c,
      name: "account___ch-fr",
      children: [{
        path: "login",
        component: _75760464,
        name: "account-index-login___ch-fr"
      }, {
        path: "register",
        component: _61cbec10,
        name: "account-index-register___ch-fr"
      }, {
        path: "reset",
        component: _5490cf4a,
        name: "account-index-reset___ch-fr"
      }]
    }, {
      path: "courses",
      component: _53dbda30,
      children: [{
        path: "",
        component: _5df0069a,
        name: "account-courses___ch-fr"
      }]
    }, {
      path: "exercises",
      component: _5915ce93,
      children: [{
        path: "",
        component: _2d03dad6,
        name: "account-exercises___ch-fr"
      }, {
        path: "premium",
        component: _46ba811e,
        name: "account-exercises-premium___ch-fr"
      }, {
        path: "workouts",
        component: _4b247922,
        name: "account-exercises-workouts___ch-fr"
      }, {
        path: "premium/:slug",
        component: _4503dfd6,
        name: "account-exercises-premium-slug___ch-fr"
      }]
    }, {
      path: "orders",
      component: _7b37ff1d,
      children: [{
        path: "",
        component: _5cddfbe0,
        name: "account-orders___ch-fr"
      }, {
        path: ":id",
        component: _b50d1370,
        name: "account-orders-id___ch-fr"
      }]
    }, {
      path: "passwordReset",
      component: _31542828,
      name: "account-passwordReset___ch-fr"
    }, {
      path: "profile",
      component: _625adfa1,
      name: "account-profile___ch-fr",
      children: [{
        path: "addresses",
        component: _36dec734,
        name: "account-profile-addresses___ch-fr"
      }, {
        path: "details",
        component: _0c952454,
        name: "account-profile-details___ch-fr"
      }]
    }, {
      path: "verifyEmail",
      component: _7f96e0ca,
      name: "account-verifyEmail___ch-fr"
    }]
  }, {
    path: "/ch-fr/cart",
    component: _62856e36,
    name: "cart___ch-fr"
  }, {
    path: "/ch-fr/connaissances",
    component: _881a424a,
    children: [{
      path: "",
      component: _7a95951e,
      name: "knowledge-index___ch-fr"
    }, {
      path: "category/:category?",
      component: _ba442e20,
      name: "knowledge-index-category-category___ch-fr"
    }, {
      path: ":topic",
      component: _e1af13d2,
      name: "knowledge-index-topic___ch-fr"
    }, {
      path: ":topic/articles",
      component: _4e488f88,
      name: "knowledge-index-topic-articles___ch-fr"
    }, {
      path: ":topic/:category?",
      component: _5e1440bc,
      name: "knowledge-index-topic-category___ch-fr"
    }]
  }, {
    path: "/ch-fr/debug",
    component: _4f75b2a6,
    name: "debug___ch-fr"
  }, {
    path: "/ch-fr/exercices",
    component: _76b7afb8,
    children: [{
      path: "",
      component: _6580e78a,
      name: "exercises-index___ch-fr"
    }, {
      path: "*",
      component: _b0493470,
      name: "exercises-index-all___ch-fr"
    }]
  }, {
    path: "/ch-fr/experts",
    component: _18dd84a6,
    name: "experts___ch-fr"
  }, {
    path: "/ch-fr/fonts",
    component: _ed4e6444,
    name: "fonts___ch-fr"
  }, {
    path: "/ch-fr/processing",
    component: _cc75cbae,
    name: "processing___ch-fr"
  }, {
    path: "/ch-fr/search",
    component: _7656ad3e,
    name: "search___ch-fr"
  }, {
    path: "/ch-fr/shop",
    component: _0d62008f,
    name: "shop___ch-fr"
  }, {
    path: "/ch-fr/stories",
    component: _444418f0,
    name: "stories___ch-fr"
  }, {
    path: "/de/academy",
    component: _5a5f2c49,
    name: "academy___de"
  }, {
    path: "/de/account",
    component: _aa3855f2,
    children: [{
      path: "",
      component: _6bef8b6c,
      name: "account___de",
      children: [{
        path: "login",
        component: _75760464,
        name: "account-index-login___de"
      }, {
        path: "register",
        component: _61cbec10,
        name: "account-index-register___de"
      }, {
        path: "reset",
        component: _5490cf4a,
        name: "account-index-reset___de"
      }]
    }, {
      path: "courses",
      component: _53dbda30,
      children: [{
        path: "",
        component: _5df0069a,
        name: "account-courses___de"
      }]
    }, {
      path: "exercises",
      component: _5915ce93,
      children: [{
        path: "",
        component: _2d03dad6,
        name: "account-exercises___de"
      }, {
        path: "premium",
        component: _46ba811e,
        name: "account-exercises-premium___de"
      }, {
        path: "workouts",
        component: _4b247922,
        name: "account-exercises-workouts___de"
      }, {
        path: "premium/:slug",
        component: _4503dfd6,
        name: "account-exercises-premium-slug___de"
      }]
    }, {
      path: "orders",
      component: _7b37ff1d,
      children: [{
        path: "",
        component: _5cddfbe0,
        name: "account-orders___de"
      }, {
        path: ":id",
        component: _b50d1370,
        name: "account-orders-id___de"
      }]
    }, {
      path: "passwordReset",
      component: _31542828,
      name: "account-passwordReset___de"
    }, {
      path: "profile",
      component: _625adfa1,
      name: "account-profile___de",
      children: [{
        path: "addresses",
        component: _36dec734,
        name: "account-profile-addresses___de"
      }, {
        path: "details",
        component: _0c952454,
        name: "account-profile-details___de"
      }]
    }, {
      path: "verifyEmail",
      component: _7f96e0ca,
      name: "account-verifyEmail___de"
    }]
  }, {
    path: "/de/cart",
    component: _62856e36,
    name: "cart___de"
  }, {
    path: "/de/debug",
    component: _4f75b2a6,
    name: "debug___de"
  }, {
    path: "/de/experten",
    component: _18dd84a6,
    name: "experts___de"
  }, {
    path: "/de/fonts",
    component: _ed4e6444,
    name: "fonts___de"
  }, {
    path: "/de/processing",
    component: _cc75cbae,
    name: "processing___de"
  }, {
    path: "/de/search",
    component: _7656ad3e,
    name: "search___de"
  }, {
    path: "/de/shop",
    component: _0d62008f,
    name: "shop___de"
  }, {
    path: "/de/stories",
    component: _444418f0,
    name: "stories___de"
  }, {
    path: "/de/ueber-blackroll",
    component: _6c67f7ac,
    name: "about___de"
  }, {
    path: "/de/uebungen",
    component: _76b7afb8,
    children: [{
      path: "",
      component: _6580e78a,
      name: "exercises-index___de"
    }, {
      path: "*",
      component: _b0493470,
      name: "exercises-index-all___de"
    }]
  }, {
    path: "/de/wissen",
    component: _881a424a,
    children: [{
      path: "",
      component: _7a95951e,
      name: "knowledge-index___de"
    }, {
      path: "category/:category?",
      component: _ba442e20,
      name: "knowledge-index-category-category___de"
    }, {
      path: ":topic",
      component: _e1af13d2,
      name: "knowledge-index-topic___de"
    }, {
      path: ":topic/articles",
      component: _4e488f88,
      name: "knowledge-index-topic-articles___de"
    }, {
      path: ":topic/:category?",
      component: _5e1440bc,
      name: "knowledge-index-topic-category___de"
    }]
  }, {
    path: "/fr/a-propos-de-blackroll",
    component: _6c67f7ac,
    name: "about___fr"
  }, {
    path: "/fr/academy",
    component: _5a5f2c49,
    name: "academy___fr"
  }, {
    path: "/fr/account",
    component: _aa3855f2,
    children: [{
      path: "",
      component: _6bef8b6c,
      name: "account___fr",
      children: [{
        path: "login",
        component: _75760464,
        name: "account-index-login___fr"
      }, {
        path: "register",
        component: _61cbec10,
        name: "account-index-register___fr"
      }, {
        path: "reset",
        component: _5490cf4a,
        name: "account-index-reset___fr"
      }]
    }, {
      path: "courses",
      component: _53dbda30,
      children: [{
        path: "",
        component: _5df0069a,
        name: "account-courses___fr"
      }]
    }, {
      path: "exercises",
      component: _5915ce93,
      children: [{
        path: "",
        component: _2d03dad6,
        name: "account-exercises___fr"
      }, {
        path: "premium",
        component: _46ba811e,
        name: "account-exercises-premium___fr"
      }, {
        path: "workouts",
        component: _4b247922,
        name: "account-exercises-workouts___fr"
      }, {
        path: "premium/:slug",
        component: _4503dfd6,
        name: "account-exercises-premium-slug___fr"
      }]
    }, {
      path: "orders",
      component: _7b37ff1d,
      children: [{
        path: "",
        component: _5cddfbe0,
        name: "account-orders___fr"
      }, {
        path: ":id",
        component: _b50d1370,
        name: "account-orders-id___fr"
      }]
    }, {
      path: "passwordReset",
      component: _31542828,
      name: "account-passwordReset___fr"
    }, {
      path: "profile",
      component: _625adfa1,
      name: "account-profile___fr",
      children: [{
        path: "addresses",
        component: _36dec734,
        name: "account-profile-addresses___fr"
      }, {
        path: "details",
        component: _0c952454,
        name: "account-profile-details___fr"
      }]
    }, {
      path: "verifyEmail",
      component: _7f96e0ca,
      name: "account-verifyEmail___fr"
    }]
  }, {
    path: "/fr/cart",
    component: _62856e36,
    name: "cart___fr"
  }, {
    path: "/fr/connaissances",
    component: _881a424a,
    children: [{
      path: "",
      component: _7a95951e,
      name: "knowledge-index___fr"
    }, {
      path: "category/:category?",
      component: _ba442e20,
      name: "knowledge-index-category-category___fr"
    }, {
      path: ":topic",
      component: _e1af13d2,
      name: "knowledge-index-topic___fr"
    }, {
      path: ":topic/articles",
      component: _4e488f88,
      name: "knowledge-index-topic-articles___fr"
    }, {
      path: ":topic/:category?",
      component: _5e1440bc,
      name: "knowledge-index-topic-category___fr"
    }]
  }, {
    path: "/fr/debug",
    component: _4f75b2a6,
    name: "debug___fr"
  }, {
    path: "/fr/exercices",
    component: _76b7afb8,
    children: [{
      path: "",
      component: _6580e78a,
      name: "exercises-index___fr"
    }, {
      path: "*",
      component: _b0493470,
      name: "exercises-index-all___fr"
    }]
  }, {
    path: "/fr/experts",
    component: _18dd84a6,
    name: "experts___fr"
  }, {
    path: "/fr/fonts",
    component: _ed4e6444,
    name: "fonts___fr"
  }, {
    path: "/fr/processing",
    component: _cc75cbae,
    name: "processing___fr"
  }, {
    path: "/fr/search",
    component: _7656ad3e,
    name: "search___fr"
  }, {
    path: "/fr/shop",
    component: _0d62008f,
    name: "shop___fr"
  }, {
    path: "/fr/stories",
    component: _444418f0,
    name: "stories___fr"
  }, {
    path: "/nl/academy",
    component: _5a5f2c49,
    name: "academy___nl"
  }, {
    path: "/nl/account",
    component: _aa3855f2,
    children: [{
      path: "",
      component: _6bef8b6c,
      name: "account___nl",
      children: [{
        path: "login",
        component: _75760464,
        name: "account-index-login___nl"
      }, {
        path: "register",
        component: _61cbec10,
        name: "account-index-register___nl"
      }, {
        path: "reset",
        component: _5490cf4a,
        name: "account-index-reset___nl"
      }]
    }, {
      path: "courses",
      component: _53dbda30,
      children: [{
        path: "",
        component: _5df0069a,
        name: "account-courses___nl"
      }]
    }, {
      path: "exercises",
      component: _5915ce93,
      children: [{
        path: "",
        component: _2d03dad6,
        name: "account-exercises___nl"
      }, {
        path: "premium",
        component: _46ba811e,
        name: "account-exercises-premium___nl"
      }, {
        path: "workouts",
        component: _4b247922,
        name: "account-exercises-workouts___nl"
      }, {
        path: "premium/:slug",
        component: _4503dfd6,
        name: "account-exercises-premium-slug___nl"
      }]
    }, {
      path: "orders",
      component: _7b37ff1d,
      children: [{
        path: "",
        component: _5cddfbe0,
        name: "account-orders___nl"
      }, {
        path: ":id",
        component: _b50d1370,
        name: "account-orders-id___nl"
      }]
    }, {
      path: "passwordReset",
      component: _31542828,
      name: "account-passwordReset___nl"
    }, {
      path: "profile",
      component: _625adfa1,
      name: "account-profile___nl",
      children: [{
        path: "addresses",
        component: _36dec734,
        name: "account-profile-addresses___nl"
      }, {
        path: "details",
        component: _0c952454,
        name: "account-profile-details___nl"
      }]
    }, {
      path: "verifyEmail",
      component: _7f96e0ca,
      name: "account-verifyEmail___nl"
    }]
  }, {
    path: "/nl/cart",
    component: _62856e36,
    name: "cart___nl"
  }, {
    path: "/nl/debug",
    component: _4f75b2a6,
    name: "debug___nl"
  }, {
    path: "/nl/fonts",
    component: _ed4e6444,
    name: "fonts___nl"
  }, {
    path: "/nl/kennis",
    component: _881a424a,
    children: [{
      path: "",
      component: _7a95951e,
      name: "knowledge-index___nl"
    }, {
      path: "category/:category?",
      component: _ba442e20,
      name: "knowledge-index-category-category___nl"
    }, {
      path: ":topic",
      component: _e1af13d2,
      name: "knowledge-index-topic___nl"
    }, {
      path: ":topic/articles",
      component: _4e488f88,
      name: "knowledge-index-topic-articles___nl"
    }, {
      path: ":topic/:category?",
      component: _5e1440bc,
      name: "knowledge-index-topic-category___nl"
    }]
  }, {
    path: "/nl/oefeningen",
    component: _76b7afb8,
    children: [{
      path: "",
      component: _6580e78a,
      name: "exercises-index___nl"
    }, {
      path: "*",
      component: _b0493470,
      name: "exercises-index-all___nl"
    }]
  }, {
    path: "/nl/onze-experts",
    component: _18dd84a6,
    name: "experts___nl"
  }, {
    path: "/nl/over-blackroll",
    component: _6c67f7ac,
    name: "about___nl"
  }, {
    path: "/nl/processing",
    component: _cc75cbae,
    name: "processing___nl"
  }, {
    path: "/nl/search",
    component: _7656ad3e,
    name: "search___nl"
  }, {
    path: "/nl/shop",
    component: _0d62008f,
    name: "shop___nl"
  }, {
    path: "/nl/stories",
    component: _444418f0,
    name: "stories___nl"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index___en"
  }, {
    path: "/ch-de/artikel/:slug",
    component: _2fb466ce,
    name: "article-slug___ch-de"
  }, {
    path: "/ch-de/collections/:handle",
    component: _3517042c,
    name: "collections-handle___ch-de"
  }, {
    path: "/ch-de/experten/:slug",
    component: _4986eb61,
    name: "experts-slug___ch-de"
  }, {
    path: "/ch-de/products/:slug",
    component: _bae70010,
    name: "products-slug___ch-de"
  }, {
    path: "/ch-de/routine/:slug?",
    component: _ecb1424e,
    name: "routine-slug___ch-de"
  }, {
    path: "/ch-de/shop/:slug",
    component: _e1e1d6ec,
    name: "shop-slug___ch-de"
  }, {
    path: "/ch-de/stories/:slug",
    component: _353ba02b,
    name: "stories-slug___ch-de"
  }, {
    path: "/ch-de/story/:slug?",
    component: _6704d36c,
    name: "story-slug___ch-de"
  }, {
    path: "/ch-de/ueber-blackroll/:slug",
    component: _e3de9036,
    name: "about-slug___ch-de"
  }, {
    path: "/ch-fr/a-propos-de-blackroll/:slug",
    component: _e3de9036,
    name: "about-slug___ch-fr"
  }, {
    path: "/ch-fr/article/:slug",
    component: _2fb466ce,
    name: "article-slug___ch-fr"
  }, {
    path: "/ch-fr/collections/:handle",
    component: _3517042c,
    name: "collections-handle___ch-fr"
  }, {
    path: "/ch-fr/experts/:slug",
    component: _4986eb61,
    name: "experts-slug___ch-fr"
  }, {
    path: "/ch-fr/products/:slug",
    component: _bae70010,
    name: "products-slug___ch-fr"
  }, {
    path: "/ch-fr/routine/:slug?",
    component: _ecb1424e,
    name: "routine-slug___ch-fr"
  }, {
    path: "/ch-fr/shop/:slug",
    component: _e1e1d6ec,
    name: "shop-slug___ch-fr"
  }, {
    path: "/ch-fr/stories/:slug",
    component: _353ba02b,
    name: "stories-slug___ch-fr"
  }, {
    path: "/ch-fr/story/:slug?",
    component: _6704d36c,
    name: "story-slug___ch-fr"
  }, {
    path: "/de/artikel/:slug",
    component: _2fb466ce,
    name: "article-slug___de"
  }, {
    path: "/de/collections/:handle",
    component: _3517042c,
    name: "collections-handle___de"
  }, {
    path: "/de/experten/:slug",
    component: _4986eb61,
    name: "experts-slug___de"
  }, {
    path: "/de/products/:slug",
    component: _bae70010,
    name: "products-slug___de"
  }, {
    path: "/de/routine/:slug?",
    component: _ecb1424e,
    name: "routine-slug___de"
  }, {
    path: "/de/shop/:slug",
    component: _e1e1d6ec,
    name: "shop-slug___de"
  }, {
    path: "/de/stories/:slug",
    component: _353ba02b,
    name: "stories-slug___de"
  }, {
    path: "/de/story/:slug?",
    component: _6704d36c,
    name: "story-slug___de"
  }, {
    path: "/de/ueber-blackroll/:slug",
    component: _e3de9036,
    name: "about-slug___de"
  }, {
    path: "/fr/a-propos-de-blackroll/:slug",
    component: _e3de9036,
    name: "about-slug___fr"
  }, {
    path: "/fr/article/:slug",
    component: _2fb466ce,
    name: "article-slug___fr"
  }, {
    path: "/fr/collections/:handle",
    component: _3517042c,
    name: "collections-handle___fr"
  }, {
    path: "/fr/experts/:slug",
    component: _4986eb61,
    name: "experts-slug___fr"
  }, {
    path: "/fr/products/:slug",
    component: _bae70010,
    name: "products-slug___fr"
  }, {
    path: "/fr/routine/:slug?",
    component: _ecb1424e,
    name: "routine-slug___fr"
  }, {
    path: "/fr/shop/:slug",
    component: _e1e1d6ec,
    name: "shop-slug___fr"
  }, {
    path: "/fr/stories/:slug",
    component: _353ba02b,
    name: "stories-slug___fr"
  }, {
    path: "/fr/story/:slug?",
    component: _6704d36c,
    name: "story-slug___fr"
  }, {
    path: "/nl/article/:slug",
    component: _2fb466ce,
    name: "article-slug___nl"
  }, {
    path: "/nl/collections/:handle",
    component: _3517042c,
    name: "collections-handle___nl"
  }, {
    path: "/nl/onze-experts/:slug",
    component: _4986eb61,
    name: "experts-slug___nl"
  }, {
    path: "/nl/over-blackroll/:slug",
    component: _e3de9036,
    name: "about-slug___nl"
  }, {
    path: "/nl/products/:slug",
    component: _bae70010,
    name: "products-slug___nl"
  }, {
    path: "/nl/routine/:slug?",
    component: _ecb1424e,
    name: "routine-slug___nl"
  }, {
    path: "/nl/shop/:slug",
    component: _e1e1d6ec,
    name: "shop-slug___nl"
  }, {
    path: "/nl/stories/:slug",
    component: _353ba02b,
    name: "stories-slug___nl"
  }, {
    path: "/nl/story/:slug?",
    component: _6704d36c,
    name: "story-slug___nl"
  }, {
    path: "/ch-fr/academy/*",
    component: _34f93cd6,
    name: "academy-all___ch-fr"
  }, {
    path: "/ch-de/academy/*",
    component: _34f93cd6,
    name: "academy-all___ch-de"
  }, {
    path: "/nl/academy/*",
    component: _34f93cd6,
    name: "academy-all___nl"
  }, {
    path: "/fr/academy/*",
    component: _34f93cd6,
    name: "academy-all___fr"
  }, {
    path: "/de/academy/*",
    component: _34f93cd6,
    name: "academy-all___de"
  }, {
    path: "/ch-fr/service/*",
    component: _3a3629df,
    name: "service-all___ch-fr"
  }, {
    path: "/ch-de/service/*",
    component: _3a3629df,
    name: "service-all___ch-de"
  }, {
    path: "/nl/service/*",
    component: _3a3629df,
    name: "service-all___nl"
  }, {
    path: "/fr/service/*",
    component: _3a3629df,
    name: "service-all___fr"
  }, {
    path: "/de/service/*",
    component: _3a3629df,
    name: "service-all___de"
  }, {
    path: "/about-blackroll/:slug",
    component: _e3de9036,
    name: "about-slug___en"
  }, {
    path: "/article/:slug",
    component: _2fb466ce,
    name: "article-slug___en"
  }, {
    path: "/collections/:handle",
    component: _3517042c,
    name: "collections-handle___en"
  }, {
    path: "/experts/:slug",
    component: _4986eb61,
    name: "experts-slug___en"
  }, {
    path: "/products/:slug",
    component: _bae70010,
    name: "products-slug___en"
  }, {
    path: "/routine/:slug?",
    component: _ecb1424e,
    name: "routine-slug___en"
  }, {
    path: "/shop/:slug",
    component: _e1e1d6ec,
    name: "shop-slug___en"
  }, {
    path: "/stories/:slug",
    component: _353ba02b,
    name: "stories-slug___en"
  }, {
    path: "/story/:slug?",
    component: _6704d36c,
    name: "story-slug___en"
  }, {
    path: "/ch-fr/*",
    component: _5022c88e,
    name: "all___ch-fr"
  }, {
    path: "/ch-de/*",
    component: _5022c88e,
    name: "all___ch-de"
  }, {
    path: "/nl/*",
    component: _5022c88e,
    name: "all___nl"
  }, {
    path: "/fr/*",
    component: _5022c88e,
    name: "all___fr"
  }, {
    path: "/de/*",
    component: _5022c88e,
    name: "all___de"
  }, {
    path: "/academy/*",
    component: _34f93cd6,
    name: "academy-all___en"
  }, {
    path: "/service/*",
    component: _3a3629df,
    name: "service-all___en"
  }, {
    path: "/*",
    component: _5022c88e,
    name: "all___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
