import gql from 'graphql-tag';
import { transformMetaField } from '@/utils/shopify';
import { ComparisonCardProductFragment } from '@/components/shop/products/ProductCompareCardLarge';

export const collectionMetaFieldTransformer = (collectionData) => {
  const metafieldKeys = ['comparisonHeaders'];
  metafieldKeys.forEach((field) => transformMetaField(collectionData, field));
  return collectionData;
};

export const CollectionComparisonFragment = gql`
  fragment CollectionComparisonFragment on Collection {
    _comparisonHeaders: metafield(namespace: "oneworld", key: "comparisonHeaders") {
      value
      type
    }
  }
`;

export const ShopifyComparisonQuery = gql`
  query ShopifyComparisonQuery($handle: String!, $limit: Int = 20, $lang: LanguageCode!) @inContext(language: $lang) {
    collection: collectionByHandle(handle: $handle) {
      handle
      ...CollectionComparisonFragment
      products(first: $limit) {
        edges {
          node {
            ...ComparisonCardProductFragment
          }
        }
      }
    }
  }
  ${ComparisonCardProductFragment}
  ${CollectionComparisonFragment}
`;
