<template>
  <input
    class="block focus:outline-none focus:ring-2 ring-br-green fluid-sm tracking-wider font-medium w-full px-6 py-3 bg-gray-100 border border-transparent rounded-full appearance-none text-blackly"
    :class="[theme === 'dark' ? 'ring-offset-blackly' : 'ring-offset-br-green-light']"
    type="email"
    placeholder="Email"
    :value="value"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: 'light',
    },
    value: {
      type: String,
      default: '',
    },
  },
};
</script>
