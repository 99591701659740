/* eslint-disable no-unreachable */
import { httpToVercelRequest, httpToVercelResponse } from './functions/helpers.js';

export default async function (req, res, next) {
  // const body = req.read(); - this should work, but it's being very strange. Leaving for now.
  const body = Buffer.from('');

  const apiNameRegex = /\/([\w-]+).*/g;

  const matches = apiNameRegex.exec(req.url || '');

  if (!matches || !matches[1]) {
    res.statusCode = 404;
    res.end();
    return;
  }

  const apiName = matches[1];
  const moduleName = '../api/' + apiName;

  // allow dynamic code change reloads - possibly detect changes and remove that way
  const requestedApi = await import(`${moduleName}`);

  const vercelRequest = httpToVercelRequest(req, body);
  const vercelResponse = httpToVercelResponse(req, res);

  try {
    requestedApi.default(vercelRequest, vercelResponse);
  } catch (err) {
    console.error(err);
    res.statusCode = 500;
    res.end();
  }
}
