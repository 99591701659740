<template>
  <transition
    leave-class="scale-y-100 opacity-100"
    leave-to-class="scale-y-0 opacity-0"
    leave-active-class="transition"
  >
    <div
      v-if="showMarketingBar"
      class="items-center fluid-xs leading-tightest relative flex justify-between bg-blackly text-white origin-top"
    >
      <div class="w-10 bg-br-green" />
      <div class="flex flex-wrap md:flex-nowrap justify-center text-center py-2">
        <div v-for="(block, idx) in blocks" :key="idx" class="flex items-center">
          <!-- I inlined the blocks here because i felt they were to lightweight , maybe the countdown could be extracted if the logic is to big -->
          <img v-if="block.typeHandle === 'icon'" :src="block.svg[0].url" :alt="block.text" class="h-4 mr-2" />
          <div v-if="block.typeHandle === 'text'" class="mx-1">{{ block.text }}</div>
          <Countdown v-if="block.typeHandle === 'countdown'" :date-time="block.dateTime" class="mx-1" />
          <LinkField v-if="block.typeHandle === 'cta'" v-bind="block.cta" class="underline mx-1" />
        </div>
      </div>
      <button
        class="w-10 justify-center top-0 h-full flex items-center hover:opacity-80 transition-all focus:outline-none"
        @click="onClose"
      >
        <IconClose class="w-2.5" />
      </button>
    </div>
  </transition>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Countdown from '@/components/layout/Countdown';
import LinkField from '@/components/common/LinkField';
import IconClose from '@/components/layout/header/IconClose';

export default {
  components: { IconClose, LinkField, Countdown },

  data() {
    return {
      setting: null,
    };
  },

  computed: {
    ...mapState('globalMarketingBar', ['blocks', 'isOpen']),

    showMarketingBar() {
      if (this.isOpen) {
        if (this.setting) {
          if (this.setting.state === 'closed') {
            if (this.setting.expiry > new Date().getTime()) {
              return false;
            }
          }
        }
      }

      return this.isOpen;
    },
  },

  mounted() {
    this.setting = JSON.parse(localStorage.getItem('globalMarketingBar'));
  },

  methods: {
    ...mapActions('globalMarketingBar', ['close']),

    onClose() {
      if (window) {
        localStorage.setItem(
          'globalMarketingBar',
          JSON.stringify({
            state: 'closed',
            expiry: new Date().getTime() + 1000 * 60 * 60 * 24 * 7, // 7 days
          })
        );
      }

      this.close();
    },
  },
};
</script>
