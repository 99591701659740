export const transformMetaField = (metaData, fieldName) => {
  const jsonFieldName = '_' + fieldName;

  if (metaData[jsonFieldName] && metaData[jsonFieldName].value && metaData[jsonFieldName].type) {
    const { value, type } = metaData[jsonFieldName];

    if (type.toLowerCase() === 'string' || type === 'single_line_text_field') {
      metaData[fieldName] = value;
    } else if (type.toLowerCase() === 'json_string' || type.toLowerCase() === 'json') {
      try {
        metaData[fieldName] = JSON.parse(value);
      } catch (e) {
        metaData[fieldName] = null;
        console.error(`transformMetaField for ${fieldName} failed because of`, e.message);
      }
    }
  } else {
    metaData[fieldName] = null;
  }

  return metaData;
};
