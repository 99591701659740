<template>
  <Transition name="modal-fade">
    <div
      class="bg-blackly fixed inset-0 z-50 flex items-center justify-center w-full h-screen"
      :class="{ 'bg-opacity-75': !fullscreen, 'px-4': !video }"
      @mousedown.self="close"
    >
      <div
        class="relative p-4 md:p-8"
        :class="[
          fullscreen ? 'max-w-screen-2xl w-full h-full flex items-center justify-center' : 'md:p-6 bg-white max-w-xl',
          !fullscreen && !disableScroll && 'overflow-y-scroll',
          minWidth ? 'max-h-[80vh] min-w-[300px] md:min-w-[650px]' : 'w-auto'
        ]"
      >
        <button
          v-if="!noCloseBtn"
          aria-label="close"
          class="lg:w-16 lg:h-16 absolute top-0 right-0 z-20 flex items-center justify-center w-12 h-12 mx-4 my-2 fluid-3xl leading-tightest"
          :class="{ 'bg-blackly text-white bg-opacity-75 rounded-full': fullscreen }"
          @click.prevent="close"
        >
          <CloseIcon class="h-6" />
        </button>
        <slot />
      </div>
    </div>
  </Transition>
</template>
<script>
import CloseIcon from '@/components/icons/CloseIcon'

export default {
  components: { CloseIcon },
  props: {
    fullscreen: {
      type: Boolean,
      default: false
    },
    video: {
      type: Boolean,
      default: false
    },
    disableScroll: {
      type: Boolean,
      default: false
    },
    minWidth: {
      type: Boolean,
      default: false
    },
    noCloseBtn: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
