<template>
  <div class="flex flex-col w-full mb-3">
    <label v-if="label" :for="id" class="fluid-sm leading-tightest mb-2 font-light text-black"
      >{{ label }}<span v-if="required" class="text-br-red">*</span></label
    >
    <!-- <div class="h-10 h-9"></div> -->
    <div class="relative w-full" :class="higher ? 'h-10' : 'h-9'">
      <input
        :id="id"
        v-bind="$attrs"
        :type="inputType"
        :disabled="disabled"
        class="bg-lighter-gray text-[16px] md:fluid-sm leading-tightest focus:outline-none placeholder:text-[#162022] w-full h-full pl-6 pr-8 font-medium border-b"
        :class="[
          disabled ? 'text-gray-500' : 'text-black',
          !error ? 'border-gray-400 focus:border-br-green' : 'border-br-red'
        ]"
        :aria-invalid="error ? true : null"
        :aria-describedby="error ? `${id}-error` : null"
        :placeholder="placeholder ? placeholder : ''"
        @input="emitValue"
      />
      <div class="absolute top-0 right-0 flex flex-row h-full">
        <div v-if="error" class="h-9 w-9 flex items-center justify-center">
          <WarningIcon class="h-4" />
        </div>
        <div
          v-if="type === 'password'"
          class="h-9 w-9 flex items-center justify-center cursor-pointer"
          @click="togglePasswordVisibility"
        >
          <EyeIcon class="h-3.5" />
        </div>
      </div>
    </div>
    <div class="fluid-xs leading-tightest text-medium py-1">
      <p v-if="helpText">{{ helpText }}</p>
      <p v-if="error" :id="`${id}-error`" class="text-br-red">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import WarningIcon from '@/components/icons/WarningIcon'
import EyeIcon from '@/components/icons/EyeIcon'
export default {
  name: 'FormTextInput',
  components: {
    WarningIcon,
    EyeIcon
  },
  inheritAttrs: false,
  props: {
    error: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    higher: {
      type: Boolean,
      default: false
    },
    helpText: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      showPassword: false,
      id: this._uid
    }
  },
  computed: {
    inputType() {
      if (this.type !== 'password') {
        return this.type
      }
      return this.showPassword ? 'text' : 'password'
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword
    },
    emitValue(e) {
      return this.$emit('input', e.target.value)
    }
  }
}
</script>
