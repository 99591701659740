import isString from 'lodash/isString';

export default (ctx, inject) => {
  const { app } = ctx;

  const rewriteRootPath = (path) => {
    if (path === '__home__') return '/';
    return path.startsWith('/') ? path : '/' + path;
  };

  /**
   * rewrites route object or path to be relative to document-root prefixt with i18n-locale of current context
   * @param {string, object} route - nuxt route object or path
   * @param {sting} locale - optional i18n locale, defaults to current context locale
   * @returns
   */
  const localeRootPath = (route, locale = null) => {
    const args = [];
    if (!route) args[0] = route;
    else if (isString(route)) args[0] = rewriteRootPath(route);
    else if (route.path !== undefined) args[0] = { ...route, path: rewriteRootPath(route.path) };
    else args[0] = route;

    if (locale) args[1] = locale;
    return app.localePath(...args);
  };
  inject('localeRootPath', localeRootPath);
};
