var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-nowrap items-baseline"},[(_vm.compareAtPrice && _vm.compareAtPrice.amount > 0)?_c('span',{staticClass:"text-gray-light block line-through decoration-blackly mr-2 fluid-lg leading-tight self-baseline !font-light",attrs:{"data-test-id":"old-price"}},[_vm._v("\n    "+_vm._s(_vm.$n(_vm.compareAtPrice.amount, {
        style: 'currency',
        currency: _vm.compareAtPrice.currencyCode || _vm.currencyCode,
      }))+"\n  ")]):(_vm.priceFrom)?_c('span',{staticClass:"fluid-xs text-gray-light text-center leading-none md:leading-normal mr-1",attrs:{"data-test-id":"price-range"}},[_vm._v("\n    "+_vm._s(_vm.$t('product.from'))+"\n  ")]):_vm._e(),_vm._v(" "),(_vm.price)?_c('div',{staticClass:"self-baseline"},[_c('span',{class:[
        _vm.priceClass ? _vm.priceClass : 'fluid-base',
        _vm.compareAtPrice && _vm.compareAtPrice.amount > 0 ? 'leading-tight' : 'leading-7',
      ],attrs:{"data-test-id":"price"}},[_vm._v("\n      "+_vm._s(_vm.$n(_vm.price.amount, {
          style: 'currency',
          currency: _vm.price.currencyCode || _vm.currencyCode,
        }))+"\n    ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }