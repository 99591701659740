<template>
  <div
    class="fluid-sm leading-tight relative block max-w-lg px-6 py-2 mx-6 my-2 font-medium text-center cursor-pointer"
    :class="[colorization]"
    @click="$emit('close')"
  >
    {{ toast.message }}
  </div>
</template>

<script>
export default {
  props: {
    toast: {
      type: Object,
      required: true
    }
  },

  computed: {
    colorization() {
      switch (this.toast.type ? this.toast.type : 'default') {
        case 'success':
          return 'text-white bg-br-green'

        case 'error':
          return 'text-white bg-br-red'

        default:
          return 'text-white bg-black'
      }
    }
  }
}
</script>
