<template>
  <div class="overflow-auto" :class="{ 'bg-putty': backgroundGray }">
    <div class="matrix-block max-w-expanded-md mx-auto">
      <VideoWithHeading
        :video="asset"
        :autoplay="autoplay"
        :controls="!autoplay"
        :muted="!autoplay"
        full-width
        :ratio="aspectRatio"
        data-test-id="asset"
      />
    </div>
  </div>
</template>

<script>
import VideoWithHeading from '@/components/common/VideoWithHeading/VideoWithHeading'
export default {
  components: {
    VideoWithHeading
  },
  props: {
    alt: {
      type: String,
      default: null
    },
    asset: {
      type: Array,
      default: () => []
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    backgroundGray: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    aspectRatio() {
      return (this.asset.length && this.asset[0].height / this.asset[0].width) || 9 / 16
    }
  }
}
</script>
