<template>
  <client-only>
    <div class="h-full flex items-center lg:px-2">
      <div class="relative">
        <input
          type="search"
          :value="searchQuery"
          class="hidden lg:block search-input border bg-putty rounded-full h-8 pl-8 border-transparent focus:border-br-green pr-4 w-auto fluid-xs leading-tightest"
          :placeholder="$t('search.inputPlaceholder')"
          @focus="handleInputFocus"
        />
        <button
          class="flex items-center justify-center w-8 h-8 lg:text-br-green lg:absolute top-0 left-0 ml-1 text-blackly"
          @click="handleInputFocus"
        >
          <SearchIcon class="w-5 h-5" />
        </button>
      </div>
      <SearchModal />
    </div>
  </client-only>
</template>

<script>
import SearchIcon from '@/components/icons/SearchIcon'
import { mapActions, mapGetters } from 'vuex'
const SearchModal = () => import(/* webpackChunkName: "search-modal" */ '@/components/search/SearchModal')

export default {
  components: {
    SearchModal,
    SearchIcon
  },
  computed: {
    ...mapGetters('search', ['searchQuery'])
  },
  mounted() {
    this.setQuery(this.$route.query.query ?? '')
  },
  methods: {
    ...mapActions('search', ['open', 'setQuery']),
    handleInputFocus() {
      document.activeElement.blur()
      this.$nextTick(function () {
        this.open()
      })
    }
  }
}
</script>

<style scoped>
.search-input::-webkit-search-decoration,
.search-input::-webkit-search-cancel-button,
.search-input::-webkit-search-results-button,
.search-input::-webkit-search-results-decoration {
  @apply hidden;
}
.search-input {
  @apply appearance-none;
  &:focus {
    @apply outline-none;
  }
}
.search-input:focus + .search-button {
  @apply text-br-green;
}
.backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
