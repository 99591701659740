const BND_TYPE = 'combos';
const FBT_TYPE = 'bundles';

const productTransformer = (payload, shopifyProducts = []) => {
  // check format of payload
  const product = payload.product ? payload.product : payload;
  const shopifyProduct = shopifyProducts.find((p) => p?.handle === product.handle);

  const availableVariants = product.allowedVariants.length > 0 ? product.allowedVariants : product.variants;

  return {
    id: product.id,
    handle: product.handle,
    title: product.title,
    bodyHtml: product.bodyHtml,
    productCardUsp: shopifyProduct?._productCardUsp?.value ?? null,
    images: [
      product.featuredImage ? { src: product.featuredImage.src } : null,
      ...product.images.map((image) => {
        return { src: image.src };
      }),
    ].filter((image) => image !== null),
    variants: availableVariants.map((variant) => {
      const shopifyVariant = shopifyProduct?.variants?.edges.find((v) => v.node.id.includes(variant.id));

      return {
        id: variant.id,
        title: variant.title,
        price: { amount: variant.price, currencyCode: 'EUR' },
        sku: variant.sku,
        image: variant.image ? variant.image.src : null,

        // enrich with shopify data
        available: shopifyVariant?.node.available ?? false,
        compareAtPrice: shopifyVariant?.node.compareAtPrice,
        unitPrice: shopifyVariant?.node.unitPrice,
        unitPriceMeasurement: shopifyVariant?.node.unitPriceMeasurement,
      };
    }),
    shopifyProduct,
  };
};

const calculateDiscountForBND = (deal) => {
  const result = {
    bundlePrice: null,
    bundleCompareAtPrice: null,
    discountPerProduct: null,
  };

  // #region calculate the bundle compare at price
  if (deal.discountValue > 0) {
    const sum = deal.products.reduce((sum, product) => {
      return sum + product.variants[0].price.amount;
    }, 0);
    result.bundleCompareAtPrice = {
      amount: sum,
      currencyCode: 'EUR',
    };
  } else {
    const sum = deal.products.reduce((sum, product) => {
      const firstVariant = product.variants[0];
      const shopifyVariant = product.shopifyProduct.variants.edges.find((variant) =>
        variant.node.id.includes(firstVariant.id)
      );

      return sum + shopifyVariant.node.compareAtPrice ? shopifyVariant.node.compareAtPrice : firstVariant.price.amount;
    }, 0);

    result.bundleCompareAtPrice = {
      amount: sum,
      currencyCode: 'EUR',
    };
  }
  // #endregion

  // #region calculate the bundle price
  if (deal.discountValue > 0) {
    switch (deal.discountType) {
      case 'fixedAmount':
        result.bundlePrice = {
          amount: result.bundleCompareAtPrice.amount - deal.discountValue,
          currencyCode: 'EUR',
        };
        break;
      case 'percentage':
        result.bundlePrice = {
          amount: result.bundleCompareAtPrice.amount * (1 - deal.discountValue / 100),
          currencyCode: 'EUR',
        };
        break;
      case 'fixedTargetPrice':
        result.bundlePrice = {
          amount: deal.discountValue,
          currencyCode: 'EUR',
        };
        break;
    }
  } else {
    result.bundlePrice = result.bundleCompareAtPrice;
  }
  // #endregion

  // #region Calculate the discount per product
  if (deal.discountType === 'percentage') {
    result.discountPerProduct = deal.discountValue;
  } else {
    // calculate the percentage of the discount
    const percentageDiscount = ((result.bundlePrice.amount / result.bundleCompareAtPrice.amount) * -1 + 1) * 100;

    result.discountPerProduct = percentageDiscount ?? 0;
  }
  // #endregion

  return result;
};

const calculateDiscountForFBT = (deal) => {
  const result = {
    bundlePrice: 0,
    bundleCompareAtPrice: 0,
    discountPerProduct: 0,
  };

  const productsList = deal.isBogo ? [...deal.products, ...deal.discountProducts] : deal.products;

  // this is the price of the bundle without the discount
  const productsTotalPrice =
    Math.round(
      productsList?.reduce((sum, product) => {
        return sum + product.variants[0]?.price?.amount ?? 0;
      }, 0) * 100
    ) / 100;

  // #region Calculate the compare at price
  if (deal.discountValue > 0) {
    // if we have a discountValue, productsTotalPrice becomes the compare at price
    result.bundleCompareAtPrice = {
      amount: productsTotalPrice,
      currencyCode: 'EUR',
    };
  } else {
    // deal has no disount, therefore we should look for the compare at price of the shopify product
    result.bundleCompareAtPrice = {
      amount: productsList?.reduce((sum, product) => {
        // get shopify variant for first variant of product
        const shopifyVariant = product.shopifyProduct.variants.edges.find(
          (v) => v.node.sku === product.variants[0].sku
        );

        if (shopifyVariant.node.compareAtPrice) {
          return sum + Number(shopifyVariant.node.compareAtPrice.amount);
        } else {
          return sum + Number(shopifyVariant.node.price.amount);
        }
      }, 0),
      currencyCode: 'EUR',
    };
  }
  // #endregion

  // #region Calculate the price of the complete bundle
  if (deal.discountValue > 0) {
    switch (deal.discountType) {
      case 'fixedAmount':
        result.bundlePrice = {
          amount: Math.round((result.bundleCompareAtPrice.amount - deal.discountValue) * 100) / 100,
          currencyCode: 'EUR',
        };
        break;
      case 'percentage':
        result.bundlePrice = {
          amount:
            result.bundleCompareAtPrice.amount -
            ((result.bundleCompareAtPrice.amount - (deal.isBogo ? deal.products[0].variants[0].price.amount : 0)) *
              deal.discountValue) /
              100,
          currencyCode: 'EUR',
        };
        break;
      case 'fixedTargetPrice':
        result.bundlePrice = {
          amount:
            Math.round(((deal.isBogo ? deal.products[0].variants[0].price.amount : 0) + deal.discountValue) * 100) /
            100,
          currencyCode: 'EUR',
        };
        break;
    }
  } else {
    result.bundlePrice = {
      amount: productsTotalPrice,
      currencyCode: 'EUR',
    };
  }
  // #endregion

  // #region Calculate the discount per product
  if (deal.discountType === 'percentage') {
    result.discountPerProduct = deal.discountValue;
  } else if (deal.isBogo) {
    // count default price of all addOn products
    const totalAddonPrice = deal.discountProducts.reduce((sum, product) => {
      return sum + product.variants[0]?.price?.amount ?? 0;
    }, 0);

    const discountTotal = result.bundleCompareAtPrice.amount - result.bundlePrice.amount;
    const percentageDiscount = (discountTotal / totalAddonPrice) * 100;

    result.discountPerProduct = percentageDiscount;
  } else {
    // calculate the percentage of the discount
    const percentageDiscount = ((result.bundlePrice.amount / result.bundleCompareAtPrice.amount) * -1 + 1) * 100;

    result.discountPerProduct = percentageDiscount ?? 0;
  }
  // #endregion

  return result;
};

const discountCalculator = (deal, type) => {
  if (type === FBT_TYPE) {
    const { bundlePrice, bundleCompareAtPrice, discountPerProduct } = calculateDiscountForFBT(deal);
    return { ...deal, bundlePrice, bundleCompareAtPrice, discountPerProduct };
  } else if (type === BND_TYPE) {
    const { bundlePrice, bundleCompareAtPrice, discountPerProduct } = calculateDiscountForBND(deal);
    return { ...deal, bundlePrice, bundleCompareAtPrice, discountPerProduct };
  }

  return deal;
};

export { productTransformer, discountCalculator, BND_TYPE, FBT_TYPE };
