<template>
  <!-- ontouchstart allows to use :active pseudo class on safari mobile -->
  <button
    type="button"
    class="focus:outline-none outline-none arrow-button active:text-br-green"
    :class="{
      'pointer-events-none opacity-10 hover:text-black': arrowDisabled,
      'lg:hover:text-br-green': !arrowDisabled,
      'text-white': inverted
    }"
    :disabled="arrowDisabled"
    @touchstart.passive="doNothing"
    @click.prevent="$emit('click', direction)"
  >
    <ArrowIcon class="transition-all h-5" :class="direction === 'right' ? 'rotate-180' : null" />
  </button>
</template>

<script>
import ArrowIcon from '@/components/icons/ArrowIcon'

export default {
  components: { ArrowIcon },
  props: {
    arrowDisabled: {
      type: Boolean,
      default: false
    },
    inverted: {
      type: Boolean,
      default: false
    },
    direction: {
      type: String,
      default: 'left'
    }
  },
  methods: {
    doNothing() {}
  }
}
</script>

<style>
@media (hover: hover) and (pointer: fine) {
  .arrow-button:hover {
    @apply text-br-green; /* solves sticky problem */
  }
}
</style>
