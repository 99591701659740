import { removeFileExtension } from '@/utils/common';

export const RESOLUTIONS = [120, 360, 480, 768, 1080, 1440, 1920, 2560];

export default function ({ env }, inject) {
  // https://storage.googleapis.com/blackroll-cms/assets/sample.jpg
  // https://res.cloudinary.com/surfingpeeps-dev/image/upload/w_100/oneworld-dev/assets/sample.jpg

  const crops = [
    'auto',
    'scale',
    'limit',
    'fill',
    'fit',
    'crop',
    'thumb',
    'pad',
    'lfill',
    'lpad',
    'mfit',
    'mpad',
    'imagga_crop',
    'imagga_scale',
  ];

  const cldn = {
    transformOptions({ transformation, width, height, crop, quality, format, gravity }) {
      if (transformation) return transformation;
      const trans = [];
      if (width) trans.push(`w_${width}`);
      if (height) trans.push(`h_${height}`);
      if (crop) {
        if (!crops.includes(crop)) console.error('cldn wrong crop value', crop);
        trans.push(`c_${crop}`);
      }
      if (quality) trans.push(`q_${quality}`);
      if (format && format === 'auto') trans.push(`f_auto`);
      if (gravity) trans.push(`g_${gravity}`);
      return trans.join(',');
    },

    /**
     * resolves cloudinary bucket config to use by url
     * @param {string} url image/video url
     * @returns {object} cloudinary config with folder: and prefix: to build cloudinary url
     */
    resolveBucketConfig(url) {
      const bucketConfig = env.cldn.buckets.find((bucket) => url.includes(bucket.uploadUrlPrefix));
      if (!bucketConfig) return null;

      bucketConfig.cloudinaryBaseUrl = env.cloudinaryBaseUrl;
      return bucketConfig;
    },

    /**
     * extracts focal proin from url query param :v
     * splits version string extracts x and y focal coordinates
     * @param {string} url
     * @returns {array} [x, y] where x : horizontal value 0 - 100 starting form left , y : vertical value 0 -100 starting from top
     */
    focalPoint(url) {
      if (!url) return null;

      // extrats search query params and convert to object
      const result = (url.match(/([^#?]*)(\?[^#]*)?(#.*)?/) || []).splice(1);
      if (!result || !result[1]) return null;
      const params = Object.fromEntries(new URLSearchParams(result[1]));

      // uses v param
      const version = params.v;

      if (!version) return null;
      const parts = version.match(/^\d+,([0-9.]*),([0-9.]*)$/);
      if (parts === null) return null;
      if (parts.length !== 3) return null;
      const x = parseFloat(parts[1]) * 100;
      const y = parseFloat(parts[2]) * 100;
      return { x, y };
    },

    /**
     * generates cloudinary gravity string from percent waigthed focal point
     * @param {number} x - range: 0 - 100
     * @param {number} y - range: 0 - 100
     * @returns {string} - north_east, north, north_west, west, south_west, south, south_east, east, center
     */
    focalToGravity(x, y) {
      let vertical = 'center';
      if (y < 25) vertical = 'north';
      if (y > 75) vertical = 'south';

      let horizontal = 'center';
      if (x < 25) horizontal = 'west';
      if (x > 75) horizontal = 'east';

      if (vertical === 'center') return horizontal;
      if (horizontal === 'center') return vertical;
      return `${vertical}_${horizontal}`;
    },

    imgUrl(url, options = {}) {
      const config = this.resolveBucketConfig(url);
      if (!config) {
        console.warn('cldn: image url could not be matched to bucket from cldn.buckets list', url);
        return null;
      }
      const focalPoint = this.focalPoint(url);
      if (focalPoint) {
        const { x, y } = focalPoint;
        options.gravity = this.focalToGravity(x, y);
      }

      const transformation = this.transformOptions(options);
      const path = url.split(config.uploadUrlPrefix)[1];

      return [config.cloudinaryBaseUrl, 'image/upload', transformation, config.folder, path]
        .join('/')
        .replace(/([^:]\/)\/+/g, '$1');
    },
    videoUrl(url, options = {}) {
      const config = this.resolveBucketConfig(url);
      if (!config) {
        console.warn('cldn: video url could not be matched to bucket from cldn.buckets list', url);
        return null;
      }
      const transformation = this.transformOptions(options);
      const path = url.split(config.uploadUrlPrefix)[1];

      return [config.cloudinaryBaseUrl, 'video/upload', transformation, config.folder, path]
        .join('/')
        .replace(/([^:]\/)\/+/g, '$1');
    },
    videoPreviewUrl(url, options = {}) {
      const config = this.resolveBucketConfig(url);
      if (!config) {
        console.warn('cldn: video url could not be matched to bucket from cldn.buckets list', url);
        return null;
      }
      const transformation = this.transformOptions(options);
      let path = url.split(config.uploadUrlPrefix)[1];
      // trigger image rendering
      path = removeFileExtension(path) + '.jpg';

      return [config.cloudinaryBaseUrl, 'video/upload', 'e_preview,so_0', transformation, config.folder, path]
        .join('/')
        .replace(/([^:]\/)\/+/g, '$1');
    },
  };
  inject('cldn', cldn);
}
