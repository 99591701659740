<template>
  <div>
    <component :is="isInIframe ? 'div' : 'portal'" v-if="!hiddenPrerender" to="modal">
      <ModalLayout
        v-if="showing"
        :fullscreen="fullscreen"
        :disable-scroll="disableScroll"
        :min-width="minWidth"
        :video="video"
        :no-close-btn="noCloseBtn"
        @close="close"
        ><slot
      /></ModalLayout>
    </component>
    <ModalLayout
      v-else
      v-show="showing"
      :fullscreen="fullscreen"
      :disable-scroll="disableScroll"
      :min-width="minWidth"
      :no-close-btn="noCloseBtn"
      @close="close"
      ><slot
    /></ModalLayout>
  </div>
</template>
<script>
import ModalLayout from '@/components/common/ModalLayout';

export default {
  components: { ModalLayout },
  props: {
    showing: {
      required: true,
      type: Boolean,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    hiddenPrerender: {
      type: Boolean,
      default: false,
    },
    disableScroll: {
      type: Boolean,
      default: false,
    },
    minWidth: {
      type: Boolean,
      default: false,
    },
    video: {
      type: Boolean,
      default: false,
    },
    noCloseBtn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isInIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
  },
  watch: {
    showing(value) {
      if (value) {
        return document.querySelector('body').classList.add('modal-open');
      }
      document.querySelector('body').classList.remove('modal-open');
    },
  },
  mounted() {
    this.$router?.afterEach(() => {
      document.querySelector('body').classList.remove('modal-open');
    });
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style>
.modal-fade-enter-active,
.modal-fade-leave-active {
  @apply transition-all delay-[400];
}
.modal-fade-enter,
.modal-fade-leave-to {
  @apply opacity-0;
}

/* stop scroll on modal open */
body.modal-open {
  @apply overflow-hidden;
}

/* prevent page-header layered on top of model */
body.modal-open .page-header {
  @apply !z-0;
}
</style>
