<template>
  <div
    class="py-20"
    data-test-id="background"
    :class="{ 'bg-blackly': backgroundBlack, 'bg-br-green-light': !backgroundBlack }"
  >
    <div class="max-w-screen-2xl mx-auto relative flex flex-col items-center overflow-x-hidden">
      <div
        class="testimonial-font-medium fluid-base leading-relaxed font-light text-br-green"
        :class="{ 'text-white': backgroundBlack }"
      >
        {{ heading }}
      </div>

      <transition name="fade" mode="out-in" :duration="150">
        <Testimonial
          :key="currentSlide"
          class="my-10"
          :testimonial="slides[currentSlide]"
          :background-black="backgroundBlack"
          :style="{ height: testimonialHeight }"
          data-test-id="testimonial"
        />
      </transition>

      <div v-if="isCheckingHeight" class="invisible testimonials-slider absolute">
        <Testimonial v-for="slide in slides" ref="testimonials" :key="slide.id" :testimonial="slide" />
      </div>

      <div
        v-if="slides.length > 1"
        class="align-end grid h-1 w-3/4 sm:w-1/2 gap-4"
        :class="`grid-cols-${slides.length}`"
      >
        <button
          v-for="(slide, index) in slides"
          :key="slide.id"
          class="h-full w-full focus:outline-none relative"
          :class="[backgroundBlack ? 'bg-br-green-light' : 'bg-blackly-med']"
          data-test-id="slide-button"
          @click="changeSlide(index)"
        >
          <span
            v-if="index === current"
            class="h-full block bg-br-green"
            :class="{
              'slider-countdown': index === current && autoplay,
              'w-full': index === current && !autoplay,
            }"
          ></span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Testimonial from '@/components/common/blocks/TestimonialWithVariedBackground/Testimonial';
import debounce from 'lodash/debounce';

export default {
  name: 'TestimonialWithVariedBackground',

  components: { Testimonial },

  props: {
    heading: {
      type: String,
      default: '',
    },
    backgroundBlack: {
      type: Boolean,
      default: false,
    },
    slides: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      autoplay: false,
      current: 0,
      highestTestimonial: null,
      isCheckingHeight: true,
      interval: null,
      autoplaySpeed: 7000,
    };
  },

  computed: {
    currentSlide() {
      return this.current;
    },
    testimonialHeight() {
      if (this.highestTestimonial) return this.highestTestimonial + 'px';
      return 'auto';
    },
  },

  mounted() {
    this.mountObserver();
    setTimeout(this.setHeight, 200);
    window.addEventListener('resize', this.handleResize(this));
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize(this));
  },

  methods: {
    setCurrent() {
      this.interval = setInterval(() => {
        if (this.current < this.slides.length - 1) {
          this.current++;
        } else {
          this.current = 0;
        }
      }, this.autoplaySpeed);
    },

    setHeight() {
      this.isCheckingHeight = true;

      this.highestTestimonial = this.$refs.testimonials?.reduce((acc, curr) => {
        const currentHeight = Math.round(curr.$el.getBoundingClientRect().height);

        return Math.max(acc, currentHeight);
      }, 0);

      this.$nextTick(() => {
        this.isCheckingHeight = false;
      });
    },

    handleResize(ctx) {
      return debounce(() => {
        ctx.setHeight();
      }, 30);
    },

    removeInterval() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
        this.autoplay = false;
      }
    },

    changeSlide(index) {
      this.removeInterval();
      this.current = index;
    },

    mountObserver() {
      const intersectionObserver = new IntersectionObserver((entries, observer) => {
        if (entries[0].isIntersecting) {
          this.autoplay = true;
          this.setCurrent();
          observer.disconnect();
        }
      });
      intersectionObserver.observe(this.$el);
    },
  },
};
</script>

<style lang="postcss" scoped>
.fade-enter-active,
.fade-leave-active {
  @apply opacity-100 transition-all delay-300;
}
.fade-enter,
.fade-leave-active {
  @apply opacity-0;
}
.testimonials-slider {
  grid-auto-flow: column;
  grid-auto-columns: 100vw;
}

.slider-countdown {
  @apply absolute top-0 left-0 bottom-0;
  animation: filling 7s linear 1 normal;
}

@keyframes filling {
  from {
    @apply w-0;
  }
  to {
    @apply w-full;
  }
}
</style>
