var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative",class:{ '2xl:container py-6 lg:py-8': !_vm.displayOverflow && _vm.isContainer }},[(_vm.heading || _vm.showArrows)?_c('div',{staticClass:"slider-arrows-container lg:items-center lg:pb-6 container flex items-end justify-between pb-4"},[_c('div',{staticClass:"slider-arrows flex flex-wrap w-full items-center",class:{
        'justify-between': _vm.heading,
        'justify-start': !_vm.heading && _vm.arrowsLeft,
        'justify-end': !_vm.arrowsLeft,
      }},[(_vm.heading)?_c('div',{staticClass:"slider-title font-semibold pr-4 fluid-3xl leading-tightest",attrs:{"data-test-id":"heading"},domProps:{"innerHTML":_vm._s(_vm.heading)}}):_vm._e(),_vm._v(" "),(_vm.showArrows)?_c('ArrowBtnNav',{staticClass:"arrow-buttons block w-full mt-5 sm:w-16 sm:mt-0",class:{ hidden: _vm.isFirstSlideVisible && _vm.isLastSlideVisible, '2xl:hidden': _vm.totalItems === 3 },attrs:{"left-arrow-disabled":_vm.isFirstSlideVisible,"right-arrow-disabled":_vm.isLastSlideVisible,"inverted":_vm.arrowsInverted,"data-test-id":"arrowNav"},on:{"onLeftClick":function($event){return _vm.prev()},"onRightClick":function($event){return _vm.next()}}}):_vm._e()],1)]):_vm._e(),_vm._v(" "),_c('div',{ref:"slider",staticClass:"relative flex overflow-x-auto md:overflow-x-hidden overflow-y-hidden",class:{
      '2xl:-mx-4 2xl:container-pl 2xl:container-pr': _vm.displayOverflow && !_vm.fullwidthSlides,
      'is-snap-enabled': _vm.isSnapEnabled,
    }},[_c('div',{ref:"trackStart",staticClass:"-mr-0.5 track-start"}),_vm._v(" "),_c('div',{ref:"track",staticClass:"slider-inner-grid grid grid-flow-col",class:[
        {
          '2xl:!px-4': !_vm.fullwidthSlides,
          'ml-0.5': _vm.fullwidthSlides,
          'pt-4 pb-10': !_vm.noPadding,
          'min-h-[200px]': !_vm.autoHeight,
        },
        _vm.gridAutoClasses,
      ],style:(_vm.sliderInnerStyle)},[_vm._t("default")],2),_vm._v(" "),_c('div',{ref:"trackEnd",staticClass:"-ml-0.5 track-end"})]),_vm._v(" "),_c('div',{ref:"sliderContainerReference",class:[_vm.fixedWidth ? 'w-full' : 'container']})])
}
var staticRenderFns = []

export { render, staticRenderFns }