<template>
  <div class="grid grid-flow-col auto-cols-fr max-w-screen-xl">
    <div v-for="(column, indexCol) in columns" :key="indexCol" class="mx-4 grid gap-4 content-start">
      <div v-for="(block, indexBlock) in column.blocks" :key="indexBlock" class="inline-block">
        <NavBlocksLinkSection v-if="block.typeHandle == 'linkSection'" v-bind="block" />
        <NavBlocksImageFeature v-if="block.typeHandle === 'imageFeature'" v-bind="block" />
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import NavBlocksLinkSection, {
  NavBlocksLinkSectionFragment
} from '@/components/layout/header/navBlocks/NavBlocksLinkSection'
import NavBlocksImageFeature, {
  NavBlocksImageFeatureFragment
} from '@/components/layout/header/navBlocks/NavBlocksImageFeature'

export const NavBlocksFragment = gql`
  fragment NavBlocksFragment on navBlocks_MatrixField {
    ...NavBlocksLinkSectionFragment
    ...NavBlocksImageFeatureFragment
  }
  ${NavBlocksLinkSectionFragment}
  ${NavBlocksImageFeatureFragment}
`

export default {
  name: 'NavBlocks',
  components: {
    NavBlocksLinkSection,
    NavBlocksImageFeature
  },
  props: {
    blocks: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    columns() {
      const columns = []
      let currentColumnIndex = 0
      this.blocks.forEach((block, i) => {
        if (i === 0) {
          columns.push({
            blocks: [block]
          })
        } else if (block.startNewColumn || block.typeHandle === 'imageFeature') {
          columns.push({
            blocks: [block]
          })
          currentColumnIndex++
        } else {
          columns[currentColumnIndex].blocks.push(block)
        }
      })
      return columns
    }
  },
  mounted() {
    // fetch full nav on maounted
    // console.log(this.blocks)
  }
}
</script>
