<template>
  <div v-if="cartHighDemandMessage && showMessage" class="bg-lighter-gray px-4 py-6 flex">
    <InfoIcon class="inline-block h-4 shrink-0 leading-0 mr-3 mt-[3px]" />
    <div class="leading-4 fluid-xs">{{ $t('cart.reservationNotAvailable') }}</div>
  </div>
</template>

<script>
import InfoIcon from '@/components/icons/InfoIcon.vue';
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    InfoIcon,
  },

  computed: {
    ...mapGetters('cart', ['lineItems']),
    ...mapState('cartSettings', ['highDemandProducts', 'cartHighDemandMessage']),

    showMessage() {
      return this.lineItems.some((item) => this.highDemandProducts.includes(item.variant.product.handle));
    },
  },
};
</script>
