<template>
  <div ref="title" class="leading-tightest overflow-hidden box-border" v-html="text"></div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    lines: {
      type: Number,
      default: 2,
    },
  },
  mounted() {
    this.handleTitleHeight();
    window.addEventListener('resize', this.handleTitleHeight);
  },
  methods: {
    handleTitleHeight() {
      const heading = this.$refs.title;

      if (heading) {
        const computedHeading = window.getComputedStyle(heading);
        const titleLeading = parseInt(computedHeading.lineHeight);
        heading.style.height = titleLeading * this.lines + 'px';
      }
    },
  },
};
</script>
