var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full shadow-md",class:[_vm.cartDealSettings.useAlternativeStyle ? 'bg-putty' : 'bg-white']},[_c('div',{staticClass:"fluid-sm leading-tight bg-br-green-light text-black py-1 text-center"},[(_vm.cartDealSettings.showIcon)?_c('TagIcon',{staticClass:"w-3 h-3 inline-block mr-1 text-black"}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"font-medium"},[_vm._v(_vm._s(_vm.$t('cart.cartDealsHeading')))])],1),_vm._v(" "),_c('div',{staticClass:"flex items-center w-full py-2 px-4"},[_c('div',{staticClass:"w-full max-w-[82px] sm:max-w-[112px] relative mt-2"},[(_vm.image)?_c('FixedAspect',{attrs:{"ratio":2 / 3}},[_c('CldnImg',{attrs:{"src":_vm.image,"alt":_vm.title,"quality":90,"crop":"fit","ratio":2 / 3,"width":"100"}})],1):_vm._e(),_vm._v(" "),(_vm.discountAnnotation)?_c('div',{staticClass:"absolute bg-br-red fluid-xs py-px px-3 top-0 left-0 text-white font-bold"},[_vm._v("\n        - "+_vm._s(_vm.discountAnnotation)+"\n      ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"flex flex-no-wrap items-center justify-between py-2 w-full ml-2 space-x-2"},[_c('div',{staticClass:"fluid-sm leading-tightest w-full"},[_c('div',{staticClass:"font-medium overflow-hidden"},[_vm._v("\n          "+_vm._s(_vm.title)+"\n        ")]),_vm._v(" "),(_vm.variant)?_c('div',{staticClass:"text-gray-light mt-0.5"},[_vm._v("\n          "+_vm._s(_vm.variant.title)+"\n        ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"text-right pr-2"},[(_vm.calculatedPrice.amount > 0)?_c('ProductPriceLine',_vm._b({},'ProductPriceLine',{
            price: _vm.calculatedPrice,
            showVatHint: false,
            showCentered: false,
            showQuantity: true,
            unitPrice: _vm.unitPrice,
            unitPriceMeasurement: _vm.unitPriceMeasurement,
            size: 'xs',
          },false)):_c('span',{staticClass:"text-br-green text-xs font-medium uppercase"},[_vm._v(_vm._s(_vm.$t('For free')))])],1),_vm._v(" "),_c('CtaButton',{staticClass:"p-3 shrink-0 rounded-full min-w-0",attrs:{"data-testid":"smBtnAddToCart","loading":_vm.isLoading},nativeOn:{"click":function($event){return _vm.addDealToCart.apply(null, arguments)}}},[_c('PlusIcon',{staticClass:"h-4 w-4"})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }