<template>
  <div>
    <div
      :class="{
        'flex justify-center': showCentered,
        'inline-flex flex-nowrap': !showCentered,
      }"
    >
      <span
        v-if="comparePrice && comparePrice.amount > 0"
        class="inline-block self-end mr-3 text-gray-600 line-through decoration-blackly font-light leading-none"
        :class="largeTextClass"
      >
        {{
          $n(comparePrice.amount, {
            style: 'currency',
            currency: comparePrice.currencyCode,
          })
        }}
      </span>
      <span
        v-if="price"
        class="inline-block self-end font-medium leading-none"
        data-testid="price"
        :class="comparePrice && comparePrice.amount > 0 ? smallTextClass : largeTextClass"
      >
        {{
          $n(price.amount, {
            style: 'currency',
            currency: price.currencyCode,
          })
        }}
      </span>
      <span v-if="showVatHint" class="text-blackly fluid-xs self-end inline-block ml-3 whitespace-nowrap">{{
        $t('vatIncl')
      }}</span>
    </div>
    <ProductCardUnitPrice
      v-bind="{ unitPrice, unitPriceMeasurement, showQuantity, size, showMeasurement: price.amount > 0 }"
    />
  </div>
</template>

<script>
import ProductCardUnitPrice from '~/components/shop/products/ProductCardUnitPrice.vue';

export default {
  components: { ProductCardUnitPrice },

  props: {
    price: {
      type: Object,
      default: () => {},
    },
    comparePrice: {
      type: Object,
      default: () => {},
    },
    unitPrice: {
      type: Object,
      default: () => {},
    },
    unitPriceMeasurement: {
      type: Object,
      default: () => {},
    },
    showQuantity: {
      type: Boolean,
      default: false,
    },
    showVatHint: {
      type: Boolean,
      default: true,
    },
    showCentered: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'lg',
      validator: (value) => ['xs', 'sm', 'lg'].includes(value),
    },
  },

  computed: {
    largeTextClass() {
      if (this.size === 'lg') {
        return 'fluid-xl leading-tight';
      } else if (this.size === 'sm') {
        return 'fluid-lg leading-tight font-medium';
      } else {
        return 'fluid-sm';
      }
    },

    smallTextClass() {
      if (this.size === 'lg') {
        return 'fluid-lg font-light leading-normal';
      } else if (this.size === 'sm') {
        return 'text-md';
      } else {
        return 'text-[12px]';
      }
    },
  },
};
</script>
