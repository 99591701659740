var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',{staticClass:"fluid-xl text-center"},[_vm._v("\n    "+_vm._s(_vm.$t('search.noResultsFor'))+" "),_c('span',{staticClass:"whitespace-nowrap add-background"},[_vm._v(_vm._s(_vm.query))])]),_vm._v(" "),(_vm.collection)?_c('div',{staticClass:"my-12"},[_c('span',{staticClass:"mb-6 block fluid-lg font-medium text-center"},[_vm._v(_vm._s(_vm.$t('search.alternativeResultsTitle'))+":")]),_vm._v(" "),_c('div',{class:_vm.layoutContainerStyles},[(_vm.layout === 'grid')?_vm._l((_vm.collection.products.edges),function({ cursor, node }){return _c('ProductCard',_vm._b({key:cursor},'ProductCard',{ product: node },false))}):(_vm.layout === 'list')?_vm._l((_vm.collection.products.edges),function({ cursor, node }){return _c('SearchModalResultItem',_vm._b({key:cursor,staticClass:"group-item"},'SearchModalResultItem',{
            title: node.title,
            url: _vm.$localeRootPath('products/' + node.handle),
            title: node.title,
            imageUrl: node.variants.edges[0].node.image.src,
            useLargeImage: false,
            minPrice: node.priceRange ? node.priceRange.minVariantPrice : null,
            maxPrice: node.priceRange ? node.priceRange.maxVariantPrice : null,
          },false))}):_vm._e()],2)]):_c('div',{staticClass:"py-24"}),_vm._v(" "),(_vm.layout === 'grid' && _vm.settings)?_c('div',[_c('MarketingBlocks',{attrs:{"blocks":_vm.settings.marketingBlocks}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }