<template>
  <div class="my-l md:container mx-5">
    <div class="slide grid gap-y-5 lg:grid-cols-board-1-1.5">
      <div class="align-center lg:order-last relative flex justify-center">
        <FixedAspect :ratio="2 / 3" class="w-full">
          <CldnImg
            v-if="image[0]"
            :src="image[0].url"
            :alt="alt ? alt : image[0].title"
            :ratio="2 / 3"
            sizes="(min-width: 1440px) 778px,(min-width: 1024px) 40vw, 100vw"
            crop="fit"
            class="block object-cover"
          />
        </FixedAspect>
      </div>
      <div class="flex flex-col justify-center">
        <h5 v-if="productName" class="fluid-sm tracking-wider font-medium lg:mt-7 mt-4 uppercase">
          {{ productName }}
        </h5>
        <h3 v-if="heading" class="lg:my-5 my-4" v-html="heading"></h3>
        <div v-if="productEntry" class="flex flex-wrap leading-4">
          <ProductProvider
            v-slot="{ addToCartDisabled, addToCart, isCourse }"
            :handle="productEntry.shopifyHandle"
            class="mt-4"
          >
            <button
              :class="{ 'btn-pill-gray pointer-events-none': addToCartDisabled }"
              :disabled="addToCartDisabled"
              class="btn-pill-green whitespace-nowrap mr-1.5"
              data-testid="addToCart"
              @click="addToCart"
            >
              {{ isCourse ? $t('cart.bookNow') : $t('cart.addToCart') }}
            </button>
          </ProductProvider>

          <LinkField
            v-if="productEntry"
            :to="{ path: $localeRootPath(productEntry.uri) }"
            class="btn-pill-white slider-button mt-4"
            :tracking-name="productName"
          >
            {{ $t('shop.viewProduct') }}
          </LinkField>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CldnImg from '@/components/CldnImg';
import FixedAspect from '@/components/common/FixedAspect';
import ProductProvider from '@/components/common/ProductProvider';
import LinkField from '@/components/common/LinkField';

export default {
  components: {
    FixedAspect,
    CldnImg,
    ProductProvider,
    LinkField,
  },
  props: {
    productName: {
      type: String,
      default: null,
    },
    product: {
      type: Array,
      default: () => [],
    },
    heading: {
      type: String,
      default: null,
    },
    image: {
      type: Array,
      default: () => [],
    },
    alt: {
      type: String,
      default: null,
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    productEntry() {
      return this.product[0];
    },
  },
};
</script>
