var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:{
      'flex justify-center': _vm.showCentered,
      'inline-flex flex-nowrap': !_vm.showCentered,
    }},[(_vm.comparePrice && _vm.comparePrice.amount > 0)?_c('span',{staticClass:"inline-block self-end mr-3 text-gray-600 line-through decoration-blackly font-light leading-none",class:_vm.largeTextClass},[_vm._v("\n      "+_vm._s(_vm.$n(_vm.comparePrice.amount, {
          style: 'currency',
          currency: _vm.comparePrice.currencyCode,
        }))+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.price)?_c('span',{staticClass:"inline-block self-end font-medium leading-none",class:_vm.comparePrice && _vm.comparePrice.amount > 0 ? _vm.smallTextClass : _vm.largeTextClass,attrs:{"data-testid":"price"}},[_vm._v("\n      "+_vm._s(_vm.$n(_vm.price.amount, {
          style: 'currency',
          currency: _vm.price.currencyCode,
        }))+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.showVatHint)?_c('span',{staticClass:"text-blackly fluid-xs self-end inline-block ml-3 whitespace-nowrap"},[_vm._v(_vm._s(_vm.$t('vatIncl')))]):_vm._e()]),_vm._v(" "),_c('ProductCardUnitPrice',_vm._b({},'ProductCardUnitPrice',{ unitPrice: _vm.unitPrice, unitPriceMeasurement: _vm.unitPriceMeasurement, showQuantity: _vm.showQuantity, size: _vm.size, showMeasurement: _vm.price.amount > 0 },false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }