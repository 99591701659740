<template>
  <VideoInModal v-bind="$props" full-width autoplay />
</template>

<script>
import VideoInModal from '@/components/common/VideoInModal/VideoInModal'

export default {
  components: { VideoInModal },
  props: {
    preview: {
      type: Array,
      default: () => []
    },
    video: {
      type: Array,
      default: () => []
    },
    textOverlay: {
      type: String,
      default: null
    }
  }
}
</script>
