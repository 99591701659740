<template>
  <div class="animated-bg" />
</template>

<script>
export default {
  name: 'Placeholder'
}
</script>

<style>
.animated-bg {
  animation: fading 1s ease-in-out 2s infinite alternate;
}
@keyframes fading {
  from {
    @apply bg-gray-100;
  }
  to {
    @apply bg-gray-300;
  }
}
</style>
