<template>
  <button
    class="flex py-4 pr-4 pl-6 border-b fluid-sm tracking-wider font-medium w-full justify-between focus:outline-none items-center relative"
    :class="{ 'text-br-red': highlight }"
    @click="onClick"
  >
    <span>
      {{ label }}
      <sup v-if="isNew" class="text-br-green fluid-xxs uppercase">
        {{ $t('navigation.new') }}
      </sup>
    </span>

    <IconChevron class="transform transition-all" :class="{ '-rotate-90': isOpen }" />

    <div v-if="image.length > 0" class="absolute right-8 inset-y-2 w-14">
      <CldnImg class="w-full h-full object-cover" :src="image[0].url" :alt="image[0].title" sizes="60px" />
    </div>
  </button>
</template>
<script>
import IconChevron from '~/components/layout/header/IconChevron'
import CldnImg from '@/components/CldnImg'

export default {
  components: {
    IconChevron,
    CldnImg
  },
  props: {
    image: {
      type: Array,
      default: () => []
    },
    link: {
      type: Object,
      default: () => {}
    },
    highlight: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    mobileTitle: {
      type: String,
      default: null
    },
    onClick: {
      type: Function,
      default: () => {}
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    label() {
      return this.link.text ?? this.link.element.title
    }
  }
}
</script>
