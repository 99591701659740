<template>
  <div v-if="slides && slides.length" class="matrix-block gallery relative py-12">
    <CardSlider :breakpoints="breakpoints">
      <div v-for="{ image, alt, caption, id } in slides" :key="id">
        <CraftAsset
          v-if="image"
          :ratio="2 / 3"
          :asset="image[0]"
          :alt="alt"
          :autoplay="true"
          :controls="true"
          :muted="true"
          :width="864"
          crop="fill"
          data-test-id="asset"
        />
        <div v-if="caption" class="mt-4 text-sm">{{ caption }}</div>
      </div>
    </CardSlider>
  </div>
</template>

<script>
import CardSlider from '@/components/common/CardSlider';
import CraftAsset from '@/components/common/CraftAsset.vue';

export default {
  name: 'ImageGallery',
  components: {
    CardSlider,
    CraftAsset,
  },
  props: {
    slides: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      breakpoints: [
        {
          minWindowWidth: 0,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
        {
          minWindowWidth: 640,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
        {
          minWindowWidth: 1024,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      ],
    };
  },
};
</script>
