var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.cart !== null)?_c('div',[_c('div',{staticClass:"gap-y-6 grid"},[(_vm.hasDiscountApplied)?_c('div',{staticClass:"bg-br-green-light fluid-xs leading-tightest py-3 font-medium text-center text-black"},[_c('TagIcon',{staticClass:"inline-block h-3 mr-1 -mt-px"}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('cart.appliedDiscountHint')))]),_vm._v(" "),(_vm.obfuscatedDiscountCode)?_c('span',{staticClass:"-ml-px before:content-[':'] before:mr-1"},[_vm._v(_vm._s(_vm.obfuscatedDiscountCode))]):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',[(_vm.hasDiscountApplied)?_c('div',{staticClass:"fluid-base leading-relaxed flex justify-between font-normal"},[_c('div',[_vm._v(_vm._s(_vm.$t('cart.discountTotal')))]),_vm._v(" "),(_vm.discountValue)?_c('div',{staticClass:"text-br-green"},[_c('span',{staticClass:"mr-1"},[_vm._v("-")]),_vm._v(_vm._s(_vm.$n(_vm.discountValue.amount, {
              style: 'currency',
              currency: _vm.discountValue.currencyCode,
            }))+"\n        ")]):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"fluid-base leading-relaxed flex justify-between font-normal"},[_c('div',[_vm._v(_vm._s(_vm.$t('cart.subtotal')))]),_vm._v(" "),(_vm.isProcessing)?_c('div',{staticClass:"text-blackly"},[_c('SpinnerIcon',{staticClass:"h-4 animate-spin"})],1):_c('div',{staticClass:"flex flex-col items-end"},[_c('div',[_vm._v("\n            "+_vm._s(_vm.$n(_vm.cart.cost.totalAmount.amount, {
                style: 'currency',
                currency: _vm.cart.cost.totalAmount.currencyCode,
              }))+"\n          ")]),_vm._v(" "),(_vm.hasDiscountApplied)?_c('s',{staticClass:"text-gray-light fluid-xs leading-tightest font-light"},[_vm._v("\n            "+_vm._s(_vm.$n(_vm.totalAmountWithoutDiscount, {
                style: 'currency',
                currency: _vm.cart.cost.subtotalAmount.currencyCode,
              }))+"\n          ")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"fluid-xs leading-tightest text-gray-light"},[_vm._v(_vm._s(_vm.$t('cart.vatHint')))])]),_vm._v(" "),_c('DeliveryDetails'),_vm._v(" "),(!_vm.hasDiscountApplied)?[_c('hr'),_vm._v(" "),_c('div',{staticClass:"fluid-xs leading-tightest"},[_vm._v(_vm._s(_vm.$t('cart.voucherHint')))])]:_vm._e(),_vm._v(" "),_c('div',[_c('div',{staticClass:"fluid-xs leading-tightest mb-2 font-medium"},[_vm._v(_vm._s(_vm.$t('navigation.paymentMethods')))]),_vm._v(" "),(_vm.paymentMethods)?_c('PaymentMethodsIcons',{attrs:{"methods":_vm.paymentMethods.paymentMethods}}):_vm._e()],1),_vm._v(" "),_c('CartNotification')],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }