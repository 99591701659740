<template>
  <nuxt-link :to="url" class="flex items-center hover:text-br-green transition-all" @click.native="$emit('click')">
    <CldnImg
      v-if="imageUrl"
      :src="imageUrl"
      :alt="title"
      :ratio="useLargeImage ? null : '0.66'"
      :crop="useLargeImage ? 'fill' : 'fill'"
      sizes="100px"
      class="max-w-none block w-24 select-none mr-4"
    />
    <div class="py-3">
      <div>
        <div v-if="highlightTitle" class="highlight-title" v-html="highlightTitle"></div>
        <div v-else>
          {{ title }}
        </div>
      </div>
      <div v-if="minPrice">
        <ProductComparePrice
          :price="{
            amount: minPrice.amount,
            currencyCode: minPrice.currencyCode,
          }"
          :price-range-min="maxPrice && minPrice.amount !== maxPrice.amount ? minPrice.amount : null"
          :price-range-max="maxPrice && minPrice.amount !== maxPrice.amount ? maxPrice.amount : null"
          :currency-code="minPrice.currencyCode"
          :price-class="'font-medium'"
        />
      </div>
    </div>
  </nuxt-link>
</template>

<script>
import ProductComparePrice from '@/components/common/ProductComparePrice';
import CldnImg from '@/components/CldnImg';

export default {
  components: { ProductComparePrice, CldnImg },
  props: {
    url: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    highlightTitle: {
      type: String,
      default: null,
    },
    imageUrl: {
      type: String,
      default: null,
    },
    useLargeImage: {
      type: Boolean,
      default: false,
    },
    minPrice: {
      type: Object,
      default: null,
    },
    maxPrice: {
      type: Object,
      default: null,
    },
  },
};
</script>
