<template>
  <div class="product-compare-card flex flex-row flex-wrap content-between text-center">
    <div class="w-full">
      <h3 class="font-medium fluid-lg font-light leading-normal center h-12 overflow-hidden">{{ product.title }}</h3>
      <FixedAspect v-if="variants.length" :ratio="0.55">
        <ProductCardVariantImg
          v-if="selectedVariant"
          :product-variant="selectedVariant"
          :product-route="productRoute"
        />
      </FixedAspect>
      <div class="attribute-col w-full divide-y h-full flex flex-col">
        <div v-for="(attribute, idx) in attributeList" :key="idx" :style="{ height: attribute.height }">
          <div ref="attributeRow" class="attribute-row leading-5 flex flex-col md:py-3">
            <span class="md:hidden uppercase text-gray-400 fluid-xs leading-tightest pt-2">{{ attribute.title }}</span>
            <span class="fluid-xs leading-tightest font-medium">{{ attribute.html }}</span>
          </div>
        </div>
        <div class="product-variants w-full">
          <ProductOptions
            v-if="variants.length"
            v-bind="{
              variants,
              swatches: optionSwatches,
              maxSwatches: 5,
              selectedVariant,
              option: product.options[0]
            }"
            class="justify-center w-full py-2 md:pt-4"
            swatch-img-class="w-4 h-4"
            @clickOption="setOption"
          />
        </div>
      </div>
    </div>
    <div class="w-full mb-3 mt-5">
      <nuxt-link :to="productRoute" class="btn-pill-outline-black min-w-0">{{ $t('shop.viewProduct') }}</nuxt-link>
    </div>
  </div>
</template>

<script>
import FixedAspect from '@/components/common/FixedAspect'
import ProductCardVariantImg from '@/components/shop/products/ProductCardVariantImage'
import ProductOptions from '@/components/shop/products/ProductOptions'
import gql from 'graphql-tag'
import { VariantFragment } from '@/components/shop/products/Variant'
import { productComparisonFragment, productOptionSwatchesFragment } from '@/components/shop/products/Product'

export const ComparisonCardProductFragment = gql`
  fragment ComparisonCardProductFragment on Product {
    id
    handle
    availableForSale
    productType
    title

    options {
      name
      values
    }

    images(first: 1) {
      edges {
        node {
          id
          src: originalSrc
          altText
          width
          height
        }
      }
    }
    variants(first: 20) {
      edges {
        node {
          ...VariantFragment
        }
      }
    }

    ...productComparisonFragment
    ...productOptionSwatchesFragment
  }
  ${VariantFragment}
  ${productComparisonFragment}
  ${productOptionSwatchesFragment}
`

export default {
  name: 'ProductCompareCardLarge',

  components: {
    FixedAspect,
    ProductCardVariantImg,
    ProductOptions
  },

  props: {
    product: {
      type: Object,
      default: () => null,
      required: true
    },
    compareAttributes: {
      type: Array,
      default: () => []
    },
    rowHeights: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      selectedVariant: null
    }
  },

  computed: {
    productRoute() {
      return { path: this.$localeRootPath('/products/' + this.product?.handle) }
    },
    variants() {
      return this.product?.variants?.edges?.map((e) => e.node) || []
    },
    attributes() {
      return this.product?.comparison
    },
    optionSwatches() {
      return this.product?.optionSwatches
    },
    attributeList() {
      return this.compareAttributes?.map((attr, idx) => {
        return { ...attr, html: this.displayAttribute(attr), height: this.rowHeights[idx] + 'px' }
      })
    }
  },

  created() {
    this.selectedVariant = this.variants[0]
  },

  mounted() {
    this.emitRowHeights()
    window.addEventListener('resize', this.emitRowHeights)
  },

  destroyed() {
    window.removeEventListener('resize', this.emitRowHeights)
  },

  methods: {
    setOption(newVariant) {
      this.selectedVariant = newVariant
    },

    displayAttribute(attribute) {
      const value = this.product.comparison[attribute.key] || '-'
      return Array.isArray(value) ? (value.length ? value.join(', ') : '-') : value
    },
    emitRowHeights() {
      const heights = this.$refs.attributeRow?.map((a) => {
        return a.getBoundingClientRect().height
      })
      this.$emit('rowHeightsCalculated', heights)
    },
    displayNoSwatch(swatches) {
      return swatches && Object.keys(swatches).length < 2
    }
  }
}
</script>
