<template>
  <button
    :class="[buttonColor, { 'btn-pill-gray pointer-events-none': disabled && !loading }]"
    :disabled="disabled || loading"
    class="relative whitespace-nowrap"
  >
    <span v-if="loading" class="absolute inset-0 flex items-center justify-center">
      <SpinnerIcon class="h-6 animate-spin" />
    </span>
    <span :class="{ 'opacity-0 pointer-events-none': loading }">
      <slot></slot>
    </span>
  </button>
</template>

<script>
import SpinnerIcon from '@/components/icons/SpinnerIcon';

export default {
  components: {
    SpinnerIcon,
  },

  props: {
    color: { type: String, default: 'button' },
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },

  computed: {
    buttonColor() {
      switch (this.color) {
        case 'outline':
          return 'btn-pill-outline-black';
        default:
          return 'btn-pill-green';
      }
    },
  },
};
</script>
