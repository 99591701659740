<template>
  <button
    :class="[
      isLiked ? 'text-blackly md:hover:text-blackly/90' : 'text-transparent hover:text-br-green hover:stroke-br-green',
      isLoading ? 'pointer-events-none' : '',
    ]"
    class="bg-putty focus:outline-none p-4 rounded-full relative"
    @click.stop="like"
  >
    <span :class="{ invisible: isLoading }">
      <HeartIcon class="h-4" />
    </span>
    <div v-if="isLoading" class="absolute inset-0 flex items-center justify-center">
      <SpinnerIcon class="animate-spin w-6 h-6 text-br-green" />
    </div>
    <LoginModal v-else :show="showModal" @success="closeAndLike" @close="close" />
  </button>
</template>

<script>
import HeartIcon from '@/components/icons/HeartIcon';
import LoginModal from '@/components/account/LoginModal';
import SpinnerIcon from '@/components/icons/SpinnerIcon';
import Routine from '@/mixins/Routine';

export default {
  components: {
    LoginModal,
    HeartIcon,
    SpinnerIcon,
  },

  mixins: [Routine],

  props: {
    type: {
      type: String,
      required: true,
    },
    handle: {
      type: String,
      required: true,
    },
    exercise: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      showModal: false,
      isLoading: false,
    };
  },
  computed: {
    isLiked() {
      if (!this.$auth.user) {
        return false;
      }

      if (this.type === 'routine' && this.$auth.user?.blackrollCustomer?.workouts) {
        return (
          this.$auth.user?.blackrollCustomer?.workouts.filter((elem) => elem.routineObjectId === this.handle).length > 0
        );
      } else if (this.type === 'exercise' && this.$auth.user?.blackrollCustomer?.exercises) {
        return (
          this.$auth.user?.blackrollCustomer?.exercises.filter((elem) => elem.exerciseKey === this.handle).length > 0
        );
      }

      return false;
    },

    pimId() {
      return this.entry?.pimWorkout ? parseInt(this.entry?.pimWorkout) : null;
    },
  },

  methods: {
    closeAndLike() {
      this.close();

      if (!this.isLiked) {
        this.addLike();
      }
    },

    like() {
      if (this.$auth.$state.loggedIn) {
        return this.isLiked ? this.removeLike() : this.addLike();
      } else {
        this.showModal = true;
      }
    },

    async addLike() {
      this.isLoading = true;

      if (this.type === 'routine') {
        this.$_gtm.push({
          event: 'gaEvent',
          event_name: 'routine_save',
          routine_save: {
            routine_name: this.exercise.title,
            sport: this.parseExerciseProperties(this.exercise.sports),
            training_goal: this.parseExerciseProperties(this.exercise.trainingGoals),
            difficulty: this.parseExerciseProperties(this.exercise.difficulty),
            routine_duration: this.exercise.duration,
            products: this.parseExerciseProperties(this.exercise.products),
            event_category: 'Routine',
            event_action: 'Save',
            event_label: this.exercise.title,
          },
        });

        await this.$auth.strategies.oneworld.addWorkout(this.handle, this.pimId);
      } else if (this.type === 'exercise') {
        this.$_gtm.push({
          event: 'gaEvent',
          event_name: 'exercise_save',
          exercise_save: {
            exercise_name: this.exercise.title,
            product: this.parseExerciseProperties(this.exercise.products),
            training_goal: this.parseExerciseProperties(this.exercise.trainingGoals),
            // topic: '{SEO Topic}',
            event_category: 'Exercise',
            event_action: 'Save',
            event_label: this.exercise.title,
          },
        });

        await this.$auth.strategies.oneworld.addExercise(this.handle, this.exercise?.pimExerciseId);
      }

      this.isLoading = false;
    },

    async removeLike() {
      this.isLoading = true;
      if (this.type === 'routine') {
        await this.$auth.strategies.oneworld.deleteWorkout(this.handle, this.pimId);
      } else if (this.type === 'exercise') {
        await this.$auth.strategies.oneworld.deleteExercise(this.handle, this.exercise?.pimExerciseId);
      }

      this.isLoading = false;
    },

    close() {
      this.showModal = false;
    },
  },
};
</script>
