<template>
  <button
    class="whitespace-nowrap bg-br-green rounded-full h-8 w-8 py-1 border-2 border-br-green"
    :aria-label="$t('product.climateNeutral')"
    @click="showMoreInformation"
  >
    <div class="relative w-full h-full flex items-center justify-center">
      <LeafIcon class="block text-white h-full" />
      <InfoIcon class="absolute text-gray-400 left-full bottom-full h-3 -ml-px" />
    </div>

    <Modal :showing="showing" @close="closeMoreInformation">
      <div class="pr-8">
        <h2 class="mb-4">{{ $t('climateNeutral.heading') }}</h2>
        <p>{{ $t('climateNeutral.text1') }}</p>
        <a v-if="climateLogo" :href="climateLink" target="_blank">
          <img class="h-20 my-4" :src="climateLogo" alt="Climate Label for Product" />
        </a>
        <p class="mt-4">
          {{ $t('climateNeutral.text2') }}
        </p>

        <div class="mt-8 space-y-2">
          <button class="btn-pill btn-sm md:btn-base" @click="closeMoreInformation">{{ $t('close') }}</button>
          <a v-if="climateLink" class="btn-pill-green btn-sm md:btn-base" :href="climateLink" target="_blank">{{
            $t('product.moreInfo')
          }}</a>
        </div>
      </div>
    </Modal>
  </button>
</template>

<script>
import LeafIcon from '@/components/icons/LeafIcon';
import InfoIcon from '@/components/icons/InfoIcon';
import Modal from '@/components/common/Modal.vue';

export default {
  components: { InfoIcon, LeafIcon, Modal },

  props: {
    biggerIcon: {
      type: Boolean,
      default: false,
    },

    isEpp: {
      type: Boolean,
      default: false,
    },

    isBand: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showing: false,
    };
  },

  computed: {
    climateLogo() {
      const locale = this.$i18n.locale.toUpperCase();

      if (this.isEpp) {
        return `/climateid/WLXGWP_product_standardLabel_black_${locale}.png`;
      }

      if (this.isBand) {
        return `/climateid/X1QEEF_product_standardLabel_black_${locale}.png`;
      }

      return false;
    },

    climateLink() {
      if (!this.isBand && !this.isEpp) {
        return false;
      }

      const id = this.isEpp ? 'WLXGWP' : 'X1QEEF';

      return `https://climate-id.com/${id}`;
    },
  },

  methods: {
    showMoreInformation() {
      this.showing = true;
    },
    closeMoreInformation() {
      this.showing = false;
    },
  },
};
</script>
