<template>
  <SmallAddOnTeaser
    v-bind="{
      heading: title,
      image: [{ url: image }],
    }"
  />
</template>

<script>
import SmallAddOnTeaser from '@/components/common/SmallAddOnTeaser';

export default {
  components: { SmallAddOnTeaser },

  props: {
    lineItem: {
      type: Object,
      default: null,
    },
  },

  computed: {
    image() {
      return this.lineItem?.attributes.find((attr) => attr.key === '_cartImage').value;
    },
    title() {
      return this.lineItem?.attributes.find((attr) => attr.key === '_cartText').value;
    },
  },
};
</script>
