<template>
  <div
    v-if="isDev"
    class="fixed bottom-0 left-0 z-50 flex px-6 py-2 space-x-2 fluid-xs leading-tightest text-gray-600 bg-white"
  >
    <div class="sm:hidden block">
      <span class="font-bold">XS</span>
      <span class="fluid-xs leading-tightest">&lt; 640px</span>
    </div>
    <div class="sm:block md:hidden hidden">
      <span class="font-bold">SM</span>
      <span class="fluid-xs leading-tightest">&lt; 768px</span>
    </div>
    <div class="md:block lg:hidden hidden">
      <span class="font-bold">MD</span>
      <span class="fluid-xs leading-tightest">&lt; 1024px</span>
    </div>
    <div class="lg:block xl:hidden hidden">
      <span class="font-bold">LG</span>
      <span class="fluid-xs leading-tightest">&lt; 1280px</span>
    </div>
    <div class="xl:block 2xl:hidden hidden">
      <span class="font-bold">XL</span>
      <span class="fluid-xs leading-tightest">&gt; 1280px</span>
    </div>
    <div class="2xl:block hidden">
      <span class="font-bold">2XL</span>
      <span class="fluid-xs leading-tightest">&gt; 1440px</span>
    </div>
    <div v-if="isCachingProxy" class="block fluid-xs leading-tightest font-bold text-gray-500">
      json-caching-proxy active
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isDev() {
      return this.$nuxt.context.isDev
    },
    isCachingProxy() {
      return process.env.cmsHttpEndpoint === 'http://localhost:3001/graphql'
    }
  }
}
</script>
