<template>
  <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="15" height="2" fill="#182022" />
    <rect y="5" width="15" height="2" fill="#182022" />
    <rect y="10" width="15" height="2" fill="#182022" />
  </svg>
</template>

<script>
export default {}
</script>
