<template>
  <div v-if="barEnabled" id="global-usp-bar" class="fluid-xs leading-tightest relative h-10 bg-blackly text-white">
    <div class="flex justify-between h-full">
      <div
        v-for="(block, index) in blocks"
        :key="index"
        class="lg:relative slide lg:opacity-100 absolute flex items-center justify-center w-full h-full text-center opacity-0"
        :class="{ 'is-active': index === activeBlockIndex }"
      >
        <img v-if="isSvg(block.svg[0].url)" :src="block.svg[0].url" :alt="block.text" class="h-4 mr-2" />
        <CldnImg v-else-if="block.svg[0]" :src="block.svg[0].url" :alt="block.text" sizes="16px" class="h-4 mr-2" />
        <span class="font-medium">{{ block.text }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CldnImg from '@/components/CldnImg';

/* eslint-disable */
export default {
  components: {
    CldnImg,
  },
  data() {
    return {
      activeBlockIndex: 0,
    };
  },
  computed: {
    ...mapState('globalUspBar', ['globallyEnabled', 'blocks', 'locallyDisabled']),
    ...mapGetters('globalUspBar', ['barEnabled']),
  },
  methods: {
    count() {
      if (this.blocks.length === this.activeBlockIndex + 1) {
        this.activeBlockIndex = 0;
      } else {
        this.activeBlockIndex++;
      }
    },
    isSvg(url) {
      return !!url?.match(/\.svg/i);
    },
  },
  mounted() {
    const _this = this;
    this.interval = setInterval(function () {
      _this.count();
    }, 5000);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
};
</script>

<style scoped>
.slide {
  @apply transition-opacity delay-500;
}
.slide.is-active {
  @apply opacity-100 delay-500;
}
</style>
