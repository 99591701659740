<template>
  <LinkField v-bind="$props" class="hover:underline fluid-sm">
    <InlineArrowIcon v-if="reverse" class="inline transform rotate-180 h-2" />
    <span v-if="text" class="font-medium">{{ text }} </span>
    <slot v-else />
    <InlineArrowIcon v-if="!reverse" class="inline h-2" />
  </LinkField>
</template>

<script>
import LinkField from '@/components/common/LinkField'
import InlineArrowIcon from '@/components/icons/InlineArrowIcon'
export default {
  components: {
    LinkField,
    InlineArrowIcon
  },
  props: {
    type: {
      type: String,
      default: null
    },
    element: {
      type: Object,
      default: () => ({})
    },
    text: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: null
    },
    url: {
      type: String,
      default: ''
    },
    to: {
      type: [Object, String],
      default: () => null
    },
    reverse: {
      type: Boolean,
      default: false
    }
  }
}
</script>
