<template>
  <div class="py-12" :class="{ 'bg-putty': backgroundGray }">
    <CardSlider :items="routines" :heading="heading" :breakpoints="breakpoints">
      <RoutinePreview
        v-for="(routine, index) in routines"
        :key="routine.id"
        v-track:click="gtmData(routine, index)"
        v-track:view="gtmData(routine, index)"
        :routine="routine"
        :tag-facets="tagFacets"
      />
    </CardSlider>
    <div v-if="cta" class="flex container">
      <CtaArrow v-bind="cta" class="mb-4 ml-auto" />
    </div>
  </div>
</template>
<script>
import RoutinePreview from '@/components/exercises/RoutinePreview';
import { exercisesFacets } from '@/utils/algolia';
import CtaArrow from '@/components/common/CtaArrow';
import CardSlider from '@/components/common/CardSlider';

export default {
  components: {
    CardSlider,
    CtaArrow,
    RoutinePreview,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
    ctaEntry: {
      type: Array,
      default: () => [],
    },
    ctaText: {
      type: String,
      default: null,
    },
    currentPageEntry: {
      type: Object,
      default: () => null,
    },
    routines: {
      type: Array,
      default: () => [],
    },
    backgroundGray: {
      type: Boolean,
      default: false,
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      breakpoints: [
        {
          minWindowWidth: 0,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
        {
          minWindowWidth: 700,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
        {
          minWindowWidth: 1000,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      ],
    };
  },
  computed: {
    // render Routine Tag-facet-buttons derived from ctaEntry-pageProfile
    tagFacetsPageProfile() {
      if (this.ctaEntry[0] && this.ctaEntry[0].pageProfile) return this.ctaEntry[0].pageProfile;
      return this.currentPageEntry?.pageProfile || null;
    },
    tagFacets() {
      return exercisesFacets(this.tagFacetsPageProfile).slice(0, 2);
    },
    cta() {
      return this.ctaEntry?.length && this.ctaText
        ? { to: this.$localeRootPath(this.ctaEntry[0]?.uri), text: this.ctaText }
        : null;
    },
  },
  methods: {
    gtmData(payload, index) {
      return {
        promotion_id: payload.uri,
        promotion_name: [this.$route?.name, payload.title].join(' / '),
        creative_name: 'routineCardsSlider',
        creative_slot: [this.$route?.name, this.blockPosition + 1, index + 1].join(' / '),
      };
    },
  },
};
</script>
