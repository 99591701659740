<template>
  <div class="my-20">
    <CardSlider :items="routines" :heading="heading">
      <div class="text-copy leading-normal py-10" data-test-id="body" v-html="body"></div>
      <div v-for="(routine, index) in routines" :key="routine.id" data-test-id="slide">
        <RoutinePreview
          v-track:click="gtmData(routine, index)"
          v-track:view="gtmData(routine, index)"
          :routine="routine"
        />
      </div>
    </CardSlider>
  </div>
</template>
<script>
import RoutinePreview from '@/components/exercises/RoutinePreview';
import CardSlider from '@/components/common/CardSlider';

export default {
  components: {
    CardSlider,
    RoutinePreview,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    routines: {
      type: Array,
      default: () => [],
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    gtmData(payload, index) {
      return {
        promotion_id: payload.uri,
        promotion_name: [this.$route?.name, payload.title].join(' / '),
        creative_name: 'exerciseRoutineCardsSliderWithText',
        creative_slot: [this.$route?.name, this.blockPosition + 1, index + 1].join(' / '),
      };
    },
  },
};
</script>
