<template>
  <div>
    <BarText v-if="heading" :text="heading" tag="h2" class="mb-5" :class="{ 'text-center': centered }" />
    <div v-if="description" class="text-copy mb-5" :class="{ 'text-center': centered }" v-html="description"></div>
    <div class="relative overflow-hidden">
      <CraftAsset
        v-if="fullWidth"
        :ratio="ratio || 9 / 16"
        :asset="previewLink"
        crop="fill"
        :width="1440"
        fullscreen
        :autoplay="autoplay"
        hfull
      />
      <FixedAspect v-else :ratio="ratio || 9 / 16">
        <CraftAsset :asset="previewLink" :fullscreen="fullWidth" :autoplay="autoplay" crop="fill" :width="864" hfull />
      </FixedAspect>
      <slot name="playBtn" />
      <BarText
        v-if="textOverlay"
        :class="[
          smallOverlay
            ? 'md:absolute md:px-12 fluid-lg leading-tight font-medium md:py-8 bottom-0 left-0'
            : 'fluid-3xl leading-tightest lg:fluid-4xl leading-none absolute container-ml top-[6vw] max-w-[300px] lg:max-w-[42rem] lg:top-auto lg:bottom-[4rem]'
        ]"
        :text="textOverlay"
        tag="h2"
      />
    </div>
  </div>
</template>

<script>
import BarText from '@/components/BarText'
import FixedAspect from '@/components/common/FixedAspect'
import CraftAsset from '@/components/common/CraftAsset'

export default {
  name: 'VideoWithHeading',
  components: {
    BarText,
    FixedAspect,
    CraftAsset
  },
  props: {
    ratio: {
      type: Number,
      default: null
    },
    heading: {
      type: String,
      default: null
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    description: {
      type: String,
      default: null
    },
    textOverlay: {
      type: String,
      default: null
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    video: {
      type: Array,
      default: () => []
    },
    preview: {
      type: Array,
      default: () => []
    },
    smallOverlay: {
      type: Boolean,
      default: false
    },
    centered: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    previewLink() {
      return this.preview?.length > 0 ? this.preview[0] : this.video[0]
    }
  }
}
</script>
