<template>
  <div>
    <h4 class="fluid-xl text-center">
      {{ $t('search.noResultsFor') }} <span class="whitespace-nowrap add-background">{{ query }}</span>
    </h4>

    <div v-if="collection" class="my-12">
      <span class="mb-6 block fluid-lg font-medium text-center">{{ $t('search.alternativeResultsTitle') }}:</span>
      <div :class="layoutContainerStyles">
        <template v-if="layout === 'grid'">
          <ProductCard v-for="{ cursor, node } in collection.products.edges" :key="cursor" v-bind="{ product: node }" />
        </template>
        <template v-else-if="layout === 'list'">
          <SearchModalResultItem
            v-for="{ cursor, node } in collection.products.edges"
            :key="cursor"
            class="group-item"
            v-bind="{
              title: node.title,
              url: $localeRootPath('products/' + node.handle),
              title: node.title,
              imageUrl: node.variants.edges[0].node.image.src,
              useLargeImage: false,
              minPrice: node.priceRange ? node.priceRange.minVariantPrice : null,
              maxPrice: node.priceRange ? node.priceRange.maxVariantPrice : null,
            }"
          />
        </template>
      </div>
    </div>
    <!-- This is just a spacer if no collection is rendered -->
    <div v-else class="py-24"></div>

    <div v-if="layout === 'grid' && settings">
      <MarketingBlocks :blocks="settings.marketingBlocks" />
    </div>
  </div>
</template>

<script>
import ProductCard from '@/components/shop/products/ProductCard.vue';
import SearchModalResultItem from '@/components/layout/header/SearchModalResultItem.vue';
import MarketingBlocks from '~/components/marketing/MarketingBlocks.vue';

export default {
  components: { ProductCard, SearchModalResultItem, MarketingBlocks },

  props: {
    query: {
      type: String,
      default: null,
    },

    layout: {
      type: String,
      default: 'grid',
      validator: (value) => ['grid', 'list'].includes(value),
    },

    settings: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      collection: null,
    };
  },

  computed: {
    layoutContainerStyles() {
      return {
        'grid md:grid-cols-2 lg:grid-cols-3 container gap-6 xl:gap-10': this.layout === 'grid',
        'grid gap-y-2 mx-1 py-2': this.layout === 'list',
      };
    },
  },

  async created() {
    // we just load a shopify collection to show as alternative, defined via global search settings
    this.collection = await this.$shopifyCollectionRequest({ handle: this.settings?.collectionHandle ?? 'bestseller' });
  },
};
</script>
