<template>
  <button class="inline-flex flex-nowrap" :class="{ 'cursor-pointer': useScroll }" @click="scrollToReviews">
    <div class="flex flex-nowrap relative -mr-1 group">
      <div
        class="whitespace-nowrap flex-nowrap flex-row rounded-full bg-black text-white py-1 px-3 absolute bottom-6 z-10 hidden group-hover:flex"
      >
        {{ $t('product.stars', { count: ratingValue.toFixed(1) }) }}
      </div>
      <div class="flex flex-nowrap h-5 pt-0.5">
        <StarIcon
          v-for="index in 5"
          ref="blackstar"
          :key="index"
          fill="#000"
          class="star"
          :width="oneStarWidth"
          :height="oneStarWidth"
          :style="{ 'margin-right': marginSize }"
          data-test-id="black-stars"
        />
      </div>
      <div class="overflow-hidden absolute left-0 h-5 pt-0.5" :style="{ width: starsWidth + 'px' }">
        <div class="flex flex-nowrap absolute">
          <StarIcon
            v-for="index in 5"
            :key="index"
            class="star inline-block"
            :width="oneStarWidth"
            :height="oneStarWidth"
            :style="{ 'margin-right': marginSize }"
            data-test-id="green-stars"
          />
        </div>
      </div>
    </div>
    <span v-if="ratingVolume" class="inline-block"
      ><em
        class="inline-block pl-4 not-italic fluid-sm tracking-wider font-medium align-top"
        data-test-id="rating-volume"
        >({{ ratingVolume }})</em
      ></span
    >
  </button>
</template>
<script>
import StarIcon from '@/components/icons/StarIcon';

export default {
  components: { StarIcon },
  props: {
    ratingValue: {
      type: Number,
      default: 5,
    },
    ratingVolume: {
      type: Number,
      default: 0,
    },
    useScroll: {
      type: Boolean,
      default: false,
    },
    oneStarWidth: {
      type: Number,
      default: 17,
    },
    marginSize: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      starsWidth: 0,
    };
  },
  mounted() {
    this.matchWidth();
  },
  methods: {
    matchWidth() {
      const margins = Math.floor(this.ratingValue) * this.marginSize;
      this.starsWidth = this.oneStarWidth * this.ratingValue + margins;
    },
    scrollToReviews() {
      if (!this.useScroll) return;
      const element = document.getElementById('productReviews');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
};
</script>
<style scoped>
.star:last-child {
  @apply mr-0;
}
</style>
