var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.deal && _vm.bundleAvailable)?_c('div',{staticClass:"py-8",class:[_vm.applyClassesDependingOnBundleSize('md:bg-putty xl:py-20', 'md:bg-putty lg:py-20', 'md:bg-putty md:py-20')]},[_c('div',{staticClass:"flex flex-wrap items-center",class:[
      _vm.applyClassesDependingOnBundleSize(
        'xl:flex-nowrap px-8 max-w-screen-2xl mx-auto',
        'lg:flex-nowrap px-8 max-w-screen-2xl mx-auto',
        'md:flex-nowrap container'
      ),
    ]},[_c('div',{staticClass:"flex flex-col justify-center w-full text-center relative shrink-0",class:[
        _vm.applyClassesDependingOnBundleSize('xl:text-left xl:pr-4', 'lg:text-left lg:pr-4', 'md:text-left md:pr-4'),
        _vm.applyClassesDependingOnBundleSize(
          'xl:max-w-xs',
          'lg:max-w-xs lg:mr-10',
          'md:max-w-xs lg:max-w-sm 2xl:max-w-lg'
        ),
      ]},[_c('div',{staticClass:"w-full text-br-green fluid-sm leading-tight"},[_vm._v("\n        "+_vm._s(_vm.overline)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"fluid-3xl leading-tightest font-semibold my-4 w-full"},[_vm._v(_vm._s(_vm.heading))]),_vm._v(" "),_c('div',[_vm._v(_vm._s(_vm.bundleNames))]),_vm._v(" "),_c('CrossSellAction',{staticClass:"hidden w-full mt-4",class:[_vm.applyClassesDependingOnBundleSize('xl:block', 'lg:block', 'md:block')],attrs:{"price":_vm.deal.bundlePrice,"compare-price":_vm.deal.bundleCompareAtPrice.amount !== _vm.deal.bundlePrice.amount ? _vm.deal.bundleCompareAtPrice : null,"is-available":_vm.bundleAvailable,"is-loading":_vm.isSubmitting},on:{"addToCart":_vm.addToCart}})],1),_vm._v(" "),_c('div',{staticClass:"w-full mt-6 mx-auto",class:[
        _vm.layoutClasses,
        _vm.applyClassesDependingOnBundleSize('xl:mt-0 xl:w-auto', 'lg:mt-0 lg:w-auto', 'md:mt-0 md:w-auto'),
      ]},_vm._l((_vm.productsList),function(item,index){return _c('ProductCardLight',_vm._b({directives:[{name:"track",rawName:"v-track:view.item",value:(_vm.gtmData(item, index)),expression:"gtmData(item, index)",arg:"view",modifiers:{"item":true}},{name:"track",rawName:"v-track:click.item",value:(_vm.gtmData(item, index)),expression:"gtmData(item, index)",arg:"click",modifiers:{"item":true}}],key:index,staticClass:"last:after:hidden after:content-['+'] after:absolute after:top-1/2 after:left-full after:font-bold after:text-center after:-mt-4",class:[
          _vm.applyClassesDependingOnBundleSize(
            'after:w-10 lg:after:w-8 after:fluid-xl leading-tight font-medium',
            'after:w-10 lg:after:w-16 xl:after:w-24 after:fluid-3xl leading-tightest',
            'after:w-10 lg:after:w-24 after:fluid-3xl leading-tightest'
          ),
        ],on:{"update:selected":function($event){return _vm.updateSelectedItem($event, index)}}},'ProductCardLight',{
          title: item.title,
          description: item.productCardUsp,
          handle: item.handle,
          image: item.selected.image,
          price: item.selected.price,
          compareAtPrice: item.selected.compareAtPrice,
          unitPrice: item.selected.unitPrice,
          unitPriceMeasurement: item.selected.unitPriceMeasurement,
          discountPerProduct: _vm.deal.isBogo && index === 0 ? false : _vm.deal.discountPerProduct,
          variants: item.variants,
          selectedId: item.selected.id,
        },false))}),1),_vm._v(" "),_c('CrossSellAction',{staticClass:"text-center block w-full mt-4",class:[_vm.applyClassesDependingOnBundleSize('xl:hidden', 'lg:hidden', 'md:hidden')],attrs:{"price":_vm.deal.bundlePrice,"compare-price":_vm.deal.bundleCompareAtPrice.amount !== _vm.deal.bundlePrice.amount ? _vm.deal.bundleCompareAtPrice : null,"is-available":_vm.bundleAvailable,"is-loading":_vm.isSubmitting},on:{"addToCart":_vm.addToCart}})],1),_vm._v(" "),(_vm.hasPreAssessment && _vm.showPreAssessmentModal)?_c('PreAssessmentModal',{attrs:{"showing":_vm.showPreAssessmentModal,"info":_vm.agreementCategory[0]},on:{"buy":_vm.addDealToCart,"close":function($event){_vm.showPreAssessmentModal = false}}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }