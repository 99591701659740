import gql from 'graphql-tag';
import { stripLocale } from '@/utils/i18n';

const BaseQuery = gql`
  {
    base: entry(uri: "__home__", site: "en") {
      siteId
      language: siteHandle
      localized {
        siteId
        language: siteHandle
        url
      }
    }
  }
`;

const RetourQuery = gql`
  query RetourQuery($uri: String, $siteId: Int) {
    retour(uri: $uri, siteId: $siteId) {
      redirectDestUrl
      redirectHttpCode
    }
  }
`;
/**
 * @param {Object} baseQuery response
 * @return {Object} map of isoLocale to craft siteId
 */
export const localeToSiteIdFormBase = ({ siteId, language, localized }) => {
  return localized.reduce(
    (acc, { language, siteId }) => {
      acc[language] = siteId;
      return acc;
    },
    { [language]: siteId }
  );
};

export default ({ app, route, redirect, $craftRequest, $localeRootPath, $sentry, error }, inject) => {
  /**
   * @return {Object} map of isoLocale to craft siteId
   */
  const fetchBase = async () => {
    try {
      const { base } = await $craftRequest(BaseQuery);
      return base;
    } catch (error) {
      $sentry.captureException(error);
      return null;
    }
  };

  /**
   * looks up current uri in craft retour plugin for 301 redirect or 410
   * redirects to 301 if found
   * renders 410 if found
   * renders 404 if not found
   */
  const retourRedirect = async () => {
    try {
      const base = await fetchBase();
      const locale = app.i18n.locale;
      const siteId = localeToSiteIdFormBase(base)[locale];
      const uri = '/' + stripLocale(route.path, locale);
      const { retour } = await $craftRequest(RetourQuery, { uri, siteId });

      const redirectHttpCode = retour?.redirectHttpCode;
      const redirectDestUrl = retour?.redirectDestUrl || '';
      switch (redirectHttpCode) {
        case 301:
        case 302:
          return redirect(redirectHttpCode, redirectDestUrl);
        case 410:
          return error({ statusCode: 410, message: 'Page is gone forever' });
        default:
          return error({ statusCode: 404, message: 'Page not found' });
      }
    } catch (error) {
      console.log('error', error);
      $sentry.captureException(error);
    }
  };
  inject('retourRedirect', retourRedirect);
};
