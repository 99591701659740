<template>
  <div class="md:shadow-xl flex flex-col justify-between bg-white relative w-full h-full">
    <div class="flex items-center justify-center w-full h-32 md:px-8 py-4">
      <CldnImg
        v-if="image"
        :src="image"
        :alt="title"
        :ratio="0.68"
        crop="fill"
        sizes="(min-width: 1440px) 325px, (min-width: 1024px) 30vw, 50vw"
        class="h-24 object-contain"
      />
    </div>

    <div
      v-if="calculatedPrice.amount <= 0"
      class="md:hidden bg-br-green flex items-center justify-center absolute inset-x-0 sm:inset-x-6 max-w-[160px] mx-auto"
    >
      <span class="font-medium text-white text-xs">{{ $t('For free') }}</span>
    </div>

    <div class="md:px-5 px-2">
      <nuxt-link :to="productRoute" class="w-full hidden md:block">
        <div v-if="title" class="text-[16px] h-8 text-center uppercase font-medium leading-tightest">
          {{ title }}
        </div>
        <div v-if="description && !variants.length === 0" class="text-xs h-6 box-content py-3 text-center font-light">
          <span class="line-clamp-2" v-html="description"></span>
        </div>
      </nuxt-link>
      <FormSelectInput
        v-if="variants.length > 0"
        v-model="selected"
        class="max-w-[160px] md:max-w-none mx-auto -mb-px"
        :options="variantOptions"
        appearance="light"
        :show-errors="false"
        :disabled="hasOnlyOneVariant"
        :show-label="false"
        text-center
      />
      <div class="py-5 text-center h-20 items-center justify-center hidden md:flex border-t border-gray-200">
        <ProductPriceLine
          v-if="calculatedPrice.amount > 0"
          class="text-center"
          v-bind="{
            price: calculatedPrice,
            comparePrice: discountPerProduct ? price : compareAtPrice ? compareAtPrice : null,
            showVatHint: false,
            showCentered: true,
            showQuantity: true,
            unitPrice,
            unitPriceMeasurement,
            size: 'xs',
          }"
        />
        <span v-else class="text-br-green font-medium uppercase">{{ $t('For free') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import CldnImg from '@/components/CldnImg';
import ProductPriceLine from '@/components/shop/products/ProductPriceLine';
import FormSelectInput from '~/components/account/FormSelectInput.vue';

export default {
  name: 'ProductCardLight',
  components: {
    CldnImg,
    FormSelectInput,
    ProductPriceLine,
  },
  props: {
    title: { type: String, default: null },
    description: { type: String, default: null },
    image: { type: String, default: null },
    price: { type: Object, default: () => null },
    compareAtPrice: { type: Object, default: () => null },
    discountPerProduct: { type: [Number, Boolean], default: false },
    unitPrice: { type: Object, default: () => {} },
    unitPriceMeasurement: { type: Object, default: () => {} },

    variants: { type: Array, default: () => [] },
    selectedId: { type: [String, Number], default: null },

    handle: { type: String, default: null },
  },

  data() {
    return { selected: this.selectedId };
  },

  computed: {
    hasOnlyOneVariant() {
      return this.variants.length === 1;
    },

    variantOptions() {
      return this.variants.map((variant) => ({
        label: variant.title,
        value: variant.id,
        disabled: variant.available === false,
      }));
    },

    productRoute() {
      return this.handle ? { path: this.$localeRootPath('/products/' + this.handle) } : false;
    },
    calculatedPrice() {
      if (this.discountPerProduct) {
        return {
          amount: Math.round((this.price.amount - (this.price.amount * this.discountPerProduct) / 100) * 100) / 100,
          currencyCode: this.price.currencyCode,
        };
      }

      return this.price;
    },
  },

  watch: {
    selected() {
      this.$emit('update:selected', this.selected);
    },
  },
};
</script>
