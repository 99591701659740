import { addVariantToCart } from '@/utils/product';

export default (ctx, inject) => {
  /*
    window.dispatchEvent(
      new CustomEvent('ow-add-to-cart', {
        detail: {
          variant: {
            id: 'gid://shopify/ProductVariant/41016852218019',
          },
          isCourse: false,
        },
      })
    );
  */

  window.addEventListener('ow-add-to-cart', function (event) {
    addVariantToCart.call(ctx.app, {}, event.detail.variant, event.detail.isCourse);
  });
};
