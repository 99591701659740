<template>
  <nuxt-link
    v-if="productVariant && productRoute"
    :to="productRoute"
    class="outline-none flex flex-col justify-end h-full relative"
    tabindex="-1"
  >
    <div v-if="showBadges">
      <SaleBadgeIcon v-if="onSale" class="absolute h-1/3 sm:h-1/4 md:h-1/3 ml-2 sm:ml-4 z-10 top-4" />
      <div v-else-if="newBadge">
        <NewBadgeIcon class="absolute h-1/3 sm:h-1/4 md:h-1/3 ml-2 sm:ml-4 z-10 sm:top-4 -top-4" />
      </div>
    </div>
    <div class="sm:mx-10 h-full flex items-center">
      <CldnImg
        v-if="productVariant.image"
        :src="productVariant.image.src"
        :alt="productVariant.image.altText ? productVariant.image.altText : productVariant.title"
        :ratio="0.68"
        crop="fill"
        sizes="(min-width: 1440px) 325px, (min-width: 1024px) 30vw, 50vw"
        class="variant-image"
      />
    </div>
  </nuxt-link>
</template>

<script>
import CldnImg from '@/components/CldnImg'
import SaleBadgeIcon from '@/components/icons/SaleBadgeIcon'
import NewBadgeIcon from '@/components/icons/NewBadgeIcon'

export default {
  name: 'ProductCardVariantImg',

  components: { CldnImg, SaleBadgeIcon, NewBadgeIcon },

  props: {
    productVariant: {
      type: Object,
      default: () => null
    },
    productRoute: {
      type: Object,
      default: () => null
    },
    newBadge: {
      type: Boolean,
      default: false
    },
    showBadges: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    onSale() {
      if (this.productVariant.compareAtPrice) {
        return +this.productVariant?.price?.amount < +this.productVariant?.compareAtPrice?.amount
      }
      return false
    }
  }
}
</script>

<style scoped>
.variant-image {
  @apply select-none h-full sm:h-auto mx-auto;
}
</style>
