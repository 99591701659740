export const state = () => ({
  toasts: [],
});

export const mutations = {
  add(state, toast) {
    state.toasts.push({
      ...toast,
    });
  },

  shift(state) {
    state.toasts.shift();
  },
};

export const getters = {
  currentToast: (state) => {
    return state.toasts.length > 0 ? state.toasts[0] : null;
  },
};
