import { render, staticRenderFns } from "./Testimonial.vue?vue&type=template&id=02ba1345&scoped=true"
import script from "./Testimonial.vue?vue&type=script&lang=js"
export * from "./Testimonial.vue?vue&type=script&lang=js"
import style0 from "./Testimonial.vue?vue&type=style&index=0&id=02ba1345&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02ba1345",
  null
  
)

export default component.exports