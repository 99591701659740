<template>
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
    <g v-if="!isActive">
      <path d="M13.3499 15.75H11.6499V14.05H13.3499V15.75Z" />
      <path
        d="M12.5 4.7C16.24 4.7 19.3 7.76 19.3 11.5C19.3 15.24 16.24 18.3 12.5 18.3C8.76 18.3 5.7 15.24 5.7 11.5C5.7 7.76 8.76 4.7 12.5 4.7ZM12.5 3C7.825 3 4 6.825 4 11.5C4 16.175 7.825 20 12.5 20C17.175 20 21 16.175 21 11.5C21 6.825 17.175 3 12.5 3Z"
      />
      <path
        d="M11.679 13.2C11.679 12.01 12.1977 11.585 12.6299 11.16C12.8892 10.99 13.0621 10.735 13.1485 10.48C13.3214 10.225 13.3214 9.97 13.3214 9.63C13.3214 9.29 13.2349 9.035 13.0621 8.78C12.8892 8.61 12.7163 8.44 12.3705 8.44C12.1112 8.44 11.5061 8.525 11.5061 9.715H9.9502V9.375C9.9502 8.61 10.2095 8.1 10.6417 7.675C11.1604 7.42 11.7654 7.25 12.457 7.25C13.3214 7.25 13.9265 7.42 14.3587 7.93C14.7909 8.44 15.0502 8.95 15.0502 9.63C15.0502 11.67 13.3214 11.755 13.3214 13.2H11.679Z"
      />
    </g>
    <g v-if="isActive">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5 3C7.825 3 4 6.825 4 11.5C4 16.175 7.825 20 12.5 20C17.175 20 21 16.175 21 11.5C21 6.825 17.175 3 12.5 3ZM11.679 13.2C11.679 12.0839 12.1353 11.6407 12.5485 11.2393C12.5759 11.2128 12.603 11.1864 12.6299 11.16C12.8892 10.99 13.0621 10.735 13.1485 10.48C13.3214 10.225 13.3214 9.97 13.3214 9.63C13.3214 9.29 13.2349 9.035 13.0621 8.78C12.8892 8.61 12.7163 8.44 12.3705 8.44C12.1112 8.44 11.5061 8.525 11.5061 9.715H9.9502V9.375C9.9502 8.61 10.2095 8.1 10.6417 7.675C11.1604 7.42 11.7654 7.25 12.457 7.25C13.3214 7.25 13.9265 7.42 14.3587 7.93C14.7909 8.44 15.0502 8.95 15.0502 9.63C15.0502 10.7377 14.5405 11.269 14.0746 11.7546C13.6824 12.1633 13.3214 12.5396 13.3214 13.2H11.679ZM11.6499 15.75H13.3499V14.05H11.6499V15.75Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>
