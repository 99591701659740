<template>
  <div v-if="formId">
    <div ref="scriptContainer" class="overflow-hidden overflow-y-scroll" />
  </div>
</template>

<script>
export default {
  props: {
    formId: {
      type: [Number, String],
      default: null,
    },
  },
  mounted() {
    if (this.formId && !document.querySelector('[data-role="form"]')) this.embedForm();
  },
  methods: {
    embedForm() {
      const formScript = document.createElement('script');
      const attributes = [
        { attr: 'src', value: 'https://form.123formbuilder.com/embed/' + this.formId + '.js' },
        { attr: 'data-custom-vars', value: 'language=' + this.$i18n.locale },
        { attr: 'type', value: 'text/javascript' },
        { attr: 'data-default-width', value: '650' },
        { attr: 'data-role', value: 'form' }, // manditory to have data-role="form", otherwise the form won't be embedded
        { attr: 'defer', value: true },
      ];
      for (const element of attributes) {
        formScript.setAttribute(element.attr, element.value);
      }

      this.$refs.scriptContainer.appendChild(formScript);
    },
  },
};
</script>
