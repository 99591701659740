var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:shadow-xl flex flex-col justify-between bg-white relative w-full h-full"},[_c('div',{staticClass:"flex items-center justify-center w-full h-32 md:px-8 py-4"},[(_vm.image)?_c('CldnImg',{staticClass:"h-24 object-contain",attrs:{"src":_vm.image,"alt":_vm.title,"ratio":0.68,"crop":"fill","sizes":"(min-width: 1440px) 325px, (min-width: 1024px) 30vw, 50vw"}}):_vm._e()],1),_vm._v(" "),(_vm.calculatedPrice.amount <= 0)?_c('div',{staticClass:"md:hidden bg-br-green flex items-center justify-center absolute inset-x-0 sm:inset-x-6 max-w-[160px] mx-auto"},[_c('span',{staticClass:"font-medium text-white text-xs"},[_vm._v(_vm._s(_vm.$t('For free')))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"md:px-5 px-2"},[_c('nuxt-link',{staticClass:"w-full hidden md:block",attrs:{"to":_vm.productRoute}},[(_vm.title)?_c('div',{staticClass:"text-[16px] h-8 text-center uppercase font-medium leading-tightest"},[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.description && !_vm.variants.length === 0)?_c('div',{staticClass:"text-xs h-6 box-content py-3 text-center font-light"},[_c('span',{staticClass:"line-clamp-2",domProps:{"innerHTML":_vm._s(_vm.description)}})]):_vm._e()]),_vm._v(" "),(_vm.variants.length > 0)?_c('FormSelectInput',{staticClass:"max-w-[160px] md:max-w-none mx-auto -mb-px",attrs:{"options":_vm.variantOptions,"appearance":"light","show-errors":false,"disabled":_vm.hasOnlyOneVariant,"show-label":false,"text-center":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"py-5 text-center h-20 items-center justify-center hidden md:flex border-t border-gray-200"},[(_vm.calculatedPrice.amount > 0)?_c('ProductPriceLine',_vm._b({staticClass:"text-center"},'ProductPriceLine',{
          price: _vm.calculatedPrice,
          comparePrice: _vm.discountPerProduct ? _vm.price : _vm.compareAtPrice ? _vm.compareAtPrice : null,
          showVatHint: false,
          showCentered: true,
          showQuantity: true,
          unitPrice: _vm.unitPrice,
          unitPriceMeasurement: _vm.unitPriceMeasurement,
          size: 'xs',
        },false)):_c('span',{staticClass:"text-br-green font-medium uppercase"},[_vm._v(_vm._s(_vm.$t('For free')))])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }