<template>
  <InlineIconsGrid>
    <a v-track:click="gtmData('uebungen-app-play', 'Google Play', 0)" :href="playStore.appUrl">
      <span class="sr-only">
        {{ $t('navigation.getItOnGooglePlay') }}
      </span>
      <GooglePlayIcon class="h-10" />
    </a>
    <a v-track:click="gtmData('uebungen-app-ios', 'App Store', 1)" :href="appStore.appUrl">
      <span class="sr-only">
        {{ $t('navigation.downloadOnTheAppStore') }}
      </span>
      <AppStoreIcon class="h-10" />
    </a>
  </InlineIconsGrid>
</template>

<script>
import InlineIconsGrid from '~/components/common/InlineIconsGrid';
import AppStoreIcon from '~/components/icons/AppStoreIcon';
import GooglePlayIcon from '~/components/icons/GooglePlayIcon';
export default {
  components: { GooglePlayIcon, AppStoreIcon, InlineIconsGrid },
  props: {
    blockPosition: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    playStore() {
      return this.$config.blackrollApp.playStore;
    },
    appStore() {
      return this.$config.blackrollApp.appStore;
    },
  },

  methods: {
    gtmData(id, name, index) {
      return {
        promotion_id: id,
        promotion_name: [this.$route?.name, name].join(' / '),
        creative_name: 'appCta',
        creative_slot: [this.$route?.name, this.blockPosition + 1, index].join(' / '),
      };
    },
  },
};
</script>
