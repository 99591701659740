<template>
  <button
    class="w-8 h-4 overflow-hidden rounded-sm shrink-0 flex items-center justify-center"
    :aria-label="$i18n.locale"
    type="button"
    @click="openCountryBar"
  >
    <img class="inline-block h-full w-auto" :src="`/flags/${currentLocale}.svg`" :alt="currentLocale" />
  </button>
</template>

<script>
import { Bus } from '@/utils/bus';

export default {
  computed: {
    currentLocale() {
      const { locale } = this.$i18n;

      if (locale === 'ch-fr' || locale === 'ch-de') {
        return 'ch';
      }

      if (locale === 'en') {
        return 'eu';
      }

      return locale;
    },
  },

  methods: {
    openCountryBar() {
      Bus.$emit('openCountryBar');
    },
  },
};
</script>
