<template>
  <LinkField v-bind="linkField" class="relative inline-block" @click.native="setOpenNavTopIndex(null)">
    <CldnImg v-if="image[0]" :src="image[0].url" :alt="image[0].title" sizes="250px" class="w-full select-none" />
    <div class="inset-4 absolute flex items-end">
      <BarText :text="heading" class="fluid-lg leading-tight font-medium" />
    </div>
  </LinkField>
</template>

<script>
import gql from 'graphql-tag';
import LinkField from '@/components/common/LinkField';
import CldnImg from '@/components/CldnImg';
import BarText from '@/components/BarText';
import { mapActions } from 'vuex';

export const NavBlocksImageFeatureFragment = gql`
  fragment NavBlocksImageFeatureFragment on navBlocks_imageFeature_BlockType {
    typeHandle
    heading
    image {
      url
      title
    }
    linkField {
      ...LinkFragment
    }
  }
`;

export default {
  components: { BarText, CldnImg, LinkField },
  props: {
    heading: {
      type: String,
      default: null,
    },
    image: {
      type: Array,
      default: () => [],
    },
    linkField: {
      type: Object,
      default: () => null,
    },
  },
  methods: {
    ...mapActions('mainNav', ['setOpenNavTopIndex']),
  },
};
</script>
