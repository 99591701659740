<template>
  <figure
    class="group content-start gap-5"
    :class="
      alwaysColumn
        ? 'flex flex-col'
        : 'lg:col-span-1 lg:grid-cols-1 lg:gap-0 grid grid-cols-2 col-span-2 gap-5 sm:flex sm:flex-col'
    "
  >
    <nuxt-link :to="{ path: $localeRootPath(story.uri) }" class="relative overflow-hidden" tabindex="-1">
      <CldnImg
        v-if="story.previewImage[0] && !aspectRatio"
        :src="story.previewImage[0].url"
        :alt="story.previewImage[0].title"
        :sizes="'(min-width: 1024px) 25vw, 50vw'"
        crop="fit"
        loading="auto"
        class="max-w-none block w-full group-hover:scale-110 transition-transform duration-700 transform"
      />
      <FixedAspect v-if="story.previewImage[0] && aspectRatio" :ratio="aspectRatio">
        <CldnImg
          v-if="story.previewImage[0]"
          :src="story.previewImage[0].url"
          :alt="story.previewImage[0].title"
          :sizes="'(min-width: 1024px) 25vw, 50vw'"
          :ratio="aspectRatio"
          crop="fill"
          loading="auto"
          class="max-w-none block w-full group-hover:scale-110 transition-transform duration-700 transform"
        />
      </FixedAspect>
      <div
        class="overlay bg-blackly group-hover:opacity-25 absolute inset-0 transition-opacity duration-700 opacity-0 pointer-events-none"
      ></div>
    </nuxt-link>
    <figcaption>
      <template v-for="category in story.storiesCategories">
        <TagBtn :key="category.title" :to="$localeRootPath(category.uri)" class="lg:mt-4 inline-block mb-3">{{
          category.title
        }}</TagBtn>
      </template>
      <nuxt-link :to="{ path: $localeRootPath(story.uri) }">
        <BarText :text="story.title" class="fluid-lg leading-tight font-medium hover:underline" tag="h3" />
      </nuxt-link>
      <time-to-read :read-time="story.readTime" />
    </figcaption>
  </figure>
</template>

<script>
import CldnImg from '@/components/CldnImg';
import TagBtn from '@/components/common/TagBtn';
import BarText from '@/components/BarText.vue';
import TimeToRead from '@/components/common/TimeToRead';
import FixedAspect from '@/components/common/FixedAspect';

export default {
  components: {
    FixedAspect,
    TimeToRead,
    CldnImg,
    TagBtn,
    BarText,
  },
  props: {
    story: {
      type: Object,
      default: () => {},
    },
    alwaysColumn: {
      type: Boolean,
      default: true,
    },
    aspectRatio: {
      type: Number,
      default: null,
    },
  },
};
</script>
