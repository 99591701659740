<template>
  <div class="product-card md:shadow-xl flex flex-col bg-white relative w-full shadow-lg" v-bind="exposedProductData">
    <div class="fluid-xxs uppercase tracking-wide font-medium md:h-7 box-border w-full h-6 leading-4">
      <span
        v-if="cardHeader"
        class="flex items-center justify-center w-full h-full text-center"
        :style="cardHeader.style"
      >
        {{ cardHeader.text }}
      </span>
    </div>
    <div class="z-10 w-full h-auto pt-2 mt-2 md:mt-6 relative">
      <span
        v-if="textBadge && !climateNeutral"
        class="sm:block hidden fluid-lg font-medium tracking-wider absolute text-badge-height pr-1 leading-none"
        :style="textBadge.style"
      >
        {{ textBadge.text }}
      </span>

      <ProductTypeBadge
        v-if="productType"
        :product-type="productType"
        class="lg:mb-1 block mr-2 md:mr-6 absolute right-0 top-0"
        :class="{ 'ml-auto': !textBadge }"
        bigger-icon
      />
    </div>
    <div class="relative w-full mt-3">
      <ClimateNeutralBadge
        v-if="climateNeutral"
        :is-epp="climateEpp"
        :is-band="climateBand"
        class="-top-4 sm:top-4 right-4 md:right-6 absolute z-10 w-8 h-8"
      />
      <VibrationBadgeIcon
        v-if="!isLoading && isVibration && !climateNeutral"
        class="-top-4 sm:top-4 right-4 md:right-6 absolute z-10 w-8 h-8"
      />

      <FixedAspect v-if="variants.length" :ratio="0.55">
        <transition v-for="variant in variants" :key="variant.id" name="fade" mode="out-in" class="h-full">
          <ProductCardVariantImg
            v-if="variant.id === selectedVariant.id"
            :product-variant="variant"
            :product-route="productRoute"
            :new-badge="isNew"
            :class="isVibration && !climateNeutral ? 'lg:pt-10' : ''"
          />
        </transition>
      </FixedAspect>
    </div>
    <div v-if="isLoading" class="relative w-full p-3">
      <div class="w-full px-2 py-1 leading-4">
        <Placeholder class="w-full h-4 my-1" />
      </div>
      <FixedAspect :ratio="imageRatio" class="md:mt-3 md:px-5 w-full max-h-full px-2 mt-6 select-none">
        <div class="animated-bg" />
        <Placeholder class="absolute inset-0" />
      </FixedAspect>
    </div>
    <div class="product-variants relative w-full my-2">
      <ProductOptions
        v-if="!isLoading && !isSizeOptions && variants"
        v-bind="{
          variants,
          swatches: optionSwatches,
          maxSwatches: MAX_SWATCHES,
          selectedVariant,
          option: product.options[0],
        }"
        class="absolute top-0 justify-center w-full h-full"
        swatch-img-class="w-4 h-4"
        @clickOption="setOption"
      />
    </div>
    <div v-if="isLoading" class="product-variants flex flex-row justify-center px-8 py-3">
      <Placeholder v-for="index in 3" :key="index" class="w-4 h-4 mx-2 my-1 rounded-full" />
    </div>
    <div class="divide-gray-divider md:px-5 px-2 divide-y">
      <div class="fluid-xs leading-tightest font-semibold box-content h-8 py-3 text-center">
        <span v-if="productUsp" v-html="productUsp"></span>
      </div>
      <nuxt-link :to="productRoute" class="inline-block w-full">
        <DynamicHeader
          v-if="productTransformedMeta && productTransformedMeta.title"
          class="fluid-lg leading-tight font-medium mt-4 mb-3 text-center uppercase"
          :text="productTransformedMeta.title"
          :lines="2"
        />
        <div v-if="isLoading" class="my-3">
          <Placeholder v-for="index in 2" :key="index" class="w-full h-6 my-1" />
        </div>
      </nuxt-link>
    </div>
    <div class="px-2 mb-2 md:px-5 text-center">
      <div v-if="variants.length" class="flex justify-center">
        <ProductCardPrice :price-ranges="productTransformedMeta.priceRange" :current-variant="currentVariant" />
      </div>
      <ProductCardUnitPrice
        v-if="currentVariant"
        v-bind="{
          unitPrice: currentVariant.unitPrice,
          unitPriceMeasurement: currentVariant.unitPriceMeasurement,
          showQuantity: true,
        }"
      />
    </div>
    <div v-if="isLoading" class="md:px-5 self-center w-full px-2 mb-2">
      <Placeholder v-for="index in 2" :key="index" class="w-1/2 h-4 mx-auto my-1" />
    </div>
    <div class="fluid-xxs uppercase tracking-wide leading-tight font-medium text-br-red self-center h-3 mb-3">
      <span
        v-if="productTransformedMeta && productTransformedMeta.availableForSale === false"
        data-test-id="unavailable"
        class="h-full"
        >{{ $t('product.soldOut') }}</span
      >
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import DynamicHeader from '@/components/shop/products/DynamicHeader';
import {
  productMetaFieldTransformer,
  productOptionSwatchesFragment,
  productBadgesFragment,
  productFlagsFragment,
  productCardHeaderFragment,
  productProductCardUspFragment,
  productDigitalProductTypeFragment,
} from '@/components/shop/products/Product.js';
import FixedAspect from '@/components/common/FixedAspect';
import Placeholder from '@/components/common/Placeholder';
import ProductOptions from '@/components/shop/products/ProductOptions';
import ProductCardPrice from '@/components/shop/products/ProductCardPrice';
import ProductCardVariantImg from '@/components/shop/products/ProductCardVariantImage';
import VibrationBadgeIcon from '@/components/icons/VibrationBadgeIcon';
import ClimateNeutralBadge from '@/components/shop/products/ClimateNeutralBadge';
import ProductTypeBadge from '@/components/shop/products/ProductTypeBadge';
import ProductCardUnitPrice from '@/components/shop/products/ProductCardUnitPrice';
import { VariantFragment } from '@/components/shop/products/Variant.js';

export const ProductCardFragment = gql`
  fragment ProductCardFragment on Product {
    handle
    id
    productType
    availableForSale
    title
    options {
      name
      values
    }
    priceRange {
      minVariantPrice {
        amount
        currencyCode
      }
      maxVariantPrice {
        amount
        currencyCode
      }
    }
    variants(first: 99) {
      edges {
        node {
          ...VariantFragment
        }
      }
    }
    ...productOptionSwatchesFragment
    ...productBadgesFragment
    ...productFlagsFragment
    ...productCardHeaderFragment
    ...productProductCardUspFragment
    ...productDigitalProductTypeFragment
  }
  ${productOptionSwatchesFragment}
  ${productBadgesFragment}
  ${productFlagsFragment}
  ${productCardHeaderFragment}
  ${productProductCardUspFragment}
  ${productDigitalProductTypeFragment}
  ${VariantFragment}
`;

export const ProductCardByHandleQuery = gql`
  query ProductCardByHandleQuery($handle: String!) {
    product: productByHandle(handle: $handle) {
      ...ProductCardFragment
    }
  }
  ${ProductCardFragment}
`;

export const ProductCardByVariantIdQuery = gql`
  query ProductCardByVariantIdQuery($id: String!) {
    productVariant(id: $id) {
      product {
        ...ProductCardFragment
      }
    }
  }
  ${ProductCardFragment}
`;

export default {
  name: 'ProductCard',

  components: {
    ProductTypeBadge,
    ProductCardVariantImg,
    ProductOptions,
    Placeholder,
    ProductCardPrice,
    FixedAspect,
    DynamicHeader,
    VibrationBadgeIcon,
    ProductCardUnitPrice,
    ClimateNeutralBadge,
  },

  props: {
    product: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      MAX_SWATCHES: 3,
      imageRatio: 170 / 250,
      selectedVariant: null,
    };
  },

  computed: {
    exposedProductData() {
      return {
        'data-category': this.productType,
        'data-price': this.selectedVariant?.price?.amount,
        'data-is-climate': this.climateNeutral || this.climateEpp || this.climateBand ? 'true' : 'false',
        'data-is-new': this.isNew ? 'true' : 'false',
        'data-is-vibration': this.isVibration ? 'true' : 'false',
      };
    },

    productTransformedMeta() {
      if (this.product) return productMetaFieldTransformer(this.product);
      return null;
    },
    isLoading() {
      return !this.productTransformedMeta;
    },
    productRoute() {
      return { path: this.$localeRootPath('/products/' + this.productTransformedMeta?.handle) };
    },
    optionSwatches() {
      return this.productTransformedMeta?.optionSwatches;
    },
    productType() {
      return this.productTransformedMeta?.productType;
    },
    variants() {
      const variants = this.productTransformedMeta?.variants?.edges?.map((e) => e.node) || [];
      return variants.sort((a, b) => a.price.amount - b.price.amount);
    },

    currentVariant() {
      return this.selectedVariant || this.variants[0];
    },

    textBadge() {
      const validBadges = this.productTransformedMeta?.badges?.filter((badge) => {
        // we filter out each badge that is used for climate badges
        if (
          badge.text === 'CLIMATE_NEUTRAL_BADGE' ||
          badge.text === 'Badge Climate EPP' ||
          badge.text === 'Badge Climate Bands'
        ) {
          return false;
        }
        return true;
      });

      if (validBadges?.length) {
        const badge = validBadges[0];

        return { text: badge.text, style: { color: badge.textColor, backgroundColor: badge.backgroundColor } };
      }

      return null;
    },
    climateNeutral() {
      return (
        this.productTransformedMeta?.badges?.findIndex((badge) => {
          return badge.text === 'CLIMATE_NEUTRAL_BADGE';
        }) > -1 ||
        this.climateEpp ||
        this.climateBand
      );
    },
    climateEpp() {
      return (
        this.productTransformedMeta?.badges?.findIndex((badge) => {
          return badge.text === 'Badge Climate EPP';
        }) > -1
      );
    },
    climateBand() {
      return (
        this.productTransformedMeta?.badges?.findIndex((badge) => {
          return badge.text === 'Badge Climate Bands';
        }) > -1
      );
    },
    cardHeader() {
      if (this.productTransformedMeta?.cardHeader?.active) {
        const header = this.productTransformedMeta.cardHeader;
        return { text: header.text, style: { color: header.textColor, backgroundColor: header.backgroundColor } };
      }
      return null;
    },
    isNew() {
      return !!this.productTransformedMeta?.flags?.new;
    },
    isVibration() {
      return !!this.productTransformedMeta?.flags?.vibrate;
    },
    productUsp() {
      return this.productTransformedMeta?.productCardUsp;
    },
    isSizeOptions() {
      try {
        return this.product.options[0]?.name === 'Size';
      } catch (e) {
        return false;
      }
    },
  },

  created() {
    this.selectedVariant = this.variants[0];
  },

  methods: {
    setOption(newVariant) {
      this.selectedVariant = newVariant;
    },
  },
};
</script>

<style lang="postcss" scoped>
.slide img {
  transition: transform 200ms ease-in-out;
}
.slide:hover img {
  transition: transform 200ms ease-in-out;
}
.product-variants {
  @apply pt-[5%];
}
.fade-enter-active {
  animation: displayTestimonial 0.5s;
}
.fade-leave-active {
  animation: hideTestimonial 0.5s;
}

.text-badge-height {
  height: auto;
  @apply max-h-[1.15em];
}

@keyframes displayTestimonial {
  0% {
    @apply opacity-0 hidden;
  }
  51% {
    @apply opacity-0 block;
  }
  66% {
    @apply opacity-0 block;
  }
  100% {
    @apply opacity-100 block;
  }
}
@keyframes hideTestimonial {
  0% {
    @apply opacity-100 block;
  }
  34% {
    @apply opacity-0 block;
  }
  50% {
    @apply opacity-0 hidden;
  }
  100% {
    @apply opacity-0 hidden;
  }
}
</style>
