<template>
  <div id="toast-container" class="absolute inset-x-0 z-20">
    <div class="flex justify-center">
      <transition
        enter-active-class="transition duration-200 ease-out transform"
        enter-class="-translate-y-2 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <Toast v-if="isEnabled && currentToast" :toast="currentToast" @close="remove" />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Toast from '@/components/toast/Toast'

const TOAST_CLOSE_TIMEOUT = 8000

export default {
  components: {
    Toast
  },

  data: () => ({
    maxDeferTries: 4,
    isEnabled: false,
    timeout: null
  }),

  computed: {
    ...mapGetters('toast', ['currentToast'])
  },

  watch: {
    currentToast(current) {
      if (this.isEnabled && current !== false) {
        this.removeAfterTimeout()
      }
    }
  },

  mounted() {
    // check if one trust script is installed, if not the case, skip defer part
    const script = document.querySelector('[data-hid=Ot-Sdk]')
    if (script) {
      this.deferToastsAfterConsent()
    } else {
      this.enableToasts()
    }
  },

  methods: {
    /**
     * This function detects if a consent screen is shown to the user.
     *
     * If the consent screen is shown, the displaying of the toasts is deferred to
     * after the event when the user interacted with the consent.
     *
     * If no consent is shown, the toasts will enable by default
     *
     * To detect the consent Modal and the OneTrust object, we use a timeout with
     * 4 attempts to check for existence. If nothing is found we default to showing
     * the toasts.
     */
    deferToastsAfterConsent() {
      setTimeout(() => {
        const consentModal = document.querySelector('#onetrust-banner-sdk')
        const isVisible = consentModal && consentModal.style.display === ''

        if (isVisible && window.OneTrust) {
          window.OneTrust.OnConsentChanged(() => {
            this.enableToasts()
          })
        } else if (this.maxDeferTries > 0) {
          this.maxDeferTries--
          this.deferToastsAfterConsent()
        } else {
          this.enableToasts()
        }
      }, 600)
    },

    enableToasts() {
      setTimeout(() => {
        this.isEnabled = true

        if (this.currentToast && !this.timeout) {
          this.removeAfterTimeout()
        }
      }, 300)
    },

    removeAfterTimeout() {
      this.timeout = setTimeout(() => {
        this.remove()
      }, TOAST_CLOSE_TIMEOUT)
    },

    remove() {
      if (this.timeout) {
        clearTimeout(this.timeout)
        this.timeout = null
      }

      this.$store.commit('toast/shift')
    }
  }
}
</script>
