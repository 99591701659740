<template>
  <div v-if="testimonial" class="flex flex-col items-center mx-5 sm:mx-10 md:mx-20">
    <div
      v-if="testimonial.body"
      data-test-id="body"
      class="leading-normal fluid-xl font-medium text-center lg:w-2/3"
      :class="{ 'text-white': backgroundBlack }"
      v-html="testimonial.body"
    ></div>
    <div
      v-if="testimonial.authorImage && testimonial.authorImage[0]"
      class="lg:w-1/4 flex justify-center"
      data-test-id="author-image"
    >
      <CldnImg
        :src="testimonial.authorImage[0].url"
        width="96"
        :ratio="1"
        crop="fill"
        :alt="testimonial.author ? testimonial.author : testimonial.authorImage[0].title"
        class="rounded-full mt-5 w-24 h-24 object-cover object-center"
      />
    </div>
    <p
      v-if="testimonial.author"
      data-test-id="author"
      class="testimonial-font-medium leading-normal fluid-base font-light mt-5 mx-auto"
      :class="{ 'text-br-green-light': backgroundBlack }"
    >
      {{ testimonial.author }}
    </p>
  </div>
</template>

<script>
import CldnImg from '@/components/CldnImg'

export default {
  name: 'Testimonial',

  components: { CldnImg },

  props: {
    testimonial: {
      type: Object,
      default: () => null
    },
    backgroundBlack: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="postcss" scoped>
.testimonial-font-medium {
  @apply font-medium;
}
</style>
