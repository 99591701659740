<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58">
    <g fill="none" fill-rule="evenodd">
      <circle fill="#162022" cx="29" cy="29" r="29" />
      <path
        d="M12.245 36v-7.084c0-.782.241-1.418.724-1.909.483-.49 1.077-.736 1.783-.736.69 0 1.215.222 1.575.667.36.445.54 1.081.54 1.909V36h2.76v-7.958c0-1.319-.364-2.373-1.092-3.163-.728-.79-1.713-1.184-2.956-1.184-.674 0-1.291.13-1.851.391-.56.26-1.054.636-1.483 1.127v-1.288h-2.76V36h2.76Zm15.295.253c1.195 0 2.25-.299 3.162-.897.912-.598 1.583-1.426 2.012-2.484l-2.368-.897a2.597 2.597 0 0 1-1.024 1.414c-.498.345-1.092.518-1.782.518-.89 0-1.622-.299-2.197-.897-.575-.598-.916-1.41-1.023-2.438h8.463v-.92c0-1.779-.483-3.216-1.449-4.313-.965-1.096-2.269-1.644-3.91-1.644-.828 0-1.602.161-2.322.483-.721.322-1.35.79-1.887 1.403a6.13 6.13 0 0 0-1.138 1.99 7.157 7.157 0 0 0-.403 2.403c0 .89.135 1.713.403 2.473a5.915 5.915 0 0 0 1.161 1.989 5.504 5.504 0 0 0 1.91 1.346 6.026 6.026 0 0 0 2.392.471ZM30 28.502h-5.543c.2-.797.556-1.41 1.07-1.84.514-.43 1.139-.644 1.874-.644.798 0 1.427.226 1.887.678.46.453.697 1.055.712 1.806ZM39.477 36l2.208-7.912L43.892 36h2.392l3.635-12.075h-2.737l-2.14 8.004-2.162-8.004h-2.277l-2.139 8.004-2.184-8.004h-2.83L37.084 36h2.392Z"
        fill="#E2EED6"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'NewBadgeIcon'
}
</script>
