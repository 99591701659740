<template>
  <div>
    <div v-for="(block, index) in blocks" :key="index">
      <component
        :is="block.typeHandle"
        v-if="isVisible(block)"
        v-bind="{
          product,
          tsProduct,
          description: product.descriptionHtml,
          accordions,
          addOn,
          youtubeId: product.video,
          exercisePage,
          testimonials: product.testimonial,
          handles: product.relatedProducts,
          handle: product.bundleProduct,
          features: product.keyFeatures,
          agreementCategory: block.agreementCategory ? block.agreementCategory : agreementCategory,
          blockPosition: index,
          heading: block.typeHandle === 'accordion' ? $t('shop.accordionHeading') : null,
          backgroundGreen: block.typeHandle === 'accordion',
          ...block,
        }"
      />
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import guestComponent from '@/mixins/guestComponent';

import ConversionAreaOne, {
  ProductBlocksConversionAreaOneFragment,
} from '@/components/common/blocks/ConversionAreaOne';
import AccordionBlock, {
  ProductBlocksAccordionBlockFragment,
  ProductBlocksAccordionFragment,
} from '@/components/common/blocks/AccordionBlock';
import AppCta, { ProductBlocksAppCtaFragment } from '@/components/common/blocks/AppCta';
import VideoFeature, { ProductBlocksVideoFeatureFragment } from '~/components/common/blocks/VideoFeature';
import ExerciseTeaser, { ProductBlocksExerciseTeaserFragment } from '@/components/common/blocks/ExerciseTeaser';
import Expert, { ProductBlocksExpertFragment } from '@/components/common/blocks/Expert';
import RelatedProducts, { ProductBlocksRelatedProductsFragment } from '@/components/common/blocks/RelatedProducts';
import ProductInformation, {
  ProductBlocksProductInformationFragment,
} from '@/components/common/blocks/ProductInformation';
import ProductReviews, { ProductBlocksProductReviewsFragment } from '@/components/common/blocks/ProductReviews';
import AlsoBought, { ProductBlocksAlsoBoughtFragment } from '@/components/common/blocks/AlsoBought';
import ImageText, { ProductBlocksImageTextFragment } from '@/components/common/blocks/MediaText';
import SplitContentSlider, {
  ProductBlocksSplitContentSliderFragment,
} from '@/components/common/blocks/SplitContentSlider';
import GenericIframe, { ProductBlocksGenericIframeFragment } from '@/components/common/blocks/GenericIframe';
import TeaserWithCta, { ProductBlocksTeaserWithCtaFragment } from '@/components/common/blocks/TeaserWithCta';
import NewsletterSignupForm, {
  ProductBlocksNewsletterSignupFormFragment,
} from '@/components/common/blocks/NewsletterSignupForm';
import CenteredContent from '@/components/common/blocks/CenteredContent';
import { ProductBlocksCenteredContentFragment } from '@/components/common/blocks/CenteredContent/index';
import VideoWithDescription, {
  ProductBlocksVideoWithDescriptionFragment,
} from '@/components/common/blocks/VideoWithDescription';
import CourseConversionBanner, {
  ProductBlocksCourseConversionBannerFragment,
} from '~/components/common/blocks/CourseConversionBanner';
import FeatureList, { ProductBlocksFeatureListFragment } from '@/components/common/blocks/FeatureList';
import InsuranceReimbursement, {
  ProductBlocksInsuranceReimbursementFragment,
} from '@/components/common/blocks/InsuranceReimbursement';
import CourseContent, { ProductBlocksCourseContentFragment } from '~/components/common/blocks/CourseContent';
import CrossSell, { ProductBlocksCrossSellFragment } from '@/components/common/blocks/CrossSell';
import Upsell, { ProductBlocksUpsellFragment } from '@/components/common/blocks/Upsell';
import TestimonialWithVariedBackground, {
  ProductBlocksTestimonialWithVariedBackgroundFragment,
} from '@/components/common/blocks/TestimonialWithVariedBackground';
import ImageGallery, { ProductBlocksImageGalleryFragment } from '@/components/common/blocks/ImageGallery';
import FeaturesWithIcons, {
  ProductBlocksFeaturesWithIconsFragment,
} from '@/components/common/blocks/FeaturesWithIcons';

export const ProductBlocksMatrixFragment = gql`
  fragment ProductBlocksMatrixFragment on productBlocks_MatrixField {
    ...ProductBlocksConversionAreaOneFragment
    ...ProductBlocksAccordionFragment
    ...ProductBlocksAppCtaFragment
    ...ProductBlocksVideoFeatureFragment
    ...ProductBlocksExerciseTeaserFragment
    ...ProductBlocksExpertFragment
    ...ProductBlocksRelatedProductsFragment
    ...ProductBlocksProductInformationFragment
    ...ProductBlocksProductReviewsFragment
    ...ProductBlocksAlsoBoughtFragment
    ...ProductBlocksImageTextFragment
    ...ProductBlocksSplitContentSliderFragment
    ...ProductBlocksAccordionBlockFragment
    ...ProductBlocksGenericIframeFragment
    ...ProductBlocksTeaserWithCtaFragment
    ...ProductBlocksNewsletterSignupFormFragment
    ...ProductBlocksCenteredContentFragment
    ...ProductBlocksVideoWithDescriptionFragment
    ...ProductBlocksCourseConversionBannerFragment
    ...ProductBlocksFeatureListFragment
    ...ProductBlocksInsuranceReimbursementFragment
    ...ProductBlocksCourseContentFragment
    ...ProductBlocksUpsellFragment
    ...ProductBlocksCrossSellFragment
    ...ProductBlocksTestimonialWithVariedBackgroundFragment
    ...ProductBlocksImageGalleryFragment
    ...ProductBlocksFeaturesWithIconsFragment
  }
  ${ProductBlocksConversionAreaOneFragment}
  ${ProductBlocksAccordionFragment}
  ${ProductBlocksAppCtaFragment}
  ${ProductBlocksVideoFeatureFragment}
  ${ProductBlocksExerciseTeaserFragment}
  ${ProductBlocksExpertFragment}
  ${ProductBlocksRelatedProductsFragment}
  ${ProductBlocksProductInformationFragment}
  ${ProductBlocksProductReviewsFragment}
  ${ProductBlocksAlsoBoughtFragment}
  ${ProductBlocksImageTextFragment}
  ${ProductBlocksSplitContentSliderFragment}
  ${ProductBlocksAccordionBlockFragment}
  ${ProductBlocksGenericIframeFragment}
  ${ProductBlocksTeaserWithCtaFragment}
  ${ProductBlocksNewsletterSignupFormFragment}
  ${ProductBlocksCenteredContentFragment}
  ${ProductBlocksCourseConversionBannerFragment}
  ${ProductBlocksVideoWithDescriptionFragment}
  ${ProductBlocksFeatureListFragment}
  ${ProductBlocksInsuranceReimbursementFragment}
  ${ProductBlocksCourseContentFragment}
  ${ProductBlocksUpsellFragment}
  ${ProductBlocksCrossSellFragment}
  ${ProductBlocksTestimonialWithVariedBackgroundFragment}
  ${ProductBlocksImageGalleryFragment}
  ${ProductBlocksFeaturesWithIconsFragment}
`;

export default {
  name: 'ProductBlocks',
  components: {
    GenericIframe,
    Accordion: AccordionBlock,
    AccordionBlock,
    ConversionAreaOne,
    AppCta,
    VideoFeature,
    ExerciseTeaser,
    Expert,
    RelatedProducts,
    ProductInformation,
    ProductReviews,
    AlsoBought,
    ImageText,
    SplitContentSlider,
    CenteredContent,
    TeaserWithCta,
    NewsletterSignupForm,
    FeatureList,
    CourseConversionBanner,
    VideoWithDescription,
    InsuranceReimbursement,
    CourseContent,
    CrossSell,
    Upsell,
    TestimonialWithVariedBackground,
    ImageGallery,
    FeaturesWithIcons,
  },
  mixins: [guestComponent],
  props: {
    entry: {
      type: Object,
      default: () => null,
    },
    product: {
      type: Object,
      default: () => null,
    },
    tsProduct: {
      type: Object,
      default: () => null,
    },
    addOn: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    exercisePage() {
      return this.entry?.exercisePage;
    },
    agreementCategory() {
      return this.entry?.agreementCategory;
    },
    accordions() {
      return (
        this.product.accordion?.map((f) => ({
          heading: f.title,
          body: f.description,
        })) || []
      );
    },
    blocks() {
      return this.entry && this.entry.productBlocks ? this.entry.productBlocks : [];
    },
  },
  methods: {
    tsIsSet() {
      const { locale, locales } = this.$i18n;
      const tsid = locales.find((l) => l.code === locale)?.tsid;
      return !!tsid;
    },
  },
};
</script>
