import eos from 'end-of-stream';

export function streamToBuffer(stream) {
  return new Promise((resolve, reject) => {
    const buffers = [];

    stream.on('data', buffers.push.bind(buffers));

    eos(stream, (err) => {
      if (err) {
        reject(err);
        return;
      }
      switch (buffers.length) {
        case 0:
          resolve(Buffer.allocUnsafe(0));
          break;
        case 1:
          resolve(buffers[0]);
          break;
        default:
          resolve(Buffer.concat(buffers));
      }
    });
  });
}

export async function serializeBody(request) {
  return request.method !== 'GET' && request.method !== 'HEAD' ? await streamToBuffer(request) : undefined;
}
