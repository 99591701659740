import { GraphQLClient } from 'graphql-request';
import { print } from 'graphql/language';

import createCartMutation from './createCartMutation.gql';
import addLinesMutation from './addLinesMutation.gql';
import addDiscountCodeMutation from './addDiscountCodeMutation.gql';
import getCartQuery from './getCartQuery.gql';
import cartAttributesUpdate from './updateCartAttributesMutation.gql';
import updateLinesMutation from './updateLinesMutation.gql';
import updateBuyerIdentityMutation from './updateBuyerIdentityMutation.gql';

export default function ({ env, app, store }, inject) {
  const {
    i18n: { locale, locales },
  } = app;

  const { shopifyApiVersion } = env;

  let client = null;

  const createClient = (locale) => {
    const { shopifyApiAccessToken, shopifyApiDomain } = locales.find((l) => l.code === locale);

    const endPoint = `https://${shopifyApiDomain}/api/${shopifyApiVersion}/graphql`;
    client = new GraphQLClient(endPoint, {
      headers: {
        'X-Shopify-Storefront-Access-Token': shopifyApiAccessToken,
        'accept-language': locale,
        Accept: 'application/json',
      },
    });
  };

  createClient(locale);

  app.i18n.onBeforeLanguageSwitch = (oldLocale, newLocale) => {
    createClient(newLocale);
    app.$shopifyRefreshClient(newLocale);
  };

  app.i18n.onLanguageSwitched = () => {
    store.dispatch('cart/resetCart');
  };

  const resolveCountry = () => {
    const map = {
      'ch-fr': 'CH',
      'ch-de': 'CH',
      en: 'GB',
    };

    return map[locale] || 'GB';
  };

  const resolveLocale = () => {
    let currentLocale = locale;

    const map = {
      'ch-fr': 'fr',
      'ch-de': 'de',
    };

    if (map[locale]) {
      currentLocale = map[locale];
    }

    return currentLocale.toUpperCase();
  };

  const updateBuyerIdentity = async (cartId, buyerIdentity) => {
    try {
      const response = await client.request(print(updateBuyerIdentityMutation), {
        id: cartId,
        buyerIdentity,
        lang: resolveLocale(),
      });

      if (response.cartBuyerIdentityUpdate.userErrors.length) {
        return Promise.reject(response.cartBuyerIdentityUpdate.userErrors);
      }

      return response.cartBuyerIdentityUpdate.cart;
    } catch (error) {
      console.warn('oh no', error);
      return Promise.reject(error);
    }
  };

  const createCart = async (lang) => {
    try {
      const response = await client.request(print(createCartMutation), { input: {}, lang });

      if (response.cartCreate.userErrors.length) {
        return Promise.reject(response.cartCreate.userErrors);
      }

      return response.cartCreate.cart;
    } catch (error) {
      console.warn('oh no', error);
      return Promise.reject(error);
    }
  };

  const getCart = async (cartId) => {
    try {
      const response = await client.request(print(getCartQuery), { id: cartId, lang: resolveLocale() });

      return response.cart;
    } catch (error) {
      console.warn('oh no', error);
      return Promise.reject(error);
    }
  };

  const addLines = async (cartId, lines) => {
    try {
      const response = await client.request(print(addLinesMutation), { id: cartId, lines, lang: resolveLocale() });

      if (response.cartLinesAdd.userErrors.length) {
        return Promise.reject(response.cartLinesAdd.userErrors);
      }

      return response.cartLinesAdd.cart;
    } catch (error) {
      console.warn('oh no', error);
      return Promise.reject(error);
    }
  };

  const updateLines = async (cartId, lines) => {
    try {
      const response = await client.request(print(updateLinesMutation), { id: cartId, lines, lang: resolveLocale() });

      if (response.cartLinesUpdate.userErrors.length) {
        return Promise.reject(response.cartLinesUpdate.userErrors);
      }

      return response.cartLinesUpdate.cart;
    } catch (error) {
      console.warn('oh no', error);
      return Promise.reject(error);
    }
  };

  const addDiscount = async (cartId, discountCodes) => {
    try {
      const response = await client.request(print(addDiscountCodeMutation), {
        id: cartId,
        discountCodes,
        lang: resolveLocale(),
      });

      if (response.cartDiscountCodesUpdate.userErrors.length) {
        return Promise.reject(response.cartDiscountCodesUpdate.userErrors);
      }

      return response.cartDiscountCodesUpdate.cart;
    } catch (error) {
      console.warn('oh no', error);
      return Promise.reject(error);
    }
  };

  const updateAttributes = async (cartId, attributes = []) => {
    try {
      const response = await client.request(print(cartAttributesUpdate), {
        id: cartId,
        attributes,
        lang: resolveLocale(),
      });

      if (response.cartAttributesUpdate.userErrors.length) {
        return Promise.reject(response.cartAttributesUpdate.userErrors);
      }

      return response.cartAttributesUpdate.cart;
    } catch (error) {
      console.warn('oh no', error);
      return Promise.reject(error);
    }
  };

  inject('shopifyClient', {
    addLines,
    createCart,
    getCart,
    addDiscount,
    updateAttributes,
    updateLines,
    updateBuyerIdentity,
    resolveLocale,
    resolveCountry,
  });
}
