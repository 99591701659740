<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
    <path
      d="M8.482.5a.369.369 0 0 0-.333.251L6.297 6.228H.367c-.158 0-.3.101-.349.25a.36.36 0 0 0 .132.406l4.816 3.472-1.851 5.668c-.05.15.005.314.134.407.13.092.305.092.435-.001L8.5 12.957l4.816 3.473c.13.093.305.093.435.001a.36.36 0 0 0 .134-.407l-1.851-5.668 4.816-3.472a.362.362 0 0 0-.218-.656h-5.93L8.852.75a.368.368 0 0 0-.37-.25z"
      :fill="fill"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: '#93AB39'
    }
  }
}
</script>
