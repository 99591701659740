<template>
  <ProductComparePrice
    v-if="currentVariant"
    :price="currentVariant.price"
    :compare-at-price="currentVariant.compareAtPrice"
    :price-range-max="priceRangeMax"
    :price-range-min="priceRangeMin"
    class="relative"
  />
</template>

<script>
import ProductComparePrice from '@/components/common/ProductComparePrice'

export default {
  name: 'ProductCardPrice',
  components: {
    ProductComparePrice
  },
  props: {
    currentVariant: {
      type: Object,
      default: () => {}
    },
    priceRanges: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    priceRangeMin() {
      return this.priceRanges.minVariantPrice.amount
    },
    priceRangeMax() {
      return this.priceRanges.maxVariantPrice.amount
    }
  }
}
</script>
