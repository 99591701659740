const middleware = {}

middleware['flushDatalayer'] = require('../middleware/flushDatalayer.js')
middleware['flushDatalayer'] = middleware['flushDatalayer'].default || middleware['flushDatalayer']

middleware['functions'] = require('../middleware/functions.js')
middleware['functions'] = middleware['functions'].default || middleware['functions']

middleware['functions/helpers'] = require('../middleware/functions/helpers.js')
middleware['functions/helpers'] = middleware['functions/helpers'].default || middleware['functions/helpers']

middleware['functions/utils'] = require('../middleware/functions/utils.js')
middleware['functions/utils'] = middleware['functions/utils'].default || middleware['functions/utils']

middleware['responseHeaders'] = require('../middleware/responseHeaders.js')
middleware['responseHeaders'] = middleware['responseHeaders'].default || middleware['responseHeaders']

export default middleware
