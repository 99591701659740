import gql from 'graphql-tag';
import { getCookie } from '@/utils/cookie';
import { seomaticHead } from '@/utils/seomatic';

export const SeomaticFragment = gql`
  fragment SeomaticFragment on SeomaticInterface {
    metaTagContainer
    metaTitleContainer
    metaJsonLdContainer
    metaLinkContainer
  }
`;
export const EntryPageFragment = gql`
  fragment EntryPageFragment on EntryInterface {
    typeHandle
    sectionHandle

    localized(status: "live") {
      uri
      language
    }
    id
    title
    uri
    parent {
      title
      uri
      slug
      parent {
        title
        uri
        slug
      }
    }
    ... on routine_workoutCollection_Entry {
      id
      premiumContentRestrictions {
        ... on premiumContentRestrictions_settings_BlockType {
          id
          authenticatedOnly
          purchasedProductOnly
        }
      }
    }
  }
`;

export const ShopHomeBreadcrumbFragment = gql`
  fragment ShopHomeBreadcrumbFragment on Query {
    shopHomeEntry: entry(section: "shop", site: [$site], level: 1) {
      title
      uri
    }
  }
`;

function anonymizePagePath(route) {
  if (!route) {
    return '';
  }

  const filterKeys = ['email', 'password', 'token', 'key'];
  const urlParams = new URLSearchParams(window.location.search);

  filterKeys.forEach((key) => {
    if (urlParams.has(key)) {
      urlParams.set(key, '***');
    }
  });

  return route.path + '?' + urlParams.toString();
}

export default {
  scrollToTop: true,

  data() {
    return {
      seomatic: null,
      entry: null,
      isRestricted: false,
      breadCrumbs: [],
    };
  },
  /**
   * set page head
   * add html lang tag
   * add craft seomatic meta head tags (optional)
   * @returns {object} - nuxt head
   */
  head() {
    const locale = this.$i18n.locales.find((l) => l.code === this.$i18n.locale);

    let head = {
      htmlAttrs: {
        lang: locale.iso,
      },
    };

    const transformedHead = seomaticHead(this);

    if (this.seomatic !== null && typeof this.seomatic === 'object') {
      head = { ...head, ...transformedHead };
    }

    return head;
  },
  /**
   * timing specific methods go here
   * lifecycle of hooks are in this order:
   * 1. Pagecompoent created
   * 2... InContent-Components mounted
   * last Pagecomponent mounted
   */
  created() {
    this.setSectionHandle();
    this.setAlternates();
    this.resetNewsletterBlocks();

    this.isRestricted = this.entry?.premiumContentRestrictions?.length > 0;
  },
  mounted() {
    this.checkPremiumContent();
    // FIXME: Disabled usp bar setting overwrite for a/b testing
    // this.setUspBar();
  },
  methods: {
    checkPremiumContent() {
      const premiumSettings = this.entry?.premiumContentRestrictions;

      if (premiumSettings?.length > 0) {
        premiumSettings.forEach((setting) => {
          if (setting.authenticatedOnly && !this.$auth.$state.loggedIn) {
            return window.location.replace(this.$localeRootPath('/account/login'));
          }

          if (setting.purchasedProductOnly && this.$auth.$state.loggedIn) {
            // check for purchased product in account data
            let hasPurchase = false;

            // if not found, redirect away
            this.$auth.user?.blackrollCustomer?.addOns?.forEach((addOn) => {
              if (addOn.handle === setting.purchasedProductOnly) {
                hasPurchase = true;
              }
            });

            if (!hasPurchase) {
              return window.location.replace(this.$localeRootPath('/account/exercises/premium'));
            } else {
              this.isRestricted = false;
            }
          }
        });
      }
    },
    resetNewsletterBlocks() {
      this.$store.commit('newsletterBlocks/reset');
    },
    setSectionHandle() {
      this.$store.dispatch('page/setSectionHandle', this.entry?.sectionHandle);
    },
    /**
     * check needs to be specific as enabled value (true) for showing USP bar
     * comes as "undefined", also if there is no entry type block on site it should be shown
     */
    setUspBar() {
      let uspBarDisabled = false;
      if (this.entry && this.entry.allowUspBar === false) uspBarDisabled = true;
      this.$store.commit('globalUspBar/SET_LOCALLY_DISABLED', uspBarDisabled, { root: true });
    },
    metaInfoTitle() {
      let curr = this;
      let title = null;
      do {
        title = curr?.$metaInfo?.title;
        curr = curr.$parent;
      } while (!title && curr);
      return title;
    },
    gtmPushPageMeta(options, preventUserUpdate = false) {
      const defaultOptions = {
        event: 'virtPageView',
        page_type: this.entry?.typeHandle || '--',
        page_path: anonymizePagePath(this.$route),
        page_title: this.metaInfoTitle(),
        client_id: getCookie('_ga'),
        country: this.$i18n.locale,
        language: this.$i18n.locale,
        topic: this.entry?.seoTopics?.join(',') || '--',
        system: 'CMS',
        environment: process.env.gtmEnv || '--',
        content_category: this.entry?.sectionHandle || '--',
        collection: '--',
        search_results: '--',
        product_category: '--',
        product_subcategory: '--',
        content_subcategory: '--',
      };
      this.$_gtm.push({ ...defaultOptions, ...options });
      if (!preventUserUpdate) this.gtmPushUserMeta();
    },
    countOrdersTotal() {
      return (
        this.$auth.user.blackrollCustomer?.orders?.reduce((a, b) => {
          return a + +b.totalPrice;
        }, 0) || 0
      );
    },
    getUserCountry() {
      const defaultAddress = this.$auth.user.blackrollCustomer?.addresses?.filter((address) => {
        return address.defaultAddress === true;
      });
      return defaultAddress?.length ? this.$t(`countries.${defaultAddress[0]?.country}`, 'en') : null;
    },
    gtmPushUserMeta() {
      const userData = {
        event: 'User Meta',
        client_id: getCookie('_ga'),
        login_status: 'false',
      };
      if (this.$auth.user) {
        userData.user_id = this.$auth.user.uid;
        userData.user_country = this.getUserCountry();
        // newsletter_subscriber: '(true | false)'
        userData.user_exercises = this.$auth.user.blackrollCustomer?.exercises?.length || 0;
        userData.user_workouts = this.$auth.user.blackrollCustomer?.workouts?.length || 0;
        userData.user_registration = this.$auth.user.creationTime;
        userData.last_time_ordered = this.$auth.user.blackrollCustomer?.orders[0]?.date;
        userData.total_spent_to_date = this.countOrdersTotal().toFixed(2);
        userData.total_orders = this.$auth.user.blackrollCustomer?.orders?.length || 0;
        userData.login_status = 'true';
      }
      this.$_gtm.push(userData);
    },
    /**
     * builds breadcrumb from parents property recursively - skips for root-entry with uri: ''
     * @returns {array} - shape list of objects {label: 'title' , route: {path: '/some/uri'}}
     */
    buildBreadCrumb() {
      if (!this.entry) return [];

      const getCrumbs = (entry) => {
        if (entry.uri === '') return [];
        const crumb = { label: entry.title, route: { path: this.localePath({ path: '/' + entry.uri }) } };
        if (entry.parent !== undefined && entry.parent !== null) {
          return [...getCrumbs(entry.parent), crumb];
        } else {
          return [crumb];
        }
      };
      return getCrumbs(this.entry);
    },
    setBreadCrumb() {
      this.$store.dispatch('page/setBreadCrumb', this.buildBreadCrumb());
    },
    setChildBreadCrumb(crumbs) {
      const fullCrumbs = [...this.buildBreadCrumb(), ...crumbs];
      this.$store.dispatch('page/setBreadCrumb', fullCrumbs);
    },
    setAlternates() {
      this.$store.commit('mainNav/SET_ALTERNATES', this.alternates);
    },
  },
  computed: {
    alternates() {
      const localized =
        this.entry?.localized?.reduce((acc, curr) => {
          if (curr.language === 'gsw-CH') {
            curr.language = 'ch-de';
          } else if (curr.language === 'gsw-FR') {
            curr.language = 'ch-fr';
          }

          acc[curr.language] = this.$localeRootPath(curr.uri, curr.language);
          return acc;
        }, {}) || {};

      return {
        [this.$i18n.locale]: this.$route.path,
        ...localized,
      };
    },
  },
};
