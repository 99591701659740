export const addVariantToCart = async function (
  product,
  currentVariant,
  isCourse = false,
  rating = null,
  addOn = null
) {
  if (product.handle) {
    this.$dlPush.eECaddToCart(currentVariant, product, 1, rating);
  }

  const store = this.$store || this.store;

  const currentLineItem = store.getters['cart/lineItems']
    ? store.getters['cart/lineItems'].find((lineItem) => {
        if (currentVariant.id.startsWith('gid://shopify/ProductVariant')) {
          return lineItem.variant?.id === currentVariant.id;
        } else {
          try {
            return lineItem.variant?.id === atob(currentVariant.id);
          } catch (e) {
            return false;
          }
        }
      })
    : null;

  // check if we need to discard this because max quantity is reached
  if (currentLineItem) {
    const maxQuantity =
      Number(
        currentLineItem.attributes.find((attr) => {
          return attr.key === '_maxQuantity';
        })?.value
      ) || false;

    if (maxQuantity && currentLineItem.quantity >= maxQuantity) {
      // open cart and quit early
      this.$store.dispatch('cart/open');
      return;
    }
  }

  const basket = [
    {
      merchandiseId: currentVariant.id,
      quantity: 1,
      attributes: [
        { key: '_maxQuantity', value: isCourse ? '1' : '0' },
        { key: '_isCourse', value: isCourse ? 'true' : 'false' },
      ],
    },
  ];

  if (addOn) {
    const variant = addOn.variants?.edges[0]?.node?.id;

    // need to check if add on is already in cart
    const addOnInCart =
      this.lineItems?.findIndex((lineItem) => {
        return lineItem.variant.id === variant;
      }) !== -1;

    if (!addOnInCart) {
      const IS_ADDON_KEY = '_isAddOn';

      basket.push({
        merchandiseId: variant,
        quantity: 1,
        attributes: [
          { key: IS_ADDON_KEY, value: 'true' },
          { key: '_bundledBy', value: product.handle },
          { key: '_cartImage', value: product.digitalAddOn?.digitalAddOnMainImageFullPath },
          { key: '_cartText', value: product.digitalAddOn?.digitalAddOnName },
          { key: '_maxQuantity', value: '1' },
        ],
      });
    }
  }

  await store.dispatch('cart/addLineItems', basket);

  store.dispatch('cart/open');
};
