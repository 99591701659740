<template>
  <div v-if="contactWidget && contactWidget.globallyEnabled" class="flex my-6 px-4 justify-between items-center">
    <div class="text-sm mr-3">
      <span class="block font-bold">{{ contactWidget.heading }}</span>
      <span class="block">{{ contactWidget.teaser }}</span>
    </div>

    <a
      :href="contactWidget.externalUrl"
      target="_blank"
      class="animate-icon block h-12 w-12 bg-white bg-origin-padding bg-cover bg-no-repeat shadow-md rounded-full shrink-0 border-2 border-white"
      :style="`background-image: url(${contactIcon})`"
    ></a>
  </div>
</template>

<script>
import { mapState } from 'vuex'

const DEFAULT_ICON =
  'https://uni-3fc5f4cd-94b8-44e2-a5c7-108c80448672.storage.googleapis.com/assets/a67b0eba-5408-4b43-abd2-0c4e559bb5d3.svg'

export default {
  computed: {
    ...mapState('mainNav', ['contactWidget']),

    contactIcon() {
      return this.contactWidget.icon?.length > 0 ? this.contactWidget.icon[0].url : DEFAULT_ICON
    }
  }
}
</script>

<style scoped>
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.animate-icon {
  animation: pulse 0.75s 3 ease-in-out;
}
</style>
