<template>
  <div class="relative pr-2 lg:pr-0 lg:mr-4 h-full">
    <button
      class="h-full p-2 flex items-center justify-center text-black no-underline transition-all"
      data-testid="btn-cart"
      @click.prevent="handleClick"
    >
      <div class="h-6 relative">
        <CartIcon />
        <div
          v-if="cartCount"
          class="bg-br-green min-w-[1.25rem] p-1 scale-75 color absolute flex items-center justify-center h-4 fluid-xs leading-tightest font-medium rounded-full -top-1.5 -right-1.5"
        >
          <span class="text-white">
            {{ cartCount }}
          </span>
        </div>
      </div>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CartIcon from '@/components/CartIcon'

export default {
  components: {
    CartIcon
  },
  computed: {
    ...mapGetters('cart', ['lineItems', 'cartCount'])
  },
  methods: {
    handleClick() {
      this.$store.dispatch('cart/open')
    }
  }
}
</script>
