var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container mx-auto max-w-xl py-8"},[(_vm.resultsLength === 0)?_c('EmptySearchResults',_vm._b({},'EmptySearchResults',{ query: _vm.query, layout: 'list' },false)):_c('div',{staticClass:"grid gap-y-2"},_vm._l((_vm.results),function(group,i){return _c('div',{key:i},[(group.items.length > 0)?_c('div',[_c('h2',{staticClass:"bg-gray-lighter p-2 fluid-xs leading-tightest font-light"},[_vm._v(_vm._s(group.title))]),_vm._v(" "),_vm._l((group.items),function(item){return _c('div',{key:item.id,ref:"list",refInFor:true,staticClass:"grid gap-y-2 group-item mx-1 py-2"},[_c('SearchModalResultItem',_vm._b({on:{"click":_vm.onLinkClick}},'SearchModalResultItem',{
                url: _vm.$localeRootPath(item.uri) + (item.sectionHandle === 'exerciseCard' ? '#' + item.objectID : ''),
                title: item.title,
                highlightTitle: item._highlightResult ? item._highlightResult.title.value : null,
                imageUrl: item.imageUrl,
                useLargeImage: item.sectionGroup !== 'Products',
                minPrice: item.priceRange ? item.priceRange.minVariantPrice : null,
                maxPrice: item.priceRange ? item.priceRange.maxVariantPrice : null,
              },false)),_vm._v(" "),(false)?_c('nuxt-link',{staticClass:"flex items-center hover:text-br-green transition-all",attrs:{"to":_vm.$localeRootPath(item.uri) + (item.sectionHandle === 'exerciseCard' ? '#' + item.objectID : '')},nativeOn:{"click":function($event){return _vm.onLinkClick.apply(null, arguments)}}},[(item.imageUrl)?_c('CldnImg',{staticClass:"max-w-none block w-24 select-none mr-4",attrs:{"src":item.imageUrl,"alt":item.title,"ratio":item.sectionGroup === 'Products' ? null : '0.66',"crop":item.sectionGroup === 'Products' ? 'fill' : 'fill',"sizes":"100px"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"py-3"},[(item.sectionGroup === 'Products')?_c('div',[_c('div',[(item._highlightResult)?_c('div',{staticClass:"highlight-title",domProps:{"innerHTML":_vm._s(item._highlightResult.title.value)}}):_c('div',[_vm._v("\n                      "+_vm._s(item.title)+"\n                    ")])]),_vm._v(" "),(item.priceRange)?_c('div',[_c('ProductComparePrice',{attrs:{"price":{
                        amount: item.priceRange.minVariantPrice.amount,
                        currencyCode: item.priceRange.minVariantPrice.currencyCode,
                      },"price-range-min":item.priceRange.minVariantPrice.amount !== item.priceRange.maxVariantPrice.amount
                          ? item.priceRange.minVariantPrice.amount
                          : null,"price-range-max":item.priceRange.minVariantPrice.amount !== item.priceRange.maxVariantPrice.amount
                          ? item.priceRange.maxVariantPrice.amount
                          : null,"currency-code":item.priceRange.minVariantPrice.currencyCode,"price-class":'font-medium'}})],1):_vm._e()]):_c('div',[(item._highlightResult && item._highlightResult.title)?_c('div',{staticClass:"highlight-title",domProps:{"innerHTML":_vm._s(item._highlightResult.title.value)}}):_c('div',[_vm._v("\n                    "+_vm._s(item.title)+"\n                  ")])])])],1):_vm._e()],1)})],2):_vm._e()])}),0)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }