<template>
  <div class="flex items-center">
    <div class="h-full w-full">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <circle
          :r="radius"
          cx="100"
          cy="100"
          fill="transparent"
          :stroke-width="width"
          stroke="currentColor"
          class="text-lighter-gray"
        />
        <circle
          :r="radius"
          cx="100"
          cy="100"
          fill="transparent"
          :stroke-width="width"
          stroke="currentColor"
          :stroke-dasharray="circumference"
          :stroke-dashoffset="offset"
          class="transition-all duration-500 -rotate-90 origin-center ease-in-out"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 30
    }
  },

  data() {
    const radius = 100 - this.width / 2

    return {
      radius,
      circumference: 2 * Math.PI * radius
    }
  },

  computed: {
    offset() {
      return ((100 - this.progress) / 100) * this.circumference
    }
  }
}
</script>
