<template>
  <div
    v-if="routineEntry"
    class="group relative flex flex-col shadow-lg cursor-pointer"
    @click="$router.push(routineRoute)"
  >
    <div v-if="accountEnabled" class="top-3 right-3 absolute z-10 flex flex-row items-center">
      <LikeBtn :handle="routineEntry.id" :exercise="routineEntry" class="inline-block ml-4" type="routine" />
    </div>
    <nuxt-link :to="routineRoute" class="block" @click.native="handleRoutineClick">
      <FixedAspect :ratio="2 / 3" class="relative">
        <CldnImg
          v-if="routineImage"
          :src="routineImage.url"
          :alt="routineImage.title"
          :ratio="2 / 3"
          crop="fill"
          class="max-w-none absolute inset-0 z-0 block object-cover w-full h-full"
          sizes="(min-width: 1440px) 410px, (min-width: 1280px) 30vw, (min-width: 768px) 45vw, 100vw"
        />
      </FixedAspect>
    </nuxt-link>
    <div class="grow px-4 pb-8 bg-white flex flex-col justify-between">
      <div v-if="tagsFacetValues.length > 0" class="md:h-4 h-8 mt-2">
        <RoutineTags :tags="tagsFacetValues" />
      </div>

      <div class="fluid-lg leading-tight font-medium my-4">
        <nuxt-link :to="routineRoute" @click.native="handleRoutineClick">{{ routineEntry.title }}</nuxt-link>
      </div>

      <RoutineFeatures
        :duration="routineDuration"
        :exercise-count="routineEntry.exerciseCount"
        :difficulty="routineDifficulty"
      />
    </div>
  </div>
</template>

<script>
import Routine from '@/mixins/Routine';

import CldnImg from '@/components/CldnImg';
import FixedAspect from '@/components/common/FixedAspect';
import RoutineFeatures from '@/components/exercises/RoutineFeatures';
import RoutineTags from '@/components/exercises/RoutineTags';
import LikeBtn from '@/components/common/LikeBtn';
export { RoutinePreviewFragment } from '@/components/common/RoutinePreviewFragment.gql';

export default {
  components: { CldnImg, FixedAspect, RoutineFeatures, RoutineTags, LikeBtn },
  mixins: [Routine],
  props: {
    routine: {
      type: Object,
      required: true,
    },
    tagFacets: {
      type: Array,
      default: () => [],
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
    itemPosition: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    /**
     * overwrite binding of routine from Routine mixin to match props
     */
    routineEntry() {
      return this.routine;
    },
    routineImage() {
      return this.routineEntry?.image?.length ? this.routineEntry.image[0] : null;
    },
    tagsFacetValues() {
      return this.tagFacets
        .reduce((acc, curr, index) => {
          const routineRootName = curr.split('.')[0];
          acc[index] =
            routineRootName && this.routineEntry[routineRootName] && this.routineEntry[routineRootName][0]
              ? this.routineEntry[routineRootName][0].title
              : null;
          return acc;
        }, [])
        .filter(Boolean);
    },
    routineRoute() {
      return {
        name: `routine-slug___${this.$i18n.locale}`,
        params: { slug: this.routineEntry.slug },
      };
    },
    accountEnabled() {
      if (this.$config && this.$config.auth) {
        return this.$config.auth.enabled;
      }

      return false;
    },
  },
  methods: {
    handleRoutineClick() {
      const routineData = {
        event: 'gaEvent',
        event_name: 'routine_select',
        routine_select: {
          routine_name: this.routine.title,
          sport: this.parseExerciseProperties(this.routine.sports),
          training_goal: this.parseExerciseProperties(this.routine.trainingGoals),
          difficulty: this.parseExerciseProperties(this.routine.difficulty),
          routine_duration: this.routine.duration,
          event_category: 'Routine',
          event_action: 'Select',
          event_label: this.routine.title,
        },
      };
      this.$_gtm.push(routineData);
    },
  },
};
</script>
