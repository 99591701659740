<template>
  <div class="sm:container relative">
    <div class="bg-blackly max-w-screen-md mx-auto">
      <div v-for="(block, index) in ctaBlocks" :key="index">
      <div
          class="md:py-8 md:px-16 sm:flex-row sm:text-left sm:justify-between flex flex-col items-center p-6 text-center"
        >
          <div
            v-if="block.heading"
            class="fluid-lg leading-tight font-medium sm:pr-8 sm:mb-0 mb-4 text-white"
            v-html="block.heading"
          ></div>
          <LinkField v-bind="block.cta" class="btn-pill-outline-white" />
        </div>
        <hr v-if="index !== ctaBlocks.length - 1" class="md:mx-16 opacity-20 mx-4 border-white" />
      </div>
    </div>
  </div>
</template>

<script>
import LinkField from '@/components/common/LinkField';

export default {
  components: {
    LinkField,
  },
  props: {
    ctaBlocks: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
