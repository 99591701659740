<template>
  <Modal :showing="showing" hidden-prerender :min-width="true" class="modal" no-close-btn @close="$emit('close')">
    <h3 class="fluid-xl leading-tight font-medium">{{ info.title }}</h3>
    <div class="text-copy fluid-sm tracking-wider font-light mt-4" v-html="info.agreementBody"></div>
    <div class="mt-4">
      <FormCheckboxInput v-model="hasAgreed" :label="info.agreementLabel" />
      <FormCheckboxInput v-model="hasDisagreed" :label="info.disagreementLabel" />
      <div class="flex justify-center items-center flex-wrap md:flex-nowrap mt-3">
        <FormButton class="mx-1 my-0 mb-2" :disabled="!hasAgreed" @click.native="$emit('buy'), $emit('close')">{{
          info.confirmButtonLabel
        }}</FormButton>
        <FormButton class="mx-1 my-0 mb-2" :disabled="!hasDisagreed" @click.native="$emit('close')">{{
          info.cancelButtonLabel
        }}</FormButton>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/common/Modal'
import FormCheckboxInput from '@/components/account/FormCheckboxInput'
import FormButton from '@/components/account/FormButton'
export default {
  components: { Modal, FormCheckboxInput, FormButton },

  props: {
    info: {
      type: Object,
      required: true
    },
    showing: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      hasAgreed: false,
      hasDisagreed: false
    }
  },

  watch: {
    hasAgreed(newValue) {
      if (newValue) {
        this.hasDisagreed = false
      }
    },

    hasDisagreed(newValue) {
      if (newValue) {
        this.hasAgreed = false
      }
    }
  }
}
</script>
