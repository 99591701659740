<template>
  <div v-if="variant" class="w-full h-full overflow-hidden relative">
    <SaleBadgeIcon
      v-if="onSale"
      class="sm:w-12 sm:h-12 lg:w-14 lg:h-14 absolute z-10 h-8 ml-6"
      :class="[adjusted ? 'mt-16' : 'mt-10']"
    />
    <div v-if="isNew">
      <NewBadgeIcon
        class="sm:w-12 sm:h-12 lg:w-14 lg:h-14 absolute z-10 w-10 h-10 ml-6"
        :class="[adjusted ? 'mt-16' : 'mt-10']"
      />
    </div>
    <slot />
  </div>
</template>

<script>
import SaleBadgeIcon from '@/components/icons/SaleBadgeIcon'
import NewBadgeIcon from '@/components/icons/NewBadgeIcon'
import { variantOnSale } from '@/components/shop/products/Variant'

export default {
  name: 'ProductDetailVariantImage',
  components: { SaleBadgeIcon, NewBadgeIcon },
  props: {
    adjusted: {
      type: Boolean,
      default: false
    },
    variant: {
      type: Object,
      default: () => null
    },
    productIsNew: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    onSale() {
      return variantOnSale(this.variant)
    },
    isNew() {
      return !this.onSale && this.productIsNew
    }
  }
}
</script>
