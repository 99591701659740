<template>
  <VideoInModal v-bind="$props" :ratio="2 / 3" small-overlay />
</template>

<script>
import VideoInModal from '~/components/common/VideoInModal/VideoInModal';

export default {
  components: { VideoInModal },
  props: {
    heading: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    textOverlay: {
      type: String,
      default: null,
    },
    video: {
      type: Array,
      default: () => [],
    },
    preview: {
      type: Array,
      default: () => [],
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    backgroundGray: {
      type: Boolean,
      default: false,
    },
    centered: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
