/**
 * creates unlocalized without leading "/" uri from path
 * removes locale like "/de/"" or "/":
 * /de/path/to/page => path/to/page
 * /path/to/page => path/to/page
 *
 * @param  {string} path  - localized uri with leading slash
 * @param  {string} locale - [en de ...]
 */
export const stripLocale = (path, locale) => {
  path = stripTrailingSlash(path);

  if (locale === 'en') {
    if (!path.startsWith('/')) {
      console.warn('path should have leading slash');
      return path;
    }
    return path.substring(1);
  } else {
    const match = path.match(/^(\/[a-z]{2}(-[a-z]{2})?\/)/);

    if (!match) {
      console.warn('path should be prefixed with locale and leading slash');
      return path;
    }

    return path.replace(match[0], '');
  }
};

export const stripTrailingSlash = (path) => path.replace(/\/$/, '');

/**
 *
 * A function that maps a complex locale like ch-fr or ch-de to a basic locale
 *
 * @param {String} locale
 */
export const mapToBasicLocale = (locale) => {
  if (locale === 'ch-fr') {
    return 'fr';
  } else if (locale === 'ch-de') {
    return 'de';
  } else {
    return locale;
  }
};
