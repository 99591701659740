<template>
  <div v-if="!hasDiscountApplied && validDeals.length > 0" class="py-4">
    <CartProductSlider
      v-bind="{
        lineItems,
        deals: validDeals,
      }"
    />
  </div>
</template>

<script>
import CartProductSlider from '@/components/cart/CartDeals/CartProductSlider';
import { Bus } from '@/utils/bus';
import { mapGetters } from 'vuex';

export default {
  components: {
    CartProductSlider,
  },

  data() {
    return {
      deals: [],
    };
  },

  computed: {
    ...mapGetters('cart', ['lineItems', 'hasDiscountApplied']),

    validDeals() {
      return (
        this.deals
          // only unique deals
          .filter((value, index, self) => {
            return self.findIndex((deal) => deal.id === value.id) === index;
          })
          // only deals that have two products
          .filter((deal) => {
            const products = deal.discountProducts ? [...deal.products, ...deal.discountProducts] : deal.products;

            return deal.id && products.length === 2;
          })
          // a valid deal must have at least one variant match with a cart line item
          .filter((deal) => {
            return this.lineItems.some((item) => {
              // has this lineitem a product in the deal?
              return deal.products.some((product) => {
                return product.variants.some((variant) => {
                  return variant.sku === item.variant.sku;
                });
              });
            });
          })
          // find the product of the deal that is not in the cart yet and make it focus product
          .map((deal) => {
            const products = deal.discountProducts ? [...deal.products, ...deal.discountProducts] : deal.products;

            return {
              ...deal,
              focusProduct: products.find((product) => {
                return (
                  this.lineItems
                    .map((l) => l.variant.sku)
                    .filter((sku) => product.variants.map((v) => v.sku).includes(sku)).length === 0
                );
              }),
            };
          })
          // only deals that have a focus product
          .filter((deal) => deal.focusProduct)
      );
    },
  },

  async mounted() {
    const dealType = this.$pickystory.types.FBT_TYPE;
    const locationName = 'xsell-pdp';
    const maxProducts = 2;

    if (this.$pickystory.isPickyStoryReady()) {
      try {
        this.deals = await Promise.all(
          this.lineItems.map((item) => {
            return this.$pickystory.findDeal(locationName, item.variant.product.handle, dealType, maxProducts);
          })
        );
      } catch (e) {
        console.warn(e);
      }
    } else {
      Bus.$on('pickystory-ready', async () => {
        try {
          this.deals = await Promise.all(
            this.lineItems.map((item) => {
              return this.$pickystory.findDeal(locationName, item.variant.product.handle, dealType, maxProducts);
            })
          );
        } catch (e) {
          console.warn(e);
        }
      });
    }
  },
};
</script>
