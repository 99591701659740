<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    class="fill-current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.48 15.24C16.094 15.24 14.96 16.374 14.96 17.76C14.96 19.146 16.094 20.28 17.48 20.28C18.866 20.28 20 19.146 20 17.76C20 16.374 18.866 15.24 17.48 15.24ZM17.48 18.768C16.9256 18.768 16.472 18.3144 16.472 17.76C16.472 17.2056 16.9256 16.752 17.48 16.752C18.0344 16.752 18.488 17.2056 18.488 17.76C18.488 18.3144 18.0344 18.768 17.48 18.768Z"
    />
    <path
      d="M21.3002 6.47242C21.1547 6.30819 20.9607 6.19088 20.7183 6.19088H6.4856L5.83094 3.53963C5.7582 3.21116 5.46724 3 5.12779 3H2.72739C2.3152 3 2 3.30501 2 3.70387C2 4.10273 2.3152 4.40774 2.72739 4.40774H4.54588L6.92203 13.9804C6.99477 14.3088 7.28573 14.52 7.62518 14.52H18.9483C19.2877 14.52 19.5787 14.3088 19.6514 13.9804L21.4214 7.05898C21.4699 6.84782 21.4214 6.63666 21.3002 6.47242ZM18.3906 13.1123H8.20709L6.84929 7.59862H19.7969L18.3906 13.1123Z"
    />
    <path
      d="M8.83982 15.24C7.45382 15.24 6.31982 16.374 6.31982 17.76C6.31982 19.146 7.45382 20.28 8.83982 20.28C10.2258 20.28 11.3598 19.146 11.3598 17.76C11.3598 16.374 10.2258 15.24 8.83982 15.24ZM8.83982 18.768C8.28542 18.768 7.83182 18.3144 7.83182 17.76C7.83182 17.2056 8.28542 16.752 8.83982 16.752C9.39422 16.752 9.84782 17.2056 9.84782 17.76C9.84782 18.3144 9.39422 18.768 8.83982 18.768Z"
    />
  </svg>
</template>

<script>
export default {}
</script>
