<template>
  <CardSlider :breakpoints="breakpoints" :heading="heading">
    <LinkField
      v-for="(collection, index) in collections"
      :key="index"
      v-bind="collection.cta"
      v-track:click="gtmData(collection, index)"
      v-track:view="gtmData(collection, index)"
      class="focus:outline-none group px-6"
    >
      <FixedAspect :ratio="1.0" class="object-cover w-full align-middle">
        <div class="overflow-hidden rounded-full">
          <CldnImg
            v-if="collection.image[0]"
            :src="collection.image[0].url"
            :alt="collection.image[0].title"
            class="group-hover:scale-110 transition-transform duration-700 transform border-none max-w-[200px] max-h-[200px] w-full"
            width="200"
            :ratio="1"
            crop="fill"
          />
        </div>
      </FixedAspect>
      <h5 class="fluid-xs leading-tightest md:mt-6 mt-3 text-center uppercase" v-html="collection.cta.text"></h5>
    </LinkField>
  </CardSlider>
</template>

<script>
import CldnImg from '@/components/CldnImg';
import FixedAspect from '@/components/common/FixedAspect';
import LinkField from '@/components/common/LinkField';
import CardSlider from '@/components/common/CardSlider';
import gql from 'graphql-tag';


export default {
  components: {
    CardSlider,
    FixedAspect,
    CldnImg,
    LinkField,
  },
  props: {
    heading: {
      type: String,
      default: null,
    },
    collections: {
      type: Array,
      default: () => [],
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      breakpoints: [
        {
          minWindowWidth: 0,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
        {
          minWindowWidth: 640,
          slidesToShow: 3,
          slidesToScroll: 1,
        },
        {
          minWindowWidth: 768,
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      ],
    };
  },

  methods: {
    gtmData(payload, index) {
      return {
        promotion_id: payload.cta.element?.uri,
        promotion_name: [this.$route?.name, payload.cta.text].join(' / '),
        creative_name: 'collectionsSlider',
        creative_slot: [this.$route?.name, this.blockPosition + 1, index + 1].join(' / '),
      };
    },
  },
};
</script>
