<template>
  <RoutineCardsSlider v-if="routinesSlider.active" v-bind="routinesSlider" />
</template>

<script>
import RoutineCardsSlider from '~/components/common/blocks/RoutineCardsSlider';
import gql from 'graphql-tag';
import { mapState } from 'vuex';

export const AccountRoutinesFragment = gql`
  fragment AccountRoutinesFragment on account_GlobalSet {
    recommendedOn
    routinesSliderHeading
    routines: routineSlider {
      ...RoutinePreviewFragment
    }
  }
`;
export default {
  name: 'AccountRoutineSlider',
  components: { RoutineCardsSlider },
  computed: {
    ...mapState('account', ['routinesSlider']),
  },
};
</script>
