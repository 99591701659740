<template>
  <div ref="root" class="parallax-root">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    factor: {
      type: Number,
      default: null
    },
    startBreakpoint: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, { passive: true })
    window.addEventListener('resize', this.handleResize)
    this.$nextTick(() => {
      this.handleAnimation()
    })
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    getRootOffsetAsDecimal() {
      const refRoot = this.$refs.root
      const math =
        2 *
        ((window.innerHeight - refRoot.getBoundingClientRect().top) / (window.innerHeight + refRoot.offsetHeight) - 0.5)
      return math
    },
    handleAnimation() {
      if (!this.factor) {
        return
      }

      const refRoot = this.$refs.root
      if (window.innerWidth >= this.startBreakpoint) {
        const math = this.getRootOffsetAsDecimal()
        if (math > -1 && math < 1) {
          window.requestAnimationFrame(() => {
            refRoot.style.transform = `translateY(${-math * this.factor}px)`
          })
        }
      } else {
        refRoot.style.transform = null
      }
    },
    handleResize() {
      this.handleAnimation()
    },
    handleScroll(event) {
      this.handleAnimation()
    }
  }
}
</script>
