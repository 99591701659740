<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 9" fill="currentColor">
    <path
      fill-rule="evenodd"
      d="m12.831 1.564 3.231 2.91L0 4.511v.86l16.102.019-3.251 2.929.687.622L18 4.95 13.517.941z"
    />
  </svg>
</template>
<script>
export default {}
</script>
