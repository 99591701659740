import gql from 'graphql-tag';
export const VariantFragment = gql`
  fragment VariantFragment on ProductVariant {
    id
    title
    price {
      amount
      currencyCode
    }
    unitPrice {
      amount
      currencyCode
    }
    unitPriceMeasurement {
      measuredType
      quantityUnit
      quantityValue
      referenceUnit
      referenceValue
    }
    weight
    available: availableForSale
    sku
    compareAtPrice {
      amount
      currencyCode
    }
    image {
      id
      src: originalSrc
      altText
      width
      height
    }
    selectedOptions {
      name
      value
    }
  }
`;
export const variantOnSale = (variant) => {
  if (!variant?.compareAtPrice) return false;
  return +variant?.price?.amount < +variant?.compareAtPrice?.amount;
};
