<template>
  <component :is="tag" v-html="processedText" />
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    tag: {
      type: String,
      default: 'div',
    },
  },
  computed: {
    processedText() {
      if (process.client) {
        const EDITOR_CLASS_BG = 'add-background';
        const EDITOR_CLASS_HIGHLIGHT = 'add-highlight';
        const CLASS_BG = 'text__bg';
        const CLASS_HIGHLIGHT = 'text__bg--highlight';

        const el = document.createElement(this.tag);
        el.innerHTML = this.text;
        const bgElems = el.querySelectorAll('.' + EDITOR_CLASS_BG);

        for (const bgElem of bgElems) {
          const computedNodes = [];

          bgElem.childNodes.forEach((node) => {
            if (node.outerHTML === '<br>') {
              computedNodes.push(node.outerHTML);
            } else {
              const classes =
                !node.data && node.classList.contains(EDITOR_CLASS_HIGHLIGHT)
                  ? `${EDITOR_CLASS_HIGHLIGHT} ${CLASS_BG} ${CLASS_HIGHLIGHT}`
                  : CLASS_BG;
              const newNode = node.textContent
                .split(' ')
                .filter((word) => {
                  return word !== '' && word !== ' ' && word !== '\n';
                })
                .map((word) => {
                  return `<span class="${classes}">${word} </span>`;
                })
                .join(' ');
              computedNodes.push(newNode);
            }
          });

          bgElem.innerHTML = computedNodes.join(' ');
        }

        const highlightElems = el.querySelectorAll('.' + EDITOR_CLASS_HIGHLIGHT);
        highlightElems.forEach((e) => e.classList.add(CLASS_HIGHLIGHT));

        return el.innerHTML;
      } else {
        return this.text;
      }
    },
  },
};
</script>
