<template>
  <div class="group content-start gap-5 leading-6">
    <div class="relative overflow-hidden">
      <FixedAspect v-if="image && image.length" :ratio="1.26">
        <CldnImg
          :src="image[0].url"
          :sizes="'(min-width: 1024px) 25vw, 100vw'"
          :ratio="1.26"
          crop="fill"
          class="max-w-none block w-full group-hover:scale-110 transition-transform duration-700 transform"
          :alt="image[0].title"
        />
      </FixedAspect>
      <div
        class="overlay bg-blackly group-hover:opacity-25 absolute inset-0 transition-opacity duration-700 opacity-0 pointer-events-none"
      ></div>
    </div>
    <h3 v-if="caption" class="fluid-lg leading-tight font-medium my-4" data-test-id="caption" v-html="caption"></h3>
  </div>
</template>

<script>
import CldnImg from '@/components/CldnImg'
import FixedAspect from '@/components/common/FixedAspect'
export default {
  name: 'ImageWithCaption',
  components: {
    CldnImg,
    FixedAspect
  },
  props: {
    image: {
      type: Array,
      default: () => []
    },
    caption: {
      type: String,
      default: null
    }
  }
}
</script>
