<template>
  <div class="relative h-full">
    <button
      class="focus:outline-none relative flex items-center justify-center h-full p-2 transition-all"
      @click="onToggle(popoverType)"
    >
      <slot :is-active="isOpen" />
      <Indicator
        v-if="isOpen"
        class="text-br-green-light absolute z-10 pointer-events-none"
        :class="{ '-top-0 rotate-180 transform': displayAboveToggle, '-bottom-px': !displayAboveToggle }"
      />
      <!-- save from purge <div class="-top-px -bottom-px" /> -->
    </button>
    <MiniNav
      v-if="isOpen"
      v-on-click-outside="() => onClose()"
      class="absolute right-0 shadow-lg"
      :nav-type="popoverType"
      :class="{ 'bottom-full -mb-2': displayAboveToggle, 'top-full -mt-2': !displayAboveToggle }"
    />
    <!-- save from purge <div class="bottom-full top-full" /> -->
  </div>
</template>
<script>
import { mixin as onClickOutside } from 'vue-on-click-outside'
import Indicator from '~/components/layout/header/Indicator'
import MiniNav from '~/components/layout/header/MiniNav'

export default {
  components: {
    Indicator,
    MiniNav
  },
  mixins: [onClickOutside],
  props: {
    popoverType: {
      type: String,
      default: null
    },
    onToggle: {
      type: Function,
      default: () => null
    },
    onClose: {
      type: Function,
      default: () => null
    },
    displayAboveToggle: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isOpen() {
      return this.$parent.currentlyOpenPopover === this.popoverType
    }
  }
}
</script>
