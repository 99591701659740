<template>
  <div
    class="flex items-center justify-center fluid-base leading-relaxed font-light font-normal text-center h-screen p-4 w-full absolute top-0 left-0 pointer-events-none"
  >
    <div class="w-56">
      {{ $t('cart.emptyCart') }}
    </div>
  </div>
</template>

<script>
export default {}
</script>
