var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative"},[_c('CardSlider',{ref:"slider",staticClass:"shadow-md overflow-x-hidden",attrs:{"breakpoints":_vm.breakpoints,"show-arrows":false,"fullwidth-slides":true,"is-container":false,"auto-height":"","no-padding":""}},_vm._l((_vm.deals),function(deal){return _c('div',{key:'deal-' + deal.id},[_c('CartDealBundle',_vm._b({},'CartDealBundle',{
          discountPerProduct: deal.discountPerProduct,
          price: deal.focusProduct.variants[0].price,
          image: deal.focusProduct.images[0].src,
          title: deal.focusProduct.title,
          description: deal.focusProduct.productCardUsp,
          deal,
        },false))],1)}),0),_vm._v(" "),(_vm.deals.length >= 2)?_c('div',{staticClass:"relative mt-3 flex justify-between items-center"},[_c('div',{staticClass:"w-8"},[_c('ArrowButton',{attrs:{"direction":"left","arrow-disabled":_vm.isFirst},on:{"click":_vm.prevSlide}})],1),_vm._v(" "),_c('div',{staticClass:"mx-auto w-full flex justify-center space-x-1"},_vm._l((_vm.deals),function(deal,index){return _c('button',{key:deal.id,staticClass:"h-1 bg-gray-200 w-full max-w-[46px] focus:outline-none relative",attrs:{"data-test-id":"slide-button"},on:{"click":function($event){return _vm.changeSlide(index)}}},[(index === _vm.current)?_c('span',{staticClass:"h-full block bg-black",class:{
            'w-full': index === _vm.current,
          }}):_vm._e()])}),0),_vm._v(" "),_c('div',{staticClass:"w-8"},[_c('ArrowButton',{attrs:{"direction":"right","arrow-disabled":_vm.isLast},on:{"click":_vm.nextSlide}})],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }