<template>
  <div v-if="notification.active" class="container bg-br-green-light text-center p-5 mb-10">
    <h3 class="fluid-base leading-tight mb-5">{{ notification.heading }}</h3>
    <div class="text-copy" v-html="notification.description"></div>
    <slot />
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { mapState } from 'vuex'

export const AccountNotificationFragment = gql`
  fragment AccountNotificationFragment on account_GlobalSet {
    heading
    onOffSwitch
    description
  }
`
export default {
  name: 'AccountNotifications',
  computed: {
    ...mapState('account', ['notification'])
  }
}
</script>
