<template>
  <div>
    <transition name="drawer-slide" appear>
      <div v-if="isOpen" class="fixed inset-0 z-50">
        <div class="backdrop absolute inset-0" @click="close" />
        <div
          class="cart-drawer md:max-w-[500px] lg:w-1/2 absolute top-0 bottom-0 right-0 flex flex-col h-full overflow-y-auto bg-white"
        >
          <div
            class="h-header-bar-height-mobile lg:h-header-bar-height flex items-center justify-between flex-shrink-0 w-full border-b"
          >
            <div class="lg:pl-6 px-4">
              <h2 class="fluid-base leading-relaxed font-medium">{{ $t('cart.title') }} {{ totalQuantity }}</h2>
            </div>
            <button
              type="button"
              class="text-blackly lg:px-6 flex items-center justify-center h-full px-4 transition-all"
              @click.prevent="close"
            >
              <span class="sr-only">{{ $t('cart.closeDrawer') }}</span>
              <IconClose class="w-4 h-4" />
            </button>
          </div>
          <Cart class="grow" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { mixin as onClickOutside } from 'vue-on-click-outside';
import Cart from '@/components/cart/Cart';
import IconClose from '@/components/layout/header/IconClose';

export default {
  components: {
    IconClose,
    Cart,
  },

  mixins: [onClickOutside],

  computed: {
    ...mapState('cart', ['isOpen']),
    ...mapGetters('cart', ['cartCount']),
    totalQuantity() {
      return this.cartCount > 0 ? `(${this.cartCount})` : null;
    },
  },

  watch: {
    isOpen(newValue) {
      document.body.classList.toggle('overflow-hidden', newValue);
    },
  },

  async mounted() {
    await this.$store.dispatch('cart/initCart');

    // FIXME: debug cart functions
    // await this.$store.dispatch('cart/refreshCheckout');
    // await this.$store.dispatch('cart/applyDiscount', 'HOLGER_ENROLLMENT');
    // await this.$store.dispatch('cart/createOrUpdateCartAttributes', [{ key: 'test', value: 'test' }]);
  },

  methods: {
    ...mapActions('cart', ['open', 'close']),
  },
};
</script>

<style scoped>
.drawer-slide-enter-active,
.drawer-slide-leave-active {
  transition: all 300ms linear;
}

.cart-drawer {
  transition: transform 300ms ease;
  width: calc(100% - 3.5em);
}

.drawer-slide-enter .backdrop,
.drawer-slide-leave-to .backdrop {
  @apply opacity-0;
}

.drawer-slide-enter .cart-drawer,
.drawer-slide-leave-to .cart-drawer {
  @apply translate-x-full;
}
.backdrop {
  @apply transition-opacity delay-300 z-[-1];
  background-color: rgba(0, 0, 0, 0.75);
}
</style>
