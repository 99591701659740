var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-compare-card flex flex-row flex-wrap content-between text-center"},[_c('div',{staticClass:"w-full"},[_c('h3',{staticClass:"font-medium fluid-lg font-light leading-normal center h-12 overflow-hidden"},[_vm._v(_vm._s(_vm.product.title))]),_vm._v(" "),(_vm.variants.length)?_c('FixedAspect',{attrs:{"ratio":0.55}},[(_vm.selectedVariant)?_c('ProductCardVariantImg',{attrs:{"product-variant":_vm.selectedVariant,"product-route":_vm.productRoute}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"attribute-col w-full divide-y h-full flex flex-col"},[_vm._l((_vm.attributeList),function(attribute,idx){return _c('div',{key:idx,style:({ height: attribute.height })},[_c('div',{ref:"attributeRow",refInFor:true,staticClass:"attribute-row leading-5 flex flex-col md:py-3"},[_c('span',{staticClass:"md:hidden uppercase text-gray-400 fluid-xs leading-tightest pt-2"},[_vm._v(_vm._s(attribute.title))]),_vm._v(" "),_c('span',{staticClass:"fluid-xs leading-tightest font-medium"},[_vm._v(_vm._s(attribute.html))])])])}),_vm._v(" "),_c('div',{staticClass:"product-variants w-full"},[(_vm.variants.length)?_c('ProductOptions',_vm._b({staticClass:"justify-center w-full py-2 md:pt-4",attrs:{"swatch-img-class":"w-4 h-4"},on:{"clickOption":_vm.setOption}},'ProductOptions',{
            variants: _vm.variants,
            swatches: _vm.optionSwatches,
            maxSwatches: 5,
            selectedVariant: _vm.selectedVariant,
            option: _vm.product.options[0]
          },false)):_vm._e()],1)],2)],1),_vm._v(" "),_c('div',{staticClass:"w-full mb-3 mt-5"},[_c('nuxt-link',{staticClass:"btn-pill-outline-black min-w-0",attrs:{"to":_vm.productRoute}},[_vm._v(_vm._s(_vm.$t('shop.viewProduct')))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }