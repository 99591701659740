<template>
  <div class="md:px-0 items-stretch relative flex flex-row w-full">
    <FixedAspect :ratio="0.8" :h-full="true" class="w-3/12 md:w-1/4 block">
      <CldnImg
        v-if="image[0]"
        :src="image[0].url ? image[0].url : image"
        :alt="heading"
        :ratio="0.8"
        sizes="85px"
        crop="fill"
        h-full
        w-full
      />
    </FixedAspect>
    <div class="flex flex-col justify-center bg-blackly text-white w-9/12 py-2.5 pb-3 px-3 lg:w-3/4">
      <div class="block fluid-xs leading-tightest font-light leading-tight">{{ heading }}</div>
    </div>
  </div>
</template>

<script>
import CldnImg from '@/components/CldnImg'
import FixedAspect from '@/components/common/FixedAspect'

export default {
  name: 'SmallAddOnTeaser',
  components: {
    CldnImg,
    FixedAspect
  },
  props: {
    heading: {
      type: String,
      default: ''
    },
    image: {
      type: Array,
      default: () => []
    }
  }
}
</script>
