<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="none" fill-rule="evenodd">
      <g fill="currentColor" fill-rule="nonzero">
        <path
          d="M18 16.022L10.672 8.707 17.401 1.978 15.437 0 8.707 6.729 1.978 0 0 1.978 6.729 8.707 0 15.437 1.978 17.401 8.707 10.672 16.022 18z"
          transform="translate(-1387.000000, -29.000000) translate(1387.000000, 29.000000)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {}
</script>
