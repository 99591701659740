<template>
  <ul>
    <li>
      <button
        class="flex items-center py-4 fluid-sm tracking-wider uppercase font-medium border-b border-br-green w-full px-6 relative"
        @click="closeMenu"
      >
        <div class="absolute left-0 inset-y-0 flex items-center">
          <AngleLeftIcon class="h-5 fill-current inline-block" />
        </div>
        {{ navigationTitle }}
      </button>
    </li>
    <li v-if="navItem.link">
      <MobileNavLink v-bind="{ link: navItem.link }" class="pl-6" />
    </li>

    <template v-if="childElements">
      <li v-for="(item, indexA) in childElements" :key="indexA">
        <div v-if="item.links || item.link">
          <MobileNavButton
            v-if="item.links && item.links.length > 1"
            v-bind="{ ...item.links[0] }"
            :on-click="() => (openIndex === indexA ? (openIndex = -1) : (openIndex = indexA))"
            :is-open="openIndex !== indexA"
            :is-new="item.links[0].new"
          />

          <MobileNavLink
            v-else
            v-bind="item.link ? item : item.links ? item.links[0] : {}"
            :is-new="item.link ? item.link.new : item.links ? item.links[0].new : false"
            class="pl-6"
          />

          <transition
            enter-class="translate-x-full opacity-0"
            enter-to-class="translate-x-0 opacity-100"
            enter-active-class="transition duration-200 ease-out"
            leave-class="translate-x-0 opacity-100"
            leave-to-class="translate-x-full opacity-0"
            leave-active-class="transition duration-200 ease-out"
          >
            <ChildNavigation
              v-if="openIndex === indexA"
              class="fixed w-80 left-0 right-0 bottom-0 top-12 z-10 bg-white"
              v-bind="{ navItem: item }"
              @closeMenu="openIndex = -1"
            />
          </transition>
        </div>
      </li>
    </template>
  </ul>
</template>

<script>
import { mapActions } from 'vuex'
import MobileNavLink from '~/components/layout/header/mobileDrawer/MobileNavLink'
import MobileNavButton from '~/components/layout/header/mobileDrawer/MobileNavButton'
import AngleLeftIcon from '~/components/icons/AngleLeftIcon.vue'

export default {
  name: 'ChildNavigation',

  components: { MobileNavLink, AngleLeftIcon, MobileNavButton },
  props: {
    navItem: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      openIndex: -1
    }
  },

  computed: {
    firstLink() {
      return this.navItem.link ?? this.navItem.links[0]
    },

    navigationTitle() {
      return this.navItem.title ?? this.firstLink.mobileTitle
    },

    childElements() {
      if (this.navItem.navBlocks && this.navItem.navBlocks.length > 0) {
        return this.navItem.navBlocks
      }

      if (this.navItem.links && this.navItem.links.length > 0) {
        return this.navItem.links
      }

      return []
    }
  },
  methods: {
    ...mapActions('mainNav', ['close']),

    closeMenu() {
      this.$emit('closeMenu')
    }
  }
}
</script>
