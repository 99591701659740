<template>
  <div class="copy-text-wrapper container relative max-w-screen-md my-20 mt-4">
    <div class="text-copy fluid-lg font-light leading-normal break-words" v-html="body"></div>
    <div v-if="relatedEntry" class="related">
      <div class="flex flex-row items-center justify-between">
        <div class="fluid-sm leading-tightest py-4 mr-auto font-medium">{{ $t('copyTextrelated') }}</div>
        <nuxt-link :to="{ path: $localeRootPath(relatedEntry.uri) }" class="hover:text-br-green block">
          <ArrowIcon class="transform rotate-180 h-2.5" />
        </nuxt-link>
      </div>
      <nuxt-link :to="{ path: $localeRootPath(relatedEntry.uri) }" class="block">
        <FixedAspect :ratio="2 / 3">
          <CldnImg
            v-if="relatedEntryImage"
            :src="relatedEntryImage.url"
            :alt="relatedEntryImage.title"
            width="700"
            height="466"
            crop="fill"
          />
        </FixedAspect>
      </nuxt-link>
      <div class="fluid-sm leading-tightest mt-4 font-medium">{{ relatedCaption }}</div>
    </div>
  </div>
</template>

<script>
import CldnImg from '@/components/CldnImg';
import FixedAspect from '@/components/common/FixedAspect';
import ArrowIcon from '@/components/icons/ArrowIcon';

export default {
  components: {
    CldnImg,
    FixedAspect,
    ArrowIcon,
  },
  props: {
    body: {
      type: String,
      default: '',
    },
    relatedCaption: {
      type: String,
      default: '',
    },
    related: {
      type: Array,
      default: () => [],
    },
    relatedImage: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    relatedEntry() {
      return this.related ? this.related[0] : null;
    },

    relatedEntryImage() {
      if (this.relatedImage && this.relatedImage.length) {
        return this.relatedImage[0];
      }

      return this.relatedEntry && this.relatedEntry.image && this.relatedEntry.image[0]
        ? this.relatedEntry.image[0]
        : null;
    },
  },
};
</script>
<style scoped>
.copy-text-wrapper {
  @apply my-4;
}

.related {
  @apply lg:absolute lg:bottom-0;
}
@screen lg {
  .related {
    margin-left: 100%;
    width: 11vw;
  }
}
@screen xl {
  .related {
    width: 15vw;
  }
}

.copy-text-wrapper >>> blockquote {
  @apply lg:-ml-20;
}

@screen lg {
  .copy-text-wrapper >>> blockquote {
    font-size: calc(3.125rem - 10px);
  }
}
</style>
