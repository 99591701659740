<template>
  <ul v-if="navTops && navTops.length > 0" class="flex-grow relative">
    <li v-for="(navItem, indexA) in navTops" :key="indexA">
      <div>
        <button
          class="flex p-4 border-b fluid-xs leading-snug uppercase font-medium w-full justify-between focus:outline-none items-center transition-all"
          :class="{ 'border-br-green': openIndex === indexA }"
          @click="onButtonClick(indexA)"
        >
          {{ navItem.title }}
          <IconChevron class="transform transition-all" :class="{ '-rotate-90': openIndex !== indexA }" />
        </button>
      </div>

      <transition
        enter-class="translate-x-full opacity-0"
        enter-to-class="translate-x-0 opacity-100"
        enter-active-class="transition duration-200 ease-in-out"
        leave-class="translate-x-0 opacity-100"
        leave-to-class="translate-x-full opacity-0"
        leave-active-class="transition duration-200 ease-in-out"
      >
        <ChildNavigation
          v-if="openIndex === indexA"
          class="fixed w-80 left-0 right-0 bottom-0 top-12 z-10 bg-white overflow-y-auto"
          v-bind="{ navItem }"
          @closeMenu="openIndex = -1"
        />
      </transition>
    </li>
  </ul>
</template>

<script>
import { mapActions } from 'vuex'
import IconChevron from '@/components/layout/header/IconChevron'
import ChildNavigation from '@/components/layout/header/mobileDrawer/ChildNavigation'

export default {
  components: { ChildNavigation, IconChevron },
  props: {
    title: {
      type: String,
      default: ''
    },
    navTops: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      openIndex: null
    }
  },
  computed: {},
  methods: {
    ...mapActions('mainNav', ['close']),
    onButtonClick(index) {
      this.$emit('onClick')
      if (this.openIndex === index) {
        this.collapseOpenNav()
      } else {
        this.openIndex = index
      }
    },
    collapseOpenNav() {
      this.openIndex = null
    }
  }
}
</script>
