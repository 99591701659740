var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"matrix-block md:px-0 max-w-expanded-md sm:flex-row-reverse container flex flex-col items-stretch relative"},[(_vm.image[0])?_c('FixedAspect',{staticClass:"sm:hidden",attrs:{"ratio":2 / 3}},[_c('CldnImg',{attrs:{"src":_vm.image[0].url,"alt":_vm.image[0].title,"ratio":2 / 3,"sizes":"100vw","crop":"fill"}})],1):_vm._e(),_vm._v(" "),_c('FixedAspect',{staticClass:"sm:block sm:w-1/2 relative hidden h-full",attrs:{"ratio":1.0}},[(_vm.image[0])?_c('CldnImg',{attrs:{"src":_vm.image[0].url,"alt":_vm.image[0].title,"ratio":1.0,"sizes":"(min-width: 1440px) 432px, 50vw","crop":"fill"}}):_vm._e()],1),_vm._v(" "),(_vm.price)?_c('div',{staticClass:"absolute rounded-full bg-black text-white top-4 right-4 overflow-hidden flex flex-col items-center h-20 w-20 md:h-32 md:w-32"},[_c('span',{staticClass:"text-center items-center h-full w-full flex justify-center",class:_vm.priceHasDecimal ? 'fluid-lg font-light leading-normal' : 'fluid-xl leading-tight font-medium'},[_vm._v(_vm._s(_vm.$n(_vm.price, {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: _vm.priceHasDecimal ? 2 : 0,
        })))]),_vm._v(" "),_c('span',{staticClass:"fluid-xs leading-tightest line-through absolute",class:_vm.priceHasDecimal ? 'bottom-4 md:bottom-7' : 'bottom-4 md:bottom-5',attrs:{"data-test-id":"price"}},[_vm._v(_vm._s(_vm.$n(_vm.compareAtPrice, {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: _vm.compareAtPriceHasDecimal ? 2 : 0,
        })))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"box sm:w-1/2 flex flex-col items-baseline justify-center fluid-xl leading-tight font-medium"},[_c('div',{staticClass:"block"},[_vm._v(_vm._s(_vm.heading))]),_vm._v(" "),_c('LinkField',_vm._b({staticClass:"btn-pill-black inline-block mt-4",attrs:{"tracking-name":_vm.heading}},'LinkField',_vm.cta,false))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }