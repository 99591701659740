import Client from 'shopify-buy';

export default (ctx, inject) => {
  let locale = ctx.app.i18n.locale;
  const {
    app: {
      i18n: { locales },
    },
  } = ctx;
  const { shopifyApiAccessToken, shopifyApiDomain } = locales.find((l) => l.code === locale);

  const map = {
    'ch-fr': 'fr',
    'ch-de': 'de',
  };

  if (map[locale]) {
    locale = map[locale];
  }

  const config = {
    domain: shopifyApiDomain,
    storefrontAccessToken: shopifyApiAccessToken,
    language: locale,
  };
  inject('shopifyBuyCLient', () => Client.buildClient(config));
};
