<template>
  <div v-if="logisticPartners">
    <div class="fluid-xs leading-snug uppercase font-medium mb-4">{{ $t('navigation.logisticsPartners') }}</div>
    <InlineIconsGrid>
      <div v-for="(partner, idx) in logisticPartners.logisticPartners" :key="idx" class="w-12">
        <DhlIcon v-if="partner === 'dhl'" class="rounded overflow-hidden" />
        <DpdIcon v-if="partner === 'dpd'" class="rounded overflow-hidden" />
        <UpsIcon v-if="partner === 'ups'" class="rounded overflow-hidden" />
        <PostatIcon v-if="partner === 'postat'" class="rounded overflow-hidden" />
        <GlsIcon v-if="partner === 'gls'" class="rounded overflow-hidden" />
        <PostChIcon v-if="partner === 'post-ch'" class="rounded overflow-hidden" />
      </div>
    </InlineIconsGrid>
  </div>
</template>

<script>
import gql from 'graphql-tag';
import DhlIcon from '@/components/icons/DhlIcon';
import DpdIcon from '@/components/icons/DpdIcon';
import UpsIcon from '@/components/icons/UpsIcon';
import GlsIcon from '@/components/icons/GlsIcon';
import PostatIcon from '@/components/icons/PostatIcon';
import PostChIcon from '@/components/icons/PostChIcon';
import InlineIconsGrid from '@/components/common/InlineIconsGrid';

export const LogisticPartnersFragment = gql`
  fragment LogisticPartnersFragment on logisticPartners_GlobalSet {
    logisticPartners
  }
`;

export default {
  components: { InlineIconsGrid, PostatIcon, PostChIcon, UpsIcon, DpdIcon, DhlIcon, GlsIcon },
  props: {
    logisticPartners: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
