<template>
  <div class="relative">
    <div :style="{ paddingTop }" class="fixed-aspect-spanner relative" :class="hFull ? 'h-full' : ''">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ratio: {
      type: Number,
      default: 1
    },
    hFull: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    paddingTop() {
      return `${this.ratio * 100}%`
    }
  }
}
</script>
<style>
.fixed-aspect-spanner > * {
  @apply absolute inset-0 z-0 object-cover w-full h-full max-w-none;
}
</style>
