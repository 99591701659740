<template>
  <div class="relative w-full">
    <button
      class="w-20 h-20 absolute right-10 -top-10 z-20 bg-br-green-light rounded-full flex items-center justify-center shadow-lg"
      @click="modalOpened = true"
    >
      <AddImageIcon class="cursor-pointer h-11" />
    </button>
    <Modal :showing="modalOpened" hidden-prerender :min-width="true" @close="modalOpened = false">
      <div class="md:w-full">
        <FormLink :form-id="formId" />
      </div>
    </Modal>
  </div>
</template>

<script>
import FormLink from '@/components/common/blocks/FormLink';
import Modal from '@/components/common/Modal';
import AddImageIcon from '@/components/icons/AddImageIcon';

export default {
  name: 'FormInModal',
  components: { Modal, FormLink, AddImageIcon },
  props: {
    formId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      modalOpened: false,
    };
  },
};
</script>
