<template>
  <div class="relative flex justify-end fluid-xs leading-tightest font-medium" :class="[active ? 'text-br-green' : '']">
    <div class="max-w-[130px] z-10">
      <span class="block text-center mb-1">
        <DeliveryIcon v-if="type === 'delivery'" class="h-3 inline-block" />
        <GiftIcon v-else class="h-3 inline-block" />
      </span>
      <span class="block text-center uppercase">{{ label }}</span>
      <span v-if="!active && amountToAdd > 0" class="block text-center">{{
        $t('cart.amountRemaining', {
          amount: $n(amountToAdd, {
            style: 'currency',
            currency: 'EUR'
          })
        })
      }}</span>
      <div
        class="block mx-auto w-5 h-5 leading-5 text-sm text-center rounded-full"
        :class="[active ? 'bg-br-green text-white font-bold' : 'bg-putty']"
      >
        <span v-show="active">&check;</span>
      </div>
    </div>

    <div class="bg-putty absolute bottom-2 left-0 right-[50px]">
      <div
        class="bg-br-green h-1"
        :class="[active ? 'w-full' : 'w-0']"
        :style="`width: ${active ? 100 : progress}%`"
      ></div>
      <div :class="[active ? 'bg-br-green' : 'bg-putty']" class="w-[50px] absolute inset-y-0 -right-[50px]"></div>
    </div>
  </div>
</template>

<script>
import DeliveryIcon from '~/components/icons/DeliveryIcon'
import GiftIcon from '~/components/icons/GiftIcon'

export default {
  name: 'CartStep',

  components: {
    DeliveryIcon,
    GiftIcon
  },

  props: {
    label: {
      type: String,
      required: true
    },

    startingPoint: {
      type: Number,
      default: 0
    },

    threshold: {
      type: Number,
      default: null,
      required: true
    },

    amountInCart: {
      type: Number,
      default: null,
      required: true
    },

    type: {
      type: String,
      default: 'delivery',
      validator: (value) => ['delivery', 'gift'].includes(value)
    }
  },

  computed: {
    active() {
      return this.amountInCart >= this.threshold
    },

    amountToAdd() {
      return this.threshold - this.amountInCart
    },

    progress() {
      if (this.amountInCart < this.startingPoint) {
        return 0
      }

      return Math.round(((this.amountInCart - this.startingPoint) / (this.threshold - this.startingPoint)) * 100)
    }
  }
}
</script>
