import get from 'lodash/get';
import { mapGetters } from 'vuex';
import { productMetaFieldTransformer } from '@/components/shop/products/Product';
import { addVariantToCart } from '@/utils/product';

export default {
  name: 'ProductProvider',

  props: {
    handle: {
      type: String,
      required: true,
    },
  },

  data() {
    return { product: null, error: null, tsProduct: null };
  },

  async fetch() {
    // in some cases the handle passed can be filled with 'null', so skip this request
    if (this.handle && this.handle === 'null') {
      return;
    }

    try {
      const productRequest = await this.$shopifyProductByHandle(this.handle);

      this.product = productMetaFieldTransformer(productRequest);
    } catch (error) {
      this.error = error;
    }

    try {
      await this.fetchReviews();
    } catch (error) {
      this.error = error;
    }
  },

  computed: {
    firstVariant() {
      return this.product?.variants?.edges[0]?.node;
    },
    addToCartEnabled() {
      return this.product?.availableForSale;
    },
    addToCartDisabled() {
      return !this.addToCartEnabled;
    },
    price() {
      return this.firstVariant?.price || null;
    },
    compareAtPrice() {
      return this.firstVariant?.compareAtPrice || null;
    },
    priceRangeMin() {
      return get(this.product, 'priceRange.minVariantPrice.amount', null);
    },
    priceRangeMax() {
      return get(this.product, 'priceRange.maxVariantPrice.amount', null);
    },
    title() {
      return this.product?.title;
    },
    isCourse() {
      return this.product?.digitalProductType?.digitalProductType === 'Online Course';
    },
    ...mapGetters('cart', ['lineItems']),
  },

  methods: {
    async fetchReviews() {
      const sku =
        this.product?.variants?.edges && this.product?.variants?.edges[0]
          ? this.product?.variants?.edges[0].node.sku
          : null;
      if (sku) {
        this.tsProduct = await this.$productReviews.fetchReviews(sku);
      }
    },
    addToCart() {
      if (!this.addToCartEnabled) return;

      addVariantToCart.call(this, this.product, this.firstVariant, this.isCourse, 1);
    },
  },

  render(h) {
    const slots = this.$scopedSlots.default({
      product: this.product,
      error: this.error,
      addToCartEnabled: this.addToCartEnabled,
      addToCartDisabled: this.addToCartDisabled,
      addToCart: this.addToCart,
      firstVariant: this.firstVariant,
      price: this.price,
      compareAtPrice: this.compareAtPrice,
      priceRangeMin: this.priceRangeMin,
      priceRangeMax: this.priceRangeMax,
      title: this.title,
      tsProduct: this.tsProduct,
      isCourse: this.isCourse,
    });

    return h('div', {}, slots);
  },
};
