<template>
  <figure class="md:sticky group relative overflow-hidden md:top-[7.5rem]">
    <nuxt-link v-if="story.uri" :to="{ path: $localeRootPath(story.uri) }" tabindex="-1">
      <CldnImg
        :src="story.previewImage[0].url"
        :alt="story.previewImage[0].title"
        sizes="(min-width: 1440px) 628px,(min-width: 1024px) 50vw, 100vw"
        class="max-w-none group-hover:scale-110 block w-full transition-transform duration-700 transform"
      />
    </nuxt-link>
    <figcaption class="absolute bottom-0 z-10 p-8">
      <div v-if="story.storiesCategories">
        <template v-for="category in story.storiesCategories">
          <TagBtn :key="category.title" :to="$localeRootPath(category.uri)" class="lg:mt-4 inline-block mb-3">{{
            category.title
          }}</TagBtn>
        </template>
      </div>
      <nuxt-link v-if="story.uri" :to="{ path: $localeRootPath(story.uri) }">
        <BarText :text="storyTitle" tag="h2" class="fluid-4xl leading-none" />
      </nuxt-link>
    </figcaption>
    <div
      class="overlay bg-blackly group-hover:opacity-25 absolute inset-0 transition-opacity duration-700 opacity-0 pointer-events-none"
    ></div>
  </figure>
</template>

<script>
/* eslint-disable no-unused-vars */

import CldnImg from '@/components/CldnImg'
import TagBtn from '@/components/common/TagBtn'
import BarText from '@/components/BarText.vue'

export default {
  components: {
    CldnImg,
    TagBtn,
    BarText
  },
  props: {
    story: {
      type: Object,
      default: () => {}
    },
    storyTitle: {
      type: String,
      default: ''
    }
  }
}
</script>
