<template>
  <div
    v-if="unitPrice"
    class="mt-1 fluid-xs leading-tightest text-gray-light"
    :class="{ 'fluid-xs leading-tightest': size === 'md', 'text-[10px]': size === 'xs' }"
  >
    <span v-if="showQuantity">
      {{ unitPriceMeasurement.quantityValue }}
      {{ unitPriceMeasurement.quantityUnit.toLowerCase() }}
    </span>
    <span
      v-if="showMeasurement"
      class="before:content-['('] after:content-[')']"
      :class="{ 'ml-1': showQuantity, 'before:content-none after:content-none': !showQuantity }"
    >
      {{
        $n(unitPrice.amount, {
          style: 'currency',
          currency: unitPrice.currencyCode
        })
      }}
      / {{ unitPriceMeasurement.referenceUnit.toLowerCase() }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    unitPrice: {
      type: Object,
      default: () => {}
    },
    unitPriceMeasurement: {
      type: Object,
      default: () => {}
    },
    showQuantity: {
      type: Boolean,
      default: false
    },
    showMeasurement: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'md'
    }
  }
}
</script>
