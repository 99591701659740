import { transformSiteVariable } from '@/plugins/craftClient';

export const state = () => ({
  isOpen: false,
  searchQuery: '',
  indexName: '',
  isHidden: false,
});

export const actions = {
  open({ commit }) {
    commit('SET_OPEN', true);
  },
  close({ commit }) {
    commit('SET_OPEN', false);
  },

  hide({ commit }) {
    commit('SET_HIDDEN', true);
  },

  unhide({ commit }) {
    commit('SET_HIDDEN', false);
  },

  setQuery({ commit }, query) {
    commit('SET_QUERY', query);
  },
  setIndexName({ commit }) {
    const siteVariable = transformSiteVariable({ site: this.$i18n.locale }).site;

    commit('SET_INDEX_NAME', 'page_' + siteVariable);
  },
};

export const mutations = {
  SET_HIDDEN(state, isHidden) {
    state.isHidden = isHidden;
  },
  SET_OPEN(state, isOpen) {
    state.isOpen = isOpen;
  },
  SET_QUERY(state, query) {
    state.searchQuery = query;
  },
  SET_INDEX_NAME(state, name) {
    state.indexName = name;
  },
};

export const getters = {
  isHidden(state) {
    return state.isHidden;
  },
  isOpen(state) {
    return state.isOpen;
  },
  searchQuery(state) {
    return state.searchQuery;
  },
  indexName(state) {
    return state.indexName;
  },
};
