import { GraphQLClient } from 'graphql-request';
import { print, concatAST, visit } from 'graphql';

import { EntryReferenceFragment } from '@/components/common/EntryReferenceFragment.gql';
import { LinkFragment } from '@/components/common/LinkFragment.gql';
import { ArticlePreviewFragment } from '@/components/common/ArticlePreviewFragment.gql';
import { StoryPreviewFragment } from '@/components/common/StoryPreviewFragment.gql';
import { RoutinePreviewFragment } from '@/components/common/RoutinePreviewFragment.gql';
import { CraftAssetFragment } from '@/components/common/CraftAssetFragment.gql';

// transform site variable to match craft cms site handle
export const transformSiteVariable = function (variables) {
  if (!variables) {
    return {};
  }

  const map = {
    'ch-fr': 'chFr',
    'ch-de': 'ch',
  };

  if (variables.uri) {
    // check for each mapped locale if the uri contains language prefix
    for (const [key, value] of Object.entries(map)) {
      if (variables.uri.startsWith(`/${key}/`)) {
        variables.uri = variables.uri.replace(`/${key}/`, `/${value}/`);
        break;
      }
    }
  }

  if (variables.site && map[variables.site]) {
    variables.site = map[variables.site];
  }

  return variables;
};

export const sanitizeToken = (token) => {
  if (!token) return undefined;
  if (token.length === 0) return undefined;
  if (token.includes('?')) return undefined;
  return token;
};

/**
 * handles graphql requests to craft
 * handles bearer authentication
 * forwards url query preview token for craft-cms live editing preview
 */
export default function (ctx, inject) {
  const { env, route } = ctx;

  let url = env.cmsHttpEndpoint;

  const token = sanitizeToken(route.query.token);
  const preview = route.query['x-craft-live-preview'];
  const vercelNoCache = route.query._vercel_no_cache === '1';
  const options = {};

  if (token || preview || vercelNoCache) {
    url = env.cmsAdminEndpoint + '?token=' + token;
    options.headers = {
      Authorization: `Bearer ${ctx.env.cmsAuthToken}`,
    };
  }

  const client = new GraphQLClient(url, options);

  /* Add Fragments here that should be globally available in the Craft requests */
  const commonFragments = [
    ArticlePreviewFragment,
    CraftAssetFragment,
    EntryReferenceFragment,
    LinkFragment,
    RoutinePreviewFragment,
    StoryPreviewFragment,
  ];

  // sanitize gql to graphql-string
  const request = (query, variables) => {
    if (typeof query !== 'object' && query.kind !== 'Document') {
      throw new Error('Query must be a graphql query object');
    }

    const usedFragments = new Set();
    visit(query, {
      FragmentSpread(node) {
        usedFragments.add(node.name.value);
      },
    });

    const missingFragments = commonFragments.filter((fragment) =>
      usedFragments.has(fragment.definitions[0].name.value)
    );

    if (missingFragments.length) {
      query = concatAST([query, ...missingFragments]);
    }

    return client.request(print(query), transformSiteVariable(variables));
  };
  inject('craftRequest', request);
}
