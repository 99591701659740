<template>
  <div v-if="quantity > 0">
    <LineItemAddon v-if="isAddOn" v-bind="{ lineItem }" />

    <div v-else class="flex items-center pr-4 bg-white shadow-md">
      <div class="w-full max-w-[128px]">
        <LinkField v-if="href && variantAvailable" :to="href" @click.native="close">
          <FixedAspect :ratio="2 / 3">
            <CldnImg
              v-if="variant.image"
              :src="variant.image.src"
              :alt="variant.image.altText"
              :quality="90"
              crop="fit"
              :ratio="2 / 3"
              width="100"
            />
          </FixedAspect>
        </LinkField>
      </div>
      <div class="gap-x-2 gap-y-1 grid grid-cols-4 items-center py-2 w-full pl-2">
        <div class="col-span-3 grid grid-cols-3 gap-2">
          <div class="fluid-sm leading-tightest col-span-3 md:col-span-2">
            <LinkField
              v-if="href && lineItem.variant?.product?.title"
              :to="href"
              class="product-title overflow-hidden font-medium"
              @click.native="close"
            >
              {{ lineItem.variant?.product?.title }}
            </LinkField>
            <div v-else-if="!href && lineItem.variant?.product?.title" class="product-title font-medium">
              {{ lineItem.variant?.product?.title }}
            </div>
            <div v-if="!isCourse && variantAvailable && variantValue" class="text-gray-light mt-0.5">
              {{ variantValue }}
            </div>
            <div v-else-if="!variantAvailable" class="text-gray-light font-medium mt-0.5">
              {{ $t('cart.variantNotAvailable') }}
            </div>
            <div
              v-if="hasUnitPrice"
              class="sm:hidden before:content-['('] after:content-[')'] whitespace-nowrap fluid-xs leading-tightest text-gray-light mt-[2px]"
            >
              {{ unitPriceLine }}
            </div>
          </div>
          <div class="flex items-center fluid-xs leading-tightest col-span-3 md:col-span-1">
            <button
              data-testid="btn-decrement"
              class="bg-putty flex items-center justify-center w-6 h-6 md:w-5 md:h-5 fluid-xs leading-tightest font-medium transition-all rounded-full flex-shrink-0"
              @click="decrement"
            >
              -
            </button>
            <div data-testid="quantity" class="w-8 font-medium text-center">{{ quantity }}</div>
            <button
              data-testid="btn-increment"
              class="bg-putty flex items-center justify-center w-6 h-6 md:w-5 md:h-5 fluid-xs leading-tightest font-medium transition-all rounded-full flex-shrink-0"
              :class="[{ 'pointer-events-none opacity-50': maxQuantity > 0 && quantity >= maxQuantity }]"
              @click="increment"
            >
              +
            </button>
          </div>
        </div>
        <div class="text-right">
          <div class="fluid-xs leading-tightest font-medium">
            <span v-if="itemDiscountedPrice > 0">
              {{
                $n(itemDiscountedPrice, {
                  style: 'currency',
                  currency: price.currencyCode,
                })
              }}
            </span>
            <span v-else>{{ $t('For free') }}</span>
          </div>
          <div v-if="itemDiscountValue || compareAtPrice" class="text-gray-light fluid-xs leading-tightest mt-[2px]">
            <s v-if="itemDiscountValue">
              {{
                $n(itemDiscountValue, {
                  style: 'currency',
                  currency: price.currencyCode,
                })
              }}
            </s>
            <s v-else-if="compareAtPrice" class="fluid-xs leading-tightest text-gray-light">
              {{
                $n(compareAtPrice.amount, {
                  style: 'currency',
                  currency: compareAtPrice.currencyCode,
                })
              }}
            </s>
          </div>
          <div
            v-if="hasUnitPrice && itemDiscountedPrice > 0"
            class="hidden sm:block before:content-['('] after:content-[')'] whitespace-nowrap fluid-xs leading-tightest text-gray-light mt-[2px]"
          >
            {{ unitPriceLine }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import LinkField from '@/components/common/LinkField';
import FixedAspect from '@/components/common/FixedAspect';
import CldnImg from '@/components/CldnImg';
import LineItemAddon from '@/components/cart/LineItemAddon';

export default {
  components: { FixedAspect, LinkField, CldnImg, LineItemAddon },

  props: {
    lineItem: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      quantity: this.lineItem?.quantity || 1,
    };
  },

  computed: {
    isAddOn() {
      const IS_ADDON_KEY = '_isAddOn';

      return (
        this.lineItem?.attributes.findIndex((attr) => {
          return attr.key === IS_ADDON_KEY && attr.value === 'true';
        }) !== -1
      );
    },

    isCourse() {
      return (
        this.lineItem?.attributes.findIndex((attr) => {
          return attr.key === '_isCourse' && attr.value === 'true';
        }) !== -1
      );
    },

    variant() {
      return this.lineItem?.variant;
    },

    variantAvailable() {
      return !!this.variant;
    },

    variantValue() {
      return this.variant?.title;
    },

    price() {
      const price = this.variant.price;
      return {
        ...price,
        amount: price.amount * this.quantity,
      };
    },

    compareAtPrice() {
      const price = this.variant.compareAtPrice;
      if (!price) return null;
      return {
        ...price,
        amount: price.amount * this.quantity,
      };
    },

    hasUnitPrice() {
      return this.lineItem.variant.unitPrice !== null;
    },

    unitPriceLine() {
      if (!this.variant.unitPrice) return '';

      const price = this.$n(this.variant.unitPrice.amount, {
        style: 'currency',
        currency: this.variant.unitPrice.currencyCode,
      });

      return `${price} / ${this.variant.unitPriceMeasurement.referenceUnit.toLowerCase()}`;
    },

    href() {
      return this.$localeRootPath(
        this.variant?.product?.handle &&
          this.variant?.sku &&
          `/products/${this.variant.product.handle}?sku=${this.variant.sku}`
      );
    },

    itemDiscount() {
      return this.lineItem.discountAllocations
        .map((allocation) => +allocation.discountedAmount.amount)
        .reduce((a, b) => a + b, 0);
    },

    itemDiscountedPrice() {
      return this.price.amount - this.itemDiscount;
    },

    itemDiscountValue() {
      return this.itemDiscount ? this.price.amount : false;
    },

    maxQuantity() {
      const attribute = this.lineItem.attributes.find((attr) => {
        return attr.key === '_maxQuantity';
      });

      return attribute ? Number(attribute.value) : false;
    },
  },

  watch: {
    // since we pre update the quantity, before it is send to shopify api,
    // we need to update the line item quantity at some times here
    lineItem(newLineItem, oldValue) {
      if (newLineItem.quantity !== oldValue.quantity) {
        this.quantity = newLineItem.quantity;
      }
    },
  },

  methods: {
    ...mapActions('cart', ['close', 'changeQty']),

    increment() {
      this.changeQty({ id: this.lineItem.id, quantity: ++this.quantity });
    },

    decrement() {
      this.changeQty({ id: this.lineItem.id, quantity: --this.quantity });
    },
  },
};
</script>

<style scoped>
.product-title {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
