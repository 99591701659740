var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"items-center px-10 py-16 lg:text-center",class:[_vm.backgroundBlack ? 'bg-blackly text-white' : 'bg-putty', { 'container my-16': _vm.layout === 'contained' }]},[_c('div',{staticClass:"sm:container flex flex-col"},[_c('h3',{staticClass:"max-w-3xl mx-auto",class:{ 'text-br-green-light': _vm.backgroundBlack }},[_vm._v(_vm._s(_vm.heading))]),_vm._v(" "),_c('div',{staticClass:"text-copy my-4 max-w-3xl mx-auto",domProps:{"innerHTML":_vm._s(_vm.body)}}),_vm._v(" "),_c('div',{staticClass:"mx-auto w-full max-w-md mt-8"},[_c('FormSelectInput',{attrs:{"label":_vm.$t('insurance.placeholder'),"options":_vm.mappedCompanyList,"show-label":false},model:{value:(_vm.currentCompany),callback:function ($$v) {_vm.currentCompany=$$v},expression:"currentCompany"}})],1),_vm._v(" "),_c('div',{staticClass:"max-w-md w-full lg:flex lg:justify-center lg:max-w-3xl mx-auto text-black lg:space-x-8 mt-4 lg:mt-8"},[_c('Bubble',{attrs:{"before-label":_vm.$t('insurance.upto'),"after-label":_vm.$t('insurance.percentTitle'),"is-dark-theme":_vm.backgroundBlack,"percentage":_vm.percentage}},[_vm._v(_vm._s(_vm.$t('insurance.percentage', { percent: _vm.percentage ? _vm.percentage.toFixed(0) : 0 })))]),_vm._v(" "),(_vm.totalCoursePrice !== false)?_c('Bubble',{attrs:{"after-label":_vm.$t('insurance.fixedTitle'),"is-dark-theme":_vm.backgroundBlack,"percentage":_vm.reimbursementValuePercentage}},[_vm._v(_vm._s(_vm.$n(_vm.reimbursementValue ? _vm.reimbursementValue : 0, {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })))]):_vm._e(),_vm._v(" "),(_vm.totalCoursePrice !== false)?_c('Bubble',{attrs:{"after-label":_vm.$t('insurance.totalCoursePrice'),"is-dark-theme":_vm.backgroundBlack,"percentage":_vm.totalCoursePricePercentage}},[_vm._v(_vm._s(_vm.$n(_vm.totalCoursePrice, {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          })))]):_vm._e()],1),_vm._v(" "),(_vm.cta)?_c('div',{staticClass:"mx-auto text-center"},[_c('LinkField',_vm._b({staticClass:"btn-pill-black mt-5"},'LinkField',_vm.cta,false))],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }