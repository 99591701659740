import { isArray } from 'lodash';

export default function ({ $gtm }, inject) {
  /** This code checks if the dataLayer array exists and contains the OneTrustGroupsUpdated and OneTrustLoaded events.
   *  If so, it returns true. Otherwise, it returns false.
   */
  const layerInitialized = function () {
    // check if the window object exists
    if (typeof window === 'undefined' || !window.dataLayer) {
      return false;
    }

    const OneTrustGroupsUpdated = window.dataLayer.findIndex((item) => item.event === 'OneTrustGroupsUpdated') !== -1;
    const OneTrustLoaded = window.dataLayer.findIndex((item) => item.event === 'OneTrustLoaded') !== -1;

    // return true if the dataLayer array contains both events
    return OneTrustGroupsUpdated && OneTrustLoaded;
  };

  /**
   * Pushes data to Google Tag Manager.
   *
   * If the Google Tag Manager layer has not been initialized, it will wait
   * until it is initialized to push the data.
   *
   * @param {*} data - The data to push to Google Tag Manager.
   */
  const push = function (data) {
    if (!layerInitialized()) {
      return setTimeout(() => this.push(data), 20);
    }

    if (isArray(data)) {
      $gtm.push(data);
    } else {
      $gtm.push(data);
    }
  };

  inject('_gtm', {
    ...$gtm,
    push,
  });
}
