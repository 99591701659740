/**
 * count number of newsletter blocks inside page content
 * showGlobalFallback resolves true if no other newsletter block rendered
 */

export const state = () => ({
  count: 0,
});

export const mutations = {
  increment(state) {
    state.count = state.count + 1;
  },
  reset(state) {
    state.count = 0;
  },
};

export const getters = {
  showGlobalFallback(state) {
    return state.count === 0;
  },
};
