<template>
  <div v-if="showProductOptions" class="flex flex-row items-center flex-wrap">
    <button
      v-for="item in visibleOptions"
      :key="`${option.name} + ${item.option}`"
      type="button"
      class="shrink-0 box-border flex flex-row items-center mr-2 mb-2 cursor-pointer last:mr-0 relative rounded-full overflow-hidden shadow-lg"
      :class="{ 'ring-2 ring-offset-1 ring-br-green': item.variant.isActive }"
      @click="selectVariant(item.variant)"
    >
      <span
        v-if="option && ['Size', 'Material', 'Style'].includes(option.name)"
        data-test-id="textOptions"
        class="px-3 py-1 cursor-pointer relative"
        :class="{
          'text-black/50 bg-lighter-gray/50 pointer-events-none decoration-br-lighter-gray decoration-2':
            item.variant && item.variant.available === false,
        }"
        >{{ item.option }}
        <div v-if="item.variant.available === false" class="striped-background absolute inset-0"></div>
      </span>

      <CldnImg
        v-else-if="item.variant && (item.variant.swatch || item.variant.image)"
        :src="item.variant.swatch || item.variant.image.src"
        :alt="item.variant.title"
        crop="fill"
        ratio="1"
        width="36"
        class="box-content"
        data-test-id="swatch"
        :class="[swatchImgClass]"
      />
    </button>

    <div v-if="hasMoreOptions" class="fluid-xxs uppercase tracking-wide leading-tight font-medium">
      +{{ variants.length - maxSwatches }}
    </div>
  </div>
</template>
<script>
import CldnImg from '@/components/CldnImg';

export default {
  name: 'ProductOptions',
  components: { CldnImg },
  props: {
    option: {
      type: Object,
      default: () => {},
    },

    selectedVariant: {
      type: Object,
      default: () => {},
    },

    variants: {
      type: Array,
      default: () => [],
      required: true,
    },

    maxSwatches: {
      type: Number,
      default: 10,
    },

    swatches: {
      type: Object,
      default: () => {},
    },

    swatchImgClass: {
      type: String,
      default: '',
    },

    alwaysShow: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    showProductOptions() {
      return this.visibleOptions.length > 1 || this.alwaysShow;
    },

    visibleOptions() {
      const mappedOptions = this.option?.values.map((option) => {
        const params = {};

        this.selectedVariant?.selectedOptions?.forEach((option) => {
          params[option.name] = option.value;
        });

        params[this.option.name] = option;

        // find variant that matches the selected params
        const variant = this.variants.find((variant) => {
          return variant.selectedOptions?.every((option) => {
            return params[option.name] === option.value;
          });
        });

        if (!variant) {
          return { option, variant: null };
        }

        const swatch = this.swatches && this.swatches[variant.sku];

        return {
          option,
          variant: { ...variant, swatch, isActive: variant.id === this.selectedVariant.id },
        };
      });

      return (
        mappedOptions
          // filter out null variants
          ?.filter((item) => {
            return item.variant;
          })
          // respect maxSwatches
          ?.slice(0, this.maxSwatches) ?? []
      );
    },

    showSwatches() {
      return this.option?.name === 'Color';
    },

    hasMoreOptions() {
      return this.maxSwatches > 0 && this.option?.values.length > this.maxSwatches;
    },
  },

  methods: {
    selectByImage(image) {
      const selected = this.visibleOptions.find((item) => {
        return item.variant.image.id === image.id;
      });

      if (selected) {
        this.selectVariant(selected.variant);
      }
    },

    selectVariant(variant) {
      this.$emit('clickOption', variant);
    },
  },
};
</script>

<style scoped>
.striped-background {
  background: linear-gradient(
    163deg,
    rgba(100, 100, 100, 0) 0%,
    rgba(100, 100, 100, 0) 46%,
    rgba(160, 160, 160, 1) 48%,
    rgba(160, 160, 160, 1) 52%,
    rgba(100, 100, 100, 0) 54%
  );
}
</style>
