<template>
  <div>
    <div class="container mx-auto max-w-xl py-8">
      <EmptySearchResults v-if="resultsLength === 0" v-bind="{ query, layout: 'list' }" />
      <div v-else class="grid gap-y-2">
        <div v-for="(group, i) in results" :key="i">
          <div v-if="group.items.length > 0">
            <h2 class="bg-gray-lighter p-2 fluid-xs leading-tightest font-light">{{ group.title }}</h2>
            <div v-for="item in group.items" ref="list" :key="item.id" class="grid gap-y-2 group-item mx-1 py-2">
              <SearchModalResultItem
                v-bind="{
                  url: $localeRootPath(item.uri) + (item.sectionHandle === 'exerciseCard' ? '#' + item.objectID : ''),
                  title: item.title,
                  highlightTitle: item._highlightResult ? item._highlightResult.title.value : null,
                  imageUrl: item.imageUrl,
                  useLargeImage: item.sectionGroup !== 'Products',
                  minPrice: item.priceRange ? item.priceRange.minVariantPrice : null,
                  maxPrice: item.priceRange ? item.priceRange.maxVariantPrice : null,
                }"
                @click="onLinkClick"
              />
              <nuxt-link
                v-if="false"
                :to="$localeRootPath(item.uri) + (item.sectionHandle === 'exerciseCard' ? '#' + item.objectID : '')"
                class="flex items-center hover:text-br-green transition-all"
                @click.native="onLinkClick"
              >
                <CldnImg
                  v-if="item.imageUrl"
                  :src="item.imageUrl"
                  :alt="item.title"
                  :ratio="item.sectionGroup === 'Products' ? null : '0.66'"
                  :crop="item.sectionGroup === 'Products' ? 'fill' : 'fill'"
                  sizes="100px"
                  class="max-w-none block w-24 select-none mr-4"
                />
                <div class="py-3">
                  <div v-if="item.sectionGroup === 'Products'">
                    <div>
                      <div
                        v-if="item._highlightResult"
                        class="highlight-title"
                        v-html="item._highlightResult.title.value"
                      ></div>
                      <div v-else>
                        {{ item.title }}
                      </div>
                    </div>
                    <div v-if="item.priceRange">
                      <ProductComparePrice
                        :price="{
                          amount: item.priceRange.minVariantPrice.amount,
                          currencyCode: item.priceRange.minVariantPrice.currencyCode,
                        }"
                        :price-range-min="
                          item.priceRange.minVariantPrice.amount !== item.priceRange.maxVariantPrice.amount
                            ? item.priceRange.minVariantPrice.amount
                            : null
                        "
                        :price-range-max="
                          item.priceRange.minVariantPrice.amount !== item.priceRange.maxVariantPrice.amount
                            ? item.priceRange.maxVariantPrice.amount
                            : null
                        "
                        :currency-code="item.priceRange.minVariantPrice.currencyCode"
                        :price-class="'font-medium'"
                      />
                    </div>
                  </div>
                  <div v-else>
                    <div
                      v-if="item._highlightResult && item._highlightResult.title"
                      class="highlight-title"
                      v-html="item._highlightResult.title.value"
                    ></div>
                    <div v-else>
                      {{ item.title }}
                    </div>
                  </div>
                </div>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CldnImg from '@/components/CldnImg';
import ProductComparePrice from '@/components/common/ProductComparePrice';
import debounce from 'lodash/debounce';
import entryPage from '@/mixins/entryPage';
import SearchModalResultItem from '@/components/layout/header/SearchModalResultItem.vue';
import EmptySearchResults from '@/components/layout/EmptySearchResults.vue';

export default {
  components: { CldnImg, ProductComparePrice, EmptySearchResults, SearchModalResultItem },
  mixins: [entryPage],
  props: {
    hits: {
      type: Array,
      default: () => [],
    },
    query: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      lastSearch: '',
      leavingPage: false,
    };
  },
  computed: {
    results() {
      return [
        {
          title: this.$t('search.products'),
          items: this.hits.filter((item) => item.sectionGroup === 'Products'),
        },
        {
          title: this.$t('search.exercises'),
          items: this.hits.filter((item) => item.sectionGroup === 'Exercises'),
        },
        {
          title: this.$t('search.knowledge'),
          items: this.hits.filter((item) => item.sectionGroup === 'Knowledge'),
        },
        {
          title: this.$t('search.stories'),
          items: this.hits.filter((item) => item.sectionGroup === 'Story'),
        },
        {
          title: this.$t('search.service'),
          items: this.hits.filter((item) => item.sectionGroup === 'Service'),
        },
      ];
    },
    resultsLength() {
      let counter = 0;
      this.results.forEach((group) => (counter += group.items.length));
      return counter;
    },
  },
  watch: {
    results: debounce(function () {
      if (!this.query || this.query.length <= 2 || this.query === this.lastSearch || this.leavingPage) return;
      this.lastSearch = this.query;
      this.gtmPushPageMeta(
        {
          content_category: 'search',
          page_type: 'search_instant',
          search_tab: '--',
          search_results: this.hits?.length || 0,
          page_path: `/search?q=${this.query}&stype=instant`,
          page_title: this.$root.$meta().refresh().metaInfo.titleChunk || 'Search',
        },
        true
      );
    }, 4000),
  },
  destroyed() {
    this.leavingPage = true;
  },
  methods: {
    onLinkClick() {
      this.$emit('clicked');
    },
  },
};
</script>
