<template>
  <div
    class="matrix-block md:px-0 max-w-expanded-md sm:flex-row-reverse container flex flex-col items-stretch relative"
  >
    <FixedAspect v-if="image[0]" :ratio="2 / 3" class="sm:hidden">
      <CldnImg :src="image[0].url" :alt="image[0].title" :ratio="2 / 3" sizes="100vw" crop="fill" />
    </FixedAspect>
    <FixedAspect :ratio="1.0" class="sm:block sm:w-1/2 relative hidden h-full">
      <CldnImg
        v-if="image[0]"
        :src="image[0].url"
        :alt="image[0].title"
        :ratio="1.0"
        sizes="(min-width: 1440px) 432px, 50vw"
        crop="fill"
      />
    </FixedAspect>
    <div
      v-if="price"
      class="absolute rounded-full bg-black text-white top-4 right-4 overflow-hidden flex flex-col items-center h-20 w-20 md:h-32 md:w-32"
    >
      <span
        class="text-center items-center h-full w-full flex justify-center"
        :class="priceHasDecimal ? 'fluid-lg font-light leading-normal' : 'fluid-xl leading-tight font-medium'"
        >{{
          $n(price, {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: priceHasDecimal ? 2 : 0,
          })
        }}</span
      >
      <span
        class="fluid-xs leading-tightest line-through absolute"
        :class="priceHasDecimal ? 'bottom-4 md:bottom-7' : 'bottom-4 md:bottom-5'"
        data-test-id="price"
        >{{
          $n(compareAtPrice, {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: compareAtPriceHasDecimal ? 2 : 0,
          })
        }}</span
      >
    </div>
    <div class="box sm:w-1/2 flex flex-col items-baseline justify-center fluid-xl leading-tight font-medium">
      <div class="block">{{ heading }}</div>
      <LinkField :tracking-name="heading" v-bind="cta" class="btn-pill-black inline-block mt-4" />
    </div>
  </div>
</template>

<script>
import FixedAspect from '@/components/common/FixedAspect';
import CldnImg from '@/components/CldnImg';
import LinkField from '@/components/common/LinkField';

export default {
  name: 'InContentCta',
  components: {
    FixedAspect,
    CldnImg,
    LinkField,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
    cta: {
      type: Object,
      default: () => {},
    },
    image: {
      type: Array,
      default: () => [],
    },
    price: {
      type: String,
      default: null,
    },
    compareAtPrice: {
      type: String,
      default: null,
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    priceHasDecimal() {
      return this.price.split('.').length > 1;
    },
    compareAtPriceHasDecimal() {
      return this.compareAtPrice.split('.').length > 1;
    },
  },
};
</script>
