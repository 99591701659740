<template>
  <div class="relative">
    <CenteredContent v-if="claim" :caption="heading" :heading="claim" :body="body" :cta="cta" class="mb-0" />
    <div v-if="slides && slides.length" class="overflow-x-hidden">
      <div ref="slides" class="slides-container gap-x-12 mb-36 grid" :class="[`grid-cols-${slides.length}`]">
        <ParallaxBox v-for="(slide, i) in slides" :key="slide.id" :factor="i % 2 === 0 ? -50 : 50">
          <FixedAspect :ratio="1.23">
            <CldnImg
              v-if="slide.image[0] && imageSize"
              :src="slide.image[0].url"
              :alt="slide.alt ? slide.alt : slide.image[0].title"
              :ratio="1.23"
              crop="fill"
              :sizes="`${imageSize}vw`"
              class="hide-not-redrawn-pixels block w-full"
            />
          </FixedAspect>
        </ParallaxBox>
      </div>
    </div>
  </div>
</template>

<script>
import CenteredContent from '@/components/common/blocks/CenteredContent';
import CldnImg from '@/components/CldnImg';
import ParallaxBox from '@/components/common/ParallaxBox';
import FixedAspect from '@/components/common/FixedAspect';

export default {
  components: { FixedAspect, CenteredContent, CldnImg, ParallaxBox },
  props: {
    heading: {
      type: String,
      default: null,
    },
    claim: {
      type: String,
      default: null,
    },
    body: {
      type: String,
      default: null,
    },
    cta: {
      type: Object,
      default: () => {},
    },
    slides: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    imageSize() {
      if (this.slides?.length) {
        return Math.ceil(10 / this.slides.length) * 10;
      }
      return null;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, { passive: true });
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    getSlidesOffsetFromViewportCenter(rect) {
      const math = (rect.top + rect.height / 2 - window.innerHeight / 2) / window.innerHeight;
      if (math < -1) {
        return -1;
      } else if (math > 1) {
        return 1;
      } else {
        return math;
      }
    },
    handleAnimation() {
      const refSlides = this.$refs.slides;
      const rect = refSlides.getBoundingClientRect();

      const math = this.getSlidesOffsetFromViewportCenter(rect);
      if (math > 1 || math < -1) {
        return;
      }

      window.requestAnimationFrame(() => {
        refSlides.style.transform = `translateX(calc(${math} * ${(rect.width - window.innerWidth) / 2}px))`;
      });
    },
    handleResize() {
      this.handleAnimation();
    },
    handleScroll(event) {
      this.handleAnimation();
    },
  },
};
</script>

<style>
.slides-container {
  margin-left: -50vw;
  margin-right: -50vw;
}

@screen md {
  .slides-container {
    margin-left: -30vw;
    margin-right: -30vw;
  }
}

@screen lg {
  .slides-container {
    margin-left: -10vw;
    margin-right: -10vw;
  }
}
/* fix for webkit repainting moving image issue */
.hide-not-redrawn-pixels {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05);
}
</style>
