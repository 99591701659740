<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 10">
    <path
      d="m13.647 3.266-1.355-1.42c-.304-.337-.772-.495-1.216-.472l-2.056.158V.225C9.02.09 8.927 0 8.786 0H.234C.117 0 0 .09 0 .225v7.816c0 .337.28.608.63.608h.795C1.542 9.414 2.22 10 3.038 10s1.519-.586 1.612-1.351h4.16c.117.765.794 1.351 1.612 1.351.818 0 1.496-.586 1.613-1.351h1.074c.491 0 .888-.383.888-.856V4.234c.024-.36-.116-.698-.35-.968zM3.037 9.099a.69.69 0 0 1-.7-.676c0-.382.304-.675.7-.675a.69.69 0 0 1 .702.675c0 .383-.327.676-.701.676zm7.385 0a.69.69 0 0 1-.7-.676c0-.382.326-.675.7-.675a.69.69 0 0 1 .701.675c0 .383-.304.676-.7.676zm2.43-3.919h-3.13V2.59l1.425-.113h.023c.093 0 .187.046.234.113l1.355 1.442c.047.067.093.135.093.202v.946z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'DeliveryIcon'
}
</script>
