<template>
  <div class="flex flex-nowrap items-baseline">
    <span
      v-if="compareAtPrice && compareAtPrice.amount > 0"
      class="text-gray-light block line-through decoration-blackly mr-2 fluid-lg leading-tight self-baseline !font-light"
      data-test-id="old-price"
    >
      {{
        $n(compareAtPrice.amount, {
          style: 'currency',
          currency: compareAtPrice.currencyCode || currencyCode,
        })
      }}
    </span>

    <span
      v-else-if="priceFrom"
      class="fluid-xs text-gray-light text-center leading-none md:leading-normal mr-1"
      data-test-id="price-range"
    >
      {{ $t('product.from') }}
    </span>

    <div v-if="price" class="self-baseline">
      <span
        :class="[
          priceClass ? priceClass : 'fluid-base',
          compareAtPrice && compareAtPrice.amount > 0 ? 'leading-tight' : 'leading-7',
        ]"
        data-test-id="price"
      >
        {{
          $n(price.amount, {
            style: 'currency',
            currency: price.currencyCode || currencyCode,
          })
        }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    price: {
      type: Object,
      default: () => {},
    },
    compareAtPrice: {
      type: Object,
      default: () => {},
    },
    priceRangeMin: {
      type: String,
      default: undefined,
    },
    priceRangeMax: {
      type: String,
      default: undefined,
    },
    priceClass: {
      type: String,
      default: undefined,
    },
    comparePriceClass: {
      type: String,
      default: undefined,
    },
    currencyCode: {
      type: String,
      default: 'EUR',
    },
  },
  computed: {
    priceFrom() {
      return this.priceRangeMin !== this.priceRangeMax && this.price.amount === this.priceRangeMin;
    },
  },
};
</script>
