<template>
  <div class="relative">
    <CraftAsset
      v-if="isImage"
      :ratio="0.45"
      sizes="100vw"
      :asset="video[0]"
      :autoplay="true"
      crop="fill"
      class="max-w-none md:block hidden object-cover w-full h-full"
    />
    <CraftAsset
      v-if="isImage"
      :ratio="1.35"
      sizes="100vw"
      :asset="video[0]"
      :autoplay="true"
      crop="fill"
      class="max-w-none md:hidden block object-cover w-full h-full"
    />

    <FixedAspect v-if="isVideo" :ratio="0.45" class="md:block hidden">
      <CldnVideo
        v-if="videoSrc"
        :src="videoSrc"
        :width="1440"
        :ratio="0.45"
        crop="fill"
        :autoplay="true"
        :muted="true"
      />
    </FixedAspect>
    <FixedAspect v-if="isVideo" :ratio="1.35" class="md:hidden block">
      <CldnVideo
        v-if="videoSrc"
        :src="videoSrc"
        :width="425"
        :ratio="1.35"
        crop="fill"
        :autoplay="true"
        :muted="true"
      />
    </FixedAspect>
    <div v-if="heading" class="lg:items-center absolute inset-0 flex items-end py-16">
      <div class="container">
        <div class="xl:grid grid-cols-12">
          <div class="col-start-2 col-end-12">
            <BarText class="fluid-4xl leading-none font-semibold" tag="div" :text="heading" />
            <LinkField
              v-if="cta"
              v-track:view="gtmData()"
              v-track:click="gtmData()"
              no-tracking
              v-bind="cta"
              class="sm:mt-6 btn-pill-outline-white inline-block mt-4"
              :class="buttonPrimary ? 'btn-pill-black border-none' : 'btn-pill-outline-white'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LinkField from '@/components/common/LinkField';
import FixedAspect from '@/components/common/FixedAspect';
import CldnVideo from '@/components/CldnVideo';
import CraftAsset from '@/components/common/CraftAsset';
import BarText from '@/components/BarText';
import { stripHtml } from '@/utils/common';

export default {
  components: { BarText, FixedAspect, LinkField, CldnVideo, CraftAsset },
  props: {
    heading: {
      type: String,
      default: null,
    },
    video: {
      type: Array,
      default: () => [],
    },
    cta: {
      type: Object,
      default: () => {},
    },
    buttonPrimary: {
      type: Boolean,
      default: false,
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    isImage() {
      return this.video.length && this.video[0] && this.video[0].kind === 'image';
    },
    isVideo() {
      return this.video.length && this.video[0] && this.video[0].kind === 'video';
    },
    videoSrc() {
      return this.isVideo ? this.video[0].url : null;
    },
  },

  methods: {
    gtmData() {
      return {
        promotion_id: this.cta?.element?.uri,
        promotion_name: [this.$route?.name, stripHtml(this.heading)].join(' / '),
        creative_name: 'fullWidthHeadingCta',
        creative_slot: [this.$route?.name, this.blockPosition + 1].join(' / '),
      };
    },
  },
};
</script>
