<template>
  <div :class="{ 'bg-putty py-1': backgroundGray }">
    <div class="relative" :class="{ 'container max-w-expanded-md mx-auto my-20': !fullWidth }">
      <VideoWithHeading v-bind="$props">
        <template #playBtn>
          <div class="absolute inset-0 flex items-center justify-center">
            <PlayBtn class="lg:w-20 lg:h-20 w-16 h-16" @click="showModal = true" />
          </div>
        </template>
      </VideoWithHeading>
      <Modal :showing="showModal" :min-width="true" fullscreen video @close="showModal = false">
        <CraftAsset :asset="video[0]" :autoplay="autoplay" :controls="!autoplay" :muted="false" class="w-full" />
      </Modal>
    </div>
  </div>
</template>

<script>
import CraftAsset from '@/components/common/CraftAsset'
import Modal from '@/components/common/Modal'
import PlayBtn from '@/components/common/PlayBtn'
import VideoWithHeading from '@/components/common/VideoWithHeading/VideoWithHeading'

export default {
  components: { Modal, CraftAsset, PlayBtn, VideoWithHeading },
  props: {
    heading: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    textOverlay: {
      type: String,
      default: null
    },
    video: {
      type: Array,
      default: () => []
    },
    preview: {
      type: Array,
      default: () => []
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    backgroundGray: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    ratio: {
      type: Number,
      default: null
    },
    smallOverlay: {
      type: Boolean,
      default: false
    },
    centered: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModal: false,
      embedded: null
    }
  }
}
</script>

<style scoped></style>
