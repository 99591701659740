<template>
  <component :is="name" />
</template>

<script>
import AccountIcon from '@/components/icons/AccountIcon'
import ExerciseIcon from '@/components/icons/ExerciseIcon'
import ProductIcon from '@/components/icons/ProductIcon'
import AngleLeftIcon from '@/components/icons/AngleLeftIcon'
import DifficultyIcon from '@/components/icons/DifficultyIcon'
import AcademyIcon from '@/components/icons/AcademyIcon'

export default {
  name: 'IconWrapper',

  components: {
    account: AccountIcon,
    exercise: ExerciseIcon,
    product: ProductIcon,
    'angle-left': AngleLeftIcon,
    difficulty: DifficultyIcon,
    academy: AcademyIcon
  },

  props: {
    name: {
      type: String,
      default: null
    }
  }
}
</script>
