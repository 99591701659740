<template>
  <div
    :class="{ 'flex-row space-x-6 p-6 md:p-8 hover:shadow-lg': horizontal, 'flex-col space-y-4': !horizontal }"
    class="group relative flex cursor-pointer"
    @click="$router.push(articleRoute)"
  >
    <nuxt-link :to="articleRoute" :class="{ 'w-1/3': horizontal }" class="block z-20">
      <FixedAspect :ratio="2 / 3" class="relative overflow-hidden">
        <CldnImg
          v-if="article.image && article.image[0]"
          :src="article.image[0].url"
          :ratio="2 / 3"
          sizes="(min-width: 768px) 450px, 200px"
          crop="fill"
          class="max-w-none hover:scale-110 background-image absolute inset-0 z-0 block object-cover w-full h-full transition-transform duration-700 transform"
          :alt="article.image[0].title"
        />
      </FixedAspect>
    </nuxt-link>
    <div>
      <div class="flex flex-row items-center space-x-4">
        <TagBtn v-if="topicUri" :to="$localeRootPath(topicUri)" @click.native="$event.stopImmediatePropagation()">
          {{ article.topics[0].title }}
        </TagBtn>
        <TagBtn
          v-if="categorySlug"
          :to="{
            name: `knowledge-index-category-category___${$i18n.locale}`,
            params: { category: categorySlug },
          }"
          secondary
          @click.native="$event.stopImmediatePropagation()"
        >
          {{ article.categories[0].title }}
        </TagBtn>

        <TagBtn
          v-if="categoryUri"
          :to="$localeRootPath(categoryUri)"
          secondary
          @click.native="$event.stopImmediatePropagation()"
        >
          {{ article.categories[0].title }}
        </TagBtn>
      </div>

      <div class="mt-2 fluid-lg leading-tight font-medium">
        <nuxt-link :to="articleRoute">{{ article.title }}</nuxt-link>
      </div>
      <div v-if="article.readTime" class="h-4 mt-2 fluid-sm leading-tightest font-medium">
        {{ $t('readTime', { min: article.readTime }) }}
      </div>
    </div>
  </div>
</template>

<script>
import CldnImg from '@/components/CldnImg';
import FixedAspect from '@/components/common/FixedAspect';
import TagBtn from '@/components/common/TagBtn';
export { ArticlePreviewFragment } from '@/components/common/ArticlePreviewFragment.gql';

export default {
  components: { CldnImg, FixedAspect, TagBtn },
  props: {
    article: {
      type: Object,
      required: true,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    topicUri() {
      return this.article?.topics?.length && this.article?.topics[0]?.uri;
    },
    categorySlug() {
      return this.article?.categories?.length && this.article?.categories[0]?.slug;
    },
    categoryUri() {
      return this.article?.categories?.length && this.article?.categories[0]?.uri;
    },
    articleRoute() {
      return {
        name: `article-slug___${this.$i18n.locale}`,
        params: { slug: this.article.slug },
      };
    },
  },
};
</script>
