<template>
  <div class="container my-20">
    <div class="md:flex-nowrap md:gap-0 flex flex-wrap items-center gap-8">
      <div class="md:w-1/2 md:p-16 md:mb-0 w-full mb-10" :class="{ 'order-1': invert }">
        <h2 v-if="heading" class="fluid-3xl leading-tightest pr-8" v-html="heading"></h2>
        <div v-if="body" class="fluid-base leading-relaxed font-light text-copy mt-8" v-html="body"></div>
        <LinkField v-if="cta" v-bind="cta" class="btn-pill-outline-black mt-8" />
      </div>
      <div class="md:w-1/2 w-full" :class="{ 'order-0': invert }">
        <CraftAsset
          v-if="image[0]"
          :alt="alt"
          :asset="image[0]"
          :ratio="aspectRatio"
          sizes="(min-width: 1440px) 648px,(min-width: 1024px) 50vw, 100vw"
          crop="fill"
          :autoplay="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CraftAsset from '~/components/common/CraftAsset';
import LinkField from '~/components/common/LinkField';

export default {
  name: 'ImageText',
  components: { LinkField, CraftAsset },
  props: {
    image: {
      type: Array,
      default: () => [],
    },
    alt: {
      type: String,
      default: null,
    },
    invert: {
      type: Boolean,
      default: false,
    },
    heading: {
      type: String,
      default: null,
    },
    body: {
      type: String,
      default: null,
    },
    cta: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    aspectRatio() {
      return (this.image.length && this.image[0].height / this.image[0].width) || 1;
    },
  },
};
</script>
