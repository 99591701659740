<template>
  <div v-if="courseContents" class="py-20" :class="backgroundGreen ? 'bg-br-green-light' : 'bg-putty'">
    <div class="container">
      <div class="max-w-screen-md mx-auto">
        <h2 class="fluid-3xl leading-tightest mb-16 text-center">{{ $t('courseContent.title') }}</h2>
      </div>
    </div>
    <div class="max-w-screen-md mx-auto">
      <BorderedAccordion
        v-for="(panel, index) in courseContents.sections"
        :key="index"
        :nb="index"
        :heading="panel.sectionTitle"
        :body="panel.sectionDescription"
        :categories="countCategories(panel.unitSummary)"
        :is-open="index === 0"
        class="last:border-b"
      />
    </div>
  </div>
</template>

<script>
import BorderedAccordion from '~/components/common/BorderedAccordion';
import { fetchCourseContent } from '~/utils/feg';

export default {
  components: {
    BorderedAccordion,
  },
  props: {
    backgroundGreen: {
      type: Boolean,
      default: false,
    },
    course: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      courseContents: null,
      active: false,
    };
  },
  async fetch() {
    if (this.course && this.course === 'null') {
      return;
    }

    try {
      this.courseContents = await fetchCourseContent(this, this.course);
    } catch (error) {
      this.$sentry.captureException(error);
    }
  },
  methods: {
    countCategories(categories) {
      if (!categories) return [];

      if (categories && Array.isArray(categories)) {
        const categoriesList = {};

        categories.forEach((item) => {
          const key =
            item.key === 'video' || item.key === 'ebook'
              ? this.$t(`courseContent.${item.key}`)
              : this.$t('courseContent.other');

          if (categoriesList[key]) {
            categoriesList[key].count += item.count;
          } else {
            categoriesList[key] = {
              name: key,
              count: item.count,
            };
          }
        });

        return Object.values(categoriesList);
      }
    },
  },
};
</script>
