<template>
  <div class="flex flex-row justify-between flex-wrap gap-y-2">
    <div class="flex flex-row items-center">
      <div class="routine-icon">
        <ClockIcon class="h-5" />
      </div>
      <div>
        <div class="routine-description">{{ $t('exercises.duration') }}</div>
        <div class="routine-value">{{ duration }}</div>
      </div>
    </div>

    <div class="flex flex-row items-center">
      <div class="routine-icon">
        <ExerciseIcon class="h-5" />
      </div>
      <div>
        <div class="routine-description">{{ $t('exercises.exercises') }}</div>
        <div class="routine-value">{{ exerciseCount }}</div>
      </div>
    </div>

    <div class="flex flex-row items-center">
      <div class="routine-icon">
        <DifficultyIcon class="h-5 text-br-green" />
      </div>
      <div>
        <div class="routine-description">{{ $t('exercises.difficulty') }}</div>
        <div class="routine-value">{{ difficulty }}</div>
      </div>
    </div>

    <slot />
  </div>
</template>

<script>
import ExerciseIcon from '@/components/icons/ExerciseIcon.vue';
import DifficultyIcon from '@/components/icons/DifficultyIcon.vue';
import ClockIcon from '~/components/icons/ClockIcon.vue';
export default {
  components: {
    ExerciseIcon,
    DifficultyIcon,
    ClockIcon,
  },
  props: {
    duration: {
      type: String,
      default: '',
    },
    exerciseCount: {
      type: [Number, String],
      default: 0,
    },
    difficulty: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.routine-icon {
  @apply pr-2;
}
.routine-description {
  @apply fluid-xs leading-tight tracking-wider font-medium text-gray-500;
}
.routine-value {
  @apply fluid-xs leading-tightest font-medium;
}
</style>
