import gql from 'graphql-tag';

export const GlobalUspBarFragment = gql`
  fragment GlobalUspBarFragment on globalUspBar_GlobalSet {
    globallyEnabled
    blocks: uspBarBlocks {
      ... on uspBarBlocks_iconText_BlockType {
        svg {
          url
        }
        text
      }
    }
    pdpBenefits: benefitBlocks {
      ... on benefitBlocks_BlockType {
        id
        icon {
          url
          alt
        }
        text
        isMadeInGermany
      }
    }
  }
`;

export const state = () => ({
  globallyEnabled: false,
  blocks: [],
  locallyDisabled: false,
  pdpBenefits: [],
});

export const mutations = {
  INIT(state, { globallyEnabled, blocks, pdpBenefits }) {
    state.blocks = blocks;
    state.globallyEnabled = globallyEnabled;
    state.pdpBenefits = pdpBenefits;
  },
  SET_LOCALLY_DISABLED(state, locallyDisabled) {
    state.locallyDisabled = locallyDisabled;
  },
};

export const getters = {
  barEnabled(state) {
    return state.globallyEnabled && !state.locallyDisabled && state.blocks.length > 0;
  },
};
