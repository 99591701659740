<template>
  <div
    v-if="image && image[0]"
    class="relative flex flex-col"
    :class="[
      boxOutside
        ? boxPosition === 'bottomLeft' || boxPosition === 'bottomRight'
          ? 'sm:pb-12'
          : 'sm:pt-12 sm:pb-12'
        : '',
    ]"
  >
    <FixedAspect :ratio="0.55" class="order-1">
      <CraftAsset
        v-if="image[0]"
        :asset="image[0]"
        :width="1440"
        :ratio="0.55"
        :autoplay="true"
        :muted="true"
        :hfull="true"
      />
    </FixedAspect>
    <div
      class="sm:absolute inset-0 z-10"
      :class="{ 'sm:py-12': !boxOutside, 'order-0': putBoxOnTopInMoble, 'order-2': !putBoxOnTopInMoble }"
    >
      <div
        class="sm:container sm:h-full relative flex"
        :class="{
          'items-start': boxPosition === 'topLeft' || !boxPosition,
          'justify-end items-start': boxPosition === 'topRight',
          'items-center': boxPosition === 'middleLeft',
          'justify-end items-center': boxPosition === 'middleRight',
          'items-end': boxPosition === 'bottomLeft',
          'justify-end items-end': boxPosition === 'bottomRight',
        }"
      >
        <div
          class="p-6 lg:px-12 lg:pt-16 bg-blackly sm:w-80 sm:min-h-[20rem] md:w-80 md:min-h-[20rem] lg:w-[28rem] lg:min-h-[28rem] xl:w-[30rem] xl:min-h-[30rem] sm:top-1/4 flex flex-col items-start justify-center w-full"
          :class="{ 'sm:sticky': animationEnabled }"
        >
          <div v-if="tag" class="text-br-green fluid-xs leading-tightest capitalize">{{ tag }}</div>
          <div
            class="lg:mt-4 mt-2 fluid-3xl leading-tightest font-semibold text-white"
            data-test-id="heading"
            v-html="heading"
          ></div>
          <div
            v-if="teaser"
            class="text-copy fluid-base leading-relaxed font-light checkmark-bullets mt-4 text-white"
            v-html="teaser"
          ></div>
          <LinkField :tracking-name="heading" v-bind="cta" class="btn-pill-outline-white inline-block mt-8" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LinkField from '~/components/common/LinkField';
import FixedAspect from '~/components/common/FixedAspect';
import CraftAsset from '~/components/common/CraftAsset';

export default {
  components: {
    CraftAsset,
    FixedAspect,
    LinkField,
  },
  props: {
    tag: {
      type: String,
      default: '',
    },
    heading: {
      type: String,
      default: '',
    },
    teaser: {
      type: String,
      default: '',
    },
    boxPosition: {
      type: String,
      default: 'topLeft',
    },
    boxOutside: {
      type: Boolean,
      default: false,
    },
    animationEnabled: {
      type: Boolean,
      default: true,
    },
    cta: {
      type: Object,
      default: () => {},
    },
    image: {
      type: Array,
      default: () => [],
    },
    alt: {
      type: String,
      default: null,
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    putBoxOnTopInMoble() {
      return this.boxPosition === 'topLeft' || this.boxPosition === 'topRight';
    },
  },
};
</script>
