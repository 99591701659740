// expose a function that indicates if the APP_ENV is in production mode

const getCookieConsent = function () {
  if (typeof window !== 'undefined') {
    // extract the OptanonConsent cookie from the document
    const OptanonConsent = document.cookie.split(';').find((cookie) => cookie.trim().startsWith('OptanonConsent='));

    if (!OptanonConsent) {
      return;
    }

    const params = new URLSearchParams(OptanonConsent.replace('OptanonConsent=', '').trim());

    const result = {};

    if (params.has('consentId')) {
      result.consentId = params.get('consentId');
    }

    if (params.has('groups')) {
      const groups = params.get('groups').split(',');
      result.consent = {
        necessary: groups.includes('C0001:1') ?? false,
        performance: groups.includes('C0002:1') ?? false,
        functional: groups.includes('C0003:1') ?? false,
        targeting: groups.includes('C0004:1') ?? false,
        social: groups.includes('C0005:1') ?? false,
      };

      // For OneTrust Checkout Functionality
      const groupsString = params.get('groups');
      result.groups = groupsString;
    }

    return result;
  }
};

export default function (ctx, inject) {
  inject('getCookieConsent', getCookieConsent);
  inject('isProduction', process.env.APP_ENV === 'production');
}
