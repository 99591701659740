<template>
  <Modal :showing="show" disable-scroll @close="close">
    <div v-if="loginDisabled" class="flex flex-col items-center">
      <LoginDisabledMassage :heading="$t('account.loginDisabledMassage')">
        <MaintenanceIcon class="h-16 fill-br-green" />
      </LoginDisabledMassage>
    </div>
    <LoginForm
      v-else
      class="max-w-sm"
      :from-uri="$route.path"
      forgot-password-over-button
      @success="$emit('success')"
      >{{ $t('account.modal.loginSaveExercises') }}</LoginForm
    >
  </Modal>
</template>

<script>
import { mapState } from 'vuex'
import Modal from '@/components/common/Modal'
import LoginForm from '@/components/account/LoginForm'
import LoginDisabledMassage from '@/components/account/LoginDisabledMassage'
import MaintenanceIcon from '@/components/icons/MaintenanceIcon'

export default {
  name: 'LoginModal',
  components: { LoginForm, Modal, LoginDisabledMassage, MaintenanceIcon },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: { ...mapState('account', ['loginDisabled']) },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
