<template>
  <div ref="root" class="relative">
    <div
      class="sticky top-0 h-screen overflow-hidden"
      :class="[
        quantityOfMedia !== 1 ? 'paralaxStickyMobile -top-32 lg:top-0 lg:h-screen' : '',
        quantityOfMedia === 3 ? 'paralaxStickyMobile--threeItems -top-60' : ''
      ]"
    >
      <div ref="background">
        <div
          class="background-grid lg:grid-cols-2 lg:grid-rows-2 bg-blackly grid md:grid-rows-4"
          :class="quantityOfMedia === 1 ? 'lg:grid-cols-1' : ''"
        >
          <div class="lg:row-start-1 lg:row-end-4 md:row-span-2 md:h-auto relative">
            <CraftAsset
              :asset="firstImageVideo[0]"
              :autoplay="true"
              sizes="'(min-width: 1024px) 50vw, 100vw'"
              :width="firstImageVideo[0].width"
              :ratio="quantityOfMedia === 1 ? 0.7 : 1.6"
              crop="fill"
              class="max-w-none hidden md:block w-full h-full"
            />
            <CraftAsset
              :asset="firstImageVideo[0]"
              :autoplay="true"
              sizes="'(min-width: 1024px) 50vw, 100vw'"
              :width="firstImageVideo[0].width"
              :ratio="quantityOfMedia === 1 ? 2.25 : 1.25"
              crop="fill"
              class="max-w-none md:hidden w-full h-full"
            />
          </div>
          <div
            v-if="quantityOfMedia >= 2"
            :class="quantityOfMedia === 2 ? 'lg:row-start-1 lg:row-end-4 md:row-span-2' : ''"
          >
            <CraftAsset
              :asset="secondImageVideo[0]"
              :autoplay="true"
              sizes="'(min-width: 1024px) 50vw, 100vw'"
              :width="secondImageVideo[0].width"
              :ratio="quantityOfMedia === 2 ? 1.6 : 0.85"
              crop="fill"
              class="hidden md:block h-full"
            />
            <CraftAsset
              :asset="secondImageVideo[0]"
              :autoplay="true"
              sizes="'(min-width: 1024px) 50vw, 100vw'"
              :width="secondImageVideo[0].width"
              :ratio="quantityOfMedia === 2 ? 1.6 : 0.75"
              crop="fill"
              class="md:hidden h-full"
            />
          </div>
          <div v-if="quantityOfMedia === 3" class="">
            <CraftAsset
              :asset="thirdImageVideo[0]"
              :autoplay="true"
              sizes="'(min-width: 1024px) 50vw, 100vw'"
              :width="thirdImageVideo[0].width"
              :ratio="0.85"
              crop="fill"
              class="max-w-none hidden md:block w-full h-full"
            />
            <CraftAsset
              :asset="thirdImageVideo[0]"
              :autoplay="true"
              sizes="'(min-width: 1024px) 50vw, 100vw'"
              :width="thirdImageVideo[0].width"
              :ratio="0.85"
              crop="fill"
              class="max-w-none md:hidden w-full h-full"
            />
          </div>
        </div>
        <div ref="heading" class="lg:h-screen top-32 lg:top-0 absolute left-0 flex items-center">
          <BarText tag="h1" :text="heading" class="container-ml fluid-5xl leading-none" />
        </div>
      </div>
    </div>
    <div ref="overlay" class="bg-blackly absolute inset-0 top-0 opacity-0 pointer-events-none" />
    <div ref="slide2" class="slide2 container relative top-0 flex items-center h-screen">
      <div class="max-w-2xl mx-auto text-white">
        <BarText tag="h2" class="fluid-4xl leading-none" :text="overlayHeading" />
        <div class="fluid-lg font-light leading-normal mt-8" v-html="overlayText"></div>
      </div>
    </div>
    <div v-if="about" class="h-screen" />
  </div>
</template>
<script>
import BarText from '~/components/BarText'
import CraftAsset from '~/components/common/CraftAsset'

export default {
  name: 'ParallaxImage',
  components: {
    BarText,
    CraftAsset
  },
  props: {
    heading: {
      type: String,
      default: null
    },
    firstImageVideo: {
      type: Array,
      default: () => []
    },
    secondImageVideo: {
      type: Array,
      default: () => []
    },
    thirdImageVideo: {
      type: Array,
      default: () => []
    },
    overlayHeading: {
      type: String,
      default: null
    },
    overlayText: {
      type: String,
      default: null
    },
    about: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    quantityOfMedia() {
      if (
        this.secondImageVideo &&
        this.secondImageVideo.length > 0 &&
        this.thirdImageVideo &&
        this.thirdImageVideo.length > 0
      ) {
        return 3
      } else if (this.secondImageVideo && this.secondImageVideo.length > 0) {
        return 2
      } else {
        return 1
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, { passive: true })
    window.addEventListener('resize', this.handleResize)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    getRootOffsetAsDecimalInRange(start = 0, end = 1) {
      const refRoot = this.$refs.root
      const math =
        -(refRoot.offsetTop + start * refRoot.offsetHeight - (window.scrollY + 200)) /
        (500 + (end - start) * refRoot.offsetHeight)
      if (math < 0) {
        return 0
      } else if (math > 1) {
        return 1
      } else {
        return math
      }
    },
    handleAnimation() {
      if (this.getRootOffsetAsDecimalInRange() > 1) {
        return
      }
      const refOverlay = this.$refs.overlay
      const refBackground = this.$refs.background

      window.requestAnimationFrame(() => {
        refOverlay.style.opacity = this.getRootOffsetAsDecimalInRange(0, 0.01) * 0.9
        refBackground.style.transform = `scale(${1 + this.getRootOffsetAsDecimalInRange(0, 0.8) * 0.05})`
      })
    },
    handleResize() {
      this.handleAnimation()
    },
    handleScroll(event) {
      this.handleAnimation()
    }
  }
}
</script>

<style scoped>
.background-grid {
  height: calc(100vh + 100px); /* navbar height */
}
.slide2 {
  margin-top: -20vh;
}

.paralaxStickyMobile {
  height: 120vh;
}

.paralaxStickyMobile--threeItems {
  height: 130vh;
}
@media screen and (min-width: 1024px) {
  .paralaxStickyMobile--threeItems {
    height: 100vh;
  }
}
</style>
