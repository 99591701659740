<template>
  <div v-show="isOpen" class="fixed inset-0 z-50 flex items-center justify-center px-3 bg-blackly/50 backdrop-blur-sm">
    <div class="bg-white relative max-w-2xl max-h-[90vh] flex flex-col overflow-hidden" data-testid="countryBar">
      <div class="overflow-y-auto px-6 pt-8 pb-16">
        <div class="fluid-2xl leading-tightest font-semibold text-blackly mb-4">
          {{ $t('localeBar.title') }}
        </div>

        <form id="countrySelect" @submit.prevent="handleCountrySelect">
          <div v-for="group in regions" :key="group.name" class="mb-5">
            <div v-if="group.name" class="text-3xl font-base mt-5 mb-2">{{ group.name }}</div>

            <div class="text-copy sm:mr-0 text-blackly mb-4" v-html="$t(group.description)"></div>

            <div class="grid sm:grid-cols-3 gap-2">
              <label
                v-for="region in group.stores"
                :key="region.code"
                class="has-[:checked]:bg-br-green-light flex items-center py-1 px-3 cursor-pointer"
              >
                <input
                  v-model="iso"
                  class="appearance-none peer"
                  type="radio"
                  name="iso"
                  :value="region.code"
                  @change="handleCountrySelect"
                />
                <LocationIcon class="w-5 h-6 mr-2 text-blackly peer-checked:text-br-green" />
                <div>
                  <div class="text-lg font-bold">{{ region.country }}</div>
                  <div v-if="region.description" class="text-xs">{{ region.description }}</div>
                  <div>{{ region.lang }}</div>
                </div>
              </label>
            </div>
          </div>
        </form>
      </div>

      <div class="absolute bottom-0 inset-x-0 bg-white/90 backdrop-blur-sm flex px-6 py-3">
        <CtaButton type="button" color="outline" :loading="isLoading" @click.native="close">
          {{ $t('localeBar.save') }}
        </CtaButton>
      </div>

      <button
        v-if="!isLoading"
        data-testid="btn-country-bar-close"
        class="hover:opacity-80 focus:outline-none absolute top-0 right-0 flex items-center justify-center p-4 text-blackly"
        @click="close"
      >
        <IconClose class="w-4" />
      </button>
    </div>
  </div>
</template>

<script>
import IconClose from '@/components/layout/header/IconClose';
import CtaButton from '@/components/common/CtaButton';
import LocationIcon from '@/components/icons/LocationIcon';
import { Bus } from '@/utils/bus';
import { setPreferredLangCookie } from '@/utils/cookie';

export default {
  components: { CtaButton, IconClose, LocationIcon },

  data() {
    return {
      isOpen: false,
      isLoading: false,

      iso: 'en',

      regions: [
        {
          name: null,
          description: 'localeBar.chooseCountry',
          stores: [
            { code: 'de', lang: 'Deutsch', country: 'Deutschland', description: 'DE, AT' },
            { code: 'fr', lang: 'Français', country: 'France' },
            { code: 'nl', lang: 'Nederlands', country: 'Nederland' },
            // { code: 'ch-de', lang: 'Deutsch', country: 'Schweiz' },
            // { code: 'ch-fr', lang: 'Français', country: 'Suisse' },
          ],
        },
        {
          name: null,
          description: 'localeBar.chooseEurope',
          stores: [{ code: 'en', lang: 'English', description: 'DE, FR, AT, NL, LUX, BE', country: 'Europe' }],
        },
      ],
    };
  },

  computed: {
    alternates() {
      return this.$store.state.mainNav.alternates;
    },

    availableLanguages() {
      return this.regions.flatMap((region) => region.stores.map((store) => store.code));
    },
  },

  async mounted() {
    try {
      const storedIso = window.localStorage.getItem('preferredIso');

      if (storedIso) {
        // in this case the user has already selected a language
        // we just set it to the right value
        this.iso = storedIso;
      } else if (!this.$route.path.includes('/account/')) {
        // this is the first visit, so we fetch the language and set it
        await this.fetchCountryIso();
      }
    } catch (error) {
      // set to current locale if there is an error
      this.iso = this.i18n.locale;
    }

    Bus.$on('openCountryBar', () => {
      this.isLoading = false;
      this.isOpen = true;
    });
  },

  methods: {
    handleCountrySelect() {
      setPreferredLangCookie(this.iso);
      this.$i18n.setLocale(this.iso);
    },

    async fetchCountryIso() {
      try {
        return await this.$axios
          .$get(this.$config.countryApiUrl, {
            transformRequest: (data, headers) => {
              delete headers.Authorization;
              delete headers.common.Authorization;
              return data;
            },
          })
          .then((data) => data.iso)
          .then((iso) => iso.toLowerCase())
          .then((iso) => {
            switch (iso) {
              case 'be':
              case 'lu':
                return 'nl';

              case 'at':
                return 'de';

              default:
                return iso;
            }
          })
          .then((iso) => {
            if (!this.availableLanguages.includes(iso)) {
              throw new Error('Language not available');
            }

            this.isOpen = true;
            this.iso = iso;
          });
      } catch {
        this.isOpen = true;
        return null;
      }
    },

    close() {
      this.isLoading = true;

      if (this.iso !== this.$i18n.locale) {
        this.handleCountrySelect();
      }

      // save settings to localstorage
      setPreferredLangCookie(this.iso);
      window.localStorage.setItem('preferredIso', this.iso);

      // if there is no alternate site for the preferred language, navigate to home page
      if (!this.alternates[this.iso]) {
        window.location = this.$localeRootPath('/');
        return;
      }

      setTimeout(() => {
        // reload window
        window.location = this.alternates[this.iso];
        // this.isOpen = false;
      }, 500);
    },
  },
};
</script>
