<template>
  <div ref="videoContainer" class="w-full relative">
    <video
      v-if="cldnSrc"
      ref="video"
      :autoplay="autoplay"
      loop
      :muted="muted"
      playsinline
      :src="cldnSrc"
      class="max-w-none block w-full h-full object-cover"
      :controls="showControls"
    >
      Your browser does not support the video format.
    </video>
    <CldnImg
      v-if="!isLoaded"
      :src="src"
      video-preview
      :width="width"
      :height="height"
      :alt="alt"
      class="max-w-none block w-full h-full object-cover absolute inset-0"
    />
  </div>
</template>

<script>
import CldnImg from '@/components/CldnImg'
import { RESOLUTIONS } from '@/plugins/cloudinary.js'
export default {
  components: { CldnImg },
  props: {
    src: {
      type: String,
      required: true
    },
    transformation: {
      type: String,
      default: null
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    ratio: {
      type: [Number, String],
      default: null
    },
    quality: {
      type: [Number, String],
      default: 'auto'
    },
    crop: {
      type: String,
      default: null
    },
    alt: {
      type: String,
      default: ''
    },
    format: {
      type: String,
      default: 'auto'
    },
    autoplay: {
      type: Boolean,
      default: true
    },
    muted: {
      type: Boolean,
      default: true
    },
    controls: {
      type: Boolean,
      default: false
    },
    isThumbnail: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoaded: false,
      closestBreakpoint: null,
      cldnSrc: null
    }
  },
  computed: {
    adaptiveHeight() {
      return this.height ? this.height : this.ratio ? (this.closestBreakpoint * parseFloat(this.ratio)).toFixed() : null
    },
    showControls() {
      return this.controls === true
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setupVideo()
      window.addEventListener('resize', this.setupVideo)
    })
  },
  destroyed() {
    window.removeEventListener('resize', this.setupVideo)
  },
  methods: {
    /**
     * Setup the video controls depending if the video has sound or not
     * relies heavily on cross-browser implementation, might be a subject to change
     */
    hasAudio(video) {
      return (
        video.mozHasAudio ||
        Boolean(video.webkitAudioDecodedByteCount) ||
        Boolean(video.audioTracks && video.audioTracks.length)
      )
    },
    setSoundControls() {
      const video = this.$refs.video
      if (!video) return
      if (this.autoplay && this.muted) video.controls = false
      else if (this.hasAudio(video) && !this.isThumbnail) video.controls = true
    },
    setupVideo() {
      if (!this.updateCurrentBreakpoint()) return
      this.setCldnSrc()
      this.$nextTick(() => {
        this.$refs.video?.addEventListener('loadeddata', this.showVideo)
      })
    },
    setCldnSrc() {
      this.cldnSrc = this.$cldn.videoUrl(this.src, {
        width: this.closestBreakpoint,
        height: this.adaptiveHeight,
        quality: this.quality,
        crop: this.crop,
        format: this.format,
        transformation: this.transformation
      })
    },
    updateCurrentBreakpoint() {
      const containerWidth = Math.round(Number(this.$refs.videoContainer?.getBoundingClientRect().width))
      const lastBreakpointValue = this.closestBreakpoint
      this.closestBreakpoint = this.getRelatedBreakpoint(containerWidth)
      return !(!containerWidth || lastBreakpointValue === this.closestBreakpoint)
    },
    showVideo() {
      this.isLoaded = true
      this.setSoundControls()
      this.$refs.video?.removeEventListener('loadeddata', this.showVideo)
    },
    getRelatedBreakpoint(width) {
      return RESOLUTIONS.find((res) => width <= res) || RESOLUTIONS.slice(-1)[0]
    }
  }
}
</script>
