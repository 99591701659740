/**
 * add cache headers for vercel edge network to cache pages
 */
export default (_, res, next) => {
  res.setHeader('Cache-Control', 's-maxage=180, stale-while-revalidate=604800');
  res.setHeader('X-Frame-Options', 'SAMEORIGIN');

  if (process.env.NODE_ENV !== 'development') {
    res.setHeader('Content-Security-Policy', 'frame-ancestors https://*.blackroll.com;');
  }

  next();
};
