<template>
  <div v-if="tags.length" class="flex flex-row items-center space-x-2">
    <div v-if="tags[0]" class="fluid-xs leading-tightest btn-tag-fill px-1 cursor-default pointer-events-none">
      {{ tags[0] }}
    </div>
    <div v-if="tags[1]" class="fluid-xs leading-tightest btn-tag-outline px-1 cursor-default pointer-events-none">
      {{ tags[1] }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
