<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34">
    <g fill="none" fill-rule="evenodd">
      <circle cx="17" cy="17" r="17" fill="#162022" />
      <path
        d="M12.004 23.081a.998.998 0 0 1-.709-1.71l2.386-2.379-2.476-3.31a1 1 0 0 1 0-1.199l2.476-3.309-2.386-2.38a1.004 1.004 0 0 1 1.418-1.418l2.995 3a1 1 0 0 1 .09 1.309l-2.546 3.398 2.546 3.4a1 1 0 0 1-.09 1.309l-2.995 3c-.188.186-.444.29-.709.289zm8.992 3a.998.998 0 0 1-.709-.29l-2.995-3a1 1 0 0 1-.09-1.309l2.546-3.399-2.546-3.4a1 1 0 0 1 .09-1.309l2.995-2.999a1.002 1.002 0 0 1 1.418 0 1.006 1.006 0 0 1 0 1.42l-2.386 2.379 2.476 3.31a1 1 0 0 1 0 1.199l-2.476 3.308 2.386 2.38a.999.999 0 0 1-.71 1.709z"
        fill="#FFF"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>

<script>
export default {}
</script>
