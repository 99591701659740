<template>
  <CommonProductsSlider v-if="productsSlider.active" v-bind="sliderProps" />
</template>

<script>
import gql from 'graphql-tag'
import { mapState } from 'vuex'
import CommonProductsSlider from '@/components/common/blocks/ProductSlider'

export const AccountProductsFragment = gql`
  fragment AccountProductsFragment on account_GlobalSet {
    bestsellersOn
    productsSliderHeading
    products: productSlider {
      typeHandle
      ... on products_products_Entry {
        shopifyHandle
      }
      ... on collections_collections_Entry {
        collectionHandle
      }
    }
  }
`
export default {
  name: 'AccountProductSlider',
  components: { CommonProductsSlider },
  computed: {
    ...mapState('account', ['productsSlider']),
    sliderProps() {
      return {
        layout: 'onlyProducts',
        heading: this.productsSlider?.heading,
        backgroundColorGray: false,
        image: [],
        alt: 'bestseller',
        featureStyle: 'barText',
        featureText: null,
        collectionOrProducts: this.productsSlider?.products || []
      }
    }
  }
}
</script>
