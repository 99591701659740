<template>
  <div class="fluid-xs leading-tightest space-y-2">
    <div v-for="item in deliveryDetails" :key="item.id" class="flex items-center justify-between leading-none py-px">
      <span class="inline-flex items-center">
        {{ item.label }}
        <Popover v-if="item.hasTooltip" class="inline-block ml-1">
          <template #button>
            <InfoSolidIcon class="w-[14px] text-gray-light" />
          </template>

          <div class="text-copy w-full whitespace-nowrap" v-html="item.tooltipContent"></div>
        </Popover>
      </span>
      <span v-if="hasFreeShipping && item.valueWithFreeShipping" class="text-br-green font-medium"
        >{{ item.valueWithFreeShipping }}
      </span>
      <span v-else class="text-br-green font-medium">{{ item.valueDefault }}</span>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import InfoSolidIcon from '@/components/icons/InfoSolidIcon.vue'
import Popover from '@/components/Popover'

export default {
  components: {
    InfoSolidIcon,
    Popover
  },

  computed: {
    ...mapState('cartSettings', ['shippingEntry', 'deliveryDetails']),
    ...mapGetters('cart', ['hasFreeShipping'])
  }
}
</script>
