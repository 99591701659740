<template>
  <ProductPage v-bind="{ entry, product, tsProduct, addOn }" />
</template>

<script>
import gql from 'graphql-tag';
import { stripLocale } from '@/utils/i18n';
import entryPage, { SeomaticFragment, EntryPageFragment, ShopHomeBreadcrumbFragment } from '@/mixins/entryPage';
import ProductPage, { ProductPageFragment, ProductPageProductFragment } from '@/components/shop/products/ProductPage';
import { productMetaFieldTransformer } from '@/components/shop/products/Product';

export const ProductPageQuery = gql`
  query ProductPageQuery($site: String!, $slug: String!, $uri: String!) {
    entry(section: "products", site: [$site], slug: [$slug]) {
      ...EntryPageFragment
      ...ProductPageFragment
    }
    ...ShopHomeBreadcrumbFragment

    seomatic(site: $site, uri: $uri, asArray: true) {
      ...SeomaticFragment
    }
  }
  ${EntryPageFragment}
  ${ProductPageFragment}
  ${SeomaticFragment}
  ${ShopHomeBreadcrumbFragment}
`;

export const ProductByHandleQuery = gql`
  query ProductByHandleQuery($handle: String!, $lang: LanguageCode!) @inContext(language: $lang) {
    productByHandle(handle: $handle) {
      ...ProductPageProductFragment
    }
  }
  ${ProductPageProductFragment}
`;

export default {
  name: 'ProductPageController',

  components: {
    ProductPage,
  },

  mixins: [entryPage],

  async asyncData({ $shopifyRequest, $craftRequest, $retourRedirect, app, params, route, $productReviews }) {
    const { locale } = app.i18n;
    const uri = stripLocale(route.path, locale);
    const { slug } = params;
    const variables = { site: locale, slug, uri };
    let tsProduct = null;

    // first fetch craft cms data
    const entryRequest = await $craftRequest(ProductPageQuery, variables);
    const { entry, seomatic, shopHomeEntry } = entryRequest;

    if (entry === null) {
      console.warn(`Page with slug: '${slug}' not found on CMS`);
      return $retourRedirect();
    }

    // extract shopify product handle for shopify data request

    // FIXME: for now we stay with using the slug from the url, but we should use the handle from the cms
    // const handle = entryRequest.entry.shopifyHandle
    const handle = slug;

    const product = await $shopifyRequest(ProductByHandleQuery, { handle, lang: locale }).then((data) => {
      return data.productByHandle;
    });

    if (product === null) {
      console.warn(`Product with handle: '${handle}' not found on shopify`);
      return $retourRedirect();
    }

    const transformedProduct = productMetaFieldTransformer(product);

    // we fetch the shopify addon product, since we need it if we want to add it to the cart
    // since we load it here, we can pass the info down all the components where it is needed
    let addOnProduct = null;
    if (transformedProduct.digitalAddOn && transformedProduct.digitalAddOn.digitalAddOnHandle) {
      addOnProduct = productMetaFieldTransformer(
        (
          await $shopifyRequest(ProductByHandleQuery, {
            handle: transformedProduct.digitalAddOn.digitalAddOnHandle,
            lang: locale,
          })
        ).productByHandle
      );
    }

    const sku = product?.variants?.edges && product?.variants?.edges[0] ? product?.variants?.edges[0].node.sku : null;
    if (sku) {
      tsProduct = await $productReviews.fetchReviews(sku);
    }
    return { product: transformedProduct, addOn: addOnProduct, entry, seomatic, shopHomeEntry, tsProduct };
  },

  data() {
    return {
      product: null,
      shopHomeEntry: null,
    };
  },

  computed: {
    breadcrumbCollection() {
      return this.entry?.breadcrumbCollection[0] || null;
    },
    firstProductImage() {
      try {
        return this.product?.images?.edges[0]?.node?.src;
      } catch {
        return null;
      }
    },
  },

  mounted() {
    this.setBreadCrumb();
    this.gtmPushPageMeta({
      content_category: 'shop',
      product_category: this.breadcrumbCollection?.collectionHandle,
      image_url: this.firstProductImage,
    });
  },

  methods: {
    /**
     * adds shop-home, defaul-collection , product to the breadcrumb
     */
    buildBreadCrumb() {
      const breadCrumb = [];
      if (this.shopHomeEntry)
        breadCrumb.push({
          label: this.shopHomeEntry.title,
          route: this.$localeRootPath(this.shopHomeEntry.uri),
        });
      if (this.entry) {
        if (this.breadcrumbCollection)
          breadCrumb.push({
            label: this.breadcrumbCollection.title,
            route: this.$localeRootPath(this.breadcrumbCollection.uri),
          });
        breadCrumb.push({
          label: this.entry.title,
          route: this.$localeRootPath(this.entry.uri),
        });
      }
      return breadCrumb;
    },
  },
};
</script>
