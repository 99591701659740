<template>
  <div v-if="youtubeId" class="sm:bg-putty sm:py-1">
    <div class="sm:container sm:max-w-screen-lg my-l">
      <div class="ratio-wrapper relative w-full">
        <iframe
          loading="lazy"
          :src="`https://www.youtube.com/embed/${youtubeId}?rel=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Fshop.blackroll.com`"
          width="640"
          height="360"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          class="absolute inset-0 w-full h-full"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    youtubeId: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped>
.ratio-wrapper {
  padding-bottom: calc(9 / 16 * 100%);
}
</style>
