<template>
  <div class="scroll-mt-8 lg:scroll-mt-16">
    <div class="relative overflow-hidden transition-all" :aria-expanded="open" role="region" :style="styleRoot">
      <div ref="content" class="w-full transition-all" :style="styleChild">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    minimalHeight: {
      type: Number,
      default: 0,
      validator: (value) => value >= 0,
    },
    duration: {
      type: Number,
      default: 300,
      validator: (value) => value >= 0,
    },
    useOpacity: {
      type: Boolean,
      default: true,
    },
    scrollIntoView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAnimating: false,
    };
  },
  computed: {
    styleRoot() {
      const obj = {
        transitionDuration: `${this.duration}ms`,
      };
      if (this.isAnimating) {
        obj.height = this.$refs.content.getBoundingClientRect().height + 'px';
      } else if (this.open) {
        obj.height = 'auto';
      } else {
        obj.height = this.minimalHeight + 'px';
      }
      return obj;
    },
    styleChild() {
      const obj = {
        transitionDuration: `${this.duration}ms`,
      };
      if (this.isAnimating) {
        obj.position = 'absolute';
      }
      if (!this.open && this.useOpacity) {
        obj.opacity = 0;
        obj.position = 'absolute';
        obj.visibility = 'hidden';
      }
      return obj;
    },
  },
  watch: {
    open(newVal) {
      return newVal ? this.animatedOpen() : this.animatedClose();
    },
  },
  methods: {
    animatedOpen() {
      this.isAnimating = true;
      if (this.scrollIntoView) {
        this.$el.scrollIntoView({ behavior: 'smooth' });
      }
      setTimeout(() => {
        this.isAnimating = false;
      }, this.duration);
    },
    animatedClose() {
      this.isAnimating = true;
      setTimeout(() => {
        this.isAnimating = false;
      }, 20); // Weird number to let safari do rerender. It should be something like $nextTick
    },
  },
};
</script>
