<template>
  <div>
    <div
      v-if="productType"
      class="sm:px-1 sm:py-0.5 fluid-xs leading-tightest font-medium sm:border sm:border-black uppercase relative bg-white inline-block"
      :class="[paddingMobile ? 'px-1 py-0.5' : '', borderMobile ? ' border border-black' : '']"
      data-test-id="product-type"
    >
      <span class="sm:block" :class="textMobile ? 'block' : 'hidden'">{{ productType }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductTypeBadge',

  props: {
    productType: {
      type: String,
      default: null,
    },
    textMobile: {
      type: Boolean,
      default: false,
    },
    paddingMobile: {
      type: Boolean,
      default: false,
    },
    borderMobile: {
      type: Boolean,
      default: false,
    },
    inlineWithName: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
