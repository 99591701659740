/**
 *
 * @param {Sting} metaTagContainerJson - craft seomatic metaTagContainer Json
 * @returns {Array} of metafield Objects with meta-field attributes
 */
export const metaTagsTransformer = (metaTagContainerJson) => {
  if (typeof metaTagContainerJson !== 'string') return [];

  const metas = JSON.parse(metaTagContainerJson);
  return Object.entries(metas).reduce((acc, [hid, value]) => {
    // remove empty fields identified by empty array
    if (Array.isArray(value) && value.length === 0) return acc;
    // reduce array fields
    if (Array.isArray(value)) {
      return acc.concat(value.map((val, index) => ({ ...val, hid: `${hid}-${index}` })));
    }

    return acc.concat({ ...value, hid });
  }, []);
};

/**
 *
 * @param {string} metaLinkContainerJson - craft Seomatic link tags
 * @returns {array} - array of objects with link-tag attributes
 */
export const metaLinkTransformer = (metaLinkContainerJson) => {
  if (typeof metaLinkContainerJson !== 'string') return [];

  const metas = JSON.parse(metaLinkContainerJson);
  return Object.entries(metas).reduce((acc, [rel, value]) => {
    // ignore author tag
    if (rel === 'author') return acc;
    // remove empty fields identified by empty array
    if (Array.isArray(value) && value.length === 0) return acc;
    // reduce array fields
    if (Array.isArray(value)) {
      return acc.concat(value.map((val, index) => ({ ...val, rel })));
    }

    return acc.concat({ ...value, rel });
  }, []);
};

export const metaTitleTransformer = (metaTitleContainerJson) => {
  if (typeof metaTitleContainerJson !== 'string') return null;
  return JSON.parse(metaTitleContainerJson)?.title?.title;
};

export const metaJsonLdTransformer = (metaJsonLdContainer, context = null) => {
  if (typeof metaJsonLdContainer !== 'string') return [];

  const schema = JSON.parse(metaJsonLdContainer);

  schema['@context'] = 'https://schema.org';
  schema['@type'] = 'WebSite';

  // overwrite author with exprt
  try {
    if (context && context.entry?.expert && context.entry.expert.length > 0) {
      const expert = context.entry.expert[0];

      schema.mainEntityOfPage.author = {
        '@type': 'Person',
        name: expert?.title,
        url: 'https://blackroll.com' + context.$localeRootPath(expert.uri),
      };
    }
  } catch (e) {
    console.log(e);
  }

  try {
    delete schema.identity;
    delete schema.breadcrumbList;
  } catch (e) {
    console.log(e);
  }

  return [
    {
      hid: 'seomatic-json-ld',
      type: 'application/ld+json',
      innerHTML: JSON.stringify(schema),
    },
  ];
};

/**
 *
 * @param {object} seomatic - carft gql seomatic partical data
 * @returns {object} - nuxt head fromated seomatic data
 */
export const seomaticHead = (context) => {
  const head = {};

  head.script = metaJsonLdTransformer(context.seomatic?.metaJsonLdContainer, context);
  head.meta = metaTagsTransformer(context.seomatic?.metaTagContainer);
  head.link = metaLinkTransformer(context.seomatic?.metaLinkContainer);
  const title = metaTitleTransformer(context.seomatic?.metaTitleContainer);

  if (title) head.title = title;

  return head;
};
