<template>
  <div
    class="shadow-lg bg-white px-6 py-3 inline-block fluid-xs leading-tightest space-y-1 font-light w-full sm:w-auto"
  >
    <div v-for="item in visibleBenefits" :key="item.text" class="whitespace-nowrap flex items-center h-6">
      <img class="inline-block w-5 mr-2" :src="item.icon[0].url" :alt="item.icon[0].alt" />
      <span class="inline-block tracking-widest whitespace-normal">{{ item.text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    benefits: {
      type: Array,
      default: () => [],
    },
    showMadeInGermany: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    visibleBenefits() {
      return this.benefits.filter((benefit) => {
        if (benefit.isMadeInGermany && !this.showMadeInGermany) {
          return false;
        }

        return true;
      });
    },
  },
};
</script>
