import { validObjectId } from '@/utils/algolia';
import { transformSiteVariable } from '@/plugins/craftClient';

export default function ({ env, app }, inject) {
  const algoliaWorkouts = {
    /**
     * fetches exercise from algolia
     * validates format of objectID - throws TypeError on wrong format
     * throws Error on 404
     *
     * @param {string} objectID
     * @returns {Promise} - object of algoila exercise
     */
    fetchExerciseById(objectID) {
      if (!validObjectId(objectID)) throw new TypeError('argument is not a valid algolia objectId');

      const locale = transformSiteVariable({ site: app.i18n.locale }).site;

      const { algoliaApplicationId, algoliaSearchApiKey } = env;
      const indexName = `feg_exercise_${locale}`;
      const url = `https://${algoliaApplicationId}-dsn.algolia.net/1/indexes/${indexName}/${objectID}?attributesToRetrieve=*`;

      return fetch(url, {
        headers: {
          'X-Algolia-API-Key': algoliaSearchApiKey,
          'X-Algolia-Application-Id': algoliaApplicationId,
        },
      })
        .then((res) => {
          if (!res.ok)
            throw new Error(
              `fetchExerciseById msg: ${res?.statusText}, algoliaApplicationId: ${algoliaApplicationId}, index: ${indexName}, objectId: ${objectID}`
            );
          return res;
        })
        .then((res) => res.json());
    },
    fetchRoutineById(objectID) {
      if (!validObjectId(objectID)) throw new TypeError('argument is not a valid algolia objectId');

      const locale = transformSiteVariable({ site: app.i18n.locale }).site;
      const { algoliaApplicationId, algoliaSearchApiKey } = env;
      const indexName = `routine_${locale}`;
      const url = `https://${algoliaApplicationId}-dsn.algolia.net/1/indexes/${indexName}/${objectID}`;

      return fetch(url, {
        headers: {
          'X-Algolia-API-Key': algoliaSearchApiKey,
          'X-Algolia-Application-Id': algoliaApplicationId,
        },
      })
        .then((res) => {
          if (!res.ok)
            throw new Error(
              `fetchExerciseById msg: ${res?.statusText}, algoliaApplicationId: ${algoliaApplicationId}, index: ${indexName}, objectId: ${objectID}`
            );
          return res;
        })
        .then((res) => res.json());
    },
    fetchFegWorkoutById(objectID) {
      if (!validObjectId(objectID)) throw new TypeError('argument is not a valid algolia objectId');

      const locale = transformSiteVariable({ site: app.i18n.locale }).site;
      const { algoliaApplicationId, algoliaSearchApiKey } = env;
      const indexName = `feg_workout_${locale}`;
      const url = `https://${algoliaApplicationId}-dsn.algolia.net/1/indexes/${indexName}/${objectID}`;

      return fetch(url, {
        headers: {
          'X-Algolia-API-Key': algoliaSearchApiKey,
          'X-Algolia-Application-Id': algoliaApplicationId,
        },
      })
        .then((res) => {
          if (!res.ok)
            throw new Error(
              `fetchFegWorkoutById msg: ${res?.statusText}, algoliaApplicationId: ${algoliaApplicationId}, index: ${indexName}, objectId: ${objectID}`
            );
          return res;
        })
        .then((res) => res.json());
    },
    fetchCourseById(objectID) {
      if (!validObjectId(objectID)) throw new TypeError('argument is not a valid algolia objectId');

      const locale = transformSiteVariable({ site: app.i18n.locale }).site;
      const { algoliaApplicationId, algoliaSearchApiKey } = env;
      const indexName = `course_contents_learnworlds_${locale}`;
      const url = `https://${algoliaApplicationId}-dsn.algolia.net/1/indexes/${indexName}/${objectID}`;

      return fetch(url, {
        headers: {
          'X-Algolia-API-Key': algoliaSearchApiKey,
          'X-Algolia-Application-Id': algoliaApplicationId,
        },
      })
        .then((res) => {
          if (!res.ok)
            throw new Error(
              `fetchFegWorkoutById msg: ${res?.statusText}, algoliaApplicationId: ${algoliaApplicationId}, index: ${indexName}, objectId: ${objectID}`
            );
          return res;
        })
        .then((res) => res.json());
    },
  };
  inject('algoliaWorkouts', algoliaWorkouts);
}
