<template>
  <div class="pswp" tabIndex="-1" role="dialog" aria-hidden="true">
    <div class="pswp__bg" />
    <div class="pswp__scroll-wrap">
      <div class="pswp__container">
        <div class="pswp__item" />
        <div class="pswp__item" />
        <div class="pswp__item" />
      </div>
      <div class="pswp__ui pswp__ui--hidden">
        <div class="pswp__top-bar">
          <div class="pswp__counter" />
          <button class="pswp__button pswp__button--close" :title="$t('gallery.close')" />
          <button class="pswp__button pswp__button--fs" :title="$t('gallery.fullscreen')" />
          <button class="pswp__button pswp__button--zoom" :title="$t('gallery.zoom')" />
          <div class="pswp__preloader">
            <div class="pswp__preloader__icn">
              <div class="pswp__preloader__cut">
                <div class="pswp__preloader__donut" />
              </div>
            </div>
          </div>
        </div>
        <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
          <div class="pswp__share-tooltip" />
        </div>
        <button class="pswp__button pswp__button--arrow--left" :title="$t('gallery.left')" />
        <button class="pswp__button pswp__button--arrow--right" :title="$t('gallery.right')" />
        <div class="pswp__caption">
          <div class="pswp__caption__center" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PhotoSwipeFn from 'photoswipe'
import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default.js'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

export const imageList = (imgUrl, images) => {
  if (!images) return []
  const RESOLUTIONS = [480, 768, 1080, 1440, 1920, 2560]
  return images.map((item) => {
    const generatedSrc = RESOLUTIONS.map((resolution) => {
      const src = imgUrl(item.src, {
        width: item.width,
        height: item.height
      })
      return `${src} ${resolution}w`
    }).join(',')
    return { src: generatedSrc, w: item.width, h: item.height, title: item.altText }
  })
}
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    imageIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      options: {
        index: this.imageIndex
      }
    }
  },
  watch: {
    items: {
      handler(val, oldVal) {
        if (this.pswp && this.isOpen) {
          this.pswp.invalidateCurrItems()
          this.pswp.updateSize(true)
        }
      },
      deep: true
    },
    isOpen(val, oldVal) {
      if (val) {
        this.openPhotoSwipe(this.items, this.options)
      } else {
        this.close()
      }
    }
  },
  mounted() {
    this.openPhotoSwipe(this.items, this.options)
  },
  beforeDestroy() {
    this.close()
  },
  methods: {
    close() {
      if (this.pswp) {
        this.$emit('closeModal')
        this.pswp.close()
      }
    },
    openPhotoSwipe(items, options) {
      const events = [
        'beforeChange',
        'afterChange',
        'imageLoadComplete',
        'resize',
        'gettingData',
        'mouseUsed',
        'initialZoomIn',
        'initialZoomInEnd',
        'initialZoomOut',
        'initialZoomOutEnd',
        'parseVerticalMargin',
        'close',
        'unbindEvents',
        'destroy',
        'updateScrollOffset',
        'preventDragEvent',
        'shareLinkClick'
      ]
      const pswpElement = this.$el
      this.pswp = new PhotoSwipeFn(pswpElement, PhotoSwipeUIDefault, items, options)
      events.forEach((e) => {
        this.pswp.listen(e, (...args) => {
          args.unshift(this)
          this.$emit(e, [...args])
        })
      })
      this.pswp.init()
      this.pswp.listen('afterChange', () => {
        this.$emit('changeCorrespondingIndex', this.pswp.getCurrentIndex())
      })
    }
  }
}
</script>
