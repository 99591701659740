<template>
  <LinkField
    class="pr-4 py-4 border-b fluid-sm tracking-wider font-medium w-full flex relative"
    :class="{ 'text-br-red': highlight }"
    v-bind="link"
    :exact-active-class="'text-br-green'"
    @click.native="close"
  >
    {{ label }}
    <sup v-if="isNew" class="text-br-green fluid-xxs uppercase">
      {{ $t('navigation.new') }}
    </sup>
    <div v-if="image.length > 0" class="absolute right-8 inset-y-2 w-14">
      <CldnImg class="w-full h-full object-cover" :src="image[0].url" :alt="image[0].title" sizes="60px" />
    </div>
  </LinkField>
</template>

<script>
import { mapActions } from 'vuex'
import LinkField from '@/components/common/LinkField'
import CldnImg from '@/components/CldnImg'

export default {
  components: { LinkField, CldnImg },
  props: {
    image: {
      type: Array,
      default: () => []
    },
    link: {
      type: Object,
      default: () => {}
    },
    highlight: {
      type: Boolean,
      default: false
    },
    isNew: {
      type: Boolean,
      default: false
    },
    mobileTitle: {
      type: String,
      default: null
    }
  },
  computed: {
    label() {
      if (this.mobileTitle && this.mobileTitle !== '') {
        return this.mobileTitle
      } else {
        return this.link.text && this.link.text !== '' ? this.link.text : this.link.element.title
      }
    }
  },
  methods: {
    ...mapActions('mainNav', ['close'])
  }
}
</script>
