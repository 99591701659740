<template>
  <div
    class="relative flex items-center lg:block flex-shrink-0 lg:w-52 lg:h-52 rounded-full lg:bg-white py-1 lg:py-0 pl-10 lg:pl-0"
  >
    <CircularProgress
      v-if="percentage >= 0"
      :class="[
        isDarkTheme ? 'text-br-green' : 'text-blackly',
        'h-8 w-8 absolute lg:relative lg:h-auto lg:w-auto left-0 mr-4 lg:mr-0'
      ]"
      :progress="percentage"
    />
    <div
      class="lg:absolute inset-0 flex items-center lg:justify-center lg:p-10"
      :class="[isDarkTheme ? 'text-white lg:text-blackly' : 'text-blackly']"
    >
      <span
        :before="beforeLabel"
        :after="afterLabel"
        class="relative before:mr-1 after:ml-1 w-full leading-5 font-medium fluid-3xl leading-tightest lg:block before:absolute lg:before:inset-x-0 before:-top-2 lg:before:-top-4 before:content-[attr(before)] before:fluid-xs leading-tightest lg:before:text-sm lg:before:block lg:before:text-center after:content-[attr(after)] lg:after:absolute after:inset-x-0 after:top-12 after:text-sm lg:after:fluid-xs leading-tightest lg:after:block lg:after:text-center after:leading-3 before:leading-3"
        ><slot></slot
      ></span>
    </div>
  </div>
</template>

<script>
import CircularProgress from '@/components/common/CircularProgress.vue'

export default {
  components: { CircularProgress },

  props: {
    percentage: {
      type: Number,
      default: 0
    },
    isDarkTheme: {
      type: Boolean,
      default: false
    },
    beforeLabel: {
      type: String,
      default: ''
    },
    afterLabel: {
      type: String,
      default: ''
    }
  }
}
</script>
