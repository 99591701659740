<template>
  <div class="relative" :class="{ '2xl:container py-6 lg:py-8': !displayOverflow && isContainer }">
    <div
      v-if="heading || showArrows"
      class="slider-arrows-container lg:items-center lg:pb-6 container flex items-end justify-between pb-4"
    >
      <div
        class="slider-arrows flex flex-wrap w-full items-center"
        :class="{
          'justify-between': heading,
          'justify-start': !heading && arrowsLeft,
          'justify-end': !arrowsLeft,
        }"
      >
        <div
          v-if="heading"
          class="slider-title font-semibold pr-4 fluid-3xl leading-tightest"
          data-test-id="heading"
          v-html="heading"
        ></div>
        <ArrowBtnNav
          v-if="showArrows"
          class="arrow-buttons block w-full mt-5 sm:w-16 sm:mt-0"
          :class="{ hidden: isFirstSlideVisible && isLastSlideVisible, '2xl:hidden': totalItems === 3 }"
          :left-arrow-disabled="isFirstSlideVisible"
          :right-arrow-disabled="isLastSlideVisible"
          :inverted="arrowsInverted"
          data-test-id="arrowNav"
          @onLeftClick="prev()"
          @onRightClick="next()"
        />
      </div>
    </div>
    <div
      ref="slider"
      class="relative flex overflow-x-auto md:overflow-x-hidden overflow-y-hidden"
      :class="{
        '2xl:-mx-4 2xl:container-pl 2xl:container-pr': displayOverflow && !fullwidthSlides,
        'is-snap-enabled': isSnapEnabled,
      }"
    >
      <div ref="trackStart" class="-mr-0.5 track-start" />
      <!-- <div class="auto-cols-[minmax(100vw,auto)] auto-cols-[minmax(90vw,auto)] auto-cols-[minmax(80vw,auto)] auto-cols-[minmax(70vw,auto)] auto-cols-[minmax(60vw,auto)] auto-cols-[minmax(50vw,auto)] auto-cols-[minmax(40vw,auto)] auto-cols-[minmax(30vw,auto)] auto-cols-[minmax(20vw,auto)] auto-cols-[minmax(10vw,auto)]" />-->
      <!-- <div class="md:auto-cols-[minmax(100vw,auto)] md:auto-cols-[minmax(90vw,auto)] md:auto-cols-[minmax(80vw,auto)] md:auto-cols-[minmax(70vw,auto)] md:auto-cols-[minmax(60vw,auto)] md:auto-cols-[minmax(50vw,auto)] md:auto-cols-[minmax(40vw,auto)] md:auto-cols-[minmax(30vw,auto)] md:auto-cols-[minmax(20vw,auto)] md:auto-cols-[minmax(10vw,auto)]" />-->
      <div
        ref="track"
        class="slider-inner-grid grid grid-flow-col"
        :class="[
          {
            '2xl:!px-4': !fullwidthSlides,
            'ml-0.5': fullwidthSlides,
            'pt-4 pb-10': !noPadding,
            'min-h-[200px]': !autoHeight,
          },
          gridAutoClasses,
        ]"
        :style="sliderInnerStyle"
      >
        <slot />
      </div>
      <div ref="trackEnd" class="-ml-0.5 track-end" />
    </div>
    <div ref="sliderContainerReference" :class="[fixedWidth ? 'w-full' : 'container']" />
  </div>
</template>
<script>
import ArrowBtnNav from '@/components/common/ArrowBtnNav';
import debounce from 'lodash/debounce';
import { mapState } from 'vuex';

export default {
  name: 'CardSlider',
  components: {
    ArrowBtnNav,
  },
  props: {
    heading: {
      type: String,
      default: null,
    },
    showArrows: {
      type: Boolean,
      default: true,
    },
    displayOverflow: {
      type: Boolean,
      default: false,
    },
    // fullwidthSlides are used to take the width of the container
    fullwidthSlides: {
      type: Boolean,
      default: false,
    },
    // is true when used in fullwidth component with container classes
    isContainer: {
      type: Boolean,
      default: true,
    },
    arrowsInverted: {
      type: Boolean,
      default: false,
    },
    arrowsLeft: {
      type: Boolean,
      default: false,
    },
    slideGap: {
      type: Number,
      default: 16,
    },
    slideLeveling: {
      type: Boolean,
      default: true,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    fixedWidth: {
      type: Number,
      default: null,
    },
    autoHeight: {
      type: Boolean,
      default: false,
    },
    breakpoints: {
      type: Array,
      default: () => [
        {
          minWindowWidth: 0,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
        {
          minWindowWidth: 700,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
        {
          minWindowWidth: 1000,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      ],
    },
    doAutomaticallyUpdateActiveSlide: {
      type: Boolean,
      default: true,
    },
    numberOfItems: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      isFirstSlideVisible: true,
      isLastSlideVisible: false,
      containerWidth: null,
      observer: null,
      windowWidth: 0,
      isSlidingByClick: false,
      isSnapEnabled: false,
      activeSlide: 0,
    };
  },
  computed: {
    ...mapState('cart', {
      isOpen: (state) => state.isOpen,
    }),
    totalItems() {
      return this.numberOfItems?.length || 0;
    },
    gutterWidth() {
      if (this.fullwidthSlides) return 1;
      if (this.containerWidth * 0.025 > this.slideGap) {
        return this.containerWidth * 0.025;
      } else {
        return this.slideGap;
      }
    },
    currentBreakpoint() {
      return this.breakpoints.filter((item) => this.windowWidth >= item.minWindowWidth).pop();
    },
    slidesToScroll() {
      return this.currentBreakpoint?.slidesToScroll;
    },
    slideWidth() {
      if (this.fixedWidth) {
        return this.fixedWidth;
      }
      if (this.currentBreakpoint?.slidesToShow) {
        if (this.fullwidthSlides) {
          return this.$refs.slider?.getBoundingClientRect()?.width / this.currentBreakpoint?.slidesToShow;
        } else {
          return (
            (this.containerWidth - this.gutterWidth * this.currentBreakpoint?.slidesToShow) /
            this.currentBreakpoint?.slidesToShow
          );
        }
      } else {
        return this.$refs.sliderContainerReference?.getBoundingClientRect()?.width;
      }
    },
    currentContainerWidth() {
      return this.containerWidth;
    },
    columnSize() {
      if (!this.fullwidthSlides && this.currentContainerWidth && this.slideWidth > this.currentContainerWidth) {
        return this.currentContainerWidth;
      } else {
        return this.slideWidth;
      }
    },
    gridAutoClasses() {
      const lowestSlidesToShow = Math.trunc(10 / this.breakpoints[0].slidesToShow) * 10;
      const highestSlidesToShow = Math.trunc(10 / this.breakpoints[this.breakpoints.length - 1].slidesToShow) * 10;
      return `auto-cols-[minmax(${lowestSlidesToShow}vw,auto)] md:auto-cols-[minmax(${highestSlidesToShow}vw,auto)]`;
    },
    sliderInnerStyle() {
      const styles = {
        gridColumnGap: this.gutterWidth + 'px',
        gridAutoColumns: this.columnSize && `minmax(${this.columnSize}px, auto)`,
      };
      if (!this.noPadding) {
        styles.paddingLeft = this.gutterWidth / 2 + 'px';
        styles.paddingRight = this.gutterWidth / 2 + 'px';
      }
      return styles;
    },
  },
  watch: {
    isFirstSlideVisible() {
      this.emitSlideChange();
    },
    isLastSlideVisible() {
      this.emitSlideChange();
    },
    isOpen() {
      this.$nextTick(() => {
        this.setLayout();
      });
    },
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    this.$refs.slider.addEventListener('scroll', this.debounceUpdateActiveSlide);
    window.addEventListener('resize', this.handleWindowResize);
    this.$refs.slider.addEventListener('scroll', this.handleScroll);
    this.setLayout();
    this.$on('prev', this.prev);
    this.$on('next', this.next);
    this.$on('scrollToSlide', this.scrollToSlide);
    this.emitSlideChange();
  },
  destroyed() {
    this.observer?.disconnect();
    window.removeEventListener('resize', this.handleWindowResize);
  },
  updated() {
    this.$nextTick(function () {
      if (!this.observer && this.$refs.track?.childElementCount > 0) this.mountObserver();
    });
  },
  methods: {
    emitSlideChange() {
      this.$emit('change', {
        currentSlide: this.activeSlide,
        isFirst: this.isFirstSlideVisible,
        isLast: this.isLastSlideVisible,
      });
    },
    manuallyUpdateActiveSlide(newActiveSlideIndex) {
      this.activeSlide = newActiveSlideIndex;
      this.emitSlideChange();
    },
    automaticallyUpdateActiveSlide() {
      // we need to check if the slider is still mounted
      if (!this.$refs.slider) return;

      const sliderOffset = this.$refs.slider.scrollLeft;
      const newActiveSlide = Math.round(sliderOffset / (this.slideWidth + this.gutterWidth));
      if (newActiveSlide !== this.activeSlide) {
        this.activeSlide = newActiveSlide;
        this.emitSlideChange();
      }
    },
    debounceSlidingByClick: debounce(function () {
      this.isSlidingByClick = false;
    }, 1000),
    debounceUpdateActiveSlide: debounce(function () {
      if (this.doAutomaticallyUpdateActiveSlide) {
        this.automaticallyUpdateActiveSlide();
        if (this.slideLeveling) {
          this.scrollToSlide(this.activeSlide);
        }
      }
    }, 300),
    handleScroll() {
      if (!this.isSlidingByClick && !this.isSnapEnabled) this.isSnapEnabled = true;
    },
    handleWindowResize() {
      this.setLayout();
      this.windowWidth = window.innerWidth;
    },
    setLayout() {
      const containerRect = this.$refs.sliderContainerReference?.getBoundingClientRect();
      this.containerWidth = containerRect.width;
    },
    scrollSlides(slidesCount) {
      this.isSlidingByClick = true;
      this.isSnapEnabled = false;
      this.$nextTick(() => {
        this.debounceSlidingByClick();

        const refSlider = this.$refs.slider;

        // check if refslider is mounted
        if (!refSlider) {
          return;
        }

        let destination;
        if (slidesCount !== 0) {
          destination = refSlider.scrollLeft + slidesCount * this.slideWidth + slidesCount * this.gutterWidth;
        } else {
          destination = this.activeSlide * this.slideWidth + this.activeSlide * this.gutterWidth;
        }
        refSlider.scrollTo({
          left: destination,
          behavior: 'smooth',
        });
      });
      this.emitSlideChange();
    },
    scrollToSlide(slideIndex) {
      this.manuallyUpdateActiveSlide(slideIndex);
      this.scrollSlides(slideIndex - this.activeSlide);
    },
    prev() {
      if (this.activeSlide > 0) {
        this.manuallyUpdateActiveSlide(this.activeSlide - 1);
        this.scrollSlides(-this.slidesToScroll);
      } else {
        this.scrollSlides(0);
      }
    },
    next() {
      const numberOfSlides = this.$refs.track.childElementCount - 1;
      if (this.activeSlide < numberOfSlides) {
        this.manuallyUpdateActiveSlide(this.activeSlide + 1);
        this.scrollSlides(this.slidesToScroll);
      }
    },
    mountObserver() {
      if (window?.IntersectionObserver) {
        this.observer = new window.IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.target.classList.contains('track-start')) {
                this.isFirstSlideVisible = entry.intersectionRatio === 1;
              }
              if (entry.target.classList.contains('track-end')) {
                this.isLastSlideVisible = entry.intersectionRatio === 1;
              }
            });
          },
          { root: this.$refs.slider, threshold: [0, 1] }
        );
        this.observer.observe(this.$refs.trackStart);
        this.observer.observe(this.$refs.trackEnd);
      }
    },
  },
};
</script>

<style scoped>
.slider-root {
  scroll-padding-left: 6vw;
}

@screen lg {
  .slider-root {
    scroll-padding-left: unset;
  }
}

.slider-root.is-snap-enabled {
  scroll-snap-type: x mandatory;
}
.slider-root::-webkit-scrollbar {
  @apply hidden;
}
.slider-inner-grid {
  @apply grid-flow-col;
  /*grid-auto-columns: 50vw;*/
}

.slider-inner-grid > * {
  scroll-snap-align: start;
}

@screen lg {
  .slider-inner-grid > * {
    scroll-snap-align: center;
  }
}
</style>
