export default function ({ env, app }) {
  const adbq = window.adbq || [];
  const adbs = window.adbs || {};

  adbs.accountId = 'waFAw3';
  adbs.domain = 'blackroll.com';

  adbs.currentPage = new URL(window.location.href);
  adbs.env = adbs.currentPage.searchParams.get('adb_debug') === 'true' ? 'dev' : 'prod';
  adbs.version = adbs.currentPage.searchParams.get('adb_version');
  adbs.hasSessionStorageAccess = () => {
    const test = 'sessionStorageWriteable';
    try {
      sessionStorage.setItem('test', test);
      sessionStorage.removeItem('test');

      return true;
    } catch (e) {
      return false;
    }
  };
  adbs.hasLocalStorageAccess = () => {
    const test = 'localStorageWriteable';
    try {
      localStorage.setItem('test', test);
      localStorage.removeItem('test');

      return true;
    } catch (e) {
      return false;
    }
  };
  adbs.encodeInput = (input) => btoa(encodeURIComponent(JSON.stringify(input)));
  adbs.decodeInput = (input) => JSON.parse(decodeURIComponent(atob(input)));
  adbs.isValidUrl = (url) => {
    try {
      // eslint-disable-next-line no-new
      new URL(url);

      return true;
    } catch (e) {
      return false;
    }
  };
  adbs.retry = 200;
  adbs.load = (a, d, e, v) => {
    const el = document.createElement('script');
    el.type = 'text/javascript';
    el.async = true;
    el.onerror = () => {
      setTimeout(() => {
        adbs.load(adbs.accountId, adbs.domain, adbs.env, adbs.version);

        adbs.retry = adbs.retry * 1.5;
      }, adbs.retry);
    };
    el.src = 'https://bundle.' + d + '/client?env=' + e + '&account_id=' + a + (v ? '&version=' + v : '');
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(el, s);
  };
  adbs.spaListener = (history) => {
    const pushState = history.pushState;
    history.pushState = function (state) {
      if (typeof history.onpushstate === 'function') {
        history.onpushstate({ state });
      }
      const output = pushState.apply(history, arguments);

      adbq.push({
        method: 'track',
        event_name: 'Viewed a page',
        event_type: 'standard',
        event_timestamp: new Date().getTime(),
        page_url: window.location.href,
        referrer_url: adbs.currentReferrer,
        page_title: document.title,
      });

      adbs.currentReferrer = window.location.href;

      return output;
    };
  };

  adbq.push({
    method: 'track',
    event_name: 'Viewed a page',
    event_type: 'standard',
    event_timestamp: new Date().getTime(),
    page_url: adbs.currentPage.href,
    referrer_url: document.referrer,
    page_title: document.title,
  });
  adbs.currentReferrer = adbs.currentPage.href;

  if (adbs.hasLocalStorageAccess()) {
    if (localStorage.getItem('adbq')) {
      const storedQueue = JSON.parse(localStorage.getItem('adbq'));
      storedQueue.map((event) => adbq.push(event));
    }

    localStorage.setItem('adbq', JSON.stringify(adbq));
  } else {
    const storedQueue = adbs.currentPage.searchParams.get('adbq');
    if (storedQueue) {
      adbs.decodeInput(storedQueue).map((event) => adbq.push(event));
      adbs.currentPage.searchParams.delete('adbq');
      window.history.replaceState({}, document.title, adbs.currentPage.href);
    }

    document.querySelectorAll('a').forEach((link) => {
      let currentLink = link.getAttribute('href');
      if (adbs.isValidUrl(currentLink)) {
        currentLink = new URL(link.getAttribute('href'));
        currentLink.searchParams.set('adbq', adbs.encodeInput(adbq));
        link.setAttribute('href', currentLink.href);
      }
    });
  }

  window.adbq = adbq;
  window.adbs = adbs;

  adbs.spaListener(window.history);
  adbs.load(adbs.accountId, adbs.domain, adbs.env, adbs.version);
}
