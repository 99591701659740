<template>
  <div :class="[{ 'mac-os': isMac }]">
    <CountryBar />
    <portal-target name="search-modal" />
    <GlobalMarketingBar />
    <PageHeader />
    <BreadCrumb />
    <GlobalUspBar v-if="$route.matched.some(({ path }) => path.includes('/account')) === false" />
    <div v-if="!isHidden" class="z-40 sticky top-[54px] sm:hidden">
      <Search v-bind="{ showBackdrop: true, showCloseButton: false, isInModal: false }" />
    </div>
    <ToastContainer />
    <MobileDrawer />
    <nuxt />
    <portal-target name="modal" />
    <CartDrawer />
    <ScreenIndicator />
    <PageFooter />
  </div>
</template>

<script>
import CountryBar from '@/components/layout/CountryBar';
import PageHeader from '@/components/layout/header/PageHeader';
import CartDrawer from '@/components/layout/CartDrawer';
import ScreenIndicator from '@/components/layout/ScreenIndicator';
import BreadCrumb from '@/components/layout/BreadCrumb';
import PageFooter from '@/components/layout/footer/PageFooter';
import MobileDrawer from '@/components/layout/header/mobileDrawer/MobileDrawer';
import ToastContainer from '@/components/layout/ToastContainer';
import GlobalUspBar from '@/components/layout/GlobalUspBar';
import Search from '@/components/search/Search';
import { scrollToSelectorRespectingBars } from '@/utils/common';
import GlobalMarketingBar from '@/components/layout/GlobalMarketingBar';
import { mapGetters } from 'vuex';
import { injectSpeedInsights, computeRoute } from '@vercel/speed-insights';

export default {
  components: {
    BreadCrumb,
    CartDrawer,
    CountryBar,
    GlobalMarketingBar,
    GlobalUspBar,
    MobileDrawer,
    PageFooter,
    PageHeader,
    ScreenIndicator,
    Search,
    ToastContainer,
  },
  data() {
    return {
      isMac: false,
      spi: null,
      loadedLocale: false,
    };
  },
  /**
   * initialize store for main nav + global marketing bar
   * to prevent race conditions in nav components
   */
  async fetch() {
    await this.$store.dispatch('app/initShell');
    this.loadedLocale = this.$i18n.locale;
  },

  computed: {
    ...mapGetters('search', ['isHidden']),
  },

  watch: {
    async $route() {
      if (this.loadedLocale !== this.$i18n.locale) {
        await this.$fetch();
      }
      this.onRouteComplete();
    },
  },

  created() {
    this.spi = injectSpeedInsights({ sampleRate: 0.1, framework: 'nuxt' });

    if (this.$route && this.spi) {
      this.changeRoute();
    }
  },

  mounted() {
    this.onRouteComplete();
    this.checkIfMac();
  },

  methods: {
    changeRoute() {
      this.spi.setRoute(computeRoute(this.$route.path, this.$route.params));
    },

    checkIfMac() {
      // should be computed but doesn't re-trigger when detects browser type
      this.isMac = process.browser && navigator.userAgent?.indexOf('Mac OS X') !== -1;
    },
    onRouteComplete() {
      this.$router.onReady(() => {
        this.$nextTick(function () {
          const hash = this.$route.hash;
          if (!hash) return;
          setTimeout(() => {
            scrollToSelectorRespectingBars(hash);
          }, 2000);
        });
      });
    },
  },
};
</script>
