<template>
  <CardSlider v-bind="$props" :items="products" :breakpoints="breakpoints">
    <slot />
    <ProductCard
      v-for="(product, index) in products"
      :key="index"
      v-track:view.item="gtmData(product, index)"
      v-track:click.item="gtmData(product, index)"
      :product="product"
    />
  </CardSlider>
</template>
<script>
import CardSlider from '@/components/common/CardSlider';
import ProductCard from '@/components/shop/products/ProductCard';

export default {
  name: 'ProductCardSlider',

  components: { CardSlider, ProductCard },

  props: {
    products: {
      type: Array,
      default: () => [],
    },
    heading: {
      type: String,
      default: null,
    },
    blockPosition: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      breakpoints: [
        {
          minWindowWidth: 0,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
        {
          minWindowWidth: 500,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
        {
          minWindowWidth: 1000,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      ],
    };
  },

  methods: {
    gtmData(payload, index) {
      try {
        const variant = payload.variants?.edges[0].node;

        const itemSKU = variant.sku;

        return {
          item_list_id: [this.$route?.name, this.heading.toLowerCase().replace(/ /g, '-')].join(' / '),
          item_list_name: [this.$route?.name, this.heading].join(' / '),
          index,
          item_id: itemSKU,
          price: variant.price.amount,
          currency: variant.price.currency,
          item_category: payload.productType,
          item_name: payload.title,
        };
      } catch (e) {
        return {};
      }
    },
  },
};
</script>
