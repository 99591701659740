<template>
  <div v-if="hasPhysicalProduct">
    <div>
      <div class="relative grid" :class="freeGiftThreshold && freeShippingThreshold ? 'grid-cols-5' : 'grid-cols-3'">
        <CartStep
          v-if="freeShippingThreshold"
          class="col-span-3"
          :label="$t('cart.freeShipping')"
          type="delivery"
          :threshold="freeShippingThreshold"
          :amount-in-cart="amountInCart"
        />

        <CartStep
          v-if="freeGiftThreshold"
          :class="freeShippingThreshold ? 'col-span-2' : 'col-span-3'"
          :label="$t('cart.freeGift')"
          type="gift"
          :starting-point="freeShippingThreshold"
          :threshold="freeGiftThreshold"
          :amount-in-cart="amountInCart"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CartStep from './CartStep';

export default {
  name: 'CartProgress',

  components: { CartStep },

  data() {
    return {
      freeGiftThreshold: 0,
    };
  },

  computed: {
    ...mapState('cart', ['checkout', 'freeShippingThreshold']),

    hasPhysicalProduct() {
      try {
        return this.checkout.lineItems.some((item) => {
          return item.attributes.some((attr) => {
            return attr.key === '_isCourse' && attr.value === 'false';
          });
        });
      } catch (e) {
        return false;
      }
    },

    amountInCart() {
      try {
        return Number.parseFloat(this.checkout.lineItemsSubtotalPrice?.amount) ?? 0;
      } catch (e) {
        return 0;
      }
    },
  },
};
</script>
