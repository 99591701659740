export class AddressDetailsModel {
  constructor(addressData) {
    this.firstName = addressData.firstName;
    this.lastName = addressData.lastName;
    this.fullName = addressData.firstName + ' ' + addressData.lastName;
    this.address = addressData.address;
    this.address2 = addressData.address2;
    this.company = addressData.company;
    this.city = addressData.city;
    this.zipcode = addressData.zipcode;
    this.country = addressData.country;
  }
}

export class OrderModel {
  constructor(order) {
    this.orderNumber = order.orderNumber;
    this.date = order.date;
    this.shortDate = this.getShortDate(order.date);
    this.totalPrice = order.totalPrice;
    this.trackingUrl = order.trackingUrl;
    this.paymentDetails = order.paymentDetails;
    this.paymentStatus = order.paymentStatus;
    this.deliveryStatus = order.deliveryStatus;
    this.variantThumbnail = order.variantThumbnail;
    this.orderItems = order.orderItems;
    this.deliveryAddress = order.deliveryAddress ? new AddressDetailsModel(order.deliveryAddress) : null;
    this.billingAddress = order.billingAddress ? new AddressDetailsModel(order.billingAddress) : null;
    this.orderDetails = order.orderDetails;
    this.subtotalPrice = order.subtotalPrice;
    this.includedShipping = order.includedShipping;
    this.includedDiscounts = order.includedDiscounts;
    this.totalAmountRefunded = order.totalAmountRefunded;
  }

  getShortDate(longDate) {
    const date = new Date(longDate);
    const [day, month, year] = [date.getDate(), date.getMonth(), date.getFullYear()];
    return `${day}/${month + 1}/${year.toString().slice(-2)}`;
  }

  totalOrderItems() {
    return this.orderItems.reduce((prev, curr) => prev + curr.quantity, 0);
  }

  toJSON() {
    return { ...this }; // here I make a POJO's copy of the class instance
  }
}
