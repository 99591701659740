<template>
  <div :class="{ 'bg-br-green-light': backgroundColorGreen }">
    <div class="md:px-8 md:pt-20 lg:container px-3 pb-14 md:pb-24 pt-10">
      <div
        v-if="heading"
        class="fluid-xs leading-tightest font-medium text-blackly lg:mb-14 mb-10 text-center uppercase"
      >
        {{ heading }}
      </div>
      <div class="gap-x-10 gap-y-6 md:grid-cols-4 md:px-24 grid grid-cols-2">
        <div
          v-for="(logo, index) in asSeenInLogos"
          :key="index"
          class="align-center xl:px-10 flex justify-center px-4"
          data-test-id="logo"
        >
          <CldnImg
            v-if="logo.image[0]"
            :src="logo.image[0].url"
            :alt="logo.alt ? logo.alt : logo.image[0].title"
            width="150"
            crop="fit"
            class="object-contain w-full"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CldnImg from '@/components/CldnImg';

export default {
  components: {
    CldnImg,
  },
  props: {
    heading: {
      type: String,
      default: null,
    },
    backgroundColorGreen: {
      type: Boolean,
      default: false,
    },
    asSeenInLogos: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
