<template>
  <div class="flex flex-col">
    <div
      class="flex w-full pt-3 space-x-2 border-gray-400"
      :class="registerCtaTop ? 'order-1 border-b pb-3 justify-center' : 'order-2  border-t justify-between'"
    >
      <p class="fluid-sm leading-tight block">{{ $t('account.modal.noAccount') }}</p>
      <nuxt-link
        class="fluid-sm leading-tight block font-medium text-black border-b border-black"
        :to="$localeRootPath('/account/register')"
        @click.native="handleRegisterClick"
        >{{ $t('account.modal.registerNow') }}</nuxt-link
      >
    </div>

    <form
      class="flex flex-col items-center"
      :class="registerCtaTop ? 'order-2' : 'order-1'"
      @submit.prevent="handleLogin"
    >
      <h3 class="fluid-xl leading-tight font-medium mt-10 mb-6 text-center">
        <slot>{{ $t('account.login') }}</slot>
      </h3>

      <FormTextInput v-model="form.email" label="Email adress" :error="form.errors.email" />

      <FormTextInput v-model="form.password" label="Password" type="password" :error="form.errors.password" />

      <GenericFormError v-if="form.errors.message">
        {{ form.errors.message }}
      </GenericFormError>

      <nuxt-link
        v-if="forgotPasswordOverButton"
        :to="$localeRootPath('/account/reset')"
        class="fluid-sm leading-tight h-4 font-medium text-black border-b border-black"
        >{{ $t('account.modal.forgotPassword') }}</nuxt-link
      >

      <FormButton class="self-center" :is-loading="form.processing">{{ $t('account.login') }}</FormButton>

      <div v-if="!forgotPasswordOverButton" class="space-x-2">
        <span class="fluid-sm leading-tight">
          {{ $t('account.modal.forgotPassword') }}
        </span>
        <nuxt-link
          :to="$localeRootPath('/account/reset')"
          class="fluid-sm leading-tight font-medium text-black border-b border-black"
          >{{ $t('account.modal.clickHere') }}</nuxt-link
        >
      </div>
    </form>
  </div>
</template>

<script>
import * as yup from 'yup';
import FormButton from '@/components/account/FormButton';
import FormTextInput from '@/components/account/FormTextInput';
import GenericFormError from '@/components/account/GenericFormError';
import form from '@/utils/form';

import { mapToBasicLocale } from '@/utils/i18n';

export default {
  name: 'LoginForm',

  components: { FormButton, FormTextInput, GenericFormError },

  props: {
    registerCtaTop: {
      type: Boolean,
      default: false,
    },
    forgotPasswordOverButton: {
      type: Boolean,
      default: false,
    },
    fromUri: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      form: form(
        {
          email: '',
          password: '',
          languageCode: mapToBasicLocale(this.$i18n.locale),
        },
        yup.object().shape({
          email: yup
            .string()
            .required(this.$t('account.fields.email.required'))
            .email(this.$t('account.fields.email.email')),
          password: yup.string().required(this.$t('account.fields.password.required')),
        })
      ),
    };
  },

  created() {
    const prefilledEmail = this.$route?.query?.email;

    if (prefilledEmail?.length > 0) {
      this.form.email = prefilledEmail;
    }
  },

  methods: {
    handleRegisterClick() {
      const clickData = {
        event: 'gaEvent',
        event_name: 'sign_up_click',
        sign_up_click: {
          event_category: 'Account',
          event_action: 'Sign Up',
          event_label: 'Click',
        },
      };
      this.$_gtm.push(clickData);
    },

    handleLogin() {
      if (this.fromUri) {
        window.localStorage.setItem('fromUri', this.fromUri);
      }

      this.form
        .validate()
        .then(() => {
          this.form.submit(this.$auth.loginWith('oneworld', { data: this.form.data() })).then(() => {
            if (this.form.wasSuccessful) {
              this.$emit('success');
              this.$_gtm.push({
                event: 'gaEvent',
                event_name: 'login',
                login: {
                  event_category: 'Account',
                  event_action: 'Login',
                  event_label: 'Success',
                },
              });
            } else {
              this.$_gtm.push({
                event: 'gaEvent',
                event_name: 'login_fail',
                login_fail: {
                  event_category: 'Account',
                  event_action: 'Login',
                  event_label: 'Fail',
                },
              });
            }
          });
        })
        .catch((errors) => {
          console.warn({ errors });
        });
    },
  },
};
</script>
