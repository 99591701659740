var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isDev)?_c('div',{staticClass:"fixed bottom-0 left-0 z-50 flex px-6 py-2 space-x-2 fluid-xs leading-tightest text-gray-600 bg-white"},[_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),(_vm.isCachingProxy)?_c('div',{staticClass:"block fluid-xs leading-tightest font-bold text-gray-500"},[_vm._v("\n    json-caching-proxy active\n  ")]):_vm._e()]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sm:hidden block"},[_c('span',{staticClass:"font-bold"},[_vm._v("XS")]),_vm._v(" "),_c('span',{staticClass:"fluid-xs leading-tightest"},[_vm._v("< 640px")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sm:block md:hidden hidden"},[_c('span',{staticClass:"font-bold"},[_vm._v("SM")]),_vm._v(" "),_c('span',{staticClass:"fluid-xs leading-tightest"},[_vm._v("< 768px")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:block lg:hidden hidden"},[_c('span',{staticClass:"font-bold"},[_vm._v("MD")]),_vm._v(" "),_c('span',{staticClass:"fluid-xs leading-tightest"},[_vm._v("< 1024px")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lg:block xl:hidden hidden"},[_c('span',{staticClass:"font-bold"},[_vm._v("LG")]),_vm._v(" "),_c('span',{staticClass:"fluid-xs leading-tightest"},[_vm._v("< 1280px")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"xl:block 2xl:hidden hidden"},[_c('span',{staticClass:"font-bold"},[_vm._v("XL")]),_vm._v(" "),_c('span',{staticClass:"fluid-xs leading-tightest"},[_vm._v("> 1280px")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"2xl:block hidden"},[_c('span',{staticClass:"font-bold"},[_vm._v("2XL")]),_vm._v(" "),_c('span',{staticClass:"fluid-xs leading-tightest"},[_vm._v("> 1440px")])])
}]

export { render, staticRenderFns }