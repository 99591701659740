<template>
  <ProductBlocks v-bind="{ entry, product, tsProduct, addOn }" />
</template>

<script>
import gql from 'graphql-tag';
import ProductBlocks, { ProductBlocksMatrixFragment } from '@/components/shop/products/ProductBlocks';
import { VariantFragment } from '@/components/shop/products/Variant.js';
import {
  productCardHeaderFragment,
  productAccordionFragment,
  productTestimonialFragment,
  productVideoFragment,
  productFlagsFragment,
  productOptionSwatchesFragment,
  productBadgesFragment,
  productRelatedProductsFragment,
  productKeyFeaturesFragment,
  productBundleProductFragment,
  productComparisonFragment,
  productDigitalAddOnFragment,
  digitalProductTypeFragment,
  subtitleFragment,
} from '@/components/shop/products/Product.js';

const MAX_PRODUCT_VARIANTS = 99;

export const ProductPageFragment = gql`
  fragment ProductPageFragment on products_products_Entry {
    shopifyHandle
    agreementCategory {
      ... on preAssessment_preAssessment_Entry {
        title
        agreementBody
        agreementLabel
        disagreementLabel
        confirmButtonLabel
        cancelButtonLabel
      }
    }
    breadcrumbCollection {
      title
      uri
      ... on collections_collections_Entry {
        collectionHandle
      }
    }

    exercisePage {
      uri
    }

    productBlocks {
      ...ProductBlocksMatrixFragment
    }
  }
  ${ProductBlocksMatrixFragment}
`;

export const ProductPageProductFragment = gql`
  fragment ProductPageProductFragment on Product {
    id
    handle
    availableForSale
    descriptionHtml
    productType
    title
    tags

    options {
      name
      values
    }
    images(first: ${MAX_PRODUCT_VARIANTS}) {
      edges {
        node {
          id
          src: originalSrc
          altText
          width
          height
        }
      }
    }
    variants(first: ${MAX_PRODUCT_VARIANTS}) {
      edges {
        node {
          ...VariantFragment
        }
      }
    }
    ...productCardHeaderFragment
    ...productAccordionFragment
    ...productTestimonialFragment
    ...productVideoFragment
    ...productFlagsFragment
    ...productOptionSwatchesFragment
    ...productBadgesFragment
    ...productRelatedProductsFragment
    ...productKeyFeaturesFragment
    ...productBundleProductFragment
    ...productComparisonFragment
    ...productDigitalAddOnFragment
    ...digitalProductTypeFragment
    ...subtitleFragment
  }
  ${VariantFragment}
  ${productCardHeaderFragment}
  ${productAccordionFragment}
  ${productTestimonialFragment}
  ${productVideoFragment}
  ${productFlagsFragment}
  ${productOptionSwatchesFragment}
  ${productBadgesFragment}
  ${productRelatedProductsFragment}
  ${productKeyFeaturesFragment}
  ${productBundleProductFragment}
  ${productComparisonFragment}
  ${productDigitalAddOnFragment}
  ${digitalProductTypeFragment}
  ${subtitleFragment}
`;

export default {
  name: 'ProductPage',
  components: {
    ProductBlocks,
  },
  props: {
    entry: {
      type: Object,
      default: () => null,
    },
    product: {
      type: Object,
      default: () => null,
    },
    addOn: {
      type: Object,
      default: null,
    },
    tsProduct: {
      type: Object,
      default: () => null,
    },
  },
};
</script>
