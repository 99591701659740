<template>
  <InlineIconsGrid v-if="methods && methods.length > 0">
    <div v-for="(method, idx) in methods" :key="idx" class="w-12">
      <VisaIcon
        v-if="method === 'visa'"
        class="rounded overflow-hidden"
        src="@/assets/images/icons/payment/visa.svg"
        alt="visa"
      />
      <MastercardIcon
        v-if="method === 'masterCard'"
        class="rounded overflow-hidden"
        src="@/assets/images/icons/payment/mastercard.svg"
        alt="mastercard"
      />
      <AmericanExpressIcon
        v-if="method === 'americanExpress'"
        class="rounded overflow-hidden"
        src="@/assets/images/icons/payment/american-express.svg"
        alt="american express"
      />
      <KlarnaIcon
        v-if="method === 'klarna'"
        class="rounded overflow-hidden"
        src="@/assets/images/icons/payment/klarna.svg"
        alt="klarna"
      />
      <PayPalIcon
        v-if="method === 'paypal'"
        class="rounded overflow-hidden"
        src="@/assets/images/icons/payment/paypal.svg"
        alt="paypal"
      />
      <img
        v-if="method === 'googlePay'"
        class="rounded overflow-hidden"
        src="@/assets/images/icons/payment/google-pay.svg"
        alt="google pay"
      />
      <img
        v-if="method === 'twint'"
        class="rounded overflow-hidden"
        src="@/assets/images/icons/payment/twint.png"
        alt="Twint"
      />
      <img
        v-if="method === 'postfinance'"
        class="rounded overflow-hidden"
        src="@/assets/images/icons/payment/postfinance.png"
        alt="PostFinance"
      />
      <img
        v-if="method === 'applePay'"
        class="rounded overflow-hidden"
        src="@/assets/images/icons/payment/apple-pay.svg"
        alt="apple pay"
      />
      <img
        v-if="method === 'amazonPay'"
        class="rounded overflow-hidden"
        src="@/assets/images/icons/payment/amazon-pay.svg"
        alt="amazon pay"
      />
      <img
        v-if="method === 'sepaLastschrift'"
        class="rounded overflow-hidden"
        src="@/assets/images/icons/payment/sepa-lastschrift.svg"
        alt="sepa lastschrift"
      />
      <img
        v-if="method === 'vorkasse'"
        class="rounded overflow-hidden"
        src="@/assets/images/icons/payment/vorkasse.svg"
        alt="vorkasse"
      />
      <img
        v-if="method === 'mollie'"
        class="rounded overflow-hidden"
        src="@/assets/images/icons/payment/ideal.svg"
        alt="ideal"
      />
      <img
        v-if="method === 'bancontact'"
        class="rounded overflow-hidden"
        src="@/assets/images/icons/payment/bancontact.svg"
        alt="bancontact"
      />
      <img
        v-if="method === 'belfius'"
        class="rounded overflow-hidden"
        src="@/assets/images/icons/payment/belfius.svg"
        alt="belfius"
      />
      <img
        v-if="method === 'sofort'"
        class="rounded overflow-hidden"
        src="@/assets/images/icons/payment/sofortuberweisung.png"
        srcset="
          @/assets/images/icons/payment/sofortuberweisung.png,
          @/assets/images/icons/payment/sofortuberweisung@2x.png 2x,
          @/assets/images/icons/payment/sofortuberweisung@3x.png 3x
        "
        alt="sofortuberweisung"
      />
      <img
        v-if="method === 'paydirect'"
        class="rounded overflow-hidden"
        src="@/assets/images/icons/payment/paydireckt.png"
        srcset="
          @/assets/images/icons/payment/paydireckt.png,
          @/assets/images/icons/payment/paydireckt@2x.png 2x,
          @/assets/images/icons/payment/paydireckt@3x.png 3x
        "
        alt="paydireckt"
      />
      <img
        v-if="method === 'payconiq'"
        class="rounded overflow-hidden"
        src="@/assets/images/icons/payment/payconiq.png"
        srcset="
          @/assets/images/icons/payment/payconiq.png,
          @/assets/images/icons/payment/payconiq@2x.png 2x,
          @/assets/images/icons/payment/payconiq@3x.png 3x
        "
        alt="payconiq"
      />
      <img
        v-if="method === 'kaufAufRechung'"
        class="rounded overflow-hidden"
        src="@/assets/images/icons/payment/kaufaufrechnung.png"
        srcset="
          @/assets/images/icons/payment/kaufaufrechnung.png,
          @/assets/images/icons/payment/kaufaufrechnung@2x.png 2x,
          @/assets/images/icons/payment/kaufaufrechnung@3x.png 3x
        "
        alt="kaufaufrechnung"
      />
      <img
        v-if="method === 'ingHomePay'"
        class="rounded overflow-hidden"
        src="@/assets/images/icons/payment/inghomepay.png"
        srcset="
          @/assets/images/icons/payment/inghomepay.png,
          @/assets/images/icons/payment/inghomepay@2x.png 2x,
          @/assets/images/icons/payment/inghomepay@3x.png 3x
        "
        alt="inghomepay"
      />
      <img
        v-if="method === 'sofortBanking'"
        class="rounded overflow-hidden"
        src="@/assets/images/icons/payment/sofortuberweisung.png"
        srcset="
          @/assets/images/icons/payment/sofortuberweisung.png,
          @/assets/images/icons/payment/sofortuberweisung@2x.png 2x,
          @/assets/images/icons/payment/sofortuberweisung@3x.png 3x
        "
        alt="sofortuberweisung"
      />
    </div>
  </InlineIconsGrid>
</template>

<script>
import PayPalIcon from '@/components/icons/PayPalIcon.vue';
import VisaIcon from '@/components/icons/VisaIcon.vue';
import KlarnaIcon from '@/components/icons/KlarnaIcon.vue';
import MastercardIcon from '@/components/icons/MastercardIcon.vue';
import AmericanExpressIcon from '@/components/icons/AmericanExpressIcon.vue';

const InlineIconsGrid = () => import('@/components/common/InlineIconsGrid');

export default {
  components: {
    InlineIconsGrid,
    PayPalIcon,
    VisaIcon,
    KlarnaIcon,
    MastercardIcon,
    AmericanExpressIcon,
  },
  props: {
    methods: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
