<template>
  <div class="bg-putty flex flex-col items-center w-full max-w-2xl px-10 pt-10 pb-16 text-center">
    <slot class="w-10" />
    <h2 class="fluid-lg leading-none my-8">{{ heading }}</h2>
    <div class="fluid-sm flex flex-wrap justify-center space-x-4 font-medium">
      <nuxt-link :to="$localeRootPath('/')"
        >{{ $t('account.emptyPageMessage.redirects.home') }} <InlineArrowIcon class="inline h-2" />
      </nuxt-link>
      <nuxt-link v-if="secondLink" :to="$localeRootPath(secondLink.path)"
        >{{ secondLink.text }} <InlineArrowIcon class="inline h-2" />
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import InlineArrowIcon from '@/components/icons/InlineArrowIcon'

export default {
  name: 'LoginDisabledMassage',
  components: { InlineArrowIcon },
  props: {
    heading: {
      type: String,
      default: null
    },
    secondLink: {
      type: Object,
      default: () => null
    }
  }
}
</script>
