var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.blocks),function(block,index){return _c('div',{key:index},[(_vm.isVisible(block))?_c(block.typeHandle,_vm._b({tag:"component"},'component',{
        product: _vm.product,
        tsProduct: _vm.tsProduct,
        description: _vm.product.descriptionHtml,
        accordions: _vm.accordions,
        addOn: _vm.addOn,
        youtubeId: _vm.product.video,
        exercisePage: _vm.exercisePage,
        testimonials: _vm.product.testimonial,
        handles: _vm.product.relatedProducts,
        handle: _vm.product.bundleProduct,
        features: _vm.product.keyFeatures,
        agreementCategory: block.agreementCategory ? block.agreementCategory : _vm.agreementCategory,
        blockPosition: index,
        heading: block.typeHandle === 'accordion' ? _vm.$t('shop.accordionHeading') : null,
        backgroundGreen: block.typeHandle === 'accordion',
        ...block,
      },false)):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }