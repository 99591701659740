<template>
  <ProductProvider v-slot="{ product, tsProduct }" :handle="shopifyHandle">
    <ProductReviews :product="product" :ts-product="tsProduct" />
  </ProductProvider>
</template>

<script>
import ProductProvider from '@/components/common/ProductProvider';
import ProductReviews from '@/components/common/blocks/ProductReviews';

export default {
  name: 'CommonProductReviews',
  components: {
    ProductReviews,
    ProductProvider,
  },
  props: {
    shopifyHandle: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped></style>
